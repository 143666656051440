<template>
  <div class="h-100 m-0">
    <div class="w-100 p-0 pb-5">

      <Entete v-if="!byManager" @cancelEdit="cancelEdit" @saveEdit="saveEdit()" />

      <div class="padding-top-1x padding-bottom-1x margin-left-1x margin-right-1x">
        <div class="modal-body text-center">
          <div class="text-start">

            <div v-if="!byManager" class="form-group">
              <label class="form-label text-title">Select the section that you want to add</label>
            </div>

            <template v-for="(component, index) in (byManager ? [] : components)" :key="index">

              <v-switch :case="component.type">

                <template #BioComponent>
                  <router-link v-bind:to="'/bio/edit/' + this.$route.params.link">
                    <card-base>
                      <template v-slot:icon>
                        <SVGText :fill-color="'white'"/>
                      </template>

                      <template v-slot:title>
                        {{ component.title }}
                      </template>

                      <template v-slot:description>
                        {{ component.description }}
                      </template>
                    </card-base>
                  </router-link>
                </template>

                <template #ReseauxSociauxComponent>
                  <router-link v-bind:to="'/social/edit/' + this.$route.params.link">
                    <card-base>
                      <template v-slot:icon>
                        <SVGSocial :fill-color="'white'"/>
                      </template>

                      <template v-slot:title>
                        {{ component.title }}
                      </template>

                      <template v-slot:description>
                        {{ component.description }}
                      </template>
                    </card-base>
                  </router-link>
                </template>

                <template #PhotosComponent>
                  <router-link v-bind:to="'/photo/edit/' + this.$route.params.link">
                    <card-base>
                      <template v-slot:icon>
                        <SVGPhoto :fill-color="'white'"/>
                      </template>

                      <template v-slot:title>
                        {{ component.title }}
                      </template>

                      <template v-slot:description>
                        {{ component.description }}
                      </template>
                    </card-base>
                  </router-link>
                </template>

                <template #VideoComponent>
                  <router-link v-bind:to="'/video/edit/' + this.$route.params.link">
                    <card-base>
                      <template v-slot:icon>
                        <SVGVideo :fill-color="'white'"/>
                      </template>

                      <template v-slot:title>
                        {{ component.title }}
                      </template>

                      <template v-slot:description>
                        {{ component.description }}
                      </template>
                    </card-base>
                  </router-link>
                </template>

                <template #TelechargeComponent>
                  <router-link v-bind:to="'/document/edit/' + this.$route.params.link">
                    <card-base>
                      <template v-slot:icon>
                        <SVGDoc :fill-color="'white'"/>
                      </template>

                      <template v-slot:title>
                        {{ component.title }}
                      </template>

                      <template v-slot:description>
                        {{ component.description }}
                      </template>
                    </card-base>
                  </router-link>
                </template>

                <template #SiteComponent>
                  <router-link v-bind:to="'/site/edit/' + this.$route.params.link">
                    <card-base>
                      <template v-slot:icon>
                        <SVGWeb :fill-color="'white'"/>
                      </template>

                      <template v-slot:title>
                        {{ component.title }}
                      </template>

                      <template v-slot:description>
                        {{ component.description }}
                      </template>
                    </card-base>
                  </router-link>
                </template>

                <template #ContactComponent>
                  <router-link v-bind:to="'/contact/edit/' + this.$route.params.link">
                    <card-base>
                      <template v-slot:icon>
                        <SVGCall :fill-color="'white'"/>
                      </template>

                      <template v-slot:title>
                        {{ component.title }}
                      </template>

                      <template v-slot:description>
                        {{ component.description }}
                      </template>
                    </card-base>
                  </router-link>
                </template>

                <template #CTAComponent>
                  <router-link v-bind:to="'/cta/edit/' + this.$route.params.link">
                    <card-base>
                      <template v-slot:icon>
                        <SVGMouse :fill-color="'white'"/>
                      </template>

                      <template v-slot:title>
                        {{ component.title }}
                      </template>

                      <template v-slot:description>
                        {{ component.description }}
                      </template>
                    </card-base>
                  </router-link>
                </template>

              </v-switch>
            </template>

            <template v-for="(component, index) in (byManager ? components : [])" :key="index">
              <card-base class="cursor-pointer" v-on:click="()=>selectComponentType(component.type)">
                <template v-slot:icon>
                  <component :is="component.icon" :fill-color="'white'"/>
                </template>

                <template v-slot:title>
                  {{ component.title }}
                </template>

                <template v-slot:description>
                  {{ component.description }}
                </template>
              </card-base>
            </template>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Entete from "../../Navigation/nav.enTete.vue";
import CardBase from "./card.base";
import SVGText from "../../../../assets/svgs/app/svg.section-text-big";
import SVGSocial from "../../../../assets/svgs/app/svg.section-facebook-big";
import SVGPhoto from "../../../../assets/svgs/app/svg.section-gallery-big";
import SVGVideo from "../../../../assets/svgs/app/svg.section-video-big";
import SVGDoc from "../../../../assets/svgs/app/svg.section-doc-big";
import SVGWeb from "../../../../assets/svgs/app/svg.section-website-big";
import SVGCall from "../../../../assets/svgs/app/svg.section-call-calling-big";
import SVGMouse from "../../../../assets/svgs/app/svg.section-mouse-big";

export default {
  props: ['byManager'],

  components: {
    Entete,
    CardBase,
    SVGText,
    SVGSocial,
    SVGPhoto,
    SVGVideo,
    SVGDoc,
    SVGWeb,
    SVGCall,
    SVGMouse
  },

  data() {
    return {
      components : [
        {
          type: "BioComponent",
          title: "Text zone",
          icon: "SVGText",
          description: "Create a text zone with unlimited caracters. Talk about yourself, your business, or simply tell us who are you"
        },
        {
          type: "ReseauxSociauxComponent",
          title: "Social Media",
          icon: "SVGSocial",
          description: "Add multiple social media links to share your private of professional life with your coonnections"
        },
        {
          type: "PhotosComponent",
          title: "Pictures",
          icon: "SVGPhoto",
          description: "Share unlimited pictures of your product or services that your company offers, and link them directly to a custom link"
        },
        {
          type: "VideoComponent",
          title: "Videos",
          icon: "SVGVideo",
          description: "Post one embeded YouTube video with your connections to share with them a unique experience of discovering your company"
        },
        {
          type: "TelechargeComponent",
          title: "Documents",
          icon: "SVGDoc",
          description: "Share any documents, such as PDF, pictures, videos, and much more through a direct link with your connections"
        },
        {
          type: "SiteComponent",
          title: "Website links",
          icon: "SVGWeb",
          description: "A landing page, a contact page form, or even a shop page, share as much information as you want through your website link"
        },
        {
          type: "ContactComponent",
          title: "Contact links",
          icon: "SVGCall",
          description: "Create some easy quick access contact links so  your connections can call you, send you a message or a mail within seconds"
        },
        {
          type: "CTAComponent",
          title: "Click-To-Action",
          icon: "SVGMouse",
          description: "Want to increase your conversion rate ? Simply add a CTA to a calendar, a form or your shop to offer your connections a direct action to do"
        },
      ],
    }
  },

  methods : {

    /**
     * Annuler des modifications et redirection routeur
     */
    cancelEdit(){
      this.$router.push('/profil/edit/' + this.$route.params.link);
    },

    /**
     * Enregistrer des modifications
     */
    saveEdit(){
      console.log("save")
    },

    selectComponentType(componentType){
      this.$emit("selectComponentToAdd", componentType);
    },

  },

  computed : {

  },

  mounted(){
    document.querySelector("#app").style.overflow = "unset"
  }

}
</script>

<style scoped>

</style>