<template>
  <template v-if="!isDashboard">
    <login-mobile></login-mobile>
  </template>

  <template v-else>
    <login-pc></login-pc>
  </template>
</template>

<script>
import LoginMobile from "../../admin/Login/login.mobile"
import LoginPc from "../../admin/Login/login.pc"

export default{

  components: {
    LoginMobile,
    LoginPc,
  },

  data() {
    return {
      isDashboard: (location.host.startsWith("dashboard") || (process.env.NODE_ENV === 'development' && process.env.VUE_APP_LOCATION_HOST === 'dashboard')),
    };
  },

  methods : {
  },

  mounted() {
    document.querySelector("#app").style.overflowX = "hidden"
    document.querySelector("#app").style.overflowY = "unset"
  }

}
</script>

<style scoped>

</style>

