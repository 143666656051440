<template>
  <svg width="20" height="21" viewBox="0 0 20 21" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.775 7.16647C17.4333 7.16647 17.15 6.88314 17.15 6.54147V5.0248C17.15 4.41647 16.6583 3.9248 16.05 3.9248H3.94998C3.34164 3.9248 2.84998 4.41647 2.84998 5.0248V6.5498C2.84998 6.89147 2.56664 7.1748 2.22498 7.1748C1.88331 7.1748 1.59998 6.89147 1.59998 6.54147V5.0248C1.59998 3.7248 2.65831 2.6748 3.94998 2.6748H16.05C17.35 2.6748 18.4 3.73314 18.4 5.0248V6.5498C18.4 6.89147 18.125 7.16647 17.775 7.16647Z" />
    <path d="M10 18.4579C9.65833 18.4579 9.375 18.1745 9.375 17.8329V3.99121C9.375 3.64954 9.65833 3.36621 10 3.36621C10.3417 3.36621 10.625 3.64954 10.625 3.99121V17.8329C10.625 18.1829 10.3417 18.4579 10 18.4579Z" />
    <path d="M13.2833 18.458H6.71668C6.37501 18.458 6.09167 18.1747 6.09167 17.833C6.09167 17.4913 6.37501 17.208 6.71668 17.208H13.2833C13.625 17.208 13.9083 17.4913 13.9083 17.833C13.9083 18.1747 13.625 18.458 13.2833 18.458Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>