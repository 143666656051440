<template>
  <div class="text-white">
    <div class="margin-bottom-1x margin-left-1x margin-right-1x">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">

        <div v-bind:style="textColor" class="col text-start p-0 text-black text-title">{{ title }}</div>
        <div :style="onDisplay">
          <router-link v-bind:to="'/photo/edit/' + this.link + '/' + this.id" v-if="!byManager">

            <button-base
              :textColor="'text-white'"
              :bgColor="'bg-blue'"
              class="edit-button "
            >
              <template v-slot:label>
                <SVGFatrows :fillColor="'white'"/>
                Edit
              </template>
            </button-base>

          </router-link>

          <button-base
            v-else
            v-on:click="$emit('editClicked')"
            :textColor="'text-white'"
            :bgColor="'bg-blue'"
            class="edit-button "
            >
            <template v-slot:label>
              <SVGFatrows :fillColor="'white'"/>
              Edit
            </template>
          </button-base>

        </div>
      </div>
    </div>

    <div class="row mx-3 margin-top-0-7x margin-bottom-2x">
      <div class="p-0 miniatures" v-bind:class="'wrapper-' + grillePhotos.length">

        <template v-if="this.datas.length > 4">
          <template v-for="(photo, index) in grillePhotos" :key="index">
            <div v-if="index+1 !== 4" v-on:click="showPopup(index)" :class="miniaturesLayout(index, grillePhotos.length)" class="item-4" v-bind:style="'background: url(' + photo.img + ') no-repeat center center; background-size: cover; '"></div>
            <div v-else v-on:click="showPopup(index)" :class="miniaturesLayout(index, grillePhotos.length)" class="item-4 item-plus" v-bind:style="'background: url(' + photo.img + ') no-repeat center center; background-size: cover; '">
              <div class="content text-center text-white">
                <h2>+{{ this.datas.length - 4 }}</h2>
              </div>
            </div>
          </template>
        </template>

        <template v-else-if="this.datas.length === 4">
          <template v-for="(photo, index) in grillePhotos" :key="index">
            <div v-on:click="showPopup(index)" :class="miniaturesLayout(index, grillePhotos.length)" class="item-4" v-bind:style="'background: url(' + photo.img + ') no-repeat center center; background-size: cover; '"></div>
          </template>
        </template>

        <template v-else-if="this.datas.length < 4">
          <template v-for="(photo, index) in grillePhotos" :key="index">
            <div v-on:click="showPopup(index)" v-bind:class="miniaturesLayout(index, grillePhotos.length) + ' item-' + grillePhotos.length" v-bind:style="'background: url(' + photo.img + ') no-repeat center center; background-size: cover; '"></div>
          </template>
        </template>

      </div>
    </div>

    <!-- Popup -->
    <div ref="popup" class="d-modal-photo-ensemble">
      <div class="popup-photos">

        <div class="w-100 d-flex justify-content-between text-white">
          <div class="d-flex align-items-center popup-photos-title">
            <span class="text-truncate">
              {{ this.datas[this.controlledSwiper?.activeIndex]?.title }}
            </span>
          </div>
          <button  type="button" class="btn icon m-0 p-0 shadow-none">
            <svg v-on:click="hidePopup" xmlns="http://www.w3.org/2000/svg" class="d-flex w-100 h-100" viewBox="0 0 36 36" fill="none">
              <line x1="9.89952" y1="9.89941" x2="25.4559" y2="25.4558" stroke="white" stroke-width="3" stroke-linecap="round"/>
              <line x1="9.89954" y1="25.4558" x2="25.4559" y2="9.89948" stroke="white" stroke-width="3" stroke-linecap="round"/>
            </svg>
          </button>
        </div>

        <div class="">
          <div class="slide-container">
            <!--<a v-on:click="this.plusSlides(-1)" class="prev">&#10094;</a>
            <a v-on:click="this.plusSlides(1)" class="next">&#10095;</a>-->
            <swiper
                @swiper="setControlledSwiper"
                @slideChange="scrollToSlide"
                :slides-per-view="1"
                :space-between="50"
                navigation
                :scrollbar="{ draggable: true }"
                :initialSlide="1"
            ><!-- @reachEnd="onreachEnd" -->

              <swiper-slide
                  v-for="photo, index in this.datas"
                  :key="index"
              >

                <div class="photo" :style="'background-image: url('+ photo.img +');'">
                </div>

              </swiper-slide>

            </swiper>
          </div>
          <template v-if="this.controlledSwiper">
            <div class="text-center mb-1">
              {{ this.controlledSwiper.activeIndex + 1}} / {{ this.datas.length }}
            </div>
            <div class="w-100 d-flex thumbnail-bar">
              <div class="thumbnail"
                v-for="photo, index in this.datas"
                :id="'thumbnail-' + index"
                :key="index"
                :style="'background-image: url('+ photo.img +'); opacity: '+ (index===this.controlledSwiper.activeIndex ? '1' : '0.5') +';'"
                v-on:click="() => {this.controlledSwiper.slideTo(index, 250);}" 
              >
              </div>
            </div>
            <q-button
                v-on:click="discoverImage"
                theme="secondary"
                class="btn p-0 mt-0 mb-0 margin-left-0-5x w-100 bg-blue shadow-none"
            >
              <span class="text-white">
                Discover
              </span>
            </q-button>
          </template>
        </div>
      </div>
    </div>
    
  </div>

</template>

<script>
import ButtonBase from "../../Components/Buttons/button.base"
import SVGFatrows from "../../../../assets/svgs/admin/svg.fatrows";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import { Swiper } from "swiper/vue/swiper";
import { SwiperSlide } from "swiper/vue/swiper-slide";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { ref } from 'vue';
import { createStatistic } from "../../../../services/management/StatisticsService";

SwiperCore.use([Navigation, Pagination, A11y]);


export default {

  props: [ 'byManager', 'id', 'textColor', 'title', 'datas', 'disableModif', 'link' ],

  components: { Swiper, SwiperSlide, ButtonBase, SVGFatrows },

  data() {
    return {
      slideIndex: 0,
      onDisplay: 'display: block;'
    }
  },

  setup() {
      const controlledSwiper = ref(null);
      const setControlledSwiper = (swiper) => {
        controlledSwiper.value = swiper;
      };
      return {
        controlledSwiper,
        setControlledSwiper,
      };
    },

  methods : {
    scrollToSlide(event) {
      console.log(event)
    },

    openInNewTab(url) {
      window.open(url, '_blank', 'noreferrer').focus();
    },

    discoverImage() {
      const photo = this.datas[this.controlledSwiper?.activeIndex];
      if (!photo) return;

      createStatistic({
        type: "click",
        component: this.id,
        sub_component_link: (photo.title || "no label") + "{/}" + photo.img,
      })
      .then()
      .catch(error => {
        console.log("error:", error);
      });

      this.openInNewTab(photo.link, '_blank');
    },

    miniaturesLayout(index, size) {
      switch (size) {
        case 1:
          return "miniature-radius-top-left miniature-radius-bottom-left miniature-radius-top-right miniature-radius-bottom-right";

        case 2:
          switch (index) {
            case 0:
              return "miniature-radius-top-left miniature-radius-bottom-left miniature-margin-right";
            case 1:
              return "miniature-radius-top-right miniature-radius-bottom-right miniature-margin-left";
            default:
              return "";
          }

        case 3:
          switch (index) {
            case 0:
              return "miniature-radius-top-left miniature-radius-top-right miniature-margin-bottom";
            case 1:
              return "miniature-radius-bottom-left miniature-margin-top miniature-margin-right";
            case 2:
              return "miniature-radius-bottom-right miniature-margin-top miniature-margin-left";
            default:
              return "";
          }

        case 4:
          switch (index) {
            case 0:
              return "miniature-radius-top-left miniature-margin-bottom miniature-margin-right";
            case 1:
              return "miniature-radius-bottom-left miniature-margin-top miniature-margin-right";
            case 2:
              return "miniature-radius-top-right miniature-margin-bottom miniature-margin-left";
            case 3:
              return "miniature-radius-bottom-right miniature-margin-top miniature-margin-left";
            default:
              return "";
          }
      
        default:
          return "";
      }
    },

    /**
     * afficher popup
     */
    showPopup(start){
      document.querySelector("body").style.overflow = "hidden";
      this.controlledSwiper.slideTo(start, 0);
      this.$refs.popup.style.display = 'block';
    },

    /**
     * cache popup l'ensemble de photo
     */
    hidePopup(){
      document.querySelector("body").style.overflow = "auto";
      this.$refs.popup.style.display = 'none';
    },
  },

  computed : {
    grillePhotos(){
      return this.datas?.slice(0, 4) ?? [];
    }
  },

  mounted() {
    if( this.disableModif === true ){
      this.onDisplay = 'display: none;'
    }

    document.documentElement.style.setProperty('--swiper-theme-color', 'white');
    document.documentElement.style.setProperty('--swiper-navigation-size', 'unset');
    document.documentElement.style.setProperty('--swiper-pagination-bullet-inactive-color', '#999999');

    console.log(this.datas);
  }

}
</script>

<style scoped>
.btn {
  height: var(--size-3x);
}

.popup-photos-title {
  font-size: var(--size-1x);
  overflow: hidden;
  width: inherit;
}

.popup-photos {
  background: black;
  padding: var(--size-1x);
  border-radius: var(--size-1x);
  margin: calc(0.5 * (100vh - calc((645 / 747) * 100vh))) auto;
}

.thumbnail-bar {
  overflow: scroll;
  padding-bottom: var(--size-0-5x);
  margin-bottom: var(--size-1x);
}

.thumbnail {
  min-width: calc(6 * var(--size-1x));
  height: calc(5 * var(--size-1x));
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: black;
  margin-right: var(--size-0-5x);
}

.photo {
  height: calc(100vh/2);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.miniatures {
  width: 100%;
  height: calc(10 * var(--size-1x));
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.miniatures > div {
  display: flex;
}

.miniature-radius-top-left {
  border-top-left-radius: var(--size-1x);
}

.miniature-radius-top-right {
  border-top-right-radius: var(--size-1x);
}

.miniature-radius-bottom-left {
  border-bottom-left-radius: var(--size-1x);
}

.miniature-radius-bottom-right {
  border-bottom-right-radius: var(--size-1x);
}

.miniature-margin-top {
  margin-top: calc(0.1 * var(--size-1x));
}

.miniature-margin-bottom {
  margin-bottom: calc(0.1 * var(--size-1x));
}

.miniature-margin-left {
  margin-left: calc(0.1 * var(--size-1x));
}

.miniature-margin-right {
  margin-right: calc(0.1 * var(--size-1x));
}

/* nb == 1 */
.item-1:nth-child(1) {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
}

/* nb == 2 */

.item-2:nth-child(1) {
  grid-row-start: 1;
  grid-row-end: 3;
}

.item-2:nth-child(2) {
  grid-row-start: 1;
  grid-row-end: 3;
}

/* nb == 3 */
.item-3:nth-child(1) {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}

.item-3:nth-child(2) {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
}

.item-3:nth-child(3) {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
}

/* nb == 4 */
.item-4:nth-child(1) {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}

.item-4:nth-child(2) {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
}

.item-4:nth-child(3) {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}

.item-4:nth-child(4) {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
}

.item-plus{
  background: #000000;
  position: relative;
}

.item-plus::before{
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0 0 1rem 0;
}

.content{
  position: relative;
  width: 100%;
  height: 100%;
  top: 35%;
}

.d-modal-photo-ensemble {
  display: none;
  width: 100%;
  height: 100%;
  font-size: 14px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.8);
  overflow-y: scroll;
}

@media screen and (min-width: 750px) {
  .popup-photos {
    width: calc((308 / 1280) * (100vw - var(--size-2x))) !important;
    background: black;
    margin: calc(0.5 * (100vh - calc((645 / 747) * 100vh))) auto;
    padding: var(--size-1x);
    border-radius: var(--size-1x);
  }

  .d-modal-photo-ensemble {
    display: none;
    width: 100%;
    height: 100%;
    font-size: 14px;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    overflow-y: scroll;
  }
}

.custom-slider-2 { display: none; }
.slide-container-2 {
  width: 100%;
  position: relative;
}
/*
.prev, .next {
  cursor: pointer;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: black;
  font-size: 30px;
  background-color: rgba(210, 204, 204, 0.4);
  transition: background-color 0.6s ease;
  text-decoration:none;
}

.prev{ left: 15px; }
.next { right: 15px; }
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.5);
  color: white;
}

.slide-dot{
  text-align: center;
}
*/
</style>