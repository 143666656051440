<template>
  <svg width="25" height="24" viewBox="0 0 25 24" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.4999 22.76C11.0199 22.76 9.52993 22.2 8.36993 21.09C5.41993 18.25 2.15993 13.72 3.38993 8.33C4.49993 3.44 8.76993 1.25 12.4999 1.25C12.4999 1.25 12.4999 1.25 12.5099 1.25C16.2399 1.25 20.5099 3.44 21.6199 8.34C22.8399 13.73 19.5799 18.25 16.6299 21.09C15.4699 22.2 13.9799 22.76 12.4999 22.76ZM12.4999 2.75C9.58993 2.75 5.84993 4.3 4.85993 8.66C3.77993 13.37 6.73993 17.43 9.41993 20C11.1499 21.67 13.8599 21.67 15.5899 20C18.2599 17.43 21.2199 13.37 20.1599 8.66C19.1599 4.3 15.4099 2.75 12.4999 2.75Z" />
    <path d="M11.2499 13.7499C11.0599 13.7499 10.8699 13.6799 10.7199 13.5299L9.21994 12.0299C8.92994 11.7399 8.92994 11.2599 9.21994 10.9699C9.50994 10.6799 9.98994 10.6799 10.2799 10.9699L11.2499 11.9399L14.7199 8.46994C15.0099 8.17994 15.4899 8.17994 15.7799 8.46994C16.0699 8.75994 16.0699 9.23994 15.7799 9.52994L11.7799 13.5299C11.6299 13.6799 11.4399 13.7499 11.2499 13.7499Z" />
  </svg>
</template>

<script>
export default {

  props: ['fillColor'],

}
</script>

<style scoped>

</style>