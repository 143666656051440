<template>
  <div class="row h-100 m-0">
    <div class="col-xl-12 p-0 pb-5">

      <Entete v-if="!byManager" @cancelEdit="cancelEdit" @saveEdit="saveEdit()" />

      <div class="padding-top-1x padding-bottom-1x padding-left-1x padding-right-1x h-100">
        <div class="modal-body text-center h-50">
          <div class="text-start h-100">

            <div class="form-group padding-bottom-1x">
              <label v-if="!byManager" class="form-label text-title">Add, modify or remove any contact link</label>

              <template v-for="(component, index) in components" :key="index">
                <v-switch :case="component.component">

                  <template #TelechargeComponent>
                    <social-link :has-svg="true">
                      <template v-slot:svg>
                        <SVGDoc :fill-color="'#9CA3AF'"/>
                      </template>

                      <template v-slot:label>
                        {{ component.title }}
                      </template>

                      <template v-slot:buttonbar>
                        <button-bar
                            :fill-color-up="'#0400BD'"
                            :fill-color-down="'#0400BD'"
                            :fill-color-edit="'#0400BD'"
                            :show-edit="false"
                            @moveUp="moveUp(index, component)"
                            @moveDown="moveDown(index, component)"
                        />
                      </template>
                    </social-link>
                  </template>

                  <template #SiteComponent>
                    <social-link :has-svg="true">
                      <template v-slot:svg>
                        <SVGWeb :fill-color="'#9CA3AF'"/>
                      </template>

                      <template v-slot:label>
                        {{ component.title }}
                      </template>

                      <template v-slot:buttonbar>
                        <button-bar
                            :fill-color-up="'#0400BD'"
                            :fill-color-down="'#0400BD'"
                            :fill-color-edit="'#0400BD'"
                            :show-edit="false"
                            @moveUp="moveUp(index, component)"
                            @moveDown="moveDown(index, component)"
                        />
                      </template>
                    </social-link>
                  </template>

                  <template #BioComponent>
                    <social-link :has-svg="true">
                      <template v-slot:svg>
                        <SVGText :fill-color="'#9CA3AF'"/>
                      </template>

                      <template v-slot:label>
                        {{ component.title }}
                      </template>

                      <template v-slot:buttonbar>
                        <button-bar
                            :fill-color-up="'#0400BD'"
                            :fill-color-down="'#0400BD'"
                            :fill-color-edit="'#0400BD'"
                            :show-edit="false"
                            @moveUp="moveUp(index, component)"
                            @moveDown="moveDown(index, component)"
                        />
                      </template>
                    </social-link>
                  </template>

                  <template #ContactComponent>
                    <social-link :has-svg="true">
                      <template v-slot:svg>
                        <SVGCall :fill-color="'#9CA3AF'"/>
                      </template>

                      <template v-slot:label>
                        {{ component.title }}
                      </template>

                      <template v-slot:buttonbar>
                        <button-bar
                            :fill-color-up="'#0400BD'"
                            :fill-color-down="'#0400BD'"
                            :fill-color-edit="'#0400BD'"
                            :show-edit="false"
                            @moveUp="moveUp(index, component)"
                            @moveDown="moveDown(index, component)"
                        />
                      </template>
                    </social-link>
                  </template>

                  <template #ReseauxSociauxComponent>
                    <social-link :has-svg="true">
                      <template v-slot:svg>
                        <SVGSocial :fill-color="'#9CA3AF'"/>
                      </template>

                      <template v-slot:label>
                        {{ component.title }}
                      </template>

                      <template v-slot:buttonbar>
                        <button-bar
                            :fill-color-up="'#0400BD'"
                            :fill-color-down="'#0400BD'"
                            :fill-color-edit="'#0400BD'"
                            :show-edit="false"
                            @moveUp="moveUp(index, component)"
                            @moveDown="moveDown(index, component)"
                        />
                      </template>
                    </social-link>
                  </template>

                  <template #PhotosComponent>
                    <social-link :has-svg="true">
                      <template v-slot:svg>
                        <SVGPicture :fill-color="'#9CA3AF'"/>
                      </template>

                      <template v-slot:label>
                        {{ component.title }}
                      </template>

                      <template v-slot:buttonbar>
                        <button-bar
                            :fill-color-up="'#0400BD'"
                            :fill-color-down="'#0400BD'"
                            :fill-color-edit="'#0400BD'"
                            :show-edit="false"
                            @moveUp="moveUp(index, component)"
                            @moveDown="moveDown(index, component)"
                        />
                      </template>
                    </social-link>
                  </template>

                  <template #VideoComponent>
                    <social-link :has-svg="true">
                      <template v-slot:svg>
                        <SVGVideo :fill-color="'#9CA3AF'"/>
                      </template>

                      <template v-slot:label>
                        {{ component.title }}
                      </template>

                      <template v-slot:buttonbar>
                        <button-bar
                            :fill-color-up="'#0400BD'"
                            :fill-color-down="'#0400BD'"
                            :fill-color-edit="'#0400BD'"
                            :show-edit="false"
                            @moveUp="moveUp(index, component)"
                            @moveDown="moveDown(index, component)"
                        />
                      </template>
                    </social-link>
                  </template>

                  <template #CTAComponent>
                    <social-link :has-svg="true">
                      <template v-slot:svg>
                        <SVGMouse :fill-color="'#9CA3AF'"/>
                      </template>

                      <template v-slot:label>
                        {{ component.title }}
                      </template>

                      <template v-slot:buttonbar>
                        <button-bar
                            :fill-color-up="'#0400BD'"
                            :fill-color-down="'#0400BD'"
                            :fill-color-edit="'#0400BD'"
                            :show-edit="false"
                            @moveUp="moveUp(index, component)"
                            @moveDown="moveDown(index, component)"
                        />
                      </template>
                    </social-link>
                  </template>

                </v-switch>
              </template>
            </div>

          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import SocialLink from "../../Components/Buttons/layout.link.base";
import ButtonBar from "../../Components/Buttons/layout.buttonbar";
import Entete from "../../Navigation/nav.enTete.vue";
import SVGDoc from "../../../../assets/svgs/app/svg.section-doc";
import SVGWeb from "../../../../assets/svgs/app/svg.website";
import SVGCall from "../../../../assets/svgs/app/svg.call-calling";
import SVGText from "../../../../assets/svgs/app/svg.text-small";
import SVGMouse from "../../../../assets/svgs/app/svg.section-mouse";
import SVGSocial from "../../../../assets/svgs/app/svg.section-facebook";
import SVGPicture from "../../../../assets/svgs/app/svg.section-picture"
import SVGVideo from "../../../../assets/svgs/app/svg.section-video"
import axios from "axios";

export default {

  components: {
    SocialLink,
    ButtonBar,
    Entete,
    SVGDoc,
    SVGWeb,
    SVGCall,
    SVGText,
    SVGMouse,
    SVGSocial,
    SVGPicture,
    SVGVideo,
  },

  props: [
    'byManager',
    'pageId',
  ],

  data() {
    return {
      components: null,
    };
  },

  methods: {

    moveUp(index, data) {
      console.log(data)
      console.log(index)
      if(index !== 0){
        let prev = this.components[index - 1]
        // a = 1, b = 4, to exchange two order
        // a = a + b =>  a = 5
        // b = a - b =>  b = 1
        // a = a - b =>  a = 4, b = 1
        data.order = data.order + prev.order
        prev.order = data.order - prev.order
        data.order = data.order - prev.order
        console.log(data.order)
        console.log(prev.order)
        this.components = this.components.slice().sort((i1, i2) => i1.order - i2.order);
      }

    },

    moveDown(index, data) {
      console.log(data)
      console.log(index)
      let indexMax = this.components.length - 1
      if(index !== indexMax){
        let next = this.components[index + 1]
        // a = 1, b = 4, to exchange two order
        // a = a + b =>  a = 5
        // b = a - b =>  b = 1
        // a = a - b =>  a = 4, b = 1
        data.order = data.order + next.order
        next.order = data.order - next.order
        data.order = data.order - next.order
        this.components = this.components.slice().sort((i1, i2) => i1.order - i2.order);
      }

    },

    /**
     * Annuler des modifications et redirection routeur
     */
    cancelEdit(){
      this.$router.push('/profil/edit/' + this.$route.params.link);
    },

    saveEdit(){
      const page = this.pageId || 'me';
      axios
          .patch(this.$apiUrl + '/card/pageprofiles/' + page + '/',
              { components: this.components.map(c => c.id) },
              { headers: { Authorization: this.$store.state.user.token }})
          .then((res) => {
            this.components = res.data.components.sort((i1, i2) => (i1.order - i2.order))
            this.$toast.show(
                'Votre modification a été enregistré avec succès !',
                { type: 'success' }
            );
            if (this.byManager) {
              this.$emit("saved");
            }
            else {
              this.$router.push('/profil/edit/' + this.$route.params.link);
            }
          })
          .catch(error => {
            this.$toast.show(error.message, { type: 'error' });
            console.log(error.message)
          })

    },


    getComponents() {
      const page = this.pageId || 'me';
      axios.get(this.$apiUrl + '/card/pageprofiles/' + page + '/',
          { 'headers': { 'Authorization': this.$store.state.user.token } })
          .then((response) => {
            this.components = response.data.components.sort((i1, i2) => (i1.order - i2.order))
            console.log(response.data)
          })
          .catch((error) => {
            console.log(error.message)
          })
    }

  },

  computed: {

  },

  mounted() {
    this.getComponents();
    document.querySelector("#app").style.overflow = "unset"

  },

}
</script>

<style scoped>
</style>