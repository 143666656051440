<template>
  <div>
    <div class="margin-bottom-1x margin-left-1x margin-right-1x">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">

        <div v-bind:style="textColor" class="col text-start p-0 text-black text-title">{{ title }}</div>
        <div :style="onDisplay">
          <router-link v-bind:to="'/document/edit/' + this.link + '/' + this.id" v-if="!byManager">

<button-base
  :textColor="'text-white'"
  :bgColor="'bg-blue'"
  class="edit-button "
>
  <template v-slot:label>
    <SVGFatrows :fillColor="'white'"/>
    Edit
  </template>
</button-base>

</router-link>

<button-base
v-else
v-on:click="$emit('editClicked')"
:textColor="'text-white'"
:bgColor="'bg-blue'"
class="edit-button "
>
<template v-slot:label>
  <SVGFatrows :fillColor="'white'"/>
  Edit
</template>
</button-base>
        </div>

      </div>
    </div>

    <div v-for="(doc, index) in this.datas"  :key="index"
         class="shadow-main margin-top-0-7x margin-left-1x margin-right-1x rounded-3"
         :class="index+1===this.datas.length ? 'margin-bottom-2x' : ''"
    >

      <div class="row justify-content-center align-items-center m-0 p-0">

        <a id="download" target="_blank" rel="noreferrer">
          <div v-on:click="download(doc.file ?? doc.link)" class="d-flex justify-content-between rounded-3 m-0 p-0">

            <div class="text-start text-dark link-white-part">
              <p class="text-subtitle">{{ doc.subtitle }}</p>
              <p class="text-style-muted">
                {{ doc.link ?? doc.file.substring(doc.file.lastIndexOf('/')+1, doc.file.lastIndexOf('?') != -1 ? doc.file.lastIndexOf('?') : doc.file.length) }}
              </p>
            </div>

            <div class="d-flex align-items-center padding-right-1x">
              <svg width="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.6665 9.6237V10.957C1.6665 11.3107 1.80698 11.6498 2.05703 11.8998C2.30708 12.1499 2.64622 12.2904 2.99984 12.2904H10.9998C11.3535 12.2904 11.6926 12.1499 11.9426 11.8998C12.1927 11.6498 12.3332 11.3107 12.3332 10.957V9.6237M3.6665 5.6237L6.99984 8.95703M6.99984 8.95703L10.3332 5.6237M6.99984 8.95703V0.957031" stroke="#9CA3AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>


          </div>
        </a>

      </div>
    </div>

    <!-- <div class="margin-top-1x margin-bottom-2x margin-left-1x margin-right-1x">
      <q-button
          v-on:click="createMessageBox"
          theme="secondary"
          id="btn-add-section"
          class="btn w-100 text-white text-lowercase text-font my-0 rounded-3"
          v-bind:style="bgColor"
      >
        <span class="text-18">
          <span class="text-capitalize text-18">Réserver</span> un appel de découverte
        </span>

      </q-button>
    </div> -->

  </div>
</template>

<script>
import ButtonBase from "../../Components/Buttons/button.base"
import SVGFatrows from "../../../../assets/svgs/admin/svg.fatrows";
import DownloadPopup from "../../Components/Popups/DownloadPopup.vue";
import { useMessageBox } from "@qvant/qui-max";
import { createStatistic } from "../../../../services/management/StatisticsService";


export default {

  props: [ 'byManager', 'id', 'bgColor', 'textColor', 'title', 'datas', 'link', 'disableModif' ],

  components: { ButtonBase, SVGFatrows },

  data() {
    return {
      onDisplay: 'display: block;'
    }
  },

  methods : {
    async download(url){
      try {
        let messageBox = useMessageBox();
        const result = await messageBox(
          {
            component: DownloadPopup,
            props: {
              filename: url.substring(url.lastIndexOf('/')+1, url.lastIndexOf('?') != -1 ? url.lastIndexOf('?') : url.length),
            },
            listeners: {
              download: () => {
                this.downloadFile(url);
                createStatistic({
                  type: "click",
                  component: this.id,
                  sub_component_link: url,
                })
                .then()
                .catch(error => {
                  console.log("error:", error);
                });
              },
            }
          },
        );
        console.log('resolve', result);
      } catch (result) {
        console.log('reject', result);
      }
    },

    downloadFile(url){
      document.getElementById('download').href = url;
      document.getElementById('download').click();
      document.getElementById('download').removeAttribute("href");
    },

    displayEdit(){
      if( this.disableModif === true ){
        this.onDisplay = 'display: none;'
      }
    },

  },

  mounted() {
    this.displayEdit();
  }

}
</script>

<style scoped>
.link-white-part {
  padding-left: var(--size-1x)!important;
}

.margin-top-0-7x{
  margin-top: 0.7rem;
}

.text-title{
  font-size: calc((20/390) * (100vw - var(--size-2x)));
  line-height: 162%;
  font-weight: 600;
  font-family: "Poppins";
  color: #111827;
}
</style>