<template>

  <architecture-base>

    <template v-slot:nav>
      <!-- Navigation bar -->
      <Sidebar active="equipe" />
    </template>

    <template v-slot:header>
      <!-- Title -->
      <div class="title">Team</div>

      <!-- Button tool bar -->
      <template v-if="!isSearch">
        <button-bar :bg-color2="'bg-blue'">

          <template v-slot:valueLabel1>
            <div v-on:click="clickBtnSearch">
              <SVGSearch :fillColor="'#0400BD'"/>
              Search
            </div>
          </template>

          <template v-slot:valueLabel2>
            <div v-on:click="addNewMember">
              <SVGUserAdd :fillColor="'#ffffff'"/>
              Add a new team member
            </div>
          </template>

        </button-bar>
      </template>

      <!-- Search filter -->
      <input
          v-model="search"
          type="text"
          id="member-search"
          :class="!isSearch ? 'd-none' : 'd-block'"
          placeholder="Name, Position, etc."
      />

    </template>

    <template v-slot:main>
      <div class="col-tool-bar table-responsive">
        <!-- tableau derniers leads ajoutés -->
        <tool-bar>

          <template v-slot:title>Your Team ({{teams.length}})</template>

          <template v-slot:legendbar>
            <LegendBar v-on:changeOption="changeOption"/>
          </template>

          <template v-slot:btnbar>
            <div class="text-nowrap">
              <button-base v-on:click="(isSelecting && membersSelected.length > 0) ? showInvitsPopup() : null" :textColor="'text-white'" :bgColor="(isSelecting && membersSelected.length > 0) ? 'bg-blue' : 'bg-gray'" class="border-radius-28 btn-small m-0">
                <template v-slot:label>Invitation</template>
              </button-base>

              <button-base v-on:click="(isSelecting && membersSelected.length > 0) ? showDelMemberSelectPopup() : null" :textColor="'text-white'" :bgColor="(isSelecting && membersSelected.length > 0) ? 'bg-red' : 'bg-gray'" class="border-radius-28 btn-small m-0">
                <template v-slot:label>Delete</template>
              </button-base>

              <button-base v-on:click="(isSelecting && membersSelected.length > 0) ? showAssignMemberPopup() : null" :textColor="'text-white'" :bgColor="(isSelecting && membersSelected.length > 0) ? 'bg-blue' : 'bg-gray'" class="border-radius-28 btn-small m-0">
                <template v-slot:label>Assign</template>
              </button-base>

              <button-base v-on:click="toggleSelect" :textColor="'text-white'" :bgColor="'bg-blue'" class="border-radius-28 btn-small m-0">
                <template v-slot:label>Select</template>
              </button-base>
            </div>
          </template>

        </tool-bar>
      </div>


      <div class="row me-0 row-cols-1 row-cols-sm-2 row-cols-md-3 g-3  overflow-auto">
        <template v-for="(team, index) in this.teamsFilter" :key="index">
          <Team :team="team" :showBox="isSelecting" @selectMember="selectMember"/>
        </template>
      </div>


    </template>

  </architecture-base>

</template>

<script>
import { getCurrentInstance } from "vue";
import ButtonBar from "../LayoutComponent/layout.buttonbar";
import ArchitectureBase from "../LayoutComponent/layout.base";
import Sidebar from "../LayoutComponent/layout.sidebar.vue";
import SVGUserAdd from "../../../assets/svgs/admin/svg.user.add";
import SVGSearch from "../../../assets/svgs/admin/svg.search";
import ButtonBase from "../LayoutComponent/layout.button";
import LegendBar from "../LayoutComponent/layout.legendbar";
import SendInvitsPopup from "../LayoutComponent/Popups/SendInvitationsPopup";
import DeleteMemberSelectedPopup from "../LayoutComponent/Popups/DeleteMemberSelectedPopup";
import AssignMemberPopup from "../LayoutComponent/Popups/AssignMemberPopup";
import ToolBar from "./layout.tool.bar";
import Team from "./layout.team";
import { useMessageBox } from "@qvant/qui-max";
import { getWorkers, deleteWorker } from "@/services/management/UsersService";
import { getTemplates } from "@/services/management/TemplateService";
import axios from "axios";

export default {

  components: {
    ArchitectureBase,
    Sidebar,
    ButtonBar,
    SVGUserAdd,
    SVGSearch,
    ButtonBase,
    ToolBar,
    LegendBar,
    Team
  },

  data() {
    let app = getCurrentInstance();
    return {
      app,
      isSelecting: false,
      membersSelected: [],
      teams : [],
      isSearch: false,
      search: null,
      teamsFilter: [],
    }
  },


  methods : {

    /**
     * Redirect router to edit a member
     */
    addNewMember(){
      this.$router.push('/equipe/edit')
    },

    /**
     * Setting flag isSearch true
     */
    clickBtnSearch(){
      this.isSearch = true;
    },

    /**
     * Show popup send invitation
     */
    async showInvitsPopup(){
      let messageBox = useMessageBox();
      messageBox(
          {
            component: SendInvitsPopup,
          },
      )
      .then(() => {
        axios.post(this.app.appContext.config.globalProperties.$apiUrl + '/card/management/send-invitations/', {
          team_members: this.membersSelected,
        }, { headers: { Authorization: this.$store.state.user.token } })
        .then(async () => {
          this.app.appContext.config.globalProperties.$toast.show('Invitations sent!', {type: 'success'});
          this.isSelecting = false;
          this.membersSelected = [];
        })
        .catch(error => {
          console.log('error', error);
        });
      })
      .catch(result => {
        console.log('reject', result);
      })
    },

    /**
     * Show popup members selected to delete
     */
    async showDelMemberSelectPopup(){
        let messageBox = useMessageBox();
        messageBox(
            {
              component: DeleteMemberSelectedPopup,
            },
        )
        .then(() => {
          Promise.all(this.membersSelected.map(memberId=>deleteWorker(memberId)))
          .then(async () => {
            this.app.appContext.config.globalProperties.$toast.show('Team members deleted!', {type: 'success'});
            await this.getTeams();
            this.isSelecting = false;
            this.membersSelected = [];
          })
          .catch(error => {
            console.log('error', error);
          });
        })
        .catch(result => {
          console.log('reject', result);
        })
    },

    /**
     * show popup assign member
     */
    async showAssignMemberPopup(){
      try {
        let messageBox = useMessageBox();
        const result = await messageBox(
            {
              component: AssignMemberPopup,
              listeners: {
                assignMember: (args) => {
                  console.log(args)
                },
              }
            },
        );
        console.log('resolve', result);
      } catch (result) {
        console.log('reject', result);
      }
    },

    /**
     * Getting a list of teams
     */
    async getTeams(){
      this.teams = (await getWorkers()).map(w=>{
        return {
          id: w.id,
          contacts: w,
          statistics: w.global_views_chart,
        }
      });
      this.teamsFilter = this.teams;
      console.log(this.teamsFilter)
    },

    /**
     * Permet de changer datas par rapport aux choix
     * @param timeFrame
     */
    changeOption(timeFrame) {
      console.log(timeFrame)
    },


    /**
     * Getter le nom de la template
     */
    async getNameTmp(){
      let nameTmp;
      if(this.teams[0].contacts.template !== null){
        let idTmp = this.teams[0].contacts.template
        nameTmp = (await getTemplates()).find(numTmp => numTmp.id == idTmp).label
      }
      return nameTmp;
    },


    /**
     * Filter a list of teams
     */
    handleSearch(){
      this.getNameTmp()
          .then((nameTmp) => {
            this.teamsFilter = this.teams.filter(team => {
              return (team.contacts.first_name?.toLowerCase().indexOf(this.search?.toLowerCase()) !== -1)
              || (team.contacts.last_name?.toLowerCase().indexOf(this.search?.toLowerCase()) !== -1)
              || (team.contacts.position?.toLowerCase().indexOf(this.search?.toLowerCase()) !== -1)
              || (nameTmp?.toLowerCase().indexOf(this.search?.toLowerCase()) !== -1)
            })
            //console.log(this.teamsFilter)
          })
          .catch((e) => {
            console.log(e)
          })
    },

    selectMember(memberId, isSelected){
      if (isSelected && !this.membersSelected.includes(memberId)){
        this.membersSelected.push(memberId);
      }
      else if (!isSelected && this.membersSelected.includes(memberId)){
        this.membersSelected = this.membersSelected.filter(m=>m!=memberId);
      }
      console.log(this.membersSelected)
    },

    toggleSelect(){
      this.isSelecting = !this.isSelecting;
      if (!this.isSelecting) {
        this.membersSelected = []
      }
    }
  },

  mounted() {
    this.getTeams();
    document.querySelector('#member-search').addEventListener('keyup', this.handleSearch)
  }

}
</script>

<style scoped>

.title{
  font-size: calc((48 / 1728) * (100vw - var(--size-2x)));
  line-height: 100%;
  font-weight: 600;
}

.border-radius-10{
  border-radius: 10px;
}

.bg-gray{
  background: #D1D5DB;
}

.bg-blue{
  background: #0400BD;
}

.bg-red{
  background: #EC0000;
}

.border-radius-28{
  border-radius: 28px !important;
}

.btn-small{
  width: calc((129/1728) * (100vw - var(--size-2x)));
  height: calc((38/1728) * (100vw - var(--size-2x)));
  padding: 0 !important;
}

#member-search{
  width: calc(2 * (75/358) * (100vw - var(--size-2x)) + var(--size-2x));
  background: #FFFFFF;
  border: unset !important;
  box-shadow: 0px 1px 6px rgba(211, 221, 255, 0.48), 0px 1px 4px rgba(63, 63, 63, 0.07);
  border-radius: 28px;
  height: var(--size-3x);
  padding-left: var(--size-1x);
}

.col-tool-bar{
  display: flex;
  padding: calc((36 / 1728) * (100vw - var(--size-2x)));
  margin-bottom: calc((36 / 1728) * (100vw - var(--size-2x)));
  background: #FFFFFF;
  border-radius: 10px;
}
</style>