<template>

  <architecture-base>

    <!-- Navigation -->
    <template v-slot:nav>
      <!-- Navigation bar -->
      <Sidebar active="equipe" />
    </template>

    <!-- Header -->
    <template v-slot:header>
      <!-- Title -->
      <div class="title">Add a new member</div>

      <!-- Button tool bar -->
      <button-bar :bg-color2="'bg-lightgreen'">

        <template v-slot:valueLabel1>
          <div v-on:click="backPrevious">Back</div>
        </template>

        <template v-slot:valueLabel2>
          <div v-on:click="saveMember">Save this member</div>
        </template>

      </button-bar>
    </template>


    <!-- Content -->
    <template v-slot:main>
      <div class="d-flex justify-content-between overflow-auto">
        <!-- Contact informations -->
        <contact-info 
          ref="contact"
        ></contact-info>

        <!-- Business card -->
        <business-card
        ></business-card>
      </div>
    </template>


  </architecture-base>

</template>

<script>
import ButtonBar from "../LayoutComponent/layout.buttonbar";
import ArchitectureBase from "../LayoutComponent/layout.base";
import Sidebar from "../LayoutComponent/layout.sidebar.vue";
import ContactInfo from "./layout.contact.informations";
import BusinessCard from "./layout.business.card";

export default {

  components: {
    ArchitectureBase,
    Sidebar,
    ButtonBar,
    ContactInfo,
    BusinessCard
  },

  data() {
    return {
      imageToUpload: null,
    }
  },


  methods : {

    backPrevious(){
      this.$router.push('/equipe')
    },

    saveMember(){
      this.$refs.contact.save()
      .then((user) => {
        this.$router.push('edit/' + user.id);
      })
      .catch((error) => {
        console.log(error);
      })
    },
  },

  created() {
  }

}
</script>

<style scoped>

.title{
  font-size: calc((48 / 1728) * (100vw - var(--size-2x)));
  line-height: 100%;
  font-weight: 600;
}
</style>