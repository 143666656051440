<template>
  <svg width="20" height="20" viewBox="0 0 20 20" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0002 10.625C7.3585 10.625 5.2085 8.47502 5.2085 5.83335C5.2085 3.19169 7.3585 1.04169 10.0002 1.04169C12.6418 1.04169 14.7918 3.19169 14.7918 5.83335C14.7918 8.47502 12.6418 10.625 10.0002 10.625ZM10.0002 2.29169C8.05016 2.29169 6.4585 3.88335 6.4585 5.83335C6.4585 7.78335 8.05016 9.37502 10.0002 9.37502C11.9502 9.37502 13.5418 7.78335 13.5418 5.83335C13.5418 3.88335 11.9502 2.29169 10.0002 2.29169Z" />
    <path d="M2.8418 18.9583C2.50013 18.9583 2.2168 18.675 2.2168 18.3333C2.2168 14.775 5.70846 11.875 10.0001 11.875C10.8418 11.875 11.6668 11.9834 12.4668 12.2084C12.8001 12.3 12.9918 12.6417 12.9001 12.975C12.8085 13.3083 12.4668 13.5 12.1335 13.4084C11.4501 13.2167 10.7335 13.125 10.0001 13.125C6.40013 13.125 3.4668 15.4583 3.4668 18.3333C3.4668 18.675 3.18346 18.9583 2.8418 18.9583Z" />
    <path d="M14.9998 18.9584C14.0165 18.9584 13.0832 18.5917 12.3582 17.9334C12.0665 17.6834 11.8082 17.375 11.6082 17.0334C11.2415 16.4334 11.0415 15.725 11.0415 15C11.0415 13.9584 11.4415 12.9834 12.1582 12.2417C12.9082 11.4667 13.9165 11.0417 14.9998 11.0417C16.1332 11.0417 17.2082 11.525 17.9415 12.3584C18.5915 13.0834 18.9582 14.0167 18.9582 15C18.9582 15.3167 18.9165 15.6333 18.8332 15.9333C18.7498 16.3084 18.5915 16.7 18.3748 17.0417C17.6832 18.225 16.3832 18.9584 14.9998 18.9584ZM14.9998 12.2917C14.2582 12.2917 13.5748 12.5833 13.0582 13.1083C12.5665 13.6167 12.2915 14.2834 12.2915 15C12.2915 15.4917 12.4248 15.975 12.6832 16.3917C12.8165 16.625 12.9915 16.8333 13.1915 17.0083C13.6915 17.4667 14.3332 17.7167 14.9998 17.7167C15.9415 17.7167 16.8332 17.2167 17.3165 16.4084C17.4582 16.175 17.5665 15.9084 17.6248 15.65C17.6832 15.4334 17.7082 15.225 17.7082 15.0084C17.7082 14.3417 17.4582 13.7 17.0082 13.2C16.5082 12.6167 15.7748 12.2917 14.9998 12.2917Z" />
    <path d="M16.25 15.6083H13.7583C13.4166 15.6083 13.1333 15.325 13.1333 14.9833C13.1333 14.6417 13.4166 14.3583 13.7583 14.3583H16.25C16.5916 14.3583 16.875 14.6417 16.875 14.9833C16.875 15.325 16.5916 15.6083 16.25 15.6083Z" />
    <path d="M15 16.8833C14.6583 16.8833 14.375 16.6 14.375 16.2583V13.7667C14.375 13.425 14.6583 13.1417 15 13.1417C15.3417 13.1417 15.625 13.425 15.625 13.7667V16.2583C15.625 16.6083 15.3417 16.8833 15 16.8833Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>