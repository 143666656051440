<template>
  <div class="row h-100 m-0">
    <div class="col-xl-12 p-0 pb-5">

      <Entete @cancelEdit="cancelEdit" @saveEdit="saveEdit" />

      <div class="padding-top-1x padding-bottom-1x padding-left-1x padding-right-1x h-100">
        <div class="modal-body text-center h-50">
          <div class="text-start h-100">

            <div class="form-group padding-bottom-1x">
              <label class="form-label text-title">Modify your personnal informations</label>
              <div class="left-inner-addon input-container">
                <i class="icon">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.99984 9.16667C11.8408 9.16667 13.3332 7.67428 13.3332 5.83333C13.3332 3.99238 11.8408 2.5 9.99984 2.5C8.15889 2.5 6.6665 3.99238 6.6665 5.83333C6.6665 7.67428 8.15889 9.16667 9.99984 9.16667Z" stroke="#9CA3AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5 17.5V15.8333C5 14.9493 5.35119 14.1014 5.97631 13.4763C6.60143 12.8512 7.44928 12.5 8.33333 12.5H11.6667C12.5507 12.5 13.3986 12.8512 14.0237 13.4763C14.6488 14.1014 15 14.9493 15 15.8333V17.5" stroke="#9CA3AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </i>
                <input v-model="name" type="text" class="form-control text-description" :placeholder="name" />
              </div>

              <div class="left-inner-addon input-container">
                <i class="icon">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.3332 18.9583H6.66653C2.81653 18.9583 2.09986 17.1666 1.91653 15.425L1.29153 8.74997C1.19986 7.87497 1.17486 6.5833 2.04153 5.61663C2.79153 4.7833 4.0332 4.3833 5.8332 4.3833H14.1665C15.9749 4.3833 17.2165 4.79163 17.9582 5.61663C18.8249 6.5833 18.7999 7.87497 18.7082 8.7583L18.0832 15.4166C17.8999 17.1666 17.1832 18.9583 13.3332 18.9583ZM5.8332 5.62497C4.42486 5.62497 3.4582 5.89997 2.96653 6.44997C2.5582 6.89997 2.42486 7.59163 2.5332 8.62497L3.1582 15.3C3.29986 16.6166 3.6582 17.7083 6.66653 17.7083H13.3332C16.3332 17.7083 16.6999 16.6166 16.8415 15.2916L17.4665 8.6333C17.5749 7.59163 17.4415 6.89997 17.0332 6.44997C16.5415 5.89997 15.5749 5.62497 14.1665 5.62497H5.8332Z" fill="#9CA3AF"/>
                    <path d="M13.3332 5.62484C12.9915 5.62484 12.7082 5.3415 12.7082 4.99984V4.33317C12.7082 2.84984 12.7082 2.2915 10.6665 2.2915H9.33317C7.2915 2.2915 7.2915 2.84984 7.2915 4.33317V4.99984C7.2915 5.3415 7.00817 5.62484 6.6665 5.62484C6.32484 5.62484 6.0415 5.3415 6.0415 4.99984V4.33317C6.0415 2.8665 6.0415 1.0415 9.33317 1.0415H10.6665C13.9582 1.0415 13.9582 2.8665 13.9582 4.33317V4.99984C13.9582 5.3415 13.6748 5.62484 13.3332 5.62484Z" fill="#9CA3AF"/>
                    <path d="M10.0002 13.9583C7.7085 13.9583 7.7085 12.5417 7.7085 11.6917V10.8333C7.7085 9.65833 7.99183 9.375 9.16683 9.375H10.8335C12.0085 9.375 12.2918 9.65833 12.2918 10.8333V11.6667C12.2918 12.5333 12.2918 13.9583 10.0002 13.9583ZM8.9585 10.625C8.9585 10.6917 8.9585 10.7667 8.9585 10.8333V11.6917C8.9585 12.55 8.9585 12.7083 10.0002 12.7083C11.0418 12.7083 11.0418 12.575 11.0418 11.6833V10.8333C11.0418 10.7667 11.0418 10.6917 11.0418 10.625C10.9752 10.625 10.9002 10.625 10.8335 10.625H9.16683C9.10016 10.625 9.02516 10.625 8.9585 10.625Z" fill="#9CA3AF"/>
                    <path d="M11.6667 12.3084C11.3584 12.3084 11.0834 12.075 11.0501 11.7584C11.0084 11.4167 11.2501 11.1 11.5917 11.0584C13.7917 10.7834 15.9001 9.95004 17.6751 8.65837C17.9501 8.45004 18.3417 8.5167 18.5501 8.80004C18.7501 9.07504 18.6917 9.4667 18.4084 9.67504C16.4584 11.0917 14.1584 12 11.7417 12.3084C11.7167 12.3084 11.6917 12.3084 11.6667 12.3084Z" fill="#9CA3AF"/>
                    <path d="M8.33345 12.3164C8.30845 12.3164 8.28345 12.3164 8.25845 12.3164C5.97511 12.0581 3.75011 11.2248 1.82511 9.90809C1.54178 9.71642 1.46678 9.32476 1.65845 9.04142C1.85011 8.75809 2.24178 8.68309 2.52511 8.87476C4.28345 10.0748 6.30845 10.8331 8.39178 11.0748C8.73345 11.1164 8.98345 11.4248 8.94178 11.7664C8.91678 12.0831 8.65011 12.3164 8.33345 12.3164Z" fill="#9CA3AF"/>
                  </svg>
                </i>
                <input v-model="position" type="text" class="form-control text-description" :placeholder="position" />
              </div>

              <div class="left-inner-addon input-container">
                <i class="icon">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.8332 18.9582H4.1665C2.14984 18.9582 1.0415 17.8498 1.0415 15.8332V9.1665C1.0415 7.14984 2.14984 6.0415 4.1665 6.0415H8.33317C8.67484 6.0415 8.95817 6.32484 8.95817 6.6665V15.8332C8.95817 17.1498 9.5165 17.7082 10.8332 17.7082C11.1748 17.7082 11.4582 17.9915 11.4582 18.3332C11.4582 18.6748 11.1748 18.9582 10.8332 18.9582ZM4.1665 7.2915C2.84984 7.2915 2.2915 7.84984 2.2915 9.1665V15.8332C2.2915 17.1498 2.84984 17.7082 4.1665 17.7082H8.16649C7.86649 17.2165 7.70817 16.5915 7.70817 15.8332V7.2915H4.1665Z" fill="#9CA3AF"/>
                    <path d="M8.33317 7.29183H4.1665C3.82484 7.29183 3.5415 7.0085 3.5415 6.66683V5.00016C3.5415 3.7335 4.5665 2.7085 5.83317 2.7085H8.42483C8.61649 2.7085 8.79985 2.80014 8.91651 2.95014C9.03318 3.10848 9.07483 3.3085 9.02483 3.49183C8.97483 3.67516 8.95817 3.8835 8.95817 4.16683V6.66683C8.95817 7.0085 8.67484 7.29183 8.33317 7.29183ZM4.7915 6.04183H7.70817V4.16683C7.70817 4.09183 7.70817 4.02516 7.70817 3.9585H5.83317C5.25817 3.9585 4.7915 4.42516 4.7915 5.00016V6.04183Z" fill="#9CA3AF"/>
                    <path d="M11.6665 11.4582C11.3248 11.4582 11.0415 11.1748 11.0415 10.8332V6.6665C11.0415 6.32484 11.3248 6.0415 11.6665 6.0415C12.0082 6.0415 12.2915 6.32484 12.2915 6.6665V10.8332C12.2915 11.1748 12.0082 11.4582 11.6665 11.4582Z" fill="#9CA3AF"/>
                    <path d="M15 11.4582C14.6583 11.4582 14.375 11.1748 14.375 10.8332V6.6665C14.375 6.32484 14.6583 6.0415 15 6.0415C15.3417 6.0415 15.625 6.32484 15.625 6.6665V10.8332C15.625 11.1748 15.3417 11.4582 15 11.4582Z" fill="#9CA3AF"/>
                    <path d="M14.9998 18.9582H11.6665C11.3248 18.9582 11.0415 18.6748 11.0415 18.3332V14.9998C11.0415 14.1998 11.6998 13.5415 12.4998 13.5415H14.1665C14.9665 13.5415 15.6248 14.1998 15.6248 14.9998V18.3332C15.6248 18.6748 15.3415 18.9582 14.9998 18.9582ZM12.2915 17.7082H14.3748V14.9998C14.3748 14.8832 14.2832 14.7915 14.1665 14.7915H12.4998C12.3832 14.7915 12.2915 14.8832 12.2915 14.9998V17.7082Z" fill="#9CA3AF"/>
                    <path d="M5 14.7918C4.65833 14.7918 4.375 14.5085 4.375 14.1668V10.8335C4.375 10.4918 4.65833 10.2085 5 10.2085C5.34167 10.2085 5.625 10.4918 5.625 10.8335V14.1668C5.625 14.5085 5.34167 14.7918 5 14.7918Z" fill="#9CA3AF"/>
                    <path d="M15.8335 18.9582H10.8335C8.81683 18.9582 7.7085 17.8498 7.7085 15.8332V4.1665C7.7085 2.14984 8.81683 1.0415 10.8335 1.0415H15.8335C17.8502 1.0415 18.9585 2.14984 18.9585 4.1665V15.8332C18.9585 17.8498 17.8502 18.9582 15.8335 18.9582ZM10.8335 2.2915C9.51683 2.2915 8.9585 2.84984 8.9585 4.1665V15.8332C8.9585 17.1498 9.51683 17.7082 10.8335 17.7082H15.8335C17.1502 17.7082 17.7085 17.1498 17.7085 15.8332V4.1665C17.7085 2.84984 17.1502 2.2915 15.8335 2.2915H10.8335Z" fill="#9CA3AF"/>
                  </svg>
                </i>
                <input v-model="company_label" type="text" class="form-control text-description" :placeholder="company_label" />
              </div>

              <div class="left-inner-addon input-container">
                <i class="icon">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8333 4.1665H4.16667C3.24619 4.1665 2.5 4.9127 2.5 5.83317V14.1665C2.5 15.087 3.24619 15.8332 4.16667 15.8332H15.8333C16.7538 15.8332 17.5 15.087 17.5 14.1665V5.83317C17.5 4.9127 16.7538 4.1665 15.8333 4.1665Z" stroke="#9CA3AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2.5 5.83301L10 10.833L17.5 5.83301" stroke="#9CA3AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </i>
                <input v-model="email" type="text" class="form-control text-description" :placeholder="email" />
              </div>

              <vue-tel-input :value="tel" @input="updatePhone" mode="international" id="tel-country-codes"></vue-tel-input>

            </div>

          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Entete from "../Navigation/nav.enTete.vue";
import { VueTelInput } from 'vue3-tel-input';
import axios from "axios";

export default {

  components: {
    Entete,
    VueTelInput,
  },

  data() {
    return {
      newDatas: [],
      title: null,
      name: null,
      position: null,
      company_label: null,
      email: null,
      tel: null,
    };
  },

  methods : {

    updatePhone(params) {
      if (typeof params == "string") {
        this.tel = params;
      }
    },

    /**
     * Annuler des modifications et redirection routeur
     */
    cancelEdit(){
      this.$router.push('/profil/edit/' + this.$route.params.link);
    },

    /**
     * Enregistrer des modifications
     */
    saveEdit(){
      this.newDatas[0].first_name = this.name.trim().split(/\s+/)[0]
      this.newDatas[0].last_name = this.name.trim().split(/\s+/)[1]
      this.newDatas[0].position = this.position
      this.newDatas[0].company_label = this.company_label
      this.newDatas[0].email = this.email
      this.newDatas[0].tel = this.tel
      console.log(this.newDatas)

      axios
          .patch(this.$apiUrl + '/card/components/' + this.$route.params.id + '/',
              { title: this.title, datas: this.newDatas },
              { headers: { Authorization: this.$store.state.user.token }})
          .then(() => {
            this.$toast.show(
                'Votre modification a été enregistré avec succès !',
                { type: 'success' }
            );
            this.$router.push('/profil/edit/' + this.$route.params.link);
          })
          .catch(error => {
            this.$toast.show(error.message, { type: 'error' });
            console.log(error.message)
          })

    },

    /**
     * récupération datas profil
     */
    getProfil(){
      axios
          .get(this.$apiUrl + '/card/components/' + this.$route.params.id + '/',
              { headers: { Authorization: this.$store.state.user.token } })
          .then((response) => {
            console.log(response.data);
            this.newDatas = response.data.datas
            this.title = response.data.title;
            this.name = response.data.datas[0].first_name + " " + response.data.datas[0].last_name;
            this.position = response.data.datas[0].position;
            this.company_label = response.data.datas[0].company;
            this.email = response.data.datas[0].email;
            this.tel = response.data.datas[0].tel;
          })
          .catch((error) => {
            console.log(error.message)
          })
    }

  },

  computed : {

  },

  mounted() {
    this.getProfil();
    document.querySelector("#app").style.overflow = "unset"
  }
};

</script>

<style scoped>
.text-title{
  font-size: calc((24/390) * (100vw - var(--size-2x)));
  line-height: 140%;
  font-weight: 500;
  font-family: "Lexend";
  color: #111827;
}

.text-description{
  font-size: calc((19/390) * (100vw - var(--size-2x)))!important;
  line-height: 160%;
  font-weight: 400;
  font-family: "Lexend";
  color: #6B7280;
}

#tel-country-codes{
  border-radius: 8px!important;
  border: 1px solid #ced4da;
  height: var(--size-3x);
}
</style>

