<template>

  <architecture-base>

    <template v-slot:nav>
      <!-- Navigation bar -->
      <Sidebar active="needhelp" />
    </template>

    <template v-slot:header>
      <!-- Title -->
      <div class="title">Need help?</div>

    </template>

    <template v-slot:main>
      <div class="row m-0">
        <div class="col-interg-container text-start">
          <div class="child padding-left-1x padding-top-1x padding-right-1x padding-bottom-1x bg-white border-radius-10px">
            <div class="title-card">How to add multiple and diverse links to your Templates?</div>

            <iframe class="video" :src="'https://www.youtube.com/embed/8dgZRykdc3Y?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1&origin=https://www.youtube.com'"
              frameborder="0"
              gesture="media" 
              allow="encrypted-media" 
              allowfullscreen=""
            ></iframe>

          </div>
        </div>
        <div class="col-interg-container text-start">
          <div class="child padding-left-1x padding-top-1x padding-right-1x padding-bottom-1x bg-white border-radius-10px">
            <div class="title-card">How to see, export and delete your leads?</div>

            <iframe class="video" :src="'https://www.youtube.com/embed/iHi7r2OVvq4?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1&origin=https://www.youtube.com'"
              frameborder="0"
              gesture="media" 
              allow="encrypted-media" 
              allowfullscreen=""
            ></iframe>

          </div>
        </div>
        <div class="col-interg-container text-start">
          <div class="child padding-left-1x padding-top-1x padding-right-1x padding-bottom-1x bg-white border-radius-10px">
            <div class="title-card">How to add pictures and YouTube videos to your Templates?</div>

            <iframe class="video" :src="'https://www.youtube.com/embed/A14qzbjItrc?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1&origin=https://www.youtube.com'"
              frameborder="0"
              gesture="media" 
              allow="encrypted-media" 
              allowfullscreen=""
            ></iframe>

          </div>
        </div>
        <div class="col-interg-container text-start">
          <div class="child padding-left-1x padding-top-1x padding-right-1x padding-bottom-1x bg-white border-radius-10px">
            <div class="title-card">How to add a banner?</div>

            <iframe class="video" :src="'https://www.youtube.com/embed/x8Mc5gvDHqg?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1&origin=https://www.youtube.com'"
              frameborder="0"
              gesture="media" 
              allow="encrypted-media" 
              allowfullscreen=""
            ></iframe>

          </div>
        </div>
        <div class="col-interg-container text-start">
          <div class="child padding-left-1x padding-top-1x padding-right-1x padding-bottom-1x bg-white border-radius-10px">
            <div class="title-card">How to create, modify and delete a template?</div>

            <iframe class="video" :src="'https://www.youtube.com/embed/2e49Qr5EKLA?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1&origin=https://www.youtube.com'"
              frameborder="0"
              gesture="media" 
              allow="encrypted-media" 
              allowfullscreen=""
            ></iframe>

          </div>
        </div>
      </div>
    </template>

  </architecture-base>

</template>

<script>
import ArchitectureBase from "../LayoutComponent/layout.base";
import Sidebar from "../LayoutComponent/layout.sidebar.vue";

export default {

  components: {
    ArchitectureBase,
    Sidebar,
  },

}
</script>

<style scoped>
.title{
  font-size: calc((48 / 1728) * (100vw - var(--size-2x)));
  line-height: 100%;
  font-weight: 600;
}

.video{
  width: 100%;
  aspect-ratio: 1.75;
  border-radius: 1rem;
}

.title-card{
  font-weight: 600;
  margin-bottom: 0.8rem;
}

.col-interg-container{
  width: 50%;
  height: fit-content;
}

.border-radius-10px{
  border-radius: 10px;
}

.child {
  margin-bottom: calc((36 / 1728) * (100vw - var(--size-2x)));
}
</style>