<template>
  <div>
    <div class="margin-bottom-1x margin-left-1x margin-right-1x">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">

        <div v-if="title">
          <div class="col text-start p-0 text-black text-title">
            {{ title }}
          </div>
        </div>

        <div :style="onDisplay">
          <router-link v-bind:to="'/cta/edit/' + this.path + '/' + this.id" v-if="!byManager">

<button-base
  :textColor="'text-white'"
  :bgColor="'bg-blue'"
  class="edit-button "
>
  <template v-slot:label>
    <SVGFatrows :fillColor="'white'"/>
    Edit
  </template>
</button-base>

</router-link>

<button-base
v-else
v-on:click="$emit('editClicked')"
:textColor="'text-white'"
:bgColor="'bg-blue'"
class="edit-button "
>
<template v-slot:label>
  <SVGFatrows :fillColor="'white'"/>
  Edit
</template>
</button-base>
        </div>
      </div>
    </div>


    <div  class="margin-bottom-2x margin-left-1x margin-right-1x rounded-3"
          :class="title ? 'margin-top-0-7x' : ''"
    >

      <div  v-for="(cta, index) in [...(this.datas)].sort((x, y) => x.order-y.order)"  :key="index"
            class="justify-content-center align-items-center"
            :class="index+1===this.datas.length ? '' : 'margin-bottom-0-5x'"
      >

        <!-- a tag to not trigger ad block -->
        <!-- <a class="d-none" target="_blank" rel="noreferrer"></a>  -->

        <div class="d-flex rounded-3 m-0 p-0">

          <q-button
            class="cta-button shadow-none margin-right-1x"
            v-on:click="handleCTAClick(cta)"
          >
            {{ cta.label }}
          </q-button>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import ButtonBase from "../../Components/Buttons/button.base"
import SVGFatrows from "../../../../assets/svgs/admin/svg.fatrows";

export default {

  props: [ 'byManager', 'id', 'path', 'title', 'datas', 'disableModif' ],

  components: {
    ButtonBase,
    SVGFatrows,
  },

  data() {
    return {
      onDisplay: 'display: block;'
    }
  },

  methods : {
    handleCTAClick(cta) {
      let url = "";
      switch (cta.type) {
        case "link":
          url = cta.lien
          break;

        case "appel":
          url = 'tel:' + cta.lien
          break;

        case "message":
          url = 'sms:' + cta.lien
          break;

        case "mail":
          url = 'mailto:' + cta.lien
          break;

        case "localization":
          url = 'https://www.google.com/maps/search/' + cta.address + (cta.city ? (', ' + cta.city) : '')
          break;
      
        default:
          return;
      }
      window.open(url, '_blank')
    }
  },

  mounted() {
    if( this.disableModif === true ){
      this.onDisplay = 'display: none;'
    }
  }
}
</script>

<style scoped>
.cta-button {
  width: 100%;
}

.text-subtitle{
  height: calc((10/358) * (100vw - var(--size-2x)));
  margin: 0;
  margin-top: calc((18/358) * (100vw - var(--size-2x)));

  font-size: calc((12/358) * (100vw - var(--size-2x)));
}

.text-style-muted{
  height: calc((9/358) * (100vw - var(--size-2x)));
  margin: 0;
  margin-top: calc((9/358) * (100vw - var(--size-2x)));

  font-size: calc((10/358) * (100vw - var(--size-2x)));

  color: #AEAEAE;
}

.link-white-part{
  width: calc((246/358) * (100vw - var(--size-2x)));
  height: calc((64/358) * (100vw - var(--size-2x)));
  padding-left: calc((17/358) * (100vw - var(--size-2x))); 
  padding-right: calc((17/358) * (100vw - var(--size-2x)));
}

.margin-top-0-7x{
  margin-top: 0.7rem;
}

.height-absolute{
  height: 2.5rem;
}

.btn-absolute{
  position: absolute;
  right: calc(var(--size-2x) / 2);
}
</style>