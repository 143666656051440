<template>
  <svg viewBox="0 0 26 26" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.4651 0.483932H4.31987C2.16781 0.483932 0.423218 2.22852 0.423218 4.38058V21.5258C0.423218 23.6779 2.16781 25.4225 4.31987 25.4225H21.4651C23.6172 25.4225 25.3618 23.6779 25.3618 21.5258V4.38058C25.3618 2.22852 23.6172 0.483932 21.4651 0.483932Z"/>
    <path d="M16.522 7.61424H18.2748L14.4455 11.9909L18.9504 17.9467H15.4231L12.6604 14.3346L9.49918 17.9467H7.74532L11.8412 13.2653L7.51959 7.61424H11.1365L13.6337 10.9158L16.522 7.61424ZM15.9069 16.8975H16.8781L10.6087 8.60827H9.56647L15.9069 16.8975Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>