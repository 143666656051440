<template>
  <div>

    <div class="margin-bottom-1x margin-left-1x margin-right-1x">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center ">
        <div class="col text-start p-0 text-black text-title">{{ title }}</div>
        <div :style="onDisplay">
          <router-link v-bind:to="'/video/edit/' + this.link + '/' + this.id" v-if="!byManager">

<button-base
  :textColor="'text-white'"
  :bgColor="'bg-blue'"
  class="edit-button "
>
  <template v-slot:label>
    <SVGFatrows :fillColor="'white'"/>
    Edit
  </template>
</button-base>

</router-link>

<button-base
v-else
v-on:click="$emit('editClicked')"
:textColor="'text-white'"
:bgColor="'bg-blue'"
class="edit-button "
>
<template v-slot:label>
  <SVGFatrows :fillColor="'white'"/>
  Edit
</template>
</button-base>
        </div>
      </div>
    </div>

    <div class="text-center margin-bottom-2x mx-3">

      <iframe :src="'https://www.youtube.com/embed/' + this.videoId.slice(this.videoId.lastIndexOf('v=') >= 0 ? this.videoId.lastIndexOf('v=')+2 : this.videoId.lastIndexOf('/')+1) + '?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1&origin=https://www.youtube.com'"
              frameborder="0"
              gesture="media" 
              allow="encrypted-media" 
              allowfullscreen=""
        ></iframe>

        <p v-if="this.sourceVideo" class="px-1 m-0 text-start text-description">{{ this.sourceVideo }}</p>
    </div>


  </div>
</template>

<script>
import axios from "axios";
import ButtonBase from "../../Components/Buttons/button.base"
import SVGFatrows from "../../../../assets/svgs/admin/svg.fatrows";

export default {

  components: {
    ButtonBase,
    SVGFatrows
  },

  props: [ 'byManager', 'id', 'textColor', 'title', 'sourceVideo', 'videoId', 'sourceMiniature', 'disableModif', 'link' ],

  data() {
    return {
      onDisplay: 'display: block;'
    }
  },

  methods : {

    deleteComponent(){
      axios
          .delete(this.$apiUrl + '/card/components/' + this.id + '/',
              { headers: { Authorization: this.$store.state.user.token } })

          .then(() => {
            this.$toast.show(
                'Votre suppression a été effectué avec succès !',
                {type: 'success'}
            );
            window.location.reload(true);
          })

          .catch(error => {
            this.$toast.show(error.message, {type: 'error'});
            console.log(error.message)
          })
    },

    displayEdit(){
      if( this.disableModif === true ){
        this.onDisplay = 'display: none;'
      }
    },

  },

  mounted() {
    this.displayEdit()
  }
}
</script>

<style scoped>
iframe {
  width: 100%;
  height: calc(10 * var(--size-1x));
  border-radius: var(--size-0-5x);
}
</style>