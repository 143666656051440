<template>
  <div class="text-center d-flex justify-content-center">
    <div class="figure-item fw-bolder text-white" :class="bgColor">

      <div class="figure-gap fw-bolder">
        <slot name="numfigure"></slot>
      </div>

      <p class="font-size fw-bolder text-nowrap">
        <slot name="labelfigure"></slot>
      </p>

    </div>
  </div>
</template>

<script>
export default {

  props: [ 'bgColor' ],

}
</script>

<style scoped>
.font-size {
  font-size: calc((20 / 1820) * (100vw - var(--size-2x)));
  font-weight: 600;
  line-height: 100%;
  margin: 0;
}

.figure-gap {
  padding-bottom: var(--size-0-5x);
}

.figure-item {
  width: calc((176 / 1728) * (100vw - var(--size-2x)));
  padding: var(--size-0-5x) 0;
  font-size: calc((45 / 1728) * (100vw - var(--size-2x)));
  line-height: calc((45 / 1728) * (100vw - var(--size-2x)));
  border-radius: 10px;
  margin: 0;
}

.bgBlue{
  background-color: #0400BD;
}
</style>