<template>

  <div class="col-top-download">
    <div class="sub-title">{{ this.title }}</div>
    <div class="bg-white border-radius-10">
      <leader-board>

        <template v-slot:title></template>

        <template v-slot:template></template>

        <template v-slot:legendbar>
          <LegendBar class="col-legend-bar" v-on:changeOption="selectTimeFrame" />
        </template>

        <template v-slot:table>
          <Table
              ref="leadsTable"
              :datas="displayedDownloads"
              :isSelecting="isSelecting"
          />
        </template>

      </leader-board>
    </div>
  </div>

</template>

<script>
import LeaderBoard from "../Leaderboard/layout.base";
import LegendBar from "../LayoutComponent/layout.legendbar";
import Table from "./layout.table.download";
import StatisticsService from "../../../services/management/StatisticsService";

export default {

  props: ['title'],

  components: {
    LegendBar,
    LeaderBoard,
    Table,
  },

  data() {
    return {
      filterFrom: null,
      isSelecting: false,
      files: [],
    }
  },

  methods : {

    /**
     * Permet de changer datas par rapport aux choix
     * @param event
     */
    selectTimeFrame(timeframe){
      this.filterFrom = timeframe;
    },

    getDownloads(){
      StatisticsService.getStatistics()
      .then((response) => {
        //Tirer un tableau en fonction du classement
        this.files = response.file_downloads;
      })
      .catch((error) => {
        console.log("error", error);
      })
    },
  },

  computed: {
    displayedDownloads() {
      let selectedTF = '1D';
      let computedDatas = [];
      Object.keys(StatisticsService.choices).forEach(k => {
        if (StatisticsService.choices[k] == this.filterFrom) selectedTF = k;
      });
      if(this.files[selectedTF]){
        computedDatas = this.files[selectedTF].slice().sort((i1, i2) => (i2.clicks) - (i1.clicks))
      }

      return computedDatas;
    }
  },

  created() {
    this.getDownloads();
  }

}
</script>

<style scoped>
.sub-title{
  font-family: "Poppins";
  font-size: calc((32 / 1728) * (100vw - var(--size-2x)));
  line-height: 100%;
  font-weight: 600;
  color: #000000;
}

.col-top-download{
  border-radius: 10px;
  padding: calc((36 / 1728) * (100vw - var(--size-2x)));
  margin-bottom: calc((36 / 1728) * (100vw - var(--size-2x)));
  background: #FFFFFF;
}

.col-legend-bar{
  padding: calc((36 / 1728) * (100vw - var(--size-2x))) 0;
}
</style>