<template>
  <svg width="16" height="16" viewBox="0 0 16 16" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.00033 8.50016C5.88699 8.50016 4.16699 6.78016 4.16699 4.66683C4.16699 2.5535 5.88699 0.833496 8.00033 0.833496C10.1137 0.833496 11.8337 2.5535 11.8337 4.66683C11.8337 6.78016 10.1137 8.50016 8.00033 8.50016ZM8.00033 1.8335C6.44033 1.8335 5.16699 3.10683 5.16699 4.66683C5.16699 6.22683 6.44033 7.50016 8.00033 7.50016C9.56033 7.50016 10.8337 6.22683 10.8337 4.66683C10.8337 3.10683 9.56033 1.8335 8.00033 1.8335Z" />
    <path d="M10.5465 15.1667C10.2932 15.1667 10.0532 15.0734 9.87988 14.9001C9.67321 14.6934 9.57987 14.3934 9.62654 14.0801L9.75321 13.1801C9.78654 12.9468 9.92655 12.6734 10.0932 12.5001L12.4532 10.1401C13.3999 9.19343 14.2332 9.73343 14.6399 10.1401C14.9866 10.4868 15.1665 10.8601 15.1665 11.2334C15.1665 11.6134 14.9932 11.9667 14.6399 12.3201L12.2799 14.6801C12.1132 14.8468 11.8332 14.9868 11.5999 15.0201L10.6999 15.1467C10.6465 15.1601 10.5999 15.1667 10.5465 15.1667ZM13.5399 10.6134C13.4199 10.6134 13.3132 10.6934 13.1599 10.8468L10.7999 13.2067C10.7799 13.2267 10.7465 13.2934 10.7465 13.3201L10.6265 14.1534L11.4599 14.0334C11.4865 14.0267 11.5532 13.9934 11.5732 13.9734L13.9332 11.6134C14.0399 11.5067 14.1665 11.3534 14.1665 11.2334C14.1665 11.1334 14.0866 10.9934 13.9332 10.8468C13.7732 10.6868 13.6532 10.6134 13.5399 10.6134Z" />
    <path d="M13.9471 12.8132C13.9004 12.8132 13.8537 12.8066 13.8137 12.7933C12.9337 12.5466 12.2337 11.8466 11.9871 10.9666C11.9137 10.6999 12.0671 10.4266 12.3337 10.3533C12.6004 10.2799 12.8737 10.4332 12.9471 10.6999C13.1004 11.2466 13.5337 11.6799 14.0804 11.8332C14.3471 11.9066 14.5004 12.1866 14.4271 12.4466C14.3671 12.6666 14.1671 12.8132 13.9471 12.8132Z" />
    <path d="M2.27344 15.1667C2.0001 15.1667 1.77344 14.94 1.77344 14.6667C1.77344 11.82 4.56679 9.5 8.00012 9.5C8.72679 9.5 9.44678 9.60668 10.1201 9.80668C10.3868 9.88668 10.5335 10.1667 10.4535 10.4267C10.3735 10.6933 10.0935 10.84 9.83346 10.76C9.24679 10.5867 8.63346 10.4933 8.00012 10.4933C5.12012 10.4933 2.77344 12.36 2.77344 14.66C2.77344 14.94 2.54677 15.1667 2.27344 15.1667Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>