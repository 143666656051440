<template>

  <architecture-base>

    <template v-slot:nav>

      <!-- Navigation bar -->
      <Sidebar active="parametre" />
    </template>

    <template v-slot:header>
      <!-- Title -->
      <div class="title">Settings</div>

      <!-- Button tool bar -->
      <button-bar :bg-color1="'bg-red'" :bg-color2="'bg-lightgreen'">

        <template v-slot:valueLabel1>
          <div class="text-white">
            Cancel the modifications
          </div>
        </template>

        <template v-slot:valueLabel2>
          Save the modifications
        </template>

      </button-bar>
    </template>

    <template v-slot:main>
      <crm-card></crm-card>
    </template>

  </architecture-base>

</template>

<script>
import ButtonBar from "../LayoutComponent/layout.buttonbar";
import ArchitectureBase from "../LayoutComponent/layout.base";
import Sidebar from "../LayoutComponent/layout.sidebar.vue";
import CrmCard from "./layout.crm.card";

export default {

  components: {
    ButtonBar,
    ArchitectureBase,
    Sidebar,
    CrmCard,
  },

}
</script>

<style scoped>
.title{
  font-size: calc((48 / 1728) * (100vw - var(--size-2x)));
  line-height: 100%;
  font-weight: 600;
}
</style>