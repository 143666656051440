<template>
  <svg width="33" height="33" viewBox="0 0 33 33" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.5 30.8996H12.5C5.25996 30.8996 2.16663 27.8062 2.16663 20.5662V12.5662C2.16663 5.32624 5.25996 2.23291 12.5 2.23291H19.1666C19.7133 2.23291 20.1666 2.68624 20.1666 3.23291C20.1666 3.77958 19.7133 4.23291 19.1666 4.23291H12.5C6.35329 4.23291 4.16663 6.41958 4.16663 12.5662V20.5662C4.16663 26.7129 6.35329 28.8996 12.5 28.8996H20.5C26.6466 28.8996 28.8333 26.7129 28.8333 20.5662V13.8996C28.8333 13.3529 29.2866 12.8996 29.8333 12.8996C30.38 12.8996 30.8333 13.3529 30.8333 13.8996V20.5662C30.8333 27.8062 27.74 30.8996 20.5 30.8996Z" />
    <path d="M29.8333 14.8996H24.5C19.94 14.8996 18.1666 13.1262 18.1666 8.56623V3.2329C18.1666 2.8329 18.4066 2.45956 18.78 2.3129C19.1533 2.1529 19.58 2.24623 19.8733 2.52623L30.54 13.1929C30.82 13.4729 30.9133 13.9129 30.7533 14.2862C30.5933 14.6596 30.2333 14.8996 29.8333 14.8996ZM20.1666 5.64623V8.56623C20.1666 12.0062 21.06 12.8996 24.5 12.8996H27.42L20.1666 5.64623Z" />
    <path d="M17.8334 18.8999H9.83337C9.28671 18.8999 8.83337 18.4466 8.83337 17.8999C8.83337 17.3532 9.28671 16.8999 9.83337 16.8999H17.8334C18.38 16.8999 18.8334 17.3532 18.8334 17.8999C18.8334 18.4466 18.38 18.8999 17.8334 18.8999Z" />
    <path d="M15.1667 24.2329H9.83337C9.28671 24.2329 8.83337 23.7796 8.83337 23.2329C8.83337 22.6862 9.28671 22.2329 9.83337 22.2329H15.1667C15.7134 22.2329 16.1667 22.6862 16.1667 23.2329C16.1667 23.7796 15.7134 24.2329 15.1667 24.2329Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>