<template>
  <div class="col-business-card text-start">
    <div class="padding-left-1x padding-top-1x padding-right-1x padding-bottom-1x bg-white border-radius-10px">

      <div class="sub-title">Business card</div>

      <q-form
          ref="form"
          :model="formModel"
          :rules="rul"
      >
        <!-- Input Reference number -->
        <q-form-item label="Reference number" prop="numReference" class="text-start my-2">
          <q-input disabled v-model="formModel.numReference" type="text" class="form-control" placeholder="No card registered yet"/>
        </q-form-item>

        <!-- Path -->
        <q-form-item label="Path" prop="path" class="text-start my-2">
          <q-input disabled v-model="formModel.path" type="text" class="form-control" placeholder="No card registered yet"/>
        </q-form-item>

        <!-- Profile link -->
        <q-form-item label="Profile link" prop="linkProfile" class="text-start my-2">
          <div class="input-group">
            <span class="input-group-text">app.profylead.com/profil/</span>
            <input
                :disabled="!card"
                v-model="formModel.linkProfile"
                type="text"
                id="basic-url"
                :placeholder="!card ? formModel.linkProfile: 'No card registered yet'"
            >
          </div>
        </q-form-item>

      </q-form>

      <!-- Button copy this link -->
      <button-base :is-disabled="!userId || !card" v-on:click="copyLink" :textColor="'text-white'" :bgColor="'bg-blue'" class="border-radius-28 margin-bottom-0-5x" id="btn-copy-link">
        <template v-slot:label>
          Copy this link
        </template>
      </button-base>

      <!-- Button copy this link -->
      <button-base :is-disabled="!userId || !card" v-on:click="showDeleteCard" :textColor="'text-white'" :bgColor="'bg-red'" class="border-radius-28 margin-bottom-0-5x" id="btn-delete-card">
        <template v-slot:label>
          Delete this card
        </template>
      </button-base>

      <!-- Button add a new card -->
      <button-base :is-disabled="!userId || !!card" v-on:click="showAssignNewCard" :textColor="'text-white'" :bgColor="'bg-blue'" class="border-radius-28 margin-bottom-0-5x" id="btn-add-card">
        <template v-slot:label>
          Add a new card
        </template>
      </button-base>

    </div>

    <template v-if="userId">
      <div class="padding-left-1x margin-top-1x padding-top-1x padding-right-1x padding-bottom-1x bg-white border-radius-10px">
        <!-- Global Performance -->
        <performances :title="'Global performances'" :line-datas="globalChartDatas" :is-perform-spc="'global'"></performances>

        <!-- Button send an invitation link -->
        <button-base v-on:click="showInvitationPopup" :textColor="'text-white'" :bgColor="'bg-blue'" class="border-radius-28 margin-top-1x" id="btn-send-invitation">
          <template v-slot:label>
            Send an invitation link
          </template>
        </button-base>
      </div>
    </template>

  </div>
</template>

<script>
import ButtonBase from "../LayoutComponent/layout.button";
import { ref, reactive, getCurrentInstance } from "vue";
import AssignCardPopup from "../LayoutComponent/Popups/AssignCardPopup";
import Performances from "../Performance/layout.performance";
import DeleteCardPopup from "../LayoutComponent/Popups/DeleteCardPopup";
import SendInvitPopup from "../LayoutComponent/Popups/SendInvitationPopup";
import { useMessageBox } from "@qvant/qui-max";
import StatisticsService from "../../../services/management/StatisticsService";
import { getCards, detachCard, attachCard, updateLink } from "../../../services/management/CardService";
import { getWorkers } from "@/services/management/UsersService";
import axios from "axios";

export default {

  props: ['userId'],

  components: {
    ButtonBase,
    Performances
  },

  setup() {
    let form = ref(null);
    const options = [ 'Choose a template', 'Leader’s Template', 'Salesman’s Template' ];
    const valueDefault = ref('Choose a template');
    let model = {
      numReference: null,
      path: null,
      linkProfile: null
    };
    let formModel = reactive(model);
    let app = getCurrentInstance();
    let rules = {
      numReference: app.appContext.config.globalProperties.$validator_not_null,
      path: app.appContext.config.globalProperties.$validator_not_null,
      linkProfile: app.appContext.config.globalProperties.$validator_not_null,
    }
    let rul = reactive(rules);

    return {
      app,
      form,
      rul,
      formModel,
      valueDefault,
      options,
    };
  },

  data() {
    return {
      card: null,
      globalChartDatas: {},
    };
  },

  methods : {

    getGlobalViewsChart(){
      getWorkers()
      .then((workers) => {
        const w = workers.find(worker=>worker.id==this.userId)
        this.globalChartDatas = w.global_views_chart
      })
      .catch((error) => {
        console.log("error", error);
      })
    },

    async copyLink(){
      const link = "https://app.profylead.com/profil/" + this.formModel.linkProfile;
      navigator.clipboard.writeText(link);
      this.app.appContext.config.globalProperties.$toast.show("Copied to clipboard.", {type: 'success'});
    },

    async showDeleteCard(){
      try {
        let messageBox = useMessageBox();
        const result = await messageBox(
            {
              component: DeleteCardPopup,
              listeners: {
                saveChanges: () => {
                  detachCard(this.userId)
                  .then(() => {
                    this.card = null;
                    Object.assign(
                      this.formModel,
                      {
                        numReference: null,
                        path: null,
                        linkProfile: null
                      }
                    )
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                },
              }
            },
        );
        console.log('resolve', result);
      } catch (result) {
        console.log('reject', result);
      }
    },

    async showAssignNewCard() {
      try {
        let messageBox = useMessageBox();
        const result = await messageBox(
            {
              component: AssignCardPopup,
              listeners: {
                saveChanges: (args) => {
                  attachCard(this.userId, args.numReference, args.pinCode)
                  .then(() => {
                    this.reloadCard();
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                },
              }
            },
        );
        console.log('resolve', result);
      } catch (result) {
        console.log('reject', result);
      }
    },

    async showInvitationPopup(){
      let messageBox = useMessageBox();
      messageBox({
        component: SendInvitPopup,
        listeners: {
          sendInvitation: () => {
            axios.post(this.app.appContext.config.globalProperties.$apiUrl + '/card/management/send-invitations/', {
              team_members: [parseInt(this.userId)],
            }, { headers: { Authorization: this.$store.state.user.token } })
            .then(async () => {
              this.app.appContext.config.globalProperties.$toast.show('Invitation sent!', {type: 'success'});
            })
            .catch((error) => {
              this.app.appContext.config.globalProperties.$toast.show('Error!', {type: 'error'});
              console.log("error", error)
            })
          },
        }
      })
      .then(result => {
        console.log('resolve', result);
      })
      .catch(error => {
        console.log('reject', error);
      })
    },

    async save(){
      if ((!!this.card) && this.card.pretty_link?.link != this.formModel.linkProfile) {
        updateLink(this.card.pretty_link?.id, this.formModel.linkProfile)
        .then(() => {
          this.reloadCard();
        })
        .catch((error) => {
          console.log(error);
        })
      }
    },

    reloadCard(){
      getCards().then((cards) => {
        this.card = cards.find(c=>c.owner == this.userId) ?? null
        this.formModel.numReference = this.card.serial_number;
        this.formModel.path = this.card.link?.link;
        this.formModel.linkProfile = this.card.pretty_link?.link;
      });
    }
  },

  mounted(){
    this.reloadCard();
    this.getGlobalViewsChart();
  }

}
</script>

<style scoped>
*{
  font-family: Poppins;
}

.sub-title{
  font-size: calc((33 / 1820) * (100vw - var(--size-2x)));
  line-height: calc((33 / 1820) * (100vw - var(--size-2x)));
  font-weight: 600;
  color: #000000;
}

.col-business-card{
  width: calc(50% - var(--size-1x) * 0.5);
  height: fit-content;
}

.border-radius-10px{
  border-radius: 10px;
}

.border-radius-28{
  border-radius: 28px!important;
}

#btn-copy-link, #btn-delete-card, #btn-add-card, #btn-send-invitation{
  width: 100%!important;
  margin-left: 0!important;
  margin-right: 0!important;
}

#basic-url{
  width: calc(50% - var(--size-1x) * 0.5);
  height: var(--size-3x);
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border: 1px solid #EEEFF2 !important;
  box-shadow: unset !important;
}
</style>