<template>
  <div class="d-flex justify-content-center legend-gap">
    <template v-for="(choice, index) in Object.keys(choices)" :key="index">
      <button
          v-on:click="selectTimeFrame(choice)"
          class="btn legend-item"
          :class="selectedTimeFrame === choice ? 'choice-enable' : 'choice-disable'"
      >
        {{ choice }}
      </button>
    </template>
  </div>
</template>

<script>

export default {

  data(){
    return {
      selectedTimeFrame: null,
      choices: {
        '1D': 3600*24,
        '1W': 3600*24*7,
        '1M': 3600*24*30,
        '1Y': 3600*24*365,
        'All': 3600*24*365*100,
      },
    }
  },

  methods: {
    selectTimeFrame(timeFrame) {
      this.selectedTimeFrame = timeFrame;
      this.$emit("changeOption", this.choices[this.selectedTimeFrame])
    }
  },

  mounted() {
    this.selectTimeFrame('All')
  },

}
</script>

<style scoped>
.legend-gap {
  gap: calc((20/1310) * (100vw - var(--size-2x)));
}

.btn{
  width: calc((20/640) * (100vw - var(--size-2x)));
}

.legend-item{
  margin: 0;
  padding: 0;
  height: calc((32 / 1728) * (100vw - var(--size-2x)));
  border-radius: 28px;
  box-shadow: unset !important;
  line-height: var(--line-height-20);
  font-size: calc((20 / 1728) * (100vw - var(--size-2x)));
}

.choice-disable{
  color: #9CA3AF;
}

.choice-enable{
  background: #0400BD;
  color: white;
}

.choice-enable:hover{
  background: #020087!important;
  color: white!important;
}

</style>