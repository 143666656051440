<template>

  <!-- Modal -->
  <div class="d-modal">
    <div class="d-modal-content">

      <div class="text-start">
        <btnBackPreviousPage @backPrev="hidePopup"></btnBackPreviousPage>
      </div>

      <div class="card-body">
      <div class="d-modal-body">
        <div class="slide-container">
          <div class="crop-image-dialog">

            <!-- Component crop image dialog -->
            <vue-cropper
                ref="cropper"
                :aspect-ratio="onloadAspectRatio"
                :guides="true"
                :background="true"
                :view-mode="2"
                :auto-crop-area="1"
                drag-mode="move"
                :src="uploadedImage"
                alt="Source Image"
            >
            </vue-cropper>

            <!-- Bouton valider image -->
            <q-button
                v-on:click="cropUploadedImage"
                class="w-100 cta-button bg-blue text-white margin-top-2x margin-bottom-2x"
            >
              Valider
            </q-button>

          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import btnBackPreviousPage from "../Navigation/back.previous.page";
import VueCropper from 'vue-cropperjs';
import { QButton } from '@qvant/qui-max';
import 'cropperjs/dist/cropper.css';

export default {

  name: "ImageCropperDialog",

  components: {
    VueCropper,
    btnBackPreviousPage,
    QButton,
  },

  props: {
    uploadedImage : {
      default: null,
    },
    aspectRatio : {
      default: null,
    }
  },

  data () {
    return {
      showCropper: false,
      imageFileType: null,
    }
  },

  methods: {
    /**
     * initialiser cropper et afficher image
     * @param imageFileType
     * @return {Promise<void>}
     */
    async initCropper(imageFileType) {
      this.showCropper = true;
      this.imageFileType = imageFileType;
      await new Promise(resolve => setTimeout(resolve, 50));
      this.$refs.cropper.replace(this.uploadedImage);
    },

    /**
     * Crop image
     * @return {Promise<void>}
     */
    async cropUploadedImage(){
      this.$emit('onCrop', this.$refs.cropper.getCroppedCanvas().toDataURL(this.imageFileType, 0.5));
      this.hidePopup();
    },

    /**
     * cache popup noir foncé
     */
    hidePopup(){
      this.showCropper = false;
      document.querySelector(".d-modal").style.display = "none";
    },

    /**
     * cache popup noir foncé
     */
    backPreviousPage(){
      document.querySelector(".d-modal").style.display = "none";
    },

  },

  computed : {
    /**
     * initialiser ratio d'aspect de cropper
     * @return {number}
     */
    onloadAspectRatio(){
      let res = null;
      if ((this.aspectRatio === 'portrait') || (this.aspectRatio === 'contactInfo')){
        res = 1;
      } else if (this.aspectRatio === 'landscape'){
        res = 5 / 3;
      } else if(this.aspectRatio === 'background'){
        res = 2;
      } else {
        res = null;
      }
      return res;
    }
  },

  mounted() {
    document.querySelector("#app").style.overflowX = "hidden"
    document.querySelector("#app").style.overflowY = "unset"
  }

}
</script>
<style scoped>
.d-modal {
  display: none;
  height: 100%;
  width: 100%;
  color: #333;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #000000;
  overflow-y: scroll;
  overflow-x: hidden;
}

.slide-container {
  width: 100%;
  position: relative;
}

.q-button, .btn-return{
  box-shadow: unset;
}

@media screen and (min-width: 750px) {

  .d-modal-content, .card-body, .d-modal-body, .slide-container, .crop-image-dialog{
    margin: auto!important;
    width: 80%;
    height: 100%;
  }
}
</style>