<template>
  <svg width="25" height="24" viewBox="0 0 25 24" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.5 10.75C7.98 10.75 6.75 9.52 6.75 8C6.75 6.48 7.98 5.25 9.5 5.25C11.02 5.25 12.25 6.48 12.25 8C12.25 9.52 11.02 10.75 9.5 10.75ZM9.5 6.75C8.81 6.75 8.25 7.31 8.25 8C8.25 8.69 8.81 9.25 9.5 9.25C10.19 9.25 10.75 8.69 10.75 8C10.75 7.31 10.19 6.75 9.5 6.75Z" />
    <path d="M15.5 22.75H9.5C4.07 22.75 1.75 20.43 1.75 15V9C1.75 3.57 4.07 1.25 9.5 1.25H13.5C13.91 1.25 14.25 1.59 14.25 2C14.25 2.41 13.91 2.75 13.5 2.75H9.5C4.89 2.75 3.25 4.39 3.25 9V15C3.25 19.61 4.89 21.25 9.5 21.25H15.5C20.11 21.25 21.75 19.61 21.75 15V10C21.75 9.59 22.09 9.25 22.5 9.25C22.91 9.25 23.25 9.59 23.25 10V15C23.25 20.43 20.93 22.75 15.5 22.75Z" />
    <path d="M21.75 5.75H16.25C15.84 5.75 15.5 5.41 15.5 5C15.5 4.59 15.84 4.25 16.25 4.25H21.75C22.16 4.25 22.5 4.59 22.5 5C22.5 5.41 22.16 5.75 21.75 5.75Z" />
    <path d="M19 8.5C18.59 8.5 18.25 8.16 18.25 7.75V2.25C18.25 1.84 18.59 1.5 19 1.5C19.41 1.5 19.75 1.84 19.75 2.25V7.75C19.75 8.16 19.41 8.5 19 8.5Z" />
    <path d="M3.17005 19.7001C2.93005 19.7001 2.69005 19.5801 2.55005 19.3701C2.32005 19.0301 2.41005 18.5601 2.75005 18.3301L7.68005 15.0201C8.76005 14.3001 10.25 14.3801 11.23 15.2101L11.56 15.5001C12.06 15.9301 12.91 15.9301 13.4 15.5001L17.56 11.9301C18.63 11.0201 20.2901 11.0201 21.3601 11.9301L22.99 13.3301C23.3 13.6001 23.34 14.0701 23.07 14.3901C22.8 14.7001 22.33 14.7401 22.01 14.4701L20.38 13.0701C19.88 12.6401 19.0301 12.6401 18.5401 13.0701L14.38 16.6401C13.32 17.5501 11.65 17.5501 10.58 16.6401L10.25 16.3501C9.79005 15.9601 9.03005 15.9201 8.52005 16.2701L3.60005 19.5801C3.46005 19.6601 3.31005 19.7001 3.17005 19.7001Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>