<template>
  <base-popup>

    <template v-slot:title>
      <div id="popup-title">Select the template you want to assign<br/> to the selected members</div>
    </template>

    <template v-slot:body>
      <q-form-item label="Template" prop="template" class="text-start my-2">
        <q-select v-model="valueDefault">
          <q-option
              v-for="item in options"
              :key="item"
              :label="item"
              :value="item"
          />
        </q-select>
      </q-form-item>
    </template>

    <template v-slot:button-bar>
      <button-base v-on:click="cancel" :textColor="'text-blue'" :bgColor="'bg-white'" class="border-radius-28 margin-bottom-0-5x" id="btn-cancel">
        <template v-slot:label>
          Cancel
        </template>
      </button-base>

      <button-base v-on:click="assignMember($emit)" :textColor="'text-white'" :bgColor="'bg-blue'" class="border-radius-28 margin-bottom-0-5x" id="btn-assign">
        <template v-slot:label>
          Yes, please assign this template to the selected members
        </template>
      </button-base>
    </template>

  </base-popup>


</template>

<script>
import BasePopup from "./BasePopup";
import { inject, ref } from 'vue';
import { QMessageBoxAction } from '@qvant/qui-max';
import ButtonBase from "../../LayoutComponent/layout.button";
import { QOption, QSelect, QFormItem } from "@qvant/qui-max";

let qMessageBoxContainer = undefined;

export default {

  props: [],

  components: {
    BasePopup,
    ButtonBase,
    QOption,
    QSelect,
    QFormItem,
  },

  data() {
    return {
      imageToUpload: null,
    }
  },

  setup(){
    qMessageBoxContainer = inject(
        'qMessageBoxContainer'
    )

    let options = [ 'Choose a template', 'Leader’s Template', 'Salesman’s Template' ];
    let valueDefault = ref('Leader’s Template');

    return {
      options,
      valueDefault,
      qMessageBoxContainer,
    }
  },

  methods : {

    cancel: () => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.cancel
      });
    },

    assignMember(emit){
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.confirm
      });
      emit("assignMember", "Successfully assign members");
    },

  },

  mounted() {

  }
}
</script>

<style scoped>
#btn-cancel, #btn-assign{
  width: 100%!important;
  margin-left: 0!important;
  margin-right: 0!important;
}

#btn-cancel{
  border: 1px solid #0400BD;
}

.border-radius-28{
  border-radius: 28px!important;
}

.text-blue{
  color: #0400BD;
}

.bg-blue{
  background: #0400BD;
}

.bg-red{
  background: #EC0000;
}
</style>