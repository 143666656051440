<template>
  <q-button
      class="cta-button shadow-none edit-button"
      :class="this.textColor + ' ' + this.bgColor"
  >
    <slot name="label"></slot>
  </q-button>
</template>

<script>
import { QButton } from '@qvant/qui-max';

export default {

  props: [ 'textColor', 'bgColor' ],

  components: {
    QButton,
  }

}
</script>

<style scoped>

.edit-button {
  /*font-size: calc((20/390) * (100vw - var(--size-2x)))!important;*/
  line-height: 150%;
  font-weight: 400;
  font-family: "Lexend";
  height: var(--size-2x)!important;
  border-radius: 28px !important;
}
</style>