<template>
  <base-popup>

    <template v-slot:svg-left>
      <SVGUserCircle />
    </template>

    <template v-slot:svg-right>
      <span class="row align-items-center" v-on:click="cancel">
        <SVGClose />
      </span>
    </template>

    <template v-slot:title>
        Add a new contact to your connections list
    </template>

    <template v-slot:body>
      <div class="left-inner-addon input-container">
        <i class="icon">
          <SVGUser :fill-color="'#9CA3AF'"/>
        </i>
        <input v-model="full_name" type="text" class="form-control text-description" placeholder="Full Name" />
      </div>

      <div class="left-inner-addon input-container">
        <i class="icon">
          <SVGMail :fill-color="'#9CA3AF'"/>
        </i>
        <input v-model="email" type="text" class="form-control text-description" placeholder="Email" />
      </div>

      <div class="input-container">
        <vue-tel-input :value="phone" @input="updatePhone" mode="international" id="tel-country-codes"></vue-tel-input>
      </div>
    </template>

    <template v-slot:button-bar>
      <q-button
          v-on:click="add"
          theme="secondary"
          class="btn btn-save p-0 mx-0 mt-0 margin-bottom-0-5x w-100 bg-blue"
      >
          <span class="text-white">
            Add contact
          </span>
      </q-button>
    </template>

  </base-popup>

</template>

<script>
import { inject } from 'vue';
import BasePopup from "./BasePopup"
import { QMessageBoxAction, QButton } from '@qvant/qui-max';
import SVGMail from "../../../../assets/svgs/app/svg.connection-mail";
import SVGUser from "../../../../assets/svgs/app/svg.user";
import SVGUserCircle from "../../../../assets/svgs/app/svg.connection-featured-user";
import SVGClose from "../../../../assets/svgs/app/svg.close-popup";
import { VueTelInput } from 'vue3-tel-input';

let qMessageBoxContainer = undefined;

export default {
  components: {
    BasePopup,
    SVGMail,
    SVGUser,
    SVGUserCircle,
    SVGClose,
    VueTelInput,
    QButton,
  },

  data() {
    return {
      full_name: "",
      email: "",
      phone: "",
    }
  },

  setup: () => {
    qMessageBoxContainer = inject(
        'qMessageBoxContainer'
    );

    return {
      qMessageBoxContainer,
    }
  },

  methods : {

    updatePhone(params) {
      if (typeof params == "string") {
        this.phone = params;
      }
    },

    cancel: () => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.cancel
      });
    },

    add(){
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.confirm
      });
      let contact = {
        full_name: this.full_name,
        email: this.email,
        phone: this.phone,
        tags: [],
      }
      console.log(contact);
      this.$emit("add", contact);
    }
  },

}
</script>

<style scoped>
.btn{
  height: var(--size-3x);
}

.bg-blue {
  background-color: #0400BD;
}

.text-description{
  line-height: 150%;
  font-weight: 300;
  font-family: "Lexend";
}

.form-control{
  font-size: var(--size-1x)!important;
}

#tel-country-codes{
  border-radius: 8px!important;
  border: 1px solid #ced4da;
  height: var(--size-3x);
  margin-bottom: var(--size-2x);
}
</style>