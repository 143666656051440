<template>
  <svg width="16" height="16" viewBox="0 0 16 16" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.99967 15.1667C4.04634 15.1667 0.833008 11.9533 0.833008 8C0.833008 4.04667 4.04634 0.833336 7.99967 0.833336C11.953 0.833336 15.1663 4.04667 15.1663 8C15.1663 11.9533 11.953 15.1667 7.99967 15.1667ZM7.99967 1.83334C4.59967 1.83334 1.83301 4.6 1.83301 8C1.83301 11.4 4.59967 14.1667 7.99967 14.1667C11.3997 14.1667 14.1663 11.4 14.1663 8C14.1663 4.6 11.3997 1.83334 7.99967 1.83334Z" />
    <path d="M8.83995 10.8533C8.71328 10.8533 8.58661 10.8067 8.48661 10.7067L6.13328 8.35333C5.93995 8.16 5.93995 7.84 6.13328 7.64666L8.48661 5.29333C8.67995 5.1 8.99995 5.1 9.19328 5.29333C9.38661 5.48666 9.38661 5.80666 9.19328 6L7.19328 8L9.19328 10C9.38661 10.1933 9.38661 10.5133 9.19328 10.7067C9.09995 10.8067 8.97328 10.8533 8.83995 10.8533Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>