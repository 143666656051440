<template>
  <svg width="20" height="20" viewBox="0 0 20 20" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.10834 18.9584C8.70834 18.9584 8.30837 18.8584 7.9417 18.6584C7.20003 18.2417 6.75833 17.5 6.75833 16.6583V12.2C6.75833 11.775 6.48332 11.15 6.22499 10.825L3.05833 7.50003C2.53333 6.97503 2.125 6.05835 2.125 5.38335V3.45004C2.125 2.10837 3.14168 1.05835 4.43335 1.05835H15.55C16.825 1.05835 17.8583 2.0917 17.8583 3.3667V5.2167C17.8583 6.0917 17.3334 7.10001 16.8334 7.59168C16.5917 7.83335 16.1917 7.83335 15.95 7.59168C15.7084 7.35001 15.7084 6.95002 15.95 6.70835C16.2584 6.40002 16.6083 5.70837 16.6083 5.2167V3.3667C16.6083 2.78337 16.1333 2.30835 15.55 2.30835H4.43335C3.84168 2.30835 3.375 2.80837 3.375 3.45004V5.38335C3.375 5.69168 3.625 6.30004 3.95 6.62504L7.15837 10C7.58337 10.525 7.99999 11.4084 7.99999 12.2V16.6583C7.99999 17.2084 8.37501 17.475 8.54168 17.5667C8.90001 17.7667 9.32503 17.7584 9.65837 17.5584L10.825 16.8084C11.0667 16.6667 11.3 16.2083 11.3 15.9C11.3 15.5583 11.5833 15.275 11.925 15.275C12.2667 15.275 12.55 15.5583 12.55 15.9C12.55 16.65 12.0833 17.5084 11.4917 17.8667L10.3334 18.6167C9.95836 18.8417 9.53334 18.9584 9.10834 18.9584Z" />
    <path d="M13.3918 14.3917C11.5751 14.3917 10.1001 12.9167 10.1001 11.1C10.1001 9.28336 11.5751 7.80835 13.3918 7.80835C15.2084 7.80835 16.6835 9.28336 16.6835 11.1C16.6835 12.9167 15.2084 14.3917 13.3918 14.3917ZM13.3918 9.05835C12.2668 9.05835 11.3501 9.97503 11.3501 11.1C11.3501 12.225 12.2668 13.1417 13.3918 13.1417C14.5168 13.1417 15.4335 12.225 15.4335 11.1C15.4335 9.97503 14.5168 9.05835 13.3918 9.05835Z" />
    <path d="M16.5583 14.8917C16.3999 14.8917 16.2416 14.8333 16.1166 14.7083L15.2833 13.875C15.0416 13.6333 15.0416 13.2333 15.2833 12.9917C15.525 12.75 15.925 12.75 16.1666 12.9917L17 13.825C17.2416 14.0667 17.2416 14.4667 17 14.7083C16.8833 14.825 16.7166 14.8917 16.5583 14.8917Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>