<template>
  <base-popup>

    <template v-slot:svg-left>
      <SVGDeleteCircle />
    </template>

    <template v-slot:svg-right>
      <span class="row align-items-center" v-on:click="cancel">
        <SVGClose />
      </span>
    </template>

    <template v-slot:title>
      Are you sure that you want to save the contacts from this selection?
    </template>

    <template v-slot:body></template>

    <template v-slot:button-bar>

      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center margin-top-2x">
        <q-button
            v-on:click="cancel"
            theme="secondary"
            class="btn btn-save p-0 mx-0 margin-bottom-0-5x btn-cancel shadow-none"
        >
          <span class="text-black">
            Cancel
          </span>
        </q-button>

        <q-button
            v-on:click="save($emit)"
            theme="secondary"
            class="btn btn-save p-0 mx-0 margin-bottom-0-5x btn-delete bg-blue"
        >
          <span class="text-white">
            Save
          </span>
        </q-button>
      </div>

    </template>

  </base-popup>

</template>

<script>
import { inject } from 'vue';
import BasePopup from "./BasePopup"
import { QMessageBoxAction, QButton } from '@qvant/qui-max';
import SVGDeleteCircle from "../../../../assets/svgs/app/svg.connection-featured-delete";
import SVGClose from "../../../../assets/svgs/app/svg.close-popup";

let qMessageBoxContainer = undefined;

export default {

  props: [ 'listToExport' ],

  components: {
    BasePopup,
    SVGDeleteCircle,
    SVGClose,
    QButton,
  },

  data() {
    return {
      tagSelected: [],
    }
  },

  setup: () => {
    qMessageBoxContainer = inject(
        'qMessageBoxContainer'
    );

    return {
      qMessageBoxContainer,
    }
  },

  methods : {

    cancel: () => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.cancel
      });
    },

    save(emit){
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.confirm
      });
      console.log(this.listToExport);
      emit("save", this.listToExport);
    }
  },

  mounted(){

  }

}
</script>

<style scoped>
.btn{
  height: var(--size-3x);
}

.btn-cancel{
  border: 1px solid #D1D5DB;
}

.btn-cancel, .btn-delete{
  width: calc((100vw - var(--size-4x)) * 0.5 - var(--size-0-5x));
}
</style>