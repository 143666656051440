<template>
  <svg width="21" height="21" viewBox="0 0 21 21" fill="currentColor" class="me-3" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.9167 10.5C20.9167 16.2529 16.253 20.9166 10.5 20.9166C4.74704 20.9166 0.0833435 16.2529 0.0833435 10.5C0.0833435 4.74701 4.74704 0.083313 10.5 0.083313C16.253 0.083313 20.9167 4.74701 20.9167 10.5ZM12.1235 5.98059C11.111 4.63063 8.99906 4.97336 8.46545 6.5742L8.11617 7.62204C7.97973 8.03137 7.53729 8.25259 7.12796 8.11614C6.71863 7.9797 6.49741 7.53726 6.63385 7.12793L6.98313 6.0801C7.91532 3.28352 11.6048 2.68481 13.3735 5.04309C14.4096 6.42451 14.3373 8.34261 13.2002 9.64214L12.2427 10.7364C11.6229 11.4448 11.2813 12.354 11.2813 13.2952V13.625C11.2813 14.0565 10.9315 14.4062 10.5 14.4062C10.0685 14.4062 9.71876 14.0565 9.71876 13.625V13.2952C9.71876 11.9755 10.1978 10.7007 11.0668 9.70752L12.0243 8.61323C12.6752 7.86933 12.7166 6.77136 12.1235 5.98059ZM10.5 17.7916C11.0753 17.7916 11.5417 17.3253 11.5417 16.75C11.5417 16.1747 11.0753 15.7083 10.5 15.7083C9.92471 15.7083 9.45834 16.1747 9.45834 16.75C9.45834 17.3253 9.92471 17.7916 10.5 17.7916Z" :fill="'#9191A4'"/>
  </svg>
</template>

<script>
export default {

  name: "svg.besoin.aide"

}
</script>

<style scoped>

</style>