<template>
  <svg width="25" height="24" viewBox="0 0 25 24" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.5 18.25H15.5C15.09 18.25 14.75 17.91 14.75 17.5C14.75 17.09 15.09 16.75 15.5 16.75H22.5C22.91 16.75 23.25 17.09 23.25 17.5C23.25 17.91 22.91 18.25 22.5 18.25Z" />
    <path d="M5.5 18.25H2.5C2.09 18.25 1.75 17.91 1.75 17.5C1.75 17.09 2.09 16.75 2.5 16.75H5.5C5.91 16.75 6.25 17.09 6.25 17.5C6.25 17.91 5.91 18.25 5.5 18.25Z" />
    <path d="M22.5 7.25H19.5C19.09 7.25 18.75 6.91 18.75 6.5C18.75 6.09 19.09 5.75 19.5 5.75H22.5C22.91 5.75 23.25 6.09 23.25 6.5C23.25 6.91 22.91 7.25 22.5 7.25Z" />
    <path d="M9.5 7.25H2.5C2.09 7.25 1.75 6.91 1.75 6.5C1.75 6.09 2.09 5.75 2.5 5.75H9.5C9.91 5.75 10.25 6.09 10.25 6.5C10.25 6.91 9.91 7.25 9.5 7.25Z" />
    <path d="M13.5 21.25H7.5C5.78 21.25 4.75 20.22 4.75 18.5V16.5C4.75 14.78 5.78 13.75 7.5 13.75H13.5C15.22 13.75 16.25 14.78 16.25 16.5V18.5C16.25 20.22 15.22 21.25 13.5 21.25ZM7.5 15.25C6.61 15.25 6.25 15.61 6.25 16.5V18.5C6.25 19.39 6.61 19.75 7.5 19.75H13.5C14.39 19.75 14.75 19.39 14.75 18.5V16.5C14.75 15.61 14.39 15.25 13.5 15.25H7.5Z" />
    <path d="M17.5 10.25H11.5C9.78 10.25 8.75 9.22 8.75 7.5V5.5C8.75 3.78 9.78 2.75 11.5 2.75H17.5C19.22 2.75 20.25 3.78 20.25 5.5V7.5C20.25 9.22 19.22 10.25 17.5 10.25ZM11.5 4.25C10.61 4.25 10.25 4.61 10.25 5.5V7.5C10.25 8.39 10.61 8.75 11.5 8.75H17.5C18.39 8.75 18.75 8.39 18.75 7.5V5.5C18.75 4.61 18.39 4.25 17.5 4.25H11.5Z" />
  </svg>
</template>

<script>
export default {

  props: ['fillColor'],

}
</script>

<style scoped>

</style>