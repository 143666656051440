<template>
  <info-base @prev="prev" @next="next" :hide_previous_button="!!setupToken">

    <template v-slot:title>
      Password
    </template>

    <template v-slot:form>

      <q-form
          ref="form"
          :model="formModel"
          :rules="rul"
      >
        <!-- Password -->
        <q-form-item label="Password" prop="password" class="text-start my-2">
          <q-input v-model="formModel.password" type="password" class="form-control" placeholder="Enter your password" password-switch/>
        </q-form-item>

        <!-- Confirm your password -->
        <q-form-item label="Confirm your password" prop="passwordConfirm" class="text-start my-2">
          <q-input v-model="formModel.passwordConfirm" type="password" class="form-control" placeholder="Confirm your password" password-switch/>
        </q-form-item>

      </q-form>

    </template>


    <template v-slot:label>
      Start to use Profylead
      <SVGArrowRight :fillColor="'white'"/>
    </template>


  </info-base>
</template>

<script>
import SVGArrowRight from "../../../assets/svgs/app/svg.arrow-circle-right";
import InfoBase from "./info.base"
import { useStore } from 'vuex'
import { useRouter } from "vue-router";
import { ref, reactive, getCurrentInstance } from "vue";
import axios from "axios";

export default {

  components: {
    SVGArrowRight,
    InfoBase
  },

  setup() {
    let form = ref();
    let store = useStore();
    let router = useRouter();
    let model = { password: null, passwordConfirm: null, };
    let formModel = reactive(model);
    let app = getCurrentInstance();
    let rules = {
      password: app.appContext.config.globalProperties.$validator_password,
      passwordConfirm: app.appContext.config.globalProperties.$validator_password,
    };
    let rul = reactive(rules);

    return {
      app,
      form,
      rul,
      formModel,
      store,
      router,
    };

  },

  data() {
    return {
      setupToken: null,
    };
  },

  methods : {
    /**
     * Valider le formule est par rapport règle
     * @return {Promise<void>}
     */
     async next() {
      if (!this.setupToken) {
        await this.form?.value?.validate().then((valid) => {
          if (valid) {
            let { isValid, invalidFields } = valid;
            console.log('QForm | validate', isValid, invalidFields);

            if (isValid) {
              this.store.commit("savePassword", this.formModel.password)
              this.signUp()
              this.router.push("/");
            }
          }
        })
      }
      else {
        this.form?.validate().then((valid) => {
          if (valid?.isValid) {
            axios.post(this.app.appContext.config.globalProperties.$apiUrl + '/card/users/update_password/', {
              updateToken: this.setupToken,
              newPassword: this.formModel.password,
            }, {})
            .then(async () => {
              this.router.push("/");
              this.app.appContext.config.globalProperties.$toast.show('Success!', {type: 'success'});
            })
            .catch((error) => {
              this.app.appContext.config.globalProperties.$toast.show('Error!', {type: 'error'});
              console.log("error", error)
            })
          }
        })
        .catch(err => {
            console.log(err);
          }
        )
      }
    },

    /**
     * Redirect to previous page
     */
    prev() {
      this.router.push('/background');
    },

    /**
     * Sign up
     * @return {Promise<void>}
     */
    async signUp() {
      await axios
          .post(this.app.appContext.config.globalProperties.$apiUrl + '/card/register/', {
            username: this.store.state.user.email,
            first_name: this.store.state.user.prenom,
            last_name: this.store.state.user.nom,
            email: this.store.state.user.email,
            date_joined: new Date().toISOString(),
            tel: this.store.state.user.tel,
            company_label: this.store.state.user.entreprise,
            position: this.store.state.user.poste,
            avatar: this.store.state.user.avatarImage,
            password: this.store.state.user.password,
            title_connect_button: "Connect with " + this.store.state.user.prenom
          }, {})

          .then(async (response) => {
            this.store.commit("saveId", response.data.id)
            await this.signIn(this.store.state.user.email, this.store.state.user.password)
            await this.addProfile()
            this.app.appContext.config.globalProperties.$toast.show('Votre profil a été créé avec succès !', {type: 'success'});
          })

          .catch((error) => {
            this.app.appContext.config.globalProperties.$toast.show('Erreur, adresse email est déjà existant!', {type: 'error'});
            console.log("error", error)
          })
    },

    /**
     * Sign in
     */
    async signIn(login, password) {
      await axios
          .post(this.app.appContext.config.globalProperties.$apiUrl + '/card/login/', {
            login: login,
            password: password
          }, {})

          .then((response) => {
            this.store.commit("saveToken", response.data.token)
            this.app.appContext.config.globalProperties.$toast.show('Bienvenue sur l’horizon Profylead !', {type: 'success'});
          })

          .catch((error) => {
            console.log("error", error)
            this.app.appContext.config.globalProperties.$toast.show('Erreur, adresse email est déjà existant!', {type: 'error'});
          })
    },

    /**
     * Create a profile
     * @return {Promise<void>}
     */
    async addProfile() {
      await axios
          .get(this.app.appContext.config.globalProperties.$apiUrl + '/card/pageprofiles/me/', {
            headers: { Authorization: this.store.state.user.token }
          })
          .then((response) => {
            axios
                .post(this.app.appContext.config.globalProperties.$apiUrl + '/card/components/', {
                  component: "ProfileComponent",
                  title: "My profile",
                  datas: [
                    {
                      id: this.store.state.user.id,
                      first_name: this.store.state.user.prenom,
                      last_name: this.store.state.user.nom,
                      tel: this.store.state.user.tel,
                      email: this.store.state.user.email,
                      position: this.store.state.user.poste,
                      company_label: this.store.state.user.entreprise,
                      avatar: this.store.state.user.avatarImage,
                      backgroundImage: this.store.state.user.backgroundImage,
                      logo_entreprise:  "",
                      title_connect_button: "Connect with " + this.store.state.user.prenom
                    }
                  ],
                  page_profile: response.data.id,
                }, {headers: {Authorization: this.store.state.user.token}})
                .then((response) => {
                  this.store.commit("saveIdComponent", response.data.id)
                })
                .catch((error) => {
                  console.log("error", error)
                })
          })
          .catch((error) => {
            console.log("error", error);
          })
    },
  },

  mounted() {
    if (this.$route.query.setupToken) {
      this.setupToken = this.$route.query.setupToken;
    }
  }
};
</script>

<style>

</style>

