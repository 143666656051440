<template>
  <svg width="20" height="20" viewBox="0 0 20 20" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.2917 18.9583H8.54167V12.9167H7.74999C7.26666 12.9167 6.875 12.525 6.875 12.0417V10.4583C6.875 9.97499 7.26666 9.58333 7.74999 9.58333H8.54167V7.5C8.54167 5.775 9.94167 4.375 11.6667 4.375H13.9167C14.4 4.375 14.7917 4.76666 14.7917 5.24999V7.25001C14.7917 7.73334 14.4 8.125 13.9167 8.125H12.2917V9.58333H13.8583C14.125 9.58333 14.3667 9.7 14.5333 9.9C14.7 10.1 14.7667 10.3667 14.7167 10.625L14.4 12.2083C14.3167 12.6167 13.9583 12.9083 13.5417 12.9083H12.2917V18.9583ZM9.79167 17.7083H11.0417V11.6667H13.2417L13.4083 10.8333H11.05V7.74999C11.05 7.26666 11.4417 6.875 11.925 6.875H13.55V5.625H11.6667C10.6333 5.625 9.79167 6.46667 9.79167 7.5V10.8333H8.125V11.6667H9.79167V17.7083Z" />
    <path d="M12.5001 18.9584H7.50008C2.97508 18.9584 1.04175 17.0251 1.04175 12.5001V7.50008C1.04175 2.97508 2.97508 1.04175 7.50008 1.04175H12.5001C17.0251 1.04175 18.9584 2.97508 18.9584 7.50008V12.5001C18.9584 17.0251 17.0251 18.9584 12.5001 18.9584ZM7.50008 2.29175C3.65841 2.29175 2.29175 3.65841 2.29175 7.50008V12.5001C2.29175 16.3417 3.65841 17.7084 7.50008 17.7084H12.5001C16.3417 17.7084 17.7084 16.3417 17.7084 12.5001V7.50008C17.7084 3.65841 16.3417 2.29175 12.5001 2.29175H7.50008Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>