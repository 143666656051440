<template>
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D6D6FF"/>
    <path d="M25 26.75C23.48 26.75 22.25 25.52 22.25 24C22.25 22.48 23.48 21.25 25 21.25C26.52 21.25 27.75 22.48 27.75 24C27.75 25.52 26.52 26.75 25 26.75ZM25 22.75C24.31 22.75 23.75 23.31 23.75 24C23.75 24.69 24.31 25.25 25 25.25C25.69 25.25 26.25 24.69 26.25 24C26.25 23.31 25.69 22.75 25 22.75Z" fill="#0400BD"/>
    <path d="M31 38.75H25C19.57 38.75 17.25 36.43 17.25 31V25C17.25 19.57 19.57 17.25 25 17.25H29C29.41 17.25 29.75 17.59 29.75 18C29.75 18.41 29.41 18.75 29 18.75H25C20.39 18.75 18.75 20.39 18.75 25V31C18.75 35.61 20.39 37.25 25 37.25H31C35.61 37.25 37.25 35.61 37.25 31V26C37.25 25.59 37.59 25.25 38 25.25C38.41 25.25 38.75 25.59 38.75 26V31C38.75 36.43 36.43 38.75 31 38.75Z" fill="#0400BD"/>
    <path d="M37.25 21.75H31.75C31.34 21.75 31 21.41 31 21C31 20.59 31.34 20.25 31.75 20.25H37.25C37.66 20.25 38 20.59 38 21C38 21.41 37.66 21.75 37.25 21.75Z" fill="#0400BD"/>
    <path d="M34.5 24.5C34.09 24.5 33.75 24.16 33.75 23.75V18.25C33.75 17.84 34.09 17.5 34.5 17.5C34.91 17.5 35.25 17.84 35.25 18.25V23.75C35.25 24.16 34.91 24.5 34.5 24.5Z" fill="#0400BD"/>
    <path d="M18.67 35.7001C18.43 35.7001 18.19 35.5801 18.05 35.3701C17.82 35.0301 17.91 34.5601 18.25 34.3301L23.18 31.0201C24.26 30.3001 25.75 30.3801 26.73 31.2101L27.06 31.5001C27.56 31.9301 28.41 31.9301 28.9 31.5001L33.06 27.9301C34.13 27.0201 35.7901 27.0201 36.8601 27.9301L38.49 29.3301C38.8 29.6001 38.84 30.0701 38.57 30.3901C38.3 30.7001 37.83 30.7401 37.51 30.4701L35.88 29.0701C35.38 28.6401 34.5301 28.6401 34.0401 29.0701L29.88 32.6401C28.82 33.5501 27.15 33.5501 26.08 32.6401L25.75 32.3501C25.29 31.9601 24.53 31.9201 24.02 32.2701L19.1 35.5801C18.96 35.6601 18.81 35.7001 18.67 35.7001Z" fill="#0400BD"/>
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F4F3FF" stroke-width="8"/>
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>