<template>

  <span>
    <span class="text-danger" v-on:click="showPopup">Supprimer</span>
    <div class="modal-dialog" role="document" style="position: absolute">
      <div class="modal-content rounded-3 shadow">
        <div class="modal-body p-4 text-center">
          <h5 class="mb-0 text-black">Confirmation de suppression</h5>
          <p class="mb-0 text-black">Vous les vous vraiment supprimer ce composant?</p>
        </div>
        <div class="modal-footer flex-nowrap p-0">

          <button
              type="button"
              v-on:click="deleteComponent"
              class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 border-end"
          >
            <strong>Oui</strong>
          </button>

          <button
              type="button"
              @click="closePopup"
              class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0"
              data-bs-dismiss="modal"
          >
            Non
          </button>

        </div>
      </div>
    </div>
  </span>

</template>

<script>
import axios from "axios";

export default {

  name: "profil.delete.composant",

  props: [ 'id' ],

  methods : {
    showPopup() {
      document.querySelector(".modal-dialog").style.display = "block"
    },

    closePopup() {
      document.querySelector(".modal-dialog").style.display = "none";
    },

    deleteComponent() {
      axios
          .delete(this.$apiUrl + '/card/components/' + this.id + '/',
              { headers: {Authorization: this.$store.state.user.token}})

          .then(() => {
            this.$toast.show(
                'Votre suppression a été effectué avec succès !',
                {type: 'success'}
            );
            window.location.reload(true);
          })

          .catch(error => {
            this.$toast.show(error.message, {type: 'error'});
            console.log(error.message)
          })
    }
  }

}
</script>

<style scoped>
.modal-dialog{
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  background: #ffffff;
  border: 1px solid #DEDEDE;
  display: none;
}
</style>