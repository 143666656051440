import axios from "axios";
import store from "../../store";

export const buildPageForApp = (pageId) => {
    return new Promise((resolve, reject) => {
        return axios.get(process.env.VUE_APP_ENV_API_URL + '/card/pageprofiles/' + pageId + '/',
            { 'headers': { 'Authorization': store.state.user.token } })
        .then((response) => {
            let computedPage = {};
            const page = response.data;
            console.log("page: ", page)
            console.log("template page: ", page.template?.page)
            if (page.template){
                const userProfile = page.components.find(c=>c.component === "ProfileComponent")
                if (!userProfile) {
                    reject("no pageprofile");
                    return;
                }
                const templateProfile = page.template.page.components.find(c=>c.component === "ProfileComponent");
                
                // const userContact = page.components.find(c=>c.component === "ContactComponent");
                const templateContact = page.template.page.components.find(c=>c.component === "ContactComponent");

                const userSocials = userProfile.datas[0].socials || {};
                console.log(userSocials);
                const templateSocials = page.template.page.components.find(c=>c.component === "ReseauxSociauxComponent");

                computedPage = {
                    ...page.template.page,
                    components: [
                        ...page.template.page.components.filter(c=>!["ProfileComponent", "ContactComponent", "ReseauxSociauxComponent"].includes(c.component)),
                        {
                            ...userProfile,
                            datas: [{
                                ...userProfile.datas[0],
                                backgroundImage: templateProfile.datas[0].backgroundImage,
                                company: page.template.company,
                            }]
                        },
                        ( // contacts
                            templateContact
                            ? {
                                ...templateContact,
                                datas: templateContact.datas.map(tC => {
                                    if (tC.type==="appel" && userProfile.datas[0].tel) {
                                        return {
                                            ...tC,
                                            lien: userProfile.datas[0].tel,
                                        }
                                    }
                                    else if (tC.type==="message" && userProfile.datas[0].tel) {
                                        return {
                                            ...tC,
                                            lien: userProfile.datas[0].tel,
                                        }
                                    }
                                    else if (tC.type==="mail" && userProfile.datas[0].email) {
                                        return {
                                            ...tC,
                                            lien: userProfile.datas[0].email,
                                        }
                                    }
                                    else {
                                        return tC;
                                    }
                                })
                            }
                            : {}
                        ),
                        ...( // reseaux
                            (templateSocials)// && (Object.keys(userSocials).length > 0))
                            ? [{
                                ...templateSocials,
                                datas: templateSocials.datas.map(tS => {
                                    if (tS.component==="WhatsApp" && userSocials.whatsapp) {
                                        return {
                                            ...tS,
                                            link: userSocials.whatsapp ?? tS.link,
                                        }
                                    }
                                    else if (tS.component==="LinkedIn" && userSocials.linkedin) {
                                        return {
                                            ...tS,
                                            link: userSocials.linkedin ?? tS.link,
                                        }
                                    }
                                    else {
                                        return tS;
                                    }
                                })
                            }]
                            : []
                        )
                    ],
                    owner: page.owner,
                }
                computedPage.fromTemplate = true;
            }
            else {
                computedPage = page;
            }
            console.log(computedPage)
            resolve(computedPage);
        })
        .catch((error) => {
            console.log(error.message)
            reject(error)
        })
    });
}