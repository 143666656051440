<template>
  <svg width="32" height="33" viewBox="0 0 32 33" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.4401 11.06C27.8934 11.06 27.4401 10.6066 27.4401 10.06V7.63329C27.4401 6.65996 26.6534 5.87329 25.6801 5.87329H6.32006C5.34672 5.87329 4.56006 6.65996 4.56006 7.63329V10.0733C4.56006 10.62 4.10673 11.0733 3.56006 11.0733C3.01339 11.0733 2.56006 10.62 2.56006 10.06V7.63329C2.56006 5.55329 4.25339 3.87329 6.32006 3.87329H25.6801C27.7601 3.87329 29.4401 5.56662 29.4401 7.63329V10.0733C29.4401 10.62 29.0001 11.06 28.4401 11.06Z" />
    <path d="M16 29.1266C15.4533 29.1266 15 28.6733 15 28.1266V5.97998C15 5.43331 15.4533 4.97998 16 4.97998C16.5467 4.97998 17 5.43331 17 5.97998V28.1266C17 28.6866 16.5467 29.1266 16 29.1266Z" />
    <path d="M21.2532 29.1267H10.7466C10.1999 29.1267 9.74658 28.6734 9.74658 28.1267C9.74658 27.58 10.1999 27.1267 10.7466 27.1267H21.2532C21.7999 27.1267 22.2532 27.58 22.2532 28.1267C22.2532 28.6734 21.7999 29.1267 21.2532 29.1267Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>