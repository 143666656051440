<template>
  <q-button class="shadow-none btn-tags text-tags" :style="{ background: this.bgTag }">
    <slot name="labelTag"></slot>
  </q-button>
</template>

<script>
import { QButton } from '@qvant/qui-max';

export default {

  props: [ 'bgTag' ],

  components: {
    QButton,
  },
}
</script>

<style scoped>
.btn-tags {
  display: inline-block;
  width: fit-content;
  height: fit-content;
  border-radius: 28px!important;
  padding: 0 calc((7/390) * (100vw - var(--size-2x))) !important;
  margin: var(--size-0-5x) 0 var(--size-0-5x) 0;
}

.text-tags{
  line-height: 250%;
  font-weight: 400;
}
</style>