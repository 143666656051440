<template>
  <info-base @prev="prev" @next="handleSubmitClick">

    <template v-slot:title>
      Forgot password
    </template>

    <template v-slot:form>

      <q-form
          ref="form"
          :model="formModel"
          :rules="rul"
      >

        <!-- Input email -->
        <q-form-item label="Email" prop="email" class="text-start">
          <q-input v-model="formModel.email" class="form-control" placeholder="Votre mail"/>
        </q-form-item>

        <!-- explication -->
        <p class="text-start text-10">
          Indiquez l'adresse e-mail associée à votre Espace Client pour générer un nouveau mot de passe
        </p>

      </q-form>

    </template>

    <template v-slot:label>
      Envoyer
    </template>

  </info-base>

</template>

<script>
import { ref, reactive, computed, getCurrentInstance } from "vue";
import { useRouter } from 'vue-router';
import InfoBase from "./info.base"

export default {

  components: {
    InfoBase
  },

  setup() {
    const router = useRouter();
    let form = ref(null);
    let model = { email: null };
    let formModel = reactive(model);
    let app = getCurrentInstance();
    let rules = {
      email: app.appContext.config.globalProperties.$validator_email
    };
    let rul = reactive(rules);

    /**
     * Vérification null de formule
     * @return isComplete (bool)
     */
    let isComplete = computed(()=>{
      return (/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(formModel.email));
    });

    /**
     * Valider le formule est par rapport règle
     * @return {Promise<void>}
     */
    let handleSubmitClick = async () => {
      await form?.value?.validate()
        .then((valid) => {
          if (valid) {
            let { isValid } = valid;
            //console.log('QForm | validate', isValid, invalidFields);
            if (isValid) {
              //statement of init email
              console.log("statement of init email")
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    };

    /**
     * Redirect to previous page
     */
    let prev = () => {
      router.push('/');
    };

    return {
      rul,
      form,
      formModel,
      isComplete,
      prev,
      handleSubmitClick,
    };

  }
}
</script>

<style scoped>
.text-10{
  font-size: 10px;
  font-family: "Lexend";
}
</style>