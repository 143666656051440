<template>
  <div class="row btn-toolbar d-flex justify-content-center align-items-center">

    <!-- first button -->
    <button-base v-on:click="btn1Click" v-if="!!this.$slots.valueLabel1" :textColor="'text-blue'" :bgColor="bgColor1" :boxShadow="'btn-box-shadow'" class="border-radius-28">
      <template v-slot:label>
        <slot name="valueLabel1"></slot>
      </template>
    </button-base>

    <!-- second button -->
    <button-base v-on:click="btn2Click" v-if="!!this.$slots.valueLabel2" :textColor="'text-white'" :bgColor="bgColor2" class="border-radius-28">
      <template v-slot:label>
        <slot name="valueLabel2"></slot>
      </template>
    </button-base>
    

    <!-- third button -->
    <button-base v-on:click="btn3Click" v-if="!!this.$slots.valueLabel3" :textColor="'text-white'" :bgColor="bgColor3" class="border-radius-28">
      <template v-slot:label>
        <slot name="valueLabel3"></slot>
      </template>
    </button-base>

  </div>
</template>

<script>
import ButtonBase from "./layout.button";

export default {

  props: [ 'btn1Click', 'btn2Click', 'btn3Click', 'bgColor1', 'bgColor2', 'bgColor3' ],

  components: {
    ButtonBase,
  },
}
</script>

<style scoped>
.bg-blue{
  background: #0400BD;
}

.bg-lightgreen{
  background: #25D366;
}

.text-blue{
  color: #0400BD;
}

.bg-red{
  background: #EC0000;
}

.btn-box-shadow{
  box-shadow: 0 1px 6px rgba(211, 221, 255, 0.48), 0 1px 4px rgba(63, 63, 63, 0.07) !important;
}

.border-radius-28{
  border-radius: 28px !important;
}
</style>