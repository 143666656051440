<template>
  <div class="row m-0">
    <div class="bg-header" v-bind:style="{ 'background-image': `url(${backgroundImage})` }"></div>

    <!-- image cropper dialogue -->
    <image-cropper-dialog
        ref="cropperDialog"
        aspectRatio="landscape"
        :uploadedImage="backgroundImage"
        @onCrop="(croppedImage) => {
          this.backgroundImage = croppedImage;
          this.updateProfileComponent(croppedImage);
        }"
    />

    <div class="col-xl-12 p-0 text-end margin-bottom-4x">

      <!-- Bouton de modification d'image fond -->
      <div class="mx-2 py-1 position-absolute">
        <input ref="imageField" type="file" @change="uploadImage" accept="image/*" hidden>
        <svg v-on:click="onUploaded" width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_626_807)">
            <circle cx="28" cy="28" r="20" fill="white"/>
            <path d="M36 28C36 27.5582 35.6418 27.2 35.2 27.2C34.7582 27.2 34.4 27.5582 34.4 28H36ZM28 21.6C28.4418 21.6 28.8 21.2418 28.8 20.8C28.8 20.3582 28.4418 20 28 20V21.6ZM34 34.4H22V36H34V34.4ZM21.6 34V22H20V34H21.6ZM34.4 28V34H36V28H34.4ZM22 21.6H28V20H22V21.6ZM22 34.4C21.7791 34.4 21.6 34.2209 21.6 34H20C20 35.1046 20.8954 36 22 36V34.4ZM34 36C35.1046 36 36 35.1046 36 34H34.4C34.4 34.2209 34.2209 34.4 34 34.4V36ZM21.6 22C21.6 21.7791 21.7791 21.6 22 21.6V20C20.8954 20 20 20.8954 20 22H21.6Z" fill="#FF9000"/>
            <path d="M20.7999 32.3999L25.0772 28.4791C25.3755 28.2056 25.8311 28.198 26.1384 28.4614L31.1999 32.7999" stroke="#FF9000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M29.6 30.7999L31.5094 28.8905C31.7909 28.6091 32.2366 28.5774 32.5551 28.8163L35.2 30.7999" stroke="#FF9000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M30.4 23.2H35.2" stroke="#FF9000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M32.7999 20.8V25.6" stroke="#FF9000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <defs>
            <filter id="filter0_d_626_807" x="0" y="0" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset/>
              <feGaussianBlur stdDeviation="4"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.8 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_626_807"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_626_807" result="shape"/>
            </filter>
          </defs>
        </svg>
      </div>


      <div class="card-body text-white margin-top-4x">

        <!-- carte -->
        <div class="row border-radius-10px margin-top-4x margin-bottom-2x margin-left-1x margin-right-1x" v-bind:style="linearGradient">

          <div class="p-0 border-radius-10px col-img">
            <img v-bind:src="user.avatar"
                 class="card-img-top h-100 border-radius-10px"
                 alt="avatar" />
          </div>

          <div v-bind:style='linearGradient'
               class="card-body col-infos border-radius-10px padding-left-1x padding-right-1x padding-top-2x padding-bottom-1x linear-gradient"
          >
            <div class="fw-bolder display-6 text-start mb-2 font-size">{{ this.user.prenom }}<br />{{ this.user.nom }}</div>
            <div class="text-start text-14 mb-0">{{ this.user.poste }}<br /> chez {{ this.user.entreprise }}</div>

            <!-- Bouton de modification des informations -->
            <router-link to="/modifInfo">
              <q-button
                  theme="secondary"
                  id="btn-connected"
                  class="btn w-100 shadow-none my-3 text-lowercase text-orange"
                  v-bind:style="bgTheme"
              >
                <div class="row">
                  <span class="text-18">
                    <span class="text-capitalize text-18">Modifier</span> le profil
                  </span>
                </div>
              </q-button>
            </router-link>

          </div>
          <div
              id="btn-logo"
              class="rounded-5 shadow-none"
              v-bind:style="{ 'background-image': `url(${logoEntreprise})` }"
          >
          </div>
        </div>

        <template v-for="component in components" :key="component.id">

          <v-switch :case="component.component">

            <template #BioComponent>
              <Biographie
                  :id="component.id"
                  :textColor="colorTheme"
                  :title="component.title"
                  :description="component.description"
              />
            </template>

            <template #ContactComponent>
              <Contact
                  :id="component.id"
                  :textColor="colorTheme"
                  :title="component.title"
                  :datas="component.datas"
              />
            </template>

            <template #SiteComponent>
              <Site
                  :id="component.id"
                  :textColor="colorTheme"
                  :logoSite="component.img"
                  :title="component.title"
                  :subtitle="component.subtitle || component.link"
                  :link="component.link"
              />
            </template>

            <template #TelechargeComponent>
              <Telecharge
                  :id="component.id"
                  :bgColor="linearGradient"
                  :textColor="colorTheme"
                  :title="component.title"
                  :subtitle="component.subtitle"
                  :link="component.link"
                  :pdf="component.sourceFile"
              />
            </template>

            <template #ReseauxSociauxComponent>
              <ReseauxSociaux
                  :id="component.id"
                  :textColor="colorTheme"
                  :title="component.title"
                  :datas="component.datas"
              />
            </template>

            <template #EquipeComponent>
              <Equipe
                  :id="component.id"
                  :textColor="colorTheme"
                  :equipe="component.params"
              />
            </template>

            <template #PhotosComponent>
              <Photos
                  :id="component.id"
                  :textColor="colorTheme"
                  :title="component.title"
                  :datas="component.datas"
              />
            </template>

            <template #VideoComponent>
              <Video
                  :id="component.id"
                  :textColor="colorTheme"
                  :title="component.title"
                  :player="component.player"
                  :videoId="component.videoId"
                  :sourceVideo="component.sourceVideo"
                  :sourceMiniature="component.sourceMiniature"
              />
            </template>

            <template #default></template>

          </v-switch>

        </template>

        <!-- Bouton d'agencer les sections -->
        <router-link to="/agencerSection">
          <div class="mx-3">
            <q-button
                theme="secondary"
                id="btn-agencer-section"
                class="btn w-100 text-color-blue mb-0 text-lowercase text-font m-0"
            >
              <span class="text-capitalize">Agencer</span> les sections
            </q-button>
          </div>
        </router-link>

        <!-- Bouton d'ajouter une section -->
        <router-link to="/ajoutSection">
          <div class="mx-3">
            <q-button
                theme="secondary"
                id="btn-add-section"
                class="btn w-100 text-white text-lowercase text-font"
            >
              <span class="text-capitalize">Ajouter</span> une section
            </q-button>
          </div>
        </router-link>

      </div>
    </div>

    <!-- navigation -->
    <Navigation active="profil" />

  </div>
</template>

<script>
import Navigation from "../Navigation/nav.pieds";
import ImageCropperDialog from '../GestionCrop/image.cropper.vue';
import Biographie from './Texte/texte.layout.vue';
import Contact from './Contact/contact.layout.vue';
import Site from './Site/site.layout.vue';
import Telecharge from './Telecharge/telecharge.layout.vue';
import ReseauxSociaux from './Reseaux/reseaux.layout.vue';
import Equipe from './Equipe/equipe.layout';
import Photos from './Photos/photos.layout';
import Video from './Video/video.layout.vue';
import axios from "axios";

export default {

  components: {
    Navigation,
    ImageCropperDialog,
    Biographie,
    Contact,
    Site,
    Telecharge,
    ReseauxSociaux,
    Video,
    Equipe,
    Photos
  },

  data() {
    return {
      user: {
        prenom: null,
        nom: null,
        poste: null,
        entreprise: null,
        avatar: require("../../../assets/images/photo-de-profil-avatar.png"),
      },

      components: [],

      backgroundImage: null,
      logoEntreprise: null,
      colorTheme: null,
      bgTheme: null,
      linearGradient: null,
    };
  },

  methods : {
    /**
     * uploader une image en local
     * @param event
     * @return {Promise<void>}
     */
    async uploadImage(event) {
      if (!event) return;
      let file = event.target.files[0];
      event.target.value = "";
      this.backgroundImage = await this.toBase64(file);
      await this.$refs.cropperDialog.initCropper(file.type);
      this.showPopup();
    },

    /**
     * se transforme en format base64
     * @param file
     * @return {Promise<unknown>}
     */
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },

    updateProfileComponent(croppedImage){
      axios
          .patch(this.$apiUrl + '/card/components/' + this.$store.state.user.id_component_profile + '/',
              {
                component: "ProfileComponent",
                title: "Ma profil",
                datas: [
                  {
                    id: this.$store.state.user.id_pageprofile,
                    first_name: this.user.prenom,
                    last_name: this.user.nom,
                    tel: this.$store.state.user.tel,
                    email: this.$store.state.user.email,
                    position: this.user.poste,
                    company_label: this.user.entreprise,
                    backgroundImage: croppedImage,
                    avatar: this.user.avatar,
                    title_connect_button: "Connect with " + this.$store.state.user.prenom,
                  }
                ],
                page_profile: this.$store.state.user.id_pageprofile,

              },{ headers: { Authorization: this.$store.state.user.token } })

          .then(response => {
            console.log(response.data);
          })

          .catch(error => {
            console.log(error.message)
          })
    },

    /**
     * afficher popup
     */
    showPopup(){
      document.querySelector(".d-modal").style.display = "block";
    },

    /**
     * uploader une image
     */
    onUploaded(){
      this.$refs.imageField.click();
    },
  },

  computed: {

  },

  mounted() {
    /**
     * récupération logo d'entreprise
     */
    if (this.$store.state.user.logoEntreprise !== null) {
      this.logoEntreprise = this.$store.state.user.logoEntreprise;
    }

    /**
     * récupération datas composants
     */
    axios
        .get(this.$apiUrl + '/card/pageprofiles/me/', { headers: { Authorization: this.$store.state.user.token } })
        .then((response) => {
          console.log(response.data)
          this.components = response.data.components.sort((i1, i2) => (i1.order - i2.order));
          this.$store.commit("saveIdProfiles", response.data.id)

          // récupération datas profil
          const profileComponent = this.components.find(c=>c.component==="ProfileComponent")
          if (!profileComponent) throw new Error("no ProfileComponent in the PageProfile");
          this.$store.commit("saveId", profileComponent.id)
          this.user.avatar = profileComponent.datas[0].avatar
          this.user.prenom = profileComponent.datas[0].first_name
          this.user.nom = profileComponent.datas[0].last_name
          this.user.poste = profileComponent.datas[0].position
          this.user.entreprise = profileComponent.datas[0].company
        })
        .catch((error) => {
          console.log("error", error);
        })

    /**
     * récupération image de fond
     */
    if (this.$store.state.user.backgroundImage !== null){
      this.backgroundImage = this.$store.state.user.backgroundImage;
    }

    /**
     * récupération thème
     */
    if (this.$store.state.theme !== null){
      this.colorTheme = "color: " + this.$store.state.theme.colorTheme;
      this.bgTheme = "color: #FF9000;" + "background: " + this.$store.state.theme.colorBouton;
      this.linearGradient = "background: " + this.$store.state.theme.colorTheme;
    }

    document.querySelector("#app").style.overflow = "unset"

  }
};

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Varela+Round');
*{
  font-family: "Varela Round";
}

.text-orange{
  color: #FF9000;
}

#btn-add-section{
  background: #92B2FF;
}

#btn-agencer-section{
  border: 1px solid #92B2FF;
}

.text-font{
  font-weight: 500;
  font-size: 18px;
}

.text-color-blue{
  color: #92B2FF;
}

.bg-header{
  position: absolute;
  left: 0;
  z-index: -1;
  height: 25vh;
  background-size: cover;
  background-position: center center;
  border-bottom-left-radius: 4%;
  border-bottom-right-radius: 4%;
}

#btn-logo{
  height: 47px;
  width: 47px;
  position: absolute;
  right: 1rem !important;
  margin: .3rem !important;
  z-index: 1;
  background-size: cover;
  background-position: center center;
}

.col-img{
  width: 40%;
}

.col-infos{
  width: 60%;
}

.card-body > h1{
  font-size: calc(1.375rem + 1.1vw);
}

.text-special-15{
  font-size: 15px;
}

.text-18{
  font-size: 18px; /* var(--size-18) ne fonctionne pas */
  font-weight: 600;
  line-height: var(--size-22-5);
  font-family: "Lexend";
}

.text-14{
  font-size: var(--size-14);
  line-height: var(--size-17-5);
  font-family: "Lexend";
}

.position-absolute{
  position: absolute;
  top: 0;
  right: 0;
}

.border-radius-10px{
  border-radius: 10px;
}

.card-body > .font-size{
  font-size: calc(1.405rem + 1.415vw);
}

.btn{
  height: var(--size-3x);
}

.q-button{
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 350px) and (min-width: 0) {
  .my-3{
    margin-top: calc(1rem - 3.1vw) !important;
    margin-bottom: calc(1rem - 3.1vw) !important;
  }
}
</style>

