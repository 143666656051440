<template>
  <svg width="74" height="17" viewBox="0 0 74 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2143_7670)">
      <path d="M16.6702 7.46721C16.6702 6.07752 15.8133 5.15189 14.6338 5.15189C13.4133 5.15189 12.5155 6.07752 12.5155 7.48209C12.5155 8.88667 13.4133 9.81231 14.6338 9.81231C15.8133 9.81231 16.6702 8.87178 16.6702 7.46721ZM18.3263 7.46721C18.3263 9.63859 16.8676 11.331 14.9298 11.331C14.5069 11.3458 14.0873 11.2483 13.7114 11.048C13.3354 10.8477 13.0157 10.5512 12.7827 10.187V14.0657H11.0977V3.86394H12.6695V4.98562C12.8925 4.56891 13.2231 4.22403 13.6246 3.98924C14.0262 3.75445 14.4829 3.63893 14.9443 3.65549C16.87 3.65549 18.3263 5.30574 18.3263 7.47465" fill="white"/>
      <path d="M24.2772 3.7597V5.42237C23.9722 5.2921 23.6444 5.2279 23.3143 5.23377C22.3178 5.23377 21.4897 5.82686 21.4897 7.23144V11.1102H19.8047V3.87386H21.3766V5.40748C21.6847 4.41485 22.4863 3.65796 23.543 3.65796C23.787 3.65909 24.0298 3.6933 24.2651 3.7597" fill="white"/>
      <path d="M30.7471 7.49204C30.7471 6.08746 29.8493 5.17671 28.6288 5.17671C27.4493 5.17671 26.5081 6.08746 26.5081 7.49204C26.5081 8.89662 27.4493 9.82225 28.6288 9.82225C29.8493 9.82225 30.7471 8.88173 30.7471 7.49204ZM24.8809 7.49204C24.8789 6.98547 24.9748 6.48355 25.1628 6.01553C25.3508 5.5475 25.6273 5.12272 25.9761 4.7659C26.3249 4.40909 26.7391 4.12738 27.1945 3.93717C27.6499 3.74696 28.1375 3.65205 28.6288 3.65798C29.1211 3.65073 29.6098 3.74464 30.0665 3.93422C30.5232 4.12381 30.9387 4.40527 31.2887 4.76219C31.6387 5.1191 31.9163 5.54432 32.1051 6.01301C32.294 6.48171 32.3905 6.9845 32.3888 7.49204C32.3908 8.00035 32.2946 8.50399 32.106 8.97366C31.9174 9.44333 31.6401 9.86964 31.2903 10.2278C30.9404 10.586 30.5249 10.8688 30.068 11.0599C29.6111 11.251 29.1219 11.3466 28.6288 11.341C28.1367 11.3453 27.6487 11.2487 27.193 11.057C26.7374 10.8653 26.3232 10.5822 25.9745 10.2241C25.6258 9.86604 25.3496 9.44015 25.1619 8.97115C24.9742 8.50216 24.8786 7.99939 24.8809 7.49204Z" fill="white"/>
      <path d="M36.1667 2.78938V3.87384H38.0443V5.39505H36.1643V11.1027C36.1643 13.0259 35.3363 14.2121 33.5959 14.2121C33.2834 14.2206 32.9726 14.1614 32.6836 14.0384L32.7125 12.5495C32.9216 12.6381 33.146 12.682 33.372 12.6785C34.2819 12.6785 34.5082 12.1003 34.5082 11.1176V5.38761H33.0471V3.86639H34.5082V2.75216C34.5082 1.13168 35.4711 0.104304 37.1031 0.104304C37.4526 0.0928477 37.801 0.151858 38.1286 0.278018V1.695C37.8651 1.61159 37.5905 1.57221 37.3149 1.57837C36.5278 1.57837 36.1643 2.01264 36.1643 2.77945" fill="white"/>
      <path d="M46.0328 3.8739L42.8049 12.2815C42.2994 13.5844 41.4978 14.2196 40.3616 14.2196C39.9603 14.2357 39.5639 14.1248 39.2254 13.902V12.413C39.5084 12.5891 39.8338 12.6794 40.1642 12.6736C40.8262 12.6736 41.2739 12.3411 41.582 11.4874L38.7344 3.87639H40.5301L42.3547 9.28873L44.2805 3.87639L46.0328 3.8739Z" fill="white"/>
      <path d="M47.1133 9.57658V0.27063H48.7983V9.3855C48.7983 9.71804 48.9668 9.86196 49.2195 9.86196C49.3317 9.87349 49.4446 9.84831 49.5421 9.79V11.1375C49.3005 11.2851 49.0236 11.3599 48.7429 11.3534C47.7175 11.3534 47.1157 10.6288 47.1157 9.58899" fill="white"/>
      <path d="M52.167 6.74005H55.6164C55.5466 5.64071 54.9424 5.10469 53.9314 5.10469C53.0071 5.10469 52.3595 5.66801 52.1646 6.74005M57.0896 8.11484H52.1501C52.3475 9.31593 53.0913 9.83707 54.3165 9.83707C55.2038 9.86511 56.0771 9.60465 56.8127 9.09259V10.5989C56.0906 11.1325 55.0603 11.3434 54.1889 11.3434C51.9431 11.3434 50.4844 9.88174 50.4844 7.50934C50.4844 5.2511 51.9575 3.6604 53.9506 3.6604C55.7753 3.6604 57.1642 4.93345 57.1642 7.219C57.169 7.52076 57.1407 7.82211 57.0799 8.11733" fill="white"/>
      <path d="M63.7738 7.50689C63.7738 6.08742 62.9168 5.17668 61.7662 5.17668C60.6156 5.17668 59.7875 6.1172 59.7875 7.52178C59.7875 8.92636 60.6156 9.82221 61.7662 9.82221C62.9168 9.82221 63.7738 8.90899 63.7738 7.50689ZM65.2036 3.87384V11.1102H63.6197V10.0679C63.4046 10.4627 63.0893 10.7897 62.7077 11.014C62.326 11.2382 61.8924 11.3513 61.4533 11.3409C59.5853 11.3409 58.1699 9.70558 58.1699 7.52178C58.1699 5.33798 59.5853 3.65795 61.4677 3.65795C61.8772 3.64462 62.2829 3.74179 62.6447 3.93984C63.0066 4.13788 63.312 4.42994 63.5306 4.78707V3.87384H65.2036Z" fill="white"/>
      <path d="M72.0117 7.50689C72.0117 6.08743 71.1572 5.17668 70.0042 5.17668C68.8511 5.17668 68.0255 6.1172 68.0255 7.52178C68.0255 8.92636 68.8536 9.82221 70.0042 9.82221C71.1548 9.82221 72.0117 8.90899 72.0117 7.50689ZM73.456 0.258179V11.1102H71.8697V10.0679C71.6558 10.4638 71.3408 10.7918 70.9589 11.0162C70.5769 11.2406 70.1427 11.3529 69.7033 11.3409C67.8377 11.3409 66.4199 9.70558 66.4199 7.52178C66.4199 5.33799 67.8377 3.65795 69.7177 3.65795C70.1272 3.64437 70.533 3.74144 70.8949 3.93951C71.2568 4.13757 71.5621 4.42977 71.7806 4.78708V0.270584L73.456 0.258179Z" fill="white"/>
      <path d="M4.28331 5.56382C4.28331 6.06928 4.08855 6.55405 3.74185 6.91147C3.39516 7.26889 2.92493 7.46968 2.43463 7.46968C1.94432 7.46968 1.4741 7.26889 1.1274 6.91147C0.780702 6.55405 0.585938 6.06928 0.585938 5.56382C0.585938 5.05835 0.780702 4.57359 1.1274 4.21617C1.4741 3.85875 1.94432 3.65796 2.43463 3.65796C2.92493 3.65796 3.39516 3.85875 3.74185 4.21617C4.08855 4.57359 4.28331 5.05835 4.28331 5.56382Z" fill="black"/>
      <path d="M8.71302 5.56382C8.71302 6.06928 8.51824 6.55405 8.17154 6.91147C7.82484 7.26889 7.35462 7.46968 6.86431 7.46968C6.37401 7.46968 5.9038 7.26889 5.5571 6.91147C5.2104 6.55405 5.01562 6.06928 5.01562 5.56382C5.01562 5.05835 5.2104 4.57359 5.5571 4.21617C5.9038 3.85875 6.37401 3.65796 6.86431 3.65796C7.35462 3.65796 7.82484 3.85875 8.17154 4.21617C8.51824 4.57359 8.71302 5.05835 8.71302 5.56382Z" fill="black"/>
      <path d="M4.28331 9.8991C4.28331 10.4046 4.08855 10.8893 3.74185 11.2468C3.39516 11.6042 2.92493 11.805 2.43463 11.805C1.94432 11.805 1.4741 11.6042 1.1274 11.2468C0.780702 10.8893 0.585938 10.4046 0.585938 9.8991C0.585937 9.64894 0.633767 9.40123 0.726702 9.17014C0.819637 8.93905 0.955848 8.72911 1.12755 8.55234C1.29924 8.37556 1.50307 8.23541 1.72735 8.1399C1.95163 8.04439 2.19197 7.9954 2.43463 7.99573C2.67729 7.9954 2.91762 8.04439 3.1419 8.1399C3.36618 8.23541 3.57001 8.37556 3.7417 8.55234C3.9134 8.72911 4.04961 8.93905 4.14255 9.17014C4.23548 9.40123 4.28331 9.64894 4.28331 9.8991Z" fill="black"/>
      <path d="M8.71302 9.8991C8.71302 10.4046 8.51824 10.8893 8.17154 11.2468C7.82484 11.6042 7.35462 11.805 6.86431 11.805C6.37401 11.805 5.9038 11.6042 5.5571 11.2468C5.2104 10.8893 5.01563 10.4046 5.01563 9.8991C5.01562 9.64894 5.06347 9.40123 5.1564 9.17014C5.24934 8.93905 5.38555 8.72911 5.55725 8.55234C5.72895 8.37556 5.93276 8.23541 6.15704 8.1399C6.38132 8.04439 6.62165 7.9954 6.86431 7.99573C7.10697 7.9954 7.34732 8.04439 7.5716 8.1399C7.79588 8.23541 7.99969 8.37556 8.17139 8.55234C8.34309 8.72911 8.4793 8.93905 8.57224 9.17014C8.66517 9.40123 8.71302 9.64894 8.71302 9.8991Z" fill="black"/>
      <path d="M4.28331 14.237C4.28331 14.7425 4.08855 15.2273 3.74185 15.5847C3.39516 15.9421 2.92493 16.1429 2.43463 16.1429C1.94432 16.1429 1.4741 15.9421 1.1274 15.5847C0.780702 15.2273 0.585938 14.7425 0.585938 14.237C0.585938 13.7316 0.780702 13.2468 1.1274 12.8894C1.4741 12.532 1.94432 12.3312 2.43463 12.3312C2.92493 12.3312 3.39516 12.532 3.74185 12.8894C4.08855 13.2468 4.28331 13.7316 4.28331 14.237Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_2143_7670">
        <rect width="72.8571" height="16.0286" fill="white" transform="translate(0.585938 0.114258)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>