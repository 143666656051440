<template>
  <div class="row h-100 m-0">
    <div class="col-xl-12 p-0 pb-5 text-center">

      <Entete
          :title="title"
          @handleNotification="handleNotifSave"
          @handleSubmitClick="handleSubmitClick"
      />

      <div class="card-body text-white mt-5">

        <!-- formule -->
        <q-form
            ref="form"
            :model="formModel"
            :rules="rul"
            class="mx-3 text-start text-dark"
        >
          <!-- Titre de la section -->
          <q-form-item label="Titre de la section" prop="section" class="text-start my-2">
            <q-input v-model="formModel.section" type="text" class="form-control" id="section"/>
          </q-form-item>

          <q-button
              id="btn-add-equipe"
              @click="handleNotifAdd"
              theme="secondary"
              class="btn w-100 text-white m-0 "
          >
            Ajouter une section
          </q-button>

          <template v-for="(member, index) in formModel.datas" :key="index">

            <div class="product rounded-top my-4">

              <img v-bind:src="member.avatar" class="card-img-top rounded-top" alt="">

              <div>

                <div class="p-1 mt-1 text-center">
                  <strong class="text-name">{{ member.prenom }} {{ member.nom }}</strong>
                  <div class="text-post">{{ member.poste }}</div>
                </div>

                <div>

                  <q-button
                      @click="handleNotifChange(member)"
                      theme="secondary"
                      id="btn-edit"
                      class="btn btn-orange text-white col-6 m-0 shadow-none"
                  >
                    Modifier
                  </q-button>

                  <q-button
                      @click="showPopup(member)"
                      theme="secondary"
                      id="btn-remove"
                      class="btn btn-danger col-6 m-0 shadow-none"
                  >
                    Supprimer
                  </q-button>

                </div>

              </div>
            </div>
          </template>

          <div class="modal-dialog" role="document">
            <div class="modal-content rounded-3 shadow">

              <div class="modal-body p-4 text-center">
                <h5 class="mb-0">Confirmation de suppression</h5>
                <p class="mb-0">Vous les vous vraiment supprimer <strong>{{ prenom }} {{ nom }}</strong>.</p>
              </div>

              <div class="modal-footer flex-nowrap p-0">

                <button
                    type="button"
                    @click="handleNotifDelete()"
                    class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 border-end"
                >
                  <strong>Oui</strong>
                </button>

                <button
                    type="button"
                    @click="closePopup"
                    class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0"
                    data-bs-dismiss="modal"
                >
                  Non
                </button>

              </div>
            </div>
          </div>

        </q-form>

      </div>

    </div>

    <!-- navigation -->
    <Navigation active="profil" />

  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Navigation from "../../Navigation/nav.pieds";
import Entete from "../../Navigation/nav.enTete.vue";
import { ref, reactive, onMounted, getCurrentInstance } from "vue";
import axios from "axios";
import {useMessageBox} from "@qvant/qui-max";
import {defineAsyncComponent} from "@vue/runtime-core";

export default {

  components: { Navigation, Entete },

  name: "modifEquipe",


  setup() {
    let activeNames = ref(['1']);
    let form = ref(null);
    let prenom = ref(null);
    let nom = ref(null);
    let store = useStore();
    let route = useRoute();
    let router = useRouter();
    let messageBox = useMessageBox();
    let model = { section: '', title: '', datas: null};
    let formModel = reactive(model);
    let app = getCurrentInstance();
    let rules = {
      section: app.appContext.config.globalProperties.$validator_not_null,
    };
    let rul = reactive(rules);
    let index;
    let title = ref("Équipe");


    let args = {
      title: "Attention, il reste des modifications non enregistrées!",
      message: "Vous voulez sauvgarder les modifications?",
      submessage: null,
      cancelButtonText: "Réinitialiser",
      confirmButtonText: "Enrergister",
    }

    const showPopup = (member) => {
      document.querySelector(".modal-dialog").style.display = "block"
      console.log(member.id);
      index = member.id;
      prenom.value = member.prenom;
      nom.value = member.nom;
    }

    const closePopup = () => {
      document.querySelector(".modal-dialog").style.display = "none";
    }

    const handleNotifAdd = async () => {
      try {
        const result = await messageBox(
            defineAsyncComponent(() => import('./equipe.ajouter'))
        );
        console.log('resolve', result);
      } catch (result) {
        console.log('reject', result);
      }
    }

    /**
     * Suppression membre dans reference donnée
     */
    const handleNotifDelete = () => {
      console.log(prenom.value + " " + nom.value)
      formModel.datas.splice(index - 1, 1);
      //faires des mise à jour api
      axios
          .put(app.appContext.config.globalProperties.$apiUrl + '/composants/' + route.params.id, {
            params: {
              title: formModel.title,
              section: formModel.section,
              datas: formModel.datas,
            }
          })
          .then(response => {
            console.log(response.data.params);
          })
          .catch(error => {
            console.log(error.message)
          })

      closePopup()
    }

    /**
     * Modification membre dans reference donnée
     */
    const handleNotifChange = async (member) => {
      try {
        //console.log(member)
        store.commit("saveMember", {
          id: member.id,
          prenom: member.prenom,
          nom: member.nom,
          poste: member.poste,
          avatarImage: member.avatar,
        });

        const result = await messageBox(
            defineAsyncComponent(() => import('./equipe.seul.modifier'))
        );
        console.log('resolve', result);
      } catch (result) {
        console.log('reject', result);
      }
    }

    /**
     * Identifier si la valeur a été modifié, si oui, fait l'appel de la fonction changedCallback
     * @return {Promise<void>}
     */
    const identifierModif = (changedCallback) => {
      axios
          .get(app.appContext.config.globalProperties.$apiUrl + '/composants/' + route.params.id)

          .then(response => {
            let isChanged = !((formModel.section === response.data.params.section)); //manque conditioins
            //console.log(response)
            if(isChanged){
              // valeur a été modifié, crée un popup
              changedCallback()
            }else{
              // valeur garde la même, redirige chemin vers routeur profil
              console.log("Aucune modification n'a été effectuée")
              router.push('/profil/user')
            }
          })

          .catch(error => {
            console.log(error.message)
          })
    }

    /**
     * Manipuler la notfication
     */
    const handleNotifSave = async () => {
      await identifierModif( createMessageBox )
    };

    const createMessageBox = async () => {
      try {
        const result = await messageBox(
            {
              title: args.title,
              message: args.message,
              submessage: args.submessage,
              confirmButtonText: args.confirmButtonText,
              cancelButtonText: args.cancelButtonText,
            },
            {
              onMounted: (app, container) => {
                console.log('onMounted', app, container);
              },
              onUnmounted: app => {
                console.log('onUnmounted', app);
              }
            }
        );
        await handleSubmitClick();
        console.log('resolve', result);
      } catch (result) {
        console.log('reject', result);
      }
      await router.push('/profil/user');
    };

    /**
     * Valider le formule est par rapport règle et enregistrer dans stockage
     * @return {Promise<void>}
     */
    let handleSubmitClick = async () => {
      await form?.value?.validate().then((valid) => {
        if (valid) {
          let { isValid, invalidFields } = valid;

          console.log('QForm | validate', isValid, invalidFields);
          if (isValid) {

            axios
                .put(app.appContext.config.globalProperties.$apiUrl + '/composants/' + route.params.id, {
                  params: {
                    title: formModel.title,
                    section: formModel.section,
                  }
                })

                .then(response => {
                  this.$toast.show(
                      'Votre modification a été enregistré avec succès !',
                      {type: 'success'}
                  );
                  console.log(response.data.params);
                })

                .catch(error => {
                  this.$toast.show(error.message, {type: 'error'});
                  console.log(error.message)
                })

          }
        }
      })
    }

    /**
     * Vérification la mise à jour la valeur de model
     */
    const updateModelIson = (reseau) => {
      console.log(reseau)
    };

    onMounted(() => {
      axios
          .get(app.appContext.config.globalProperties.$apiUrl + '/composants/' + route.params.id)

          .then(response => {
            formModel.section = response.data.params.section
            formModel.datas = response.data.params.datas
            formModel.title = response.data.params.title
          })

          .catch(error => {
            console.log(error.message)
          })
    })

    return {
      rul,
      nom,
      prenom,
      form,
      title,
      formModel,
      activeNames,
      showPopup,
      closePopup,
      handleNotifAdd,
      updateModelIson,
      handleSubmitClick,
      handleNotifSave,
      handleNotifDelete,
      handleNotifChange,
    };
  },


}
</script>

<style scoped>
.product{
  border: 1px solid #DEDEDE;
}

.btn-orange{
  background: #FF9000;
}

.modal-dialog{
  position: fixed;
  top: 40%;
  left: 3%;
  right: 3%;
  background: #ffffff;
  border: 1px solid #DEDEDE;
  display: none;
}

.text-name{
  font-size: 32px;
}

.text-post{
  font-size: 28px;
}

#btn-add-equipe{
  background: #FF9000;
}
</style>