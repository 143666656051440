<template>
  <svg width="25" height="24" viewBox="0 0 25 24" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.19 22.7501C14.75 22.7501 14.29 22.6201 13.82 22.3701L9.89001 20.1801C9.70001 20.0801 9.28998 20.0801 9.09998 20.1801L5.17999 22.3701C4.32999 22.8401 3.45999 22.8801 2.79999 22.4901C2.12999 22.1001 1.75 21.3201 1.75 20.3601V9.00012C1.75 6.87012 3.47999 5.14014 5.60999 5.14014H13.39C15.52 5.14014 17.25 6.87012 17.25 9.00012V20.3601C17.25 21.3201 16.87 22.1001 16.2 22.4901C15.9 22.6601 15.56 22.7501 15.19 22.7501ZM9.5 18.6001C9.9 18.6001 10.29 18.6901 10.62 18.8701L14.55 21.0601C14.91 21.2601 15.24 21.3101 15.44 21.1901C15.63 21.0801 15.75 20.7601 15.75 20.3501V8.99011C15.75 7.69011 14.69 6.63013 13.39 6.63013H5.60999C4.30999 6.63013 3.25 7.69011 3.25 8.99011V20.3501C3.25 20.7601 3.37 21.0801 3.56 21.1901C3.75 21.3001 4.09001 21.2501 4.45001 21.0501L8.38 18.8601C8.70001 18.6901 9.1 18.6001 9.5 18.6001Z" />
    <path d="M21.19 18.87C20.75 18.87 20.29 18.74 19.82 18.49L16.13 16.43C15.89 16.3 15.75 16.05 15.75 15.78V9C15.75 7.7 14.69 6.64001 13.39 6.64001H8.5C8.09 6.64001 7.75 6.30001 7.75 5.89001V5.12C7.75 2.99 9.47999 1.26001 11.61 1.26001H19.39C21.52 1.26001 23.25 2.99 23.25 5.12V16.48C23.25 17.44 22.87 18.22 22.2 18.61C21.9 18.78 21.56 18.87 21.19 18.87ZM17.25 15.33L20.55 17.18C20.91 17.38 21.24 17.43 21.44 17.31C21.63 17.2 21.75 16.88 21.75 16.47V5.10999C21.75 3.80999 20.69 2.75 19.39 2.75H11.61C10.31 2.75 9.25 3.80999 9.25 5.10999V5.13H13.39C15.52 5.13 17.25 6.85999 17.25 8.98999V15.33Z" />
    <path d="M11.5 12.75H7.5C7.09 12.75 6.75 12.41 6.75 12C6.75 11.59 7.09 11.25 7.5 11.25H11.5C11.91 11.25 12.25 11.59 12.25 12C12.25 12.41 11.91 12.75 11.5 12.75Z" />
    <path d="M9.5 14.75C9.09 14.75 8.75 14.41 8.75 14V10C8.75 9.59 9.09 9.25 9.5 9.25C9.91 9.25 10.25 9.59 10.25 10V14C10.25 14.41 9.91 14.75 9.5 14.75Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor'],
}
</script>

<style scoped>

</style>