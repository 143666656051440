<template>
  <svg width="32" height="33" viewBox="0 0 32 33" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 30.8334C9.93333 30.8334 5 25.9001 5 19.8334V13.1667C5 7.10008 9.93333 2.16675 16 2.16675C22.0667 2.16675 27 7.10008 27 13.1667V19.8334C27 25.9001 22.0667 30.8334 16 30.8334ZM16 4.16675C11.04 4.16675 7 8.20675 7 13.1667V19.8334C7 24.7934 11.04 28.8334 16 28.8334C20.96 28.8334 25 24.7934 25 19.8334V13.1667C25 8.20675 20.96 4.16675 16 4.16675Z" />
    <path d="M16 16.1667C14.3467 16.1667 13 14.82 13 13.1667V10.5C13 8.84667 14.3467 7.5 16 7.5C17.6533 7.5 19 8.84667 19 10.5V13.1667C19 14.82 17.6533 16.1667 16 16.1667ZM16 9.5C15.4533 9.5 15 9.95333 15 10.5V13.1667C15 13.7133 15.4533 14.1667 16 14.1667C16.5467 14.1667 17 13.7133 17 13.1667V10.5C17 9.95333 16.5467 9.5 16 9.5Z" />
    <path d="M16 9.50008C15.4533 9.50008 15 9.04675 15 8.50008V3.16675C15 2.62008 15.4533 2.16675 16 2.16675C16.5467 2.16675 17 2.62008 17 3.16675V8.50008C17 9.04675 16.5467 9.50008 16 9.50008Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>