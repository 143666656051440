<template>
  <svg width="20" height="20" viewBox="0 0 20 20" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.1506 18.9583C5.06727 18.9583 4.9756 18.95 4.9006 18.9416L3.09227 18.6916C2.2256 18.575 1.44227 17.8 1.30894 16.9166L1.05894 15.0916C0.975602 14.5083 1.2256 13.75 1.64227 13.325L5.3006 9.66664C4.70894 7.29998 5.39227 4.79998 7.13394 3.07498C9.83394 0.38331 14.2256 0.374977 16.9339 3.07498C18.2423 4.38331 18.9589 6.12498 18.9589 7.97498C18.9589 9.82498 18.2423 11.5666 16.9339 12.875C15.1839 14.6083 12.6923 15.2916 10.3423 14.6916L6.6756 18.35C6.3256 18.7166 5.7006 18.9583 5.1506 18.9583ZM12.0256 2.29998C10.5673 2.29998 9.11727 2.84998 8.00894 3.95831C6.50894 5.44998 5.96727 7.63331 6.59227 9.66664C6.65894 9.89164 6.6006 10.125 6.43394 10.2916L2.51727 14.2083C2.3756 14.35 2.25894 14.7166 2.28394 14.9083L2.53394 16.7333C2.58394 17.05 2.9256 17.4083 3.24227 17.45L5.05894 17.7C5.25894 17.7333 5.6256 17.6166 5.76727 17.475L9.7006 13.55C9.86727 13.3833 10.1089 13.3333 10.3256 13.4C12.3339 14.0333 14.5256 13.4916 16.0256 11.9916C17.0923 10.925 17.6839 9.49164 17.6839 7.97498C17.6839 6.44998 17.0923 5.02498 16.0256 3.95831C14.9423 2.85831 13.4839 2.29998 12.0256 2.29998Z" />
    <path d="M7.65872 17.1166C7.50039 17.1166 7.34206 17.0583 7.21706 16.9333L5.30039 15.0166C5.05872 14.7749 5.05872 14.3749 5.30039 14.1333C5.54206 13.8916 5.94206 13.8916 6.18372 14.1333L8.10039 16.0499C8.34206 16.2916 8.34206 16.6916 8.10039 16.9333C7.97539 17.0583 7.81706 17.1166 7.65872 17.1166Z" />
    <path d="M12.084 9.79163C11.0507 9.79163 10.209 8.94996 10.209 7.91663C10.209 6.88329 11.0507 6.04163 12.084 6.04163C13.1173 6.04163 13.959 6.88329 13.959 7.91663C13.959 8.94996 13.1173 9.79163 12.084 9.79163ZM12.084 7.29163C11.7423 7.29163 11.459 7.57496 11.459 7.91663C11.459 8.25829 11.7423 8.54163 12.084 8.54163C12.4257 8.54163 12.709 8.25829 12.709 7.91663C12.709 7.57496 12.4257 7.29163 12.084 7.29163Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>