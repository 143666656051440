<template>
  <base-popup>

    <template v-slot:svg-left>
      <SVGFlag :fill-color="'#0400BD'"/>
    </template>

    <template v-slot:svg-right>
      <span class="row align-items-center" v-on:click="cancel">
        <SVGClose />
      </span>
    </template>

    <template v-slot:title>
      Select the stickers for this lead
    </template>

    <template v-slot:body>
      <div class="text-start">
      <template v-for="(tag, index) in this.tags" :key="index">
        <tag v-if="tagsSelected.includes(tag.id)" :bg-tag="'#' + tag.color" v-on:click="toggleTag(tag.id)">
          <template v-slot:labelTag>
            {{ tag.name }}
          </template>
        </tag>
        <tag-outline v-else :color-outline="'#' + tag.color" v-on:click="toggleTag(tag.id)">
          <template v-slot:labelTag>
            {{ tag.name }}
          </template>
        </tag-outline>
      </template>
      </div>
    </template>

    <template v-slot:button-bar>
      <q-button
          v-on:click="filter"
          theme="secondary"
          class="btn btn-save p-0 mx-0 margin-top-2x margin-bottom-0-5x w-100 bg-blue"
      >
          <span class="text-white">
            Save
          </span>
      </q-button>
    </template>

  </base-popup>

</template>

<script>
import { inject } from 'vue';
import BasePopup from "./BasePopup"
import { QMessageBoxAction, QButton } from '@qvant/qui-max';
import SVGClose from "../../../../assets/svgs/app/svg.close-popup";
import SVGFlag from "../../../../assets/svgs/app/svg.connection-flag";
import TagOutline from "../../GestionLeads/tag.outline";
import TagsService from '../../../../services/TagsService';
import Tag from "../../GestionLeads/tag.base";

let qMessageBoxContainer = undefined;

export default {

  props: [ 'selectedTags' ],

  components: {
    BasePopup,
    SVGFlag,
    SVGClose,
    QButton,
    TagOutline,
    Tag,
  },

  data() {
    return {
      tags: [],
      tagsSelected: [...this.selectedTags ?? []],
    }
  },

  setup: () => {
    qMessageBoxContainer = inject(
        'qMessageBoxContainer'
    );

    return {
      qMessageBoxContainer,
    }
  },

  methods : {

    toggleTag(id){
      if (this.tagsSelected.includes(id)) {
        this.tagsSelected.splice(this.tagsSelected.indexOf(id), 1);
      }
      else {
        this.tagsSelected.push(id);
      }
    },

    cancel: () => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.cancel
      });
    },

    filter(){
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.confirm
      });
      console.log(this.tagsSelected)
      this.$emit("filter", this.tagsSelected);
    },

    /**
     * Getter tags sur l'API
     */
     getTags() {
      TagsService.getTags()
        .then(tags => {
          this.tags = tags;
        })
        .catch(error => {
          console.log(error)
        })
    },
  },

  mounted(){
    this.getTags();
  }

}
</script>

<style scoped>
.btn{
  height: var(--size-3x);
}

.bg-blue {
  background-color: #0400BD;
}
</style>