<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 9.25H2.5C2.09 9.25 1.75 8.91 1.75 8.5C1.75 8.09 2.09 7.75 2.5 7.75H14C14.41 7.75 14.75 8.09 14.75 8.5C14.75 8.91 14.41 9.25 14 9.25Z" :fill="fillColor"/>
    <path d="M8.5 17.25H6.5C6.09 17.25 5.75 16.91 5.75 16.5C5.75 16.09 6.09 15.75 6.5 15.75H8.5C8.91 15.75 9.25 16.09 9.25 16.5C9.25 16.91 8.91 17.25 8.5 17.25Z" :fill="fillColor"/>
    <path d="M15 17.25H11C10.59 17.25 10.25 16.91 10.25 16.5C10.25 16.09 10.59 15.75 11 15.75H15C15.41 15.75 15.75 16.09 15.75 16.5C15.75 16.91 15.41 17.25 15 17.25Z" :fill="fillColor"/>
    <path d="M18.06 21.25H6.94C2.96 21.25 1.75 20.05 1.75 16.11V7.89C1.75 3.95 2.96 2.75 6.94 2.75H14C14.41 2.75 14.75 3.09 14.75 3.5C14.75 3.91 14.41 4.25 14 4.25H6.94C3.8 4.25 3.25 4.79 3.25 7.89V16.1C3.25 19.2 3.8 19.74 6.94 19.74H18.05C21.19 19.74 21.74 19.2 21.74 16.1V12.02C21.74 11.61 22.08 11.27 22.49 11.27C22.9 11.27 23.24 11.61 23.24 12.02V16.1C23.25 20.05 22.04 21.25 18.06 21.25Z" :fill="fillColor"/>
    <path d="M22.5 7H17C16.59 7 16.25 6.66 16.25 6.25C16.25 5.84 16.59 5.5 17 5.5H22.5C22.91 5.5 23.25 5.84 23.25 6.25C23.25 6.66 22.91 7 22.5 7Z" :fill="fillColor"/>
    <path d="M19.75 9.75C19.34 9.75 19 9.41 19 9V3.5C19 3.09 19.34 2.75 19.75 2.75C20.16 2.75 20.5 3.09 20.5 3.5V9C20.5 9.41 20.16 9.75 19.75 9.75Z" :fill="fillColor"/>
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>