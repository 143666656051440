<template>

  <architecture-base>

    <template v-slot:nav>

      <!-- Navigation bar -->
      <Sidebar active="statistique" />
    </template>

    <template v-slot:header>
      <!-- Title -->
      <div class="title">Statistics</div>

      <!-- Button tool bar -->
      <button-bar></button-bar>
    </template>

    <template v-slot:main>
      <div class="d-flex justify-content-between overflow-auto">

        <performances ref="businessCard"></performances>
        <tops ref="businessCard"></tops>

      </div>
    </template>

  </architecture-base>

</template>

<script>
import ArchitectureBase from "../LayoutComponent/layout.base";
import Sidebar from "../LayoutComponent/layout.sidebar.vue";
import ButtonBar from "../LayoutComponent/layout.buttonbar";
import Performances from "./layout.col.performances";
import Tops from "./layout.col.tops";

export default {

  components: {
    ArchitectureBase,
    Sidebar,
    ButtonBar,
    Performances,
    Tops
  },

}
</script>

<style scoped>
.title{
  font-size: calc((48 / 1728) * (100vw - var(--size-2x)));
  line-height: 100%;
  font-weight: 600;
}
</style>