<template>
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D6D6FF"/>
    <path d="M27.1082 36.9584C26.7082 36.9584 26.3082 36.8584 25.9416 36.6584C25.1999 36.2417 24.7582 35.5 24.7582 34.6583V30.2C24.7582 29.775 24.4832 29.15 24.2249 28.825L21.0582 25.5C20.5332 24.975 20.1249 24.0584 20.1249 23.3834V21.45C20.1249 20.1084 21.1416 19.0583 22.4332 19.0583H33.5499C34.8249 19.0583 35.8582 20.0917 35.8582 21.3667V23.2167C35.8582 24.0917 35.3332 25.1 34.8332 25.5917C34.5916 25.8333 34.1916 25.8333 33.9499 25.5917C33.7082 25.35 33.7082 24.95 33.9499 24.7083C34.2582 24.4 34.6082 23.7084 34.6082 23.2167V21.3667C34.6082 20.7834 34.1332 20.3083 33.5499 20.3083H22.4332C21.8416 20.3083 21.3749 20.8084 21.3749 21.45V23.3834C21.3749 23.6917 21.6249 24.3 21.9499 24.625L25.1582 28C25.5832 28.525 25.9999 29.4084 25.9999 30.2V34.6583C25.9999 35.2084 26.3749 35.475 26.5416 35.5667C26.8999 35.7667 27.3249 35.7584 27.6582 35.5584L28.8249 34.8084C29.0665 34.6667 29.2999 34.2083 29.2999 33.9C29.2999 33.5583 29.5832 33.275 29.9249 33.275C30.2666 33.275 30.5499 33.5583 30.5499 33.9C30.5499 34.65 30.0832 35.5084 29.4915 35.8667L28.3332 36.6167C27.9582 36.8417 27.5332 36.9584 27.1082 36.9584Z" fill="#0400BD"/>
    <path d="M31.3918 32.3917C29.5751 32.3917 28.1001 30.9167 28.1001 29.1C28.1001 27.2834 29.5751 25.8083 31.3918 25.8083C33.2084 25.8083 34.6835 27.2834 34.6835 29.1C34.6835 30.9167 33.2084 32.3917 31.3918 32.3917ZM31.3918 27.0583C30.2668 27.0583 29.3501 27.975 29.3501 29.1C29.3501 30.225 30.2668 31.1417 31.3918 31.1417C32.5168 31.1417 33.4335 30.225 33.4335 29.1C33.4335 27.975 32.5168 27.0583 31.3918 27.0583Z" fill="#0400BD"/>
    <path d="M34.5584 32.8917C34.4001 32.8917 34.2418 32.8333 34.1168 32.7083L33.2834 31.875C33.0418 31.6333 33.0418 31.2333 33.2834 30.9917C33.5251 30.75 33.9251 30.75 34.1668 30.9917L35.0001 31.825C35.2418 32.0667 35.2418 32.4667 35.0001 32.7083C34.8834 32.825 34.7167 32.8917 34.5584 32.8917Z" fill="#0400BD"/>
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F4F3FF" stroke-width="8"/>
  </svg>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>