<template>
  <div class="padding-top-2x padding-bottom-2x padding-left-2x padding-right-2x">
    <div class="modal-body text-center">

      <p class="text-black popup-title text-start">
        <slot name="title"></slot>
      </p>

      <slot name="body"></slot>

      <div>
        <slot name="button-bar"></slot>
      </div>

    </div>
  </div>
</template>

<script>

export default {

  components: {

  }

}
</script>

<style scoped>
.popup-title{
  font-size: calc((32 / 1728) * (100vw - var(--size-2x)));
  font-weight: 600;
  line-height: 100%;
  color: #000000;
}
</style>