
import { createRouter, createWebHistory } from 'vue-router'

import Qui from '../components/app/CreerCompte/qui.etes.vous.vue'
import Connexion from '../components/app/CreerCompte/connexion.vue'
import Coordonnees from '../components/app/CreerCompte/coordonnees.vue'
import Activite from '../components/app/CreerCompte/activite.vue'
import Photo from '../components/app/CreerCompte/photo.vue'
import Background from '../components/app/CreerCompte/background'
import Password from '../components/app/CreerCompte/mot.de.passe'
import ForgotPassword from '../components/app/CreerCompte/forgot.password'

import Analytic from '../components/app/GestionAnalyse/index.vue'
import Connection from '../components/app/GestionLeads/index'
import ManageTags from "../components/app/GestionLeads/manage.tags"
import Settings from "../components/app/GestionParam/index"

import ProfilUser from '../components/app/GestionProfil/profil.mode.user.vue'
import ModifInfo from '../components/app/GestionProfil/profil.modif.user.vue'
import ProfilClient from '../components/app/GestionProfil/profil.mode.client'
import EditProfilClient from '../components/app/GestionProfil/profil.edit.client'
import ModifEquipe from '../components/app/GestionProfil/Equipe/equipe.modifier'
import ModifPhotos from '../components/app/GestionProfil/Photos/photos.modifier'

import ModifTexte from '../components/app/GestionProfil/Texte/texte.modifier.vue'
import ModifSite from '../components/app/GestionProfil/Site/site.modifier.vue'
import ModifVideo from '../components/app/GestionProfil/Video/video.modifier.vue'
import ModifTelecharge from '../components/app/GestionProfil/Telecharge/telecharge.modifier.vue'
import ModifReseaux from '../components/app/GestionProfil/Reseaux/reseaux.modifier.vue'
import ModifInteraction from '../components/app/GestionProfil/Contact/contact.modifier.vue'
import ModifCTA from '../components/app/GestionProfil/CTA/cta.modifier'
import AjoutSection from '../components/app/GestionProfil/Section/section.liste'
import CreationSection from '../components/app/GestionProfil/Section/section.creation'
import AgencerSection from '../components/app/GestionProfil/Section/section.agencer'

import Login from '../components/admin/Login/login'
import Dashboard from '../components/admin/Dashboard/dashboard.vue'
import Leads from '../components/admin/Leads/leads.vue'
import Templates from '../components/admin/Template/template'
import Statistics from '../components/admin/Statistique/statistique'
import Parametre from '../components/admin/Parametre/parametre'
import Equipe from '../components/admin/Equipe/equipe'
import AddMember from '../components/admin/Equipe/equipe.add.member'
import EditMember from '../components/admin/Equipe/equipe.edit.member'

import NeedHelp from '../components/admin/NeedHelp/needhelp'

import NotFound from '../components/notfound/notfound.vue'
import Spinner from '../components/app/Navigation/spinner'

const isDashboard = (location.host.startsWith("dashboard") || (process.env.NODE_ENV === 'development' && process.env.VUE_APP_LOCATION_HOST === 'dashboard'));
const router = new createRouter({
  history: createWebHistory(),
  routes : [
    ...(isDashboard
      ? [
        {path: "/", component: Dashboard},
        {path: "/dashboard", component: Dashboard},
        {path: "/login", component: Login},
        {path: "/leads", component: Leads},
        {path: "/template", component: Templates},
        {path: "/statistics", component: Statistics},
        {path: "/settings", component: Parametre},
        {path: "/equipe", component: Equipe},
        {path: "/equipe/edit", component: AddMember}, // add member
        {path: "/equipe/edit/:id", component: EditMember}, // edit member
        {path: "/help", component: NeedHelp},
      ]
      : [
        {path: "/", component: Connexion},
        {path: "/qui", component: Qui},
        {path: "/coordonnees", component: Coordonnees},
        {path: "/activite", component: Activite},
        {path: "/password", component: Password},
        {path: "/photo", component: Photo},
        {path: "/background", component: Background},
        {path: "/forgotPasswd", component: ForgotPassword},

        {path: "/profil/user", component: ProfilUser}, // profil user
        {path: "/modifInfo", component: ModifInfo}, //modification profil infos
        {path: "/profil/client", component: ProfilClient}, // profil client
        {path: "/profil/:link", component: ProfilClient},
        {path: "/profil/edit/:link", component: EditProfilClient},
        {path: "/modifEquipe/:id", component: ModifEquipe}, // id component

        {path: "/document/edit/:link/:id", component: ModifTelecharge}, //edit document
        {path: "/document/edit/:link", component: ModifTelecharge}, //create document
        {path: "/bio/edit/:link/:id", component: ModifTexte}, //edit text
        {path: "/bio/edit/:link", component: ModifTexte}, //create text
        {path: "/profil/edit/:link/:id", component: ModifInfo}, //edit profile
        {path: "/video/edit/:link/:id", component: ModifVideo}, //edit video
        {path: "/video/edit/:link", component: ModifVideo}, //create video
        {path: "/site/edit/:link/:id", component: ModifSite}, //edit site
        {path: "/site/edit/:link", component: ModifSite}, //create site
        {path: "/social/edit/:link/:id", component: ModifReseaux}, //edit social
        {path: "/social/edit/:link", component: ModifReseaux}, //create social
        {path: "/photo/edit/:link/:id", component: ModifPhotos}, // edit photo
        {path: "/photo/edit/:link", component: ModifPhotos}, // create photo
        {path: "/contact/edit/:link/:id", component: ModifInteraction}, // edit contact
        {path: "/contact/edit/:link", component: ModifInteraction}, // create contact
        {path: "/cta/edit/:link/:id", component: ModifCTA}, // edit cta
        {path: "/cta/edit/:link", component: ModifCTA}, // create cta

        {path: "/:link/section", component: AjoutSection},
        {path: "/section/arrange/:link", component: AgencerSection},
        {path: "/ajoutSection/:nom", component: CreationSection}, //nom de component Ex: bio, site, contact...

        {path: "/analytic", component: Analytic},
        {path: "/leads", component: Connection},
        {path: "/manageTags", component: ManageTags},
        {path: "/settings", component: Settings},

        {path: "/spinner", component: Spinner}, // spinner chargement
      ]
    ),

    {path: "/:catchAll(.*)", component: NotFound} //A mettre a la fin de tous les routeurs
  ]
})

router.beforeEach((to, from, next) => {
  let isAuthenticated = !!(JSON.parse(localStorage.vuex || "{}").user?.token)
  // If logged in, or going to the Login page.
  if (isAuthenticated || to.path === '/login' || !isDashboard) {
    // Continue to page.
    next()
  } else {
    // Not logged in, redirect to login.
    next({path: '/login'})
  }
})

export default router
