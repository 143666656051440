<template>
  <div class="col-tops text-start">
    <top-picture :title="'Top 5 Pictures'"/>
    <top-teams :title="'Team Top 5'" />
    <top-social-media :title="'Top 5 Social Media'" />
    <website-clicks :title="'Website clicks'" />
    <file-download :title="'File downloads'" />
  </div>
</template>

<script>
import TopPicture from "./layout.module.pictures";
import TopTeams from "./layout.module.teams";
import TopSocialMedia from "./layout.module.socials";
import WebsiteClicks from "./layout.module.website";
import FileDownload from "./layout.module.download";

export default {

  components: {
    TopPicture,
    TopTeams,
    TopSocialMedia,
    WebsiteClicks,
    FileDownload
  },

  data() {
    return {

    };
  },

  methods : {}

}
</script>

<style scoped>
.col-tops{
  width: 47%;
  height: fit-content;
}

</style>