<template>
  <svg width="20" height="20" viewBox="0 0 20 20" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.2915 18.9584C3.94984 18.9584 3.6665 18.675 3.6665 18.3334V1.66669C3.6665 1.32502 3.94984 1.04169 4.2915 1.04169C4.63317 1.04169 4.9165 1.32502 4.9165 1.66669V18.3334C4.9165 18.675 4.63317 18.9584 4.2915 18.9584Z" :fill="fillColor"/>
    <path d="M13.6248 13.9583H4.2915C3.94984 13.9583 3.6665 13.675 3.6665 13.3333C3.6665 12.9916 3.94984 12.7083 4.2915 12.7083H13.6248C14.5332 12.7083 14.9582 12.4666 15.0415 12.2583C15.1248 12.05 14.9998 11.5833 14.3498 10.9416L13.3498 9.94165C12.9415 9.58331 12.6915 9.04165 12.6665 8.44165C12.6415 7.80831 12.8915 7.18331 13.3498 6.72498L14.3498 5.72498C14.9665 5.10831 15.1582 4.60831 15.0665 4.39165C14.9748 4.17498 14.4998 3.95831 13.6248 3.95831H4.2915C3.9415 3.95831 3.6665 3.67498 3.6665 3.33331C3.6665 2.99165 3.94984 2.70831 4.2915 2.70831H13.6248C15.4498 2.70831 16.0332 3.46665 16.2248 3.91665C16.4082 4.36665 16.5332 5.31665 15.2332 6.61665L14.2332 7.61665C14.0248 7.82498 13.9082 8.11665 13.9165 8.40831C13.9248 8.65831 14.0248 8.88331 14.1998 9.04165L15.2332 10.0666C16.5082 11.3416 16.3832 12.2916 16.1998 12.75C16.0082 13.1916 15.4165 13.9583 13.6248 13.9583Z" :fill="fillColor"/>
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>