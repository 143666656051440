<template>
  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center bg-white border-radius-10 w-100">

    <div class="font-size text-start col-tool">
      <slot name="title"></slot>
    </div>

    <slot name="legendbar" cclass="col-tool"></slot>
    <slot name="btnbar" class="col-tool"></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
*{
  font-family: Poppins;
}

.font-size {
  font-size: calc((33 / 1820) * (100vw - var(--size-2x)));
  line-height: calc((33 / 1820) * (100vw - var(--size-2x)));
  font-weight: 600;
}

.col-tool{
  width: 20%;
}
</style>