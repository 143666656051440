<template>
  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
    <h1 class="font-size text-start m-0">
      <slot name="title"></slot>
    </h1>
  </div>

  <slot name="template"></slot>

  <slot name="legendbar"></slot>

  <slot name="table"></slot>

</template>

<script>
export default {

}
</script>

<style scoped>
*{
  font-family: Poppins;
}

.font-size {
  font-size: calc((33 / 1820) * (100vw - var(--size-2x)));
  line-height: calc((33 / 1820) * (100vw - var(--size-2x)));
  font-weight: 600;
}
</style>