<template>
  <div class="shadow-box corner-radius padding-0-5x margin-left-1x margin-right-1x margin-top-1x margin-bottom-2x">
    <div class="margin-bottom-1x">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">


        <div v-bind:style="textColor" class="col text-start p-0 text-black text-title">{{ title }}</div>
        <div :style="onDisplay">
          <router-link v-bind:to="'/bio/edit/' + this.link + '/' + this.id" v-if="!byManager">

            <button-base
              :textColor="'text-white'"
              :bgColor="'bg-blue'"
              class="edit-button "
            >
              <template v-slot:label>
                <SVGFatrows :fillColor="'white'"/>
                Edit
              </template>
            </button-base>

          </router-link>

          <button-base
            v-else
            v-on:click="$emit('editClicked')"
            :textColor="'text-white'"
            :bgColor="'bg-blue'"
            class="edit-button "
          >
            <template v-slot:label>
              <SVGFatrows :fillColor="'white'"/>
              Edit
            </template>
          </button-base>
        </div>


      </div>
    </div>
    <div class="">
      <p class="p-0 mb-0 text-start text-description">{{ description }}</p>
    </div>
  </div>
</template>

<script>
import ButtonBase from "../../Components/Buttons/button.base"
import SVGFatrows from "../../../../assets/svgs/admin/svg.fatrows";

export default {

  props: [ 'byManager', 'textColor', 'title', 'description', 'id', 'disableModif', 'link' ],

  components: { ButtonBase, SVGFatrows },

  data() {
    return {
      onDisplay: 'display: block;'
    }
  },

  methods : {
    displayEdit(){
      if( this.disableModif === true ){
        this.onDisplay = 'display: none;'
      }
    }
  },

  mounted() {
    this.displayEdit();
  }

}
</script>

<style scoped>
</style>