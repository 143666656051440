<template>
  <info-base @prev="prev" @next="next">

    <template v-slot:title>
      Current job position
    </template>

    <template v-slot:form>

      <q-form
          ref="form"
          :model="formModel"
          :rules="rul"
      >
        <!-- Input poste -->
        <q-form-item label="Title" prop="poste" class="text-start my-2">
          <q-input v-model="formModel.poste" type="text" class="form-control" id="poste" placeholder="Enter your title" />
        </q-form-item>

        <!-- Input entreprise -->
        <q-form-item label="Company" prop="entreprise" class="text-start my-2">
          <q-input v-model="formModel.entreprise" type="text" class="form-control" id="entreprise" placeholder="Enter your company’s name" />
        </q-form-item>

      </q-form>

    </template>

    <template v-slot:label>
      Next step
      <SVGArrowRight :fillColor="'white'"/>
    </template>

  </info-base>

</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from "vue-router";
import { ref, reactive, getCurrentInstance } from "vue";
import SVGArrowRight from "../../../assets/svgs/app/svg.arrow-circle-right";
import InfoBase from "./info.base"

export default {

  components: {
    SVGArrowRight,
    InfoBase
  },

  setup() {
    let form = ref();
    let store = useStore();
    let router = useRouter();
    let model = { poste: null, entreprise: null };
    let formModel = reactive(model);
    let app = getCurrentInstance();
    let rules = {
      poste: app.appContext.config.globalProperties.$validator_not_null,
      entreprise: app.appContext.config.globalProperties.$validator_not_null,
    };

    let rul = reactive(rules);


    /**
     * Redirect to next page
     */
    let next = async () => {
      await form?.value?.validate()
          .then((valid) => {
            if (valid) {
              let { isValid, invalidFields } = valid;
              console.log('QForm | validate', isValid, invalidFields);

              if (isValid) {
                store.commit("savePoste", formModel.poste)
                store.commit("saveEntreprise", formModel.entreprise)
                router.push('/photo');
              }
            }
          })

          .catch(() => {
            app.appContext.config.globalProperties.$toast.show("Veuillez remplir tous les champs", {type: 'error'});
          })
    };

    /**
     * Redirect to previous page
     */
    let prev = () => {
      router.push('/coordonnees');
    };

    return {
      form,
      rul,
      formModel,
      next,
      prev
    };

  },


  data() {
    return {

    };
  },

  methods : {

  },
};
</script>

<style>

</style>

