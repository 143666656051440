<template>
  <base-popup>

    <template v-slot:svg-left>
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="4" width="48" height="48" rx="24" fill="#D6D6FF"/>
        <path d="M35.77 27.25H31.73C29.72 27.25 28.75 26.27 28.75 24.27V20.23C28.75 18.22 29.73 17.25 31.73 17.25H35.77C37.78 17.25 38.75 18.23 38.75 20.23V24.27C38.75 26.27 37.77 27.25 35.77 27.25ZM31.73 18.75C30.55 18.75 30.25 19.05 30.25 20.23V24.27C30.25 25.45 30.55 25.75 31.73 25.75H35.77C36.95 25.75 37.25 25.45 37.25 24.27V20.23C37.25 19.05 36.95 18.75 35.77 18.75H31.73Z" fill="#0400BD"/>
        <path d="M24.27 27.25H20.23C18.22 27.25 17.25 26.36 17.25 24.52V19.98C17.25 18.14 18.23 17.25 20.23 17.25H24.27C26.28 17.25 27.25 18.14 27.25 19.98V24.51C27.25 26.36 26.27 27.25 24.27 27.25ZM20.23 18.75C18.89 18.75 18.75 19.13 18.75 19.98V24.51C18.75 25.37 18.89 25.74 20.23 25.74H24.27C25.61 25.74 25.75 25.36 25.75 24.51V19.98C25.75 19.12 25.61 18.75 24.27 18.75H20.23Z" fill="#0400BD"/>
        <path d="M24.27 38.75H20.23C18.22 38.75 17.25 37.77 17.25 35.77V31.73C17.25 29.72 18.23 28.75 20.23 28.75H24.27C26.28 28.75 27.25 29.73 27.25 31.73V35.77C27.25 37.77 26.27 38.75 24.27 38.75ZM20.23 30.25C19.05 30.25 18.75 30.55 18.75 31.73V35.77C18.75 36.95 19.05 37.25 20.23 37.25H24.27C25.45 37.25 25.75 36.95 25.75 35.77V31.73C25.75 30.55 25.45 30.25 24.27 30.25H20.23Z" fill="#0400BD"/>
        <path d="M36.5 34.25H30.5C30.09 34.25 29.75 33.91 29.75 33.5C29.75 33.09 30.09 32.75 30.5 32.75H36.5C36.91 32.75 37.25 33.09 37.25 33.5C37.25 33.91 36.91 34.25 36.5 34.25Z" fill="#0400BD"/>
        <path d="M33.5 37.25C33.09 37.25 32.75 36.91 32.75 36.5V30.5C32.75 30.09 33.09 29.75 33.5 29.75C33.91 29.75 34.25 30.09 34.25 30.5V36.5C34.25 36.91 33.91 37.25 33.5 37.25Z" fill="#0400BD"/>
        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F4F3FF" stroke-width="8"/>
      </svg>
    </template>

    <template v-slot:svg-right>
      <span class="row align-items-center" v-on:click="cancel">
        <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#0400BD"/>
          <path d="M9.16999 15.5799C8.97999 15.5799 8.78999 15.5099 8.63999 15.3599C8.34999 15.0699 8.34999 14.5899 8.63999 14.2999L14.3 8.63986C14.59 8.34986 15.07 8.34986 15.36 8.63986C15.65 8.92986 15.65 9.40986 15.36 9.69986L9.69998 15.3599C9.55998 15.5099 9.35999 15.5799 9.16999 15.5799Z" fill="#0400BD"/>
          <path d="M14.83 15.5799C14.64 15.5799 14.45 15.5099 14.3 15.3599L8.63999 9.69986C8.34999 9.40986 8.34999 8.92986 8.63999 8.63986C8.92999 8.34986 9.40998 8.34986 9.69998 8.63986L15.36 14.2999C15.65 14.5899 15.65 15.0699 15.36 15.3599C15.21 15.5099 15.02 15.5799 14.83 15.5799Z" fill="#0400BD"/>
        </svg>
      </span>
    </template>

    <template v-slot:title>
      Which contact link do you want do add ?
    </template>

    <template v-slot:body>
      <div class="margin-bottom-1x">
        <div class="row m-0 p-0">
          <div class="reseaux-btn">

            <a target="_blank" rel="noreferrer" v-on:click="createSocial('appel')">
              <q-button theme="secondary" class="btn text-danger btn-contact" :class="fondAppel">
                <SVGCall :fill-color="svgAppel"/>
              </q-button>
            </a>

            <a target="_blank" rel="noreferrer" v-on:click="createSocial('message')">
              <q-button theme="secondary" class="btn text-danger btn-contact" :class="fondMessage">
                <SVGSms :fill-color="svgMessage"/>
              </q-button>
            </a>

            <a target="_blank" rel="noreferrer" v-on:click="createSocial('mail')">
              <q-button theme="secondary" class="btn text-danger btn-contact" :class="fondMail">
                <SVGMail :fill-color="svgMail"/>
              </q-button>
            </a>

            <a target="_blank" rel="noreferrer" v-on:click="createSocial('link')">
              <q-button theme="secondary" class="btn text-danger btn-contact" :class="fondLink">
                <SVGWeb :fill-color="svgLink"/>
              </q-button>
            </a>

            <a target="_blank" rel="noreferrer" v-on:click="createSocial('localization')">
              <q-button theme="secondary" class="btn text-danger btn-contact" :class="fondLocalization">
                <SVGLocation :fill-color="svgLocalization"/>
              </q-button>
            </a>

          </div>

        </div>
      </div>


      <template v-if="isLocalization">
        <div class="left-inner-addon input-container">
          <i class="icon">
            <SVGLocation :fill-color="'#9CA3AF'"/>
          </i>
          <input v-model="address" type="text" class="form-control text-description" placeholder="Address" />
        </div>

        <div class="left-inner-addon input-container margin-bottom-1x">
          <i class="icon">
            <SVGLocation :fill-color="'#9CA3AF'"/>
          </i>
          <input v-model="city" type="text" class="form-control text-description" placeholder="City" />
        </div>
      </template>

      <template v-else-if="isLink">
        <div class="left-inner-addon input-container margin-bottom-1x">
          <i class="icon">
            <SVGLink :fill-color="'#9CA3AF'"/>
          </i>
          <input v-model="link" type="text" class="form-control text-description" placeholder="Link" />
        </div>
      </template>


      <template v-else>
        <vue-tel-input :value="link" @input="updatePhone" mode="international" id="tel-country-codes"></vue-tel-input>
      </template>

    </template>

    <template v-slot:button-bar>
      <q-button
          v-on:click="createContact($emit)"
          theme="secondary"
          class="btn btn-save p-0 mx-0 mt-0 margin-bottom-0-5x w-100 bg-blue"
      >
          <span class="text-white">
            Add this contact link
          </span>
      </q-button>
    </template>

  </base-popup>

</template>

<script>
import { inject } from 'vue';
import BasePopup from "./BasePopup"
import SVGCall from "../../../../assets/svgs/app/svg.call-calling";
import SVGMail from "../../../../assets/svgs/app/svg.mail";
import SVGSms from "../../../../assets/svgs/app/svg.sms";
import SVGWeb from "../../../../assets/svgs/app/svg.website";
import SVGLocation from "../../../../assets/svgs/app/svg.location";
import { VueTelInput } from 'vue3-tel-input';
import SVGLink from "../../../../assets/svgs/app/svg.link";
import { QMessageBoxAction, QButton } from '@qvant/qui-max';

let qMessageBoxContainer = undefined;
let idMax = 0;
let orderMax = 0;

export default {

  props: [ 'listContact' ],

  components: {
    BasePopup,
    SVGCall,
    SVGMail,
    SVGSms,
    SVGWeb,
    SVGLink,
    SVGLocation,
    VueTelInput,
    QButton
  },

  data() {
    return {

      link: null,
      address: null,
      city: null,
      isLocalization: false,
      type: null,
      isLink: false,

      fondAppel: "link-disable",
      fondMessage: "link-disable",
      fondMail: "link-disable",
      fondLink: "link-disable",
      fondLocalization: "link-disable",

      svgAppel: '#9CA3AF',
      svgMessage: '#9CA3AF',
      svgMail: '#9CA3AF',
      svgLink: '#9CA3AF',
      svgLocalization: '#9CA3AF',

    }
  },

  setup: () => {
    qMessageBoxContainer = inject(
        'qMessageBoxContainer'
    );

    return {
      qMessageBoxContainer,
    }
  },

  methods : {

    updatePhone(params) {
      if (typeof params == "string") {
        this.link = params;
      }
    },

    createSocial(nameComponent){
      //console.log(nameComponent)
      this.fondAppel = "link-disable";
      this.fondMessage = "link-disable";
      this.fondMail = "link-disable";
      this.fondLink = "link-disable";
      this.fondLocalization = "link-disable";

      this.svgAppel = '#9CA3AF';
      this.svgMessage = '#9CA3AF';
      this.svgMail = '#9CA3AF';
      this.svgLink = '#9CA3AF';
      this.svgLocalization = '#9CA3AF';

      switch (nameComponent) {
        case 'appel':
          this.isLocalization = false
          this.isLink = false
          this.type = nameComponent

          this.fondAppel = "active link-active";
          this.svgAppel = '#0400BD';
          break;
        case 'message':
          this.isLocalization = false
          this.isLink = false
          this.type = nameComponent

          this.fondMessage = "active link-active";
          this.svgMessage = '#0400BD';
          break;
        case 'mail':
          this.isLocalization = false
          this.isLink = true
          this.type = nameComponent

          this.fondMail = "active link-active";
          this.svgMail = '#0400BD';
          break;
        case 'link':
          this.isLocalization = false
          this.isLink = true
          this.type = nameComponent

          this.fondLink = "active link-active";
          this.svgLink = '#0400BD';
          break;
        case 'localization':
          this.isLocalization = true
          this.type = nameComponent

          this.fondLocalization = "active link-active";
          this.svgLocalization = '#0400BD';
          break;
      }
    },

    cancel: () => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.cancel
      });
    },

    createContact(emit){
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.confirm
      });
      let contact = []
      if(this.isLocalization){
        contact.push({
          id: idMax,
          address: this.address,
          city: this.city,
          type: this.type,
          order: orderMax,
        })
      }else{
        contact.push({
          id: idMax,
          lien: this.link,
          type: this.type,
          order: orderMax,
        })
      }

      console.log(contact[0])
      emit("add", contact[0]);
    }
  },

  mounted(){
    if (this.listContact.length !== 0) {
      this.listContact.forEach((social) => {
        if (social.id > idMax) {
          idMax = social.id
        }
        if (social.order > orderMax) {
          orderMax = social.order
        }
      })
      idMax++
      orderMax++
    }else{
      console.log("create a component")
      idMax = 0
      orderMax = 0
    }
  }

}
</script>

<style scoped>
.btn{
  align-items: center;
  justify-content: center;
  display: flex;
}

.active.link-active {
  background: #D6D6FF!important;
  border: 1px solid #0400BD!important;
}

.link-disable{
  background: #FFFFFF!important;
}

.reseaux-btn {
  display: flex;
  justify-content: space-between;
  height: var(--size-3x);
  margin: 0;
  padding: 0;
}

.bg-blue {
  background-color: #0400BD;
}

.btn-save{
  height: var(--size-3x);
  margin-left: 0!important;
}

#tel-country-codes{
  border-radius: 8px!important;
  border: 1px solid #ced4da;
  height: var(--size-3x);
  margin-bottom: var(--size-2x);
}
</style>