<template>
  <div class="d-flex justify-content-between align-items-center margin-top-0-5x margin-bottom-1x">
    <div>
      <button-base v-on:click="showAddPopup" :text-color="'white'" :bg-color="'#0400BD'" id="btn-add">
        <template v-slot:label>
          Add
          <SVGUser :fill-color="'white'" />
        </template>
      </button-base>

      <button-base v-on:click="showFilterPopup" id="btn-filter">
        <template v-slot:label>
          Filter {{ tagsFiltered.length > 0 ? '('+tagsFiltered.length+')' : '' }}
          <SVGFilter :fill-color="'#0400BD'" />
        </template>
      </button-base>

      <button-base v-on:click="redirectToManage" :text-color="'white'" :bg-color="'#0400BD'" id="btn-sticker">
        <template v-slot:label>
          All Stickers
          <SVGFlag :fill-color="'white'" />
        </template>
      </button-base>
    </div>
  </div>

  <div class="left-inner-addon input-container py-0">
    <i class="icon">
      <SVGSearch :fill-color="'#9CA3AF'" />
    </i>
    <input v-model="search" type="text" id="product-search" class="form-control text-description"
      placeholder="Search connections" />
  </div>

  <div class="margin-top-1x margin-bottom-1x">
    <div class="d-flex justify-content-between align-items-center">

      <div>
        <button-base v-on:click="checked.length === 1 ? showSelectLeadTagsPopup() : null" :textColor="'text-white'" :bgColor="checked.length === 1 ? 'bg-blue' : 'bg-gray'" class="edit-button">
          <template v-slot:label>
            Assign
            <SVGFlag :fill-color="'white'" />
          </template>
        </button-base>

        <button-base v-on:click="showDeletePopup" :textColor="'text-blue'" :bgColor="'bg-red'"
          class="edit-button border-blue">
          <template v-slot:label>
            Delete
          </template>
        </button-base>

        <button-base v-on:click="showSavePopup" :textColor="'text-white'" :bgColor="'bg-blue'" class="edit-button">
          <template v-slot:label>
            Export
          </template>
        </button-base>

      </div>

      <div>
        <input v-model="checkAll" class="form-check-input margin-right-1x" type="checkbox" value="">
      </div>

    </div>
  </div>

  <template v-for="(lead, index) in this.leadsFilter" :key="index">
    <div class="container-connection rounded-3 margin-bottom-0-5x">
      <div class="row justify-content-center align-items-center m-0 ">

        <a id="download" target="_blank" rel="noreferrer">
          <div class="d-flex justify-content-between align-items-center rounded-3">

            <div class="text-start text-dark tags-part margin-left-1x margin-right-1x">
              <p class="m-0">{{ lead.full_name }}</p>

              <template v-for="(tag, index) in lead.tags.map(tagId=>tags.find(t=>t.id===tagId)).filter(t=>!!t)" :key="index">
                <tag :bg-tag="'#' + tag.color">
                  <template v-slot:labelTag>
                    {{ tag.name }}
                  </template>
                </tag>
              </template>

            </div>

            <div>
              <input v-model="checked" :value="lead.id" class="form-check-input margin-right-1x" type="checkbox">
            </div>

          </div>
        </a>

      </div>
    </div>
  </template>
</template>

<script>
import Tag from "./tag.base";
import ButtonBase from "../Components/Buttons/button.base";
import AddPopup from '../Components/Popups/AddContactToConnectPopup';
import FilterPopup from '../Components/Popups/FilterPopup';
import SelectLeadTagsPopup from '../Components/Popups/SelectLeadTagsPopup';
import DeletePopup from "../Components/Popups/DeleteLeadsPopup";
import SavePopup from "../Components/Popups/saveLeadsPopup";
import { useMessageBox } from "@qvant/qui-max";
import SVGUser from "../../../assets/svgs/app/svg.connection-user-add";
import SVGFilter from "../../../assets/svgs/app/svg.connection-filter-search";
import SVGSearch from "../../../assets/svgs/admin/svg.search";
import SVGFlag from "../../../assets/svgs/app/svg.connection-flag";
import LeadsService from "../../../services/LeadsService";
import TagsService from "../../../services/TagsService";

export default {

  components: {
    ButtonBase,
    SVGUser,
    SVGFilter,
    SVGSearch,
    Tag,
    SVGFlag,
  },

  data() {
    return {
      search: "",
      tags: [],
      tagsFiltered: [],
      leads: [],
      checked: [],
    }
  },

  methods: {
    /**
     * Show popup to add a new contact to your connections list
     */
    async showAddPopup() {
      try {
        let messageBox = useMessageBox();
        const result = await messageBox(
          {
            component: AddPopup,
            listeners: {
              add: (lead) => {
                console.log(lead);
                LeadsService.createLead(lead)
                  .then(() => {
                    this.checked = [];
                    this.tagsFiltered = [];
                    this.getLeads();
                  })
                  .catch(error => {
                    console.log(error)
                  })
              },
            }
          },
        );
        console.log('resolve', result);
      } catch (result) {
        console.log('reject', result);
      }
    },

    /**
     * Show popup to filter leads
     */
    async showFilterPopup() {
      try {
        let messageBox = useMessageBox();
        const result = await messageBox(
          {
            component: FilterPopup,
            props: {
              selectedTags: this.tagsFiltered,
            },
            listeners: {
              filter: (tagsFiltered) => {
                this.checked = [];
                this.tagsFiltered = tagsFiltered;
              },
            }
          },
        );
        console.log('resolve', result);
      } catch (result) {
        console.log('reject', result);
      }
    },

    /**
     * Show popup to filter leads
     */
    async showSelectLeadTagsPopup() {
      try {
        let messageBox = useMessageBox();
        const result = await messageBox(
          {
            component: SelectLeadTagsPopup,
            props: {
              selectedTags: this.leads.find(t=>t.id===this.checked[0])?.tags,
            },
            listeners: {
              filter: (tagsFiltered) => {
                LeadsService.updateLead(this.checked[0], {tags: tagsFiltered})
                  .then(() => {
                    this.getLeads();
                  })
                  .catch(error => {
                    console.log(error)
                  })
              },
            }
          },
        );
        console.log('resolve', result);
      } catch (result) {
        console.log('reject', result);
      }
    },

    /**
     * Show popup to delete leads
     */
    async showDeletePopup() {
      try {
        let messageBox = useMessageBox();
        const result = await messageBox(
          {
            component: DeletePopup,
            props: {
              listToDelete: this.checked,
            },
            listeners: {
              del: (leadsToDelete) => {
                LeadsService.deleteMultipleLeads(leadsToDelete)
                .then(() => {
                  this.checked = [];
                  this.getLeads();
                })
                .catch(error => {
                  console.log(error)
                })
              },
            }
          },
        );
        console.log('resolve', result);
      } catch (result) {
        console.log('reject', result);
      }
    },

    /**
     * Show popup to export the contacts selected
     */
    async showSavePopup() {
      try {
        let messageBox = useMessageBox();
        const result = await messageBox(
          {
            component: SavePopup,
            props: {
              listToExport: this.checked,
            },
            listeners: {
              save: (leadIds) => {
                console.log(leadIds.map(lId => this.leads.find(l=>l.id===lId)))
              },
            }
          },
        );
        console.log('resolve', result);
      } catch (result) {
        console.log('reject', result);
      }
    },

    /**
     * Getter tags sur l'API
     */
    getTags() {
      TagsService.getTags()
        .then(tags => {
          this.tags = tags;
        })
        .catch(error => {
          console.log(error)
        })
    },

    /**
     * Getter connections sur l'API
     */
    getLeads() {
      LeadsService.getLeads()
        .then(leads => {
          this.leads = leads;
          console.log(leads);
        })
        .catch(error => {
          console.log(error)
        })
    },

    /**
     * Redirect to manage stickers page
     */
    redirectToManage() {
      this.$router.push('/manageTags')
    },

  },

  computed: {
    /**
     * Cocher/décocher toutes les cases
     * @constant : leads (array) tableau des leads
     * @return get => isCheck (boolean)
     * @return set => checked (array)
     */
    checkAll: {
      get: function () {
        return this.leadsFilter ? this.checked.length == this.leadsFilter.length : false;
      },
      set: function (value) {
        let checked = [];
        if (value) {
          this.leadsFilter.forEach(function (lead) {
            checked.push(lead.id);
          });
        }
        this.checked = checked;
      }
    },

    leadsFilter: function () {
      return this.leads.filter(lead =>
        ((lead.full_name?.toLowerCase().indexOf(this.search?.toLowerCase()) !== -1) || (lead.email?.toLowerCase().indexOf(this.search?.toLowerCase()) !== -1) || (lead.phone?.toLowerCase().indexOf(this.search?.toLowerCase()) !== -1))
        && (lead.tags.some(tId=>this.tagsFiltered.includes(tId)) || this.tagsFiltered.length === 0)
      )
    }
  },

  mounted() {
    this.getTags();
    this.getLeads();
    document.querySelector('#product-search').addEventListener('keyup', this.inputSearch)
  }
}
</script>

<style scoped>
.bg-red {
  background-color: #EC0000;
}

#btn-add,
#btn-sticker {
  background-color: #0400BD !important;
  padding-left: var(--size-0-5x) !important;
  padding-right: var(--size-0-5x) !important;
}

#btn-filter {
  background-color: #ffffff !important;
  border: 1px solid #0400BD !important;
  color: #0400BD !important;
  padding-left: var(--size-0-5x) !important;
  padding-right: var(--size-0-5x) !important;
}

.form-check-input:checked {
  background-color: #0400BD !important;
  border: 0;
}

.form-check-input {
  width: calc((30/390) * (100vw - var(--size-2x))) !important;
  height: calc((30/390) * (100vw - var(--size-2x))) !important;
  border: 2px solid #D6D6FF !important;
  border-radius: 8px !important;
  color: #D6D6FF !important;
}

.text-select {
  font-size: calc((18/390) * (100vw - var(--size-2x))) !important;
  line-height: 175%;
  font-weight: 400;
  font-family: "Lexend";
  color: #6B7280;
}

.container-connection {
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 1px 6px rgba(211, 221, 255, 0.48), 0px 1px 4px rgba(63, 63, 63, 0.07);
}

.tags-part {
  height: fit-content;
}
</style>