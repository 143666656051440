<template>
  <!-- entete -->
  <div class="corner-radius margin-left-1x margin-right-1x margin-top-1x margin-bottom-1x">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
      <div>
        <button-base
            :textColor="'text-blue'"
            :bgColor="'bg-white'"
            class="edit-button border-blue"
            v-on:click="cancelEdit"
        >
          <template v-slot:label>
            back
          </template>
        </button-base>
      </div>
    </div>
  </div>

  <div class="col-xl-12 margin-left-1x margin-right-1x">

    <div class="text-start margin-top-3x">
      <label class="form-label text-title text-start">
        Manage your stickers
      </label>
    </div>

    <div class="text-start margin-top-0-5x margin-bottom-1x">
      <button-base v-on:click="showAddPopup" :text-color="'white'" :bg-color="'#0400BD'" class="bg-blue">
        <template v-slot:label>
          Create a new sticker
          <SVGFlag :fill-color="'white'" />
        </template>
      </button-base>
    </div>

    <div class="left-inner-addon input-container">
      <i class="icon">
        <SVGSearch :fill-color="'#9CA3AF'" />
      </i>
      <input v-model="search" type="text" id="product-search" class="form-control text-description" placeholder="Search stickers" />
    </div>

    <!-- Component reseau social WhatsApp-->
    <template v-for="(tag, index) in this.tagsFilter" :key="index">
      <social-link :has-svg="false" :is-ellipsis="false">

        <template v-slot:svg>
          <tag :bg-tag="'#' + tag.color">
            <template v-slot:labelTag>{{ tag.name }}</template>
          </tag>
        </template>

        <template v-slot:label></template>

        <template v-slot:buttonbar>
          <div class="d-flex justify-content-between" v-on:click="showEditPopup(tag)">
            <div>
              <SVGEdit :fill-color="'#0400BD'" />
            </div>
          </div>
        </template>
      </social-link>
    </template>

  </div>

  <!-- nav -->
  <Nav :active="'second'" />
</template>

<script>
import Nav from "../Navigation/nav.pieds";
import ButtonBase from "../Components/Buttons/button.base";
import SVGFlag from "../../../assets/svgs/app/svg.connection-flag";
import SVGSearch from "../../../assets/svgs/admin/svg.search";
import SocialLink from "../Components/Buttons/layout.link.base";
import SVGEdit from "../../../assets/svgs/app/svg.connection-edit";
import Tag from "./tag.large";
import AddStickerPopup from '../Components/Popups/AddStickerPopup';
import EditPopup from '../Components/Popups/EditStickerPopup';
import { useMessageBox } from "@qvant/qui-max";
import TagsService from "../../../services/TagsService";

export default {
  components: {
    Nav,
    ButtonBase,
    SVGFlag,
    SVGSearch,
    SocialLink,
    SVGEdit,
    Tag,
  },

  data() {
    return {
      search: "",
      tags: [],
    }
  },

  methods : {
    /**
     * Annuler des modifications et redirection routeur
     */
    cancelEdit(){
      this.$router.push('/leads');
    },

    /**
     * Getter tags sur l'API
     */
    getTags(){
      TagsService.getTags()
        .then(tags => {
          this.tags = tags;
          console.log(tags);
        })
        .catch(error => {
          console.log(error)
        })
    },

    /**
     * Show popup to add a new contact to your connections list
     */
    async showAddPopup(){
      try {
        let messageBox = useMessageBox();
        const result = await messageBox(
            {
              component: AddStickerPopup,
              props: {
                tags: this.tags,
              },
              listeners: {
                add: (args) => {
                  console.log(args)
                  TagsService.createTag({
                    name: args.label,
                    color: args.color,
                  })
                    .then(tag => {
                      this.tags = [...this.tags, tag];
                      this.getTags();
                    })
                    .catch(error => {
                      console.log(error)
                    })
                },
              }
            },
        );
        console.log('resolve', result);
      } catch (result) {
        console.log('reject', result);
      }
    },

    async showEditPopup (tag) {
      try {
        let messageBox = useMessageBox();
        const result = await messageBox(
            {
              component: EditPopup,
              props: {
                tag: tag,
              },
              listeners: {
                save: (args) => {
                  TagsService.updateTag(args.id, args)
                    .then(tag => {
                      const tagIndex = this.tags.findIndex(t=>t.id===tag.id);
                      this.tags[tagIndex] = tag;
                      this.getTags();
                    })
                    .catch(error => {
                      console.log(error)
                    })
                },

                onDelete: (id) => {
                  TagsService.deleteTag(id)
                    .then(tag => {
                      this.tags = this.tags.filter(t=>t.id!==tag.id);
                      this.getTags();
                    })
                    .catch(error => {
                      console.log(error)
                    })
                },

              }
            },
        );
        console.log('resolve', result);
      } catch (result) {
        console.log('reject', result);
      }
    },

  },

  computed : {
    tagsFilter: function () {
      return this.tags.filter(tag => (tag.name?.toLowerCase().indexOf(this.search?.toLowerCase()) !== -1));
    }
  },

  mounted() {
    this.getTags();
    document.querySelector("#app").style.overflow = "unset"
  }
}
</script>

<style scoped>
.text-blue{
  color: #0400BD;
}

.border-blue{
  border: 1px solid #0400BD;
}
</style>