<template>

  <architecture-base>

    <template v-slot:nav>
      <!-- Navigation bar -->
      <Sidebar active="leads" />
    </template>

    <template v-slot:header>
      <!-- Title -->
      <div class="title">Leads</div>

      <template v-if="!isSearch">
        <!-- Button tool bar -->
        <button-bar :bg-color2="'bg-blue'">

          <template v-slot:valueLabel1>
            <div v-on:click="clickBtnSearch">
              <SVGSearch :fillColor="'#0400BD'"/>
              Search
            </div>

          </template>

        </button-bar>
      </template>

      <!-- Search filter -->
      <input
          v-model="search"
          type="text"
          id="lead-search"
          :class="!isSearch ? 'd-none' : 'd-block'"
          placeholder="Name, Position, etc."
      />

    </template>

    <template v-slot:main>
      <div class="table-responsive bg-white border-radius-10 padding-top-2x padding-bottom-2x padding-left-2x padding-right-2x">
        <!-- tableau derniers leads ajoutés -->
        <layout-last-leads>

          <template v-slot:title>{{ 'Collected leads (' + displayedLeads.length + ')' }}</template>

          <template v-slot:legendbar>
            <LegendBar class="padding-left-1x padding-right-1x" v-on:changeOption="selectTimeFrame" />
          </template>

          <template v-slot:btnbar>
            <div class="text-nowrap">
              <button-base v-on:click="handleDelete" :textColor="'text-white'" :bgColor="(isSelecting && selectedLeads.length > 0) ? 'bg-red' : 'bg-gray'" class="border-radius-28 btn-small">
                <template v-slot:label>Delete</template>
              </button-base>

              <button-base v-on:click="handleExport" :textColor="'text-white'" :bgColor="(isSelecting && selectedLeads.length > 0) ? 'bg-blue' : 'bg-gray'" class="border-radius-28 btn-small">
                <template v-slot:label>Export</template>
              </button-base>

              <button-base v-on:click="handleSelect" :textColor="'text-white'" :bgColor="'bg-blue'" class="border-radius-28 btn-small">
                <template v-slot:label>Select</template>
              </button-base>
            </div>
          </template>

          <template v-slot:table>
            <table-last-leads
                ref="leadsTable"
                :leads="displayedLeads"
                :isSelecting="isSelecting"
                @onSelect="updateSelected"
            ></table-last-leads>
          </template>

        </layout-last-leads>
      </div>
    </template>

  </architecture-base>

</template>

<script>
import ButtonBar from "../LayoutComponent/layout.buttonbar";
import SVGSearch from "../../../assets/svgs/admin/svg.search";
import ArchitectureBase from "../LayoutComponent/layout.base";
import Sidebar from "../LayoutComponent/layout.sidebar.vue";
import LayoutLastLeads from "../LastLeads/layout.last.leads";
import LegendBar from "../LayoutComponent/layout.legendbar";
import ButtonBase from "../LayoutComponent/layout.button";
import TableLastLeads from "../LastLeads/layout.table";
import { getManagerLeads, deleteManagerLeads } from "../../../services/LeadsService";
import { downloadBlob } from "../../../services/UtilsService";
//import axios from "axios";

export default {

  components: {
    SVGSearch,
    ArchitectureBase,
    ButtonBar,
    Sidebar,
    LayoutLastLeads,
    LegendBar,
    ButtonBase,
    TableLastLeads
  },

  data() {
    return {
      leads : [],
      filterFrom: null,
      isSelecting: false,
      selectedLeads: [],
      isSearch: false,
      search: null,
      leadsFilter: [],
    }
  },

  methods : {

    clickBtnSearch(){
      this.isSearch = true;
    },

    handleDelete(){
      if (!(this.isSelecting && this.selectedLeads.length > 0)) return;

      const payload = {
        leads: this.selectedLeads.map(l => l.id),
      }
      deleteManagerLeads(payload)
      .then(() => {
        this.getLeads();
      })
      .catch((error) => {
        console.log("error", error);
      })
    },

    handleExport(){
      if (!(this.isSelecting && this.selectedLeads.length > 0)) return;

      const content = 'full_name,email,phone,owner,created_date\n' + 
                      this.selectedLeads.map(l => [l.full_name, l.email, l.phone, (l.user?.first_name ? l.user?.first_name + " " : "") + (l.user?.last_name ?? ""), new Date(l.created_at).toLocaleDateString()].join()).join('\n');
      downloadBlob(content, 'leads.csv', 'text/csv');
    },

    handleSelect(){
      this.isSelecting = !this.isSelecting;
    },

    updateSelected(leads){
      this.selectedLeads = leads;
    },

    /**
     * Getter datas de leads
     */
    getLeads(){
      getManagerLeads()
      .then((leads) => {
        //console.log(leads);
        this.leads = leads;
        this.leadsFilter = leads;
      })
      .catch((error) => {
        console.log("error", error);
      })
    },


    /**
     * Filter a list of teams
     */
    handleSearch(){
      this.leadsFilter = this.leads.filter(lead => {
        //console.log(lead)
        return (lead.full_name?.toLowerCase().indexOf(this.search?.toLowerCase()) !== -1)
            || (lead.email?.toLowerCase().indexOf(this.search?.toLowerCase()) !== -1)
            || (lead.phone?.toLowerCase().indexOf(this.search?.toLowerCase()) !== -1)
            || (lead.user.first_name?.toLowerCase().indexOf(this.search?.toLowerCase()) !== -1)
            || (lead.user.last_name?.toLowerCase().indexOf(this.search?.toLowerCase()) !== -1)
            || (lead.created_at?.toLowerCase().indexOf(this.search?.toLowerCase()) !== -1)
      })
      //console.log(this.leadsFilter)
    },

    /**
     * Permet de changer datas par rapport aux choix
     * @param timeframe
     */
     selectTimeFrame(timeframe){
      this.filterFrom = timeframe;
    }
  },

  mounted() {
    this.getLeads();
    document.querySelector('#lead-search').addEventListener('keyup', this.handleSearch);
  },

  computed: {
    displayedLeads() {
      return this.leadsFilter.filter(l => (new Date(l.created_at).getTime()/1000) > ((new Date().getTime()/1000) - this.filterFrom))
    }
  },

}
</script>

<style scoped>
.table-scroll{
  height: calc(100vh - var(--size-8x));
}

tr:nth-child(odd) td:first-child { border-top-left-radius: 10px; }
tr:nth-child(odd) td:first-child { border-bottom-left-radius: 10px; }

tr:nth-child(odd) td:last-child { border-top-right-radius: 10px; }
tr:nth-child(odd) td:last-child { border-bottom-right-radius: 10px; }

tbody tr:nth-child(odd){
  background: var(--color-grey-thirdary);
}

tbody tr{
  border: 0 solid var(--color-white);
}

.title{
  font-size: calc((48 / 1728) * (100vw - var(--size-2x)));
  line-height: 100%;
  font-weight: 600;
}

.border-radius-10{
  border-radius: 10px;
}

.bg-gray{
  background: #D1D5DB;
}

.bg-red{
  background: #EC0000;
}

.bg-blue{
  background: #0400BD;
}

.border-radius-28{
  border-radius: 28px !important;
}

.btn-small{
  width: calc((26/358) * (100vw - var(--size-2x)));
  height: calc((17/358) * (100vh - var(--size-2x)));
  padding: 0 !important;
}

#lead-search{
  width: calc(2 * (75/358) * (100vw - var(--size-2x)) + var(--size-2x));
  background: #FFFFFF;
  border: unset !important;
  box-shadow: 0px 1px 6px rgba(211, 221, 255, 0.48), 0px 1px 4px rgba(63, 63, 63, 0.07);
  border-radius: 28px;
  height: var(--size-3x);
  padding-left: var(--size-1x);
}
</style>