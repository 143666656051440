import axios from "axios";
import store from "../../store";

let cards = null

export const getCards = () => {
    return new Promise((resolve, reject) => {
        if (cards==null) {
            return axios.get(process.env.VUE_APP_ENV_API_URL + '/card/management/managed-cards/',
                { headers: { Authorization: store.state.user.token } }
            )
            .then((response) => {
                cards = response.data
                resolve(cards);
            })
            .catch((error) => {
                reject(error);
            })
        }
        else {
            resolve(cards);
        }
    });
}

export const detachCard = (userId) => {
    return new Promise((resolve, reject) => {
        return axios.post(process.env.VUE_APP_ENV_API_URL + '/card/management/detach-card-from-user/' + Number(userId).toString() + '/',
            null,
            { headers: { Authorization: store.state.user.token } }
        )
        .then((response) => {
            cards = null;
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export const attachCard = (userId, serial, pin) => {
    return new Promise((resolve, reject) => {
        return axios.post(process.env.VUE_APP_ENV_API_URL + '/card/management/attach-card-to-user/' + Number(userId).toString() + '/',
            { serial_number: serial, pin_code: pin },
            { headers: { Authorization: store.state.user.token } }
        )
        .then((response) => {
            cards = null;
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export const updateLink = (linkId, newLabel) => {
    return new Promise((resolve, reject) => {
        return axios.post(process.env.VUE_APP_ENV_API_URL + '/card/management/update-pretty-link/' + Number(linkId).toString() + '/',
            { new_label: newLabel },
            { headers: { Authorization: store.state.user.token } }
        )
        .then((response) => {
            cards = null;
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}