<template>
  <info-base @prev="prev" @next="next">

    <template v-slot:title>
      Personal information
    </template>


    <template v-slot:form>

      <q-form
          ref="form"
          :model="formModel"
          :rules="rul"
      >

        <!-- Input prénom -->
        <q-form-item label="First name" prop="prenom" class="text-start my-2">
          <q-input v-model="formModel.prenom" type="text" class="form-control" id="prenom" placeholder="Enter your first name"/>
        </q-form-item>

        <!-- Input nom -->
        <q-form-item label="Last name" prop="nom" class="text-start my-2">
          <q-input v-model="formModel.nom" type="text" class="form-control" id="nom" placeholder="Enter your last name"/>
        </q-form-item>

      </q-form>

    </template>


    <template v-slot:label>
      Next step
      <SVGArrowRight :fillColor="'white'"/>
    </template>

  </info-base>

</template>

<script>
import SVGArrowRight from "../../../assets/svgs/app/svg.arrow-circle-right";
import InfoBase from "./info.base"
import { useStore } from 'vuex'
import { useRouter } from "vue-router";
import { ref, reactive, getCurrentInstance } from "vue";

export default {

  components: {
    SVGArrowRight,
    InfoBase,
  },

  setup() {
    let form = ref(null);
    let store = useStore();
    let router = useRouter();
    let model = { prenom: null, nom: null };
    let formModel = reactive(model);
    let app = getCurrentInstance();
    let rules = {
      prenom: app.appContext.config.globalProperties.$validator_not_null,
      nom: app.appContext.config.globalProperties.$validator_not_null
    };

    let rul = reactive(rules);

    /**
     * Redirect to next page
     * @return {Promise<void>}
     */
    let next = async () => {
      await form?.value?.validate()
          .then((valid) => {
            if (valid) {
              const { isValid, invalidFields } = valid;
              console.log('QForm | validate', isValid, invalidFields);

              if (isValid) {
                store.commit("savePrenom", formModel.prenom)
                store.commit("saveNom", formModel.nom)
                router.push('/coordonnees')
              }
            }
          })

          .catch(() => {
            app.appContext.config.globalProperties.$toast.show("Veuillez remplir tous les champs", {type: 'error'});
          })
    };

    /**
     * Redirect to previous page
     */
    let prev = async () => {
      await router.push('/');
    };

    return {
      form,
      rul,
      formModel,
      next,
      prev,
    };

  },


  data() {
    return {

    };
  },

  methods : {

  },

  mounted() {
    document.querySelector("#app").style.overflow = "hidden"
  }
};
</script>

<style>

</style>

