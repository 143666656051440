<template>
  <base-popup>

    <template v-slot:title>
      <div id="popup-title">Assign a new card</div>
    </template>

    <template v-slot:body>

      <q-form
          ref="form"
          :model="formModel"
          :rules="rul"
      >

        <!-- Input Reference number -->
        <q-form-item label="Reference number" prop="numReference" class="text-start my-2">
          <q-input v-model="formModel.numReference" type="text" class="form-control" placeholder="Enter the reference number"/>
        </q-form-item>

        <!-- Pin Code -->
        <q-form-item label="PIN Code" prop="pinCode" class="text-start my-2">
          <q-input v-model="formModel.pinCode" type="text" class="form-control" placeholder="Enter the PIN Code"/>
        </q-form-item>

      </q-form>
    </template>

    <template v-slot:button-bar>
      <button-base v-on:click="addNewCard($emit)" :textColor="'text-white'" :bgColor="'bg-blue'" class="border-radius-28 margin-bottom-0-5x" id="btn-add-card">
        <template v-slot:label>
          Add this card
        </template>
      </button-base>
    </template>

  </base-popup>


</template>

<script>
import BasePopup from "./BasePopup"
import { inject, ref, reactive, getCurrentInstance } from 'vue';
import { QMessageBoxAction } from '@qvant/qui-max';
import ButtonBase from "../../LayoutComponent/layout.button";
import { QForm, QInput, QFormItem } from "@qvant/qui-max";

let qMessageBoxContainer = undefined;

export default {

  props: [],

  components: {
    BasePopup,
    ButtonBase,
    QForm,
    QFormItem,
    QInput,
  },

  data() {
    return {
      imageToUpload: null,
    }
  },

  setup(){
    qMessageBoxContainer = inject(
        'qMessageBoxContainer'
    )

    let form = ref(null);
    let model = { numReference: null, pinCode: null };
    let formModel = reactive(model);
    let app = getCurrentInstance();
    let rules = {
      numReference: app.appContext.config.globalProperties.$validator_not_null,
      pinCode: app.appContext.config.globalProperties.$validator_not_null,
    };
    let rul = reactive(rules);


    let addNewCard = (emit) => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.confirm
      });
      let newCard = {
        numReference: formModel.numReference,
        pinCode: formModel.pinCode,
      }
      emit("saveChanges", newCard);
    }

    return {
      form,
      rul,
      formModel,
      addNewCard,
      qMessageBoxContainer,
    }
  },

  methods : {

    cancel: () => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.cancel
      });
    },

  },

  mounted() {

  }
}
</script>

<style scoped>
#btn-add-card{
  width: 100%!important;
  margin-left: 0!important;
  margin-right: 0!important;
}

.border-radius-28{
  border-radius: 28px!important;
}
</style>