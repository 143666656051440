<template>
  <div class="row h-100 m-0">
    <div class="col-xl-12 p-0 pb-5">

      <Entete v-if="!(componentId >= 0)" @cancelEdit="cancelEdit" @saveEdit="saveEdit()" />

      <div class="padding-top-1x padding-bottom-1x padding-left-1x padding-right-1x h-100">
        <div class="modal-body text-center h-50">
          <div class="text-start h-100">

            <div class="form-group padding-bottom-1x">
              <label for="section-title" class="form-label text-title">Modify the section title</label>
              <div class="left-inner-addon input-container">
                <i class="icon">
                  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.7751 6.60007C17.4334 6.60007 17.1501 6.31673 17.1501 5.97507V4.4584C17.1501 3.85007 16.6584 3.3584 16.0501 3.3584H3.9501C3.34176 3.3584 2.8501 3.85007 2.8501 4.4584V5.9834C2.8501 6.32507 2.56676 6.6084 2.2251 6.6084C1.88343 6.6084 1.6001 6.32507 1.6001 5.97507V4.4584C1.6001 3.1584 2.65843 2.1084 3.9501 2.1084H16.0501C17.3501 2.1084 18.4001 3.16673 18.4001 4.4584V5.9834C18.4001 6.32507 18.1251 6.60007 17.7751 6.60007Z" stroke="#9CA3AF" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10 17.8915C9.65833 17.8915 9.375 17.6081 9.375 17.2665V3.4248C9.375 3.08314 9.65833 2.7998 10 2.7998C10.3417 2.7998 10.625 3.08314 10.625 3.4248V17.2665C10.625 17.6165 10.3417 17.8915 10 17.8915Z" stroke="#9CA3AF" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13.2832 17.8916H6.71655C6.37489 17.8916 6.09155 17.6083 6.09155 17.2666C6.09155 16.9249 6.37489 16.6416 6.71655 16.6416H13.2832C13.6249 16.6416 13.9082 16.9249 13.9082 17.2666C13.9082 17.6083 13.6249 17.8916 13.2832 17.8916Z" stroke="#9CA3AF" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </i>
                <input v-model="title" type="text" class="form-control text-description" id="section-title" :placeholder="title" />
              </div>
            </div>

            <div class="form-group padding-bottom-1x">
              <label for="section-title" class="form-label text-title">
                Add, modify or remove any website
              </label>

              <template v-for="(site, index) in datas" :key="index">
                <!-- Component reseau social WhatsApp-->
                <social-link :has-svg="false" :is-ellipsis="true">
                  <template v-slot:svg>
                    {{ site.subtitle }}
                  </template>

                  <template v-slot:label></template>

                  <template v-slot:buttonbar>
                    <button-bar
                        :fill-color-up="'#0400BD'"
                        :fill-color-down="'#0400BD'"
                        :fill-color-edit="'#0400BD'"
                        @showEditPopup="showEditPopup(site)"
                        @moveUp="moveUp(index, site)"
                        @moveDown="moveDown(index, site)"
                    />
                  </template>
                </social-link>
              </template>

            </div>

            <button-base v-on:click="showAddPopup" :textColor="'text-white'" :bgColor="'bg-blue'" class="w-100 cta-button">
              <template v-slot:label>
                <SVGLink :fillColor="'white'"/>
                Add a new website
              </template>
            </button-base>

          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import SocialLink from "../../Components/Buttons/layout.link.base";
import ButtonBar from "../../Components/Buttons/layout.buttonbar";
import ButtonBase from "../../Components/Buttons/button.base"
import EditPopup from '../../Components/Popups/EditWebsitePopup';
import AddPopup from '../../Components/Popups/AddWebsitePopup';
import SVGLink from "../../../../assets/svgs/app/svg.link";
import Entete from "../../Navigation/nav.enTete.vue";
import {useMessageBox} from "@qvant/qui-max";
import axios from "axios";

export default {

  components: {
    SocialLink,
    ButtonBar,
    ButtonBase,
    Entete,
    SVGLink,
  },

  props: [
    'componentId', 'pageId'
  ],

  data() {
    return {
      title: null,
      datas: [],
    }
  },

  methods : {

    moveUp(index, data){
      console.log(data)
      console.log(index)
      if(index !== 0){
        let prev = this.datas[index - 1]
        // a = 1, b = 4, to exchange two order
        // a = a + b =>  a = 5
        // b = a - b =>  b = 1
        // a = a - b =>  a = 4, b = 1
        data.order = data.order + prev.order
        prev.order = data.order - prev.order
        data.order = data.order - prev.order
        console.log(data.order)
        console.log(prev.order)
        this.datas = this.datas.slice().sort((i1, i2) => i1.order - i2.order);
      }
    },

    moveDown(index, data){
      console.log(data)
      console.log(index)
      let indexMax = this.datas.length - 1
      if(index !== indexMax){
        let next = this.datas[index + 1]
        // a = 1, b = 4, to exchange two order
        // a = a + b =>  a = 5
        // b = a - b =>  b = 1
        // a = a - b =>  a = 4, b = 1
        data.order = data.order + next.order
        next.order = data.order - next.order
        data.order = data.order - next.order
        this.datas = this.datas.slice().sort((i1, i2) => i1.order - i2.order);
      }
    },

    async showAddPopup(){
      try {
        let messageBox = useMessageBox();
        const result = await messageBox(
            {
              component: AddPopup,
              props: {
                listWeb: this.datas,
              },
              listeners: {
                add: (args) => {
                  console.log(args)
                  this.datas.push(args)
                  console.log(this.datas)
                },
              }
            },
        );
        console.log('resolve', result);
      } catch (result) {
        console.log('reject', result);
      }
    },

    async showEditPopup (datas) {
      try {
        let messageBox = useMessageBox();
        const result = await messageBox(
            {
              component: EditPopup,
              props: {
                website: datas,
              },
              listeners: {
                save: (args) => {
                  let WebToUpdate = this.datas.find((data) => data.id == args.id)
                  WebToUpdate.link = args.link;
                  WebToUpdate.subtitle = args.subtitle
                  WebToUpdate.thumbnail = args.thumbnail
                  //console.log(WebToUpdate)
                  this.datas.map((data) => data.id !== args.id ? data : WebToUpdate)
                  console.log(this.datas);
                },

                delete: (args) => {
                  console.log(args.id)
                  this.datas = [...this.datas].filter((data) => data.id !== args.id);
                  console.log(this.datas)
                },
              }
            },
        );
        console.log('resolve', result);
      } catch (result) {
        console.log('reject', result);
      }
    },

    /**
     * Annuler des modifications et redirection routeur
     */
    cancelEdit(){
      this.$router.push('/profil/edit/' + this.$route.params.link);
    },

    /**
     * Enregistrer des modifications
     */
    saveEdit(){
      const payloadId = this.$route.params.id || this.componentId;
      if(payloadId) {
        axios
            .patch(this.$apiUrl + '/card/components/' + payloadId + '/',
                {title: this.title, datas: this.datas,},
                {headers: {Authorization: this.$store.state.user.token}})
            .then((res) => {
              this.$toast.show(
                  'Votre modification a été enregistré avec succès !',
                  {type: 'success'}
              );
              if (this.componentId) {
                this.$emit("componentSaved", res.data);
              }
              else {
                this.$router.push('/profil/edit/' + this.$route.params.link);
              }
            })
            .catch(error => {
              this.$toast.show(error.message, {type: 'error'});
              console.log(error.message)
            })

      }else{
        axios
            .post(this.$apiUrl + '/card/components/',
                {
                  component: "SiteComponent", 
                  title: this.title, 
                  datas: this.datas, 
                  page_profile: this.pageId ?? this.$store.state.user.id_pageprofile,
                },
                {headers: {Authorization: this.$store.state.user.token}})
            .then((res) => {
              this.$toast.show(
                  'Votre modification a été enregistré avec succès !',
                  {type: 'success'}
              );
              if (this.componentId >= 0){
                this.$emit("componentSaved", res.data);
              }
              else {
                this.$router.push('/profil/edit/' + this.$route.params.link);
              }
            })
            .catch(error => {
              this.$toast.show(error.message, {type: 'error'});
              console.log(error.message)
            })
      }

    },

    /**
     * Getter composant website sur l'API
     */
    getWebSite(){
      const payloadId = this.$route.params.id || this.componentId;
      if(payloadId) {
        axios
            .get(this.$apiUrl + '/card/components/' + payloadId + '/',
                {headers: {Authorization: this.$store.state.user.token}})

            .then(response => {
              this.title = response.data.title;
              this.datas = response.data.datas.slice().sort((i1, i2) => i1.order - i2.order);
            })

            .catch(error => {
              console.log(error.message)
            })
      }
    },
  },

  mounted() {
    this.getWebSite();
    document.querySelector("#app").style.overflow = "unset"
  }
};

</script>

<style scoped>
.cta-button {
  height: var(--size-3x)!important;
  font-size: calc(0.95 * var(--size-1x))!important;
  border-radius: calc(1.5 * var(--size-1x))!important;
  padding: 0 var(--size-1x) 0 var(--size-1x)!important;
  margin: 0;
  flex-grow: 1;
}
</style>

