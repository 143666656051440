<template>

  <div class="connection">

    <div class="text-start margin-top-3x">
      <label class="form-label text-title text-start">
        Select the connections<br /> to export or delete
      </label>
    </div>

    <list-connection></list-connection>

  </div>

  <!-- nav -->
  <Nav :active="'second'" />

</template>

<script>
import Nav from "../Navigation/nav.pieds";
import ListConnection from "./list";

export default {

  components: {
    Nav,
    ListConnection,
  },

  data() {
    return {

    }
  },

  methods : {

  },

  computed : {

  },

  mounted() {
    document.querySelector("#app").style.overflow = "scroll"
  }

}
</script>

<style scoped>
.connection {
  margin: 0 var(--size-1x) var(--size-5x);
}

.text-title{
  font-size: calc((22/390) * (100vw - var(--size-2x)))!important;
  line-height: 140%;
  font-weight: 500;
  font-family: "Lexend";
  color: #111827;
}
</style>