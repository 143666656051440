<template>
  <div class="shadow-none margin-top-1x margin-bottom-1x">
    <h6 class="text-period text-start">{{ this.titre }}</h6>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
      <div class="text-center wrapper padding-top-0-5x padding-bottom-0-5x">
        <div class="text-label padding-bottom-0-5x">Views</div>
        <div class="text-data">{{ this.chiffreVue }}</div>
      </div>

      <div class="text-center wrapper padding-top-0-5x padding-bottom-0-5x">
        <div class="text-label padding-bottom-0-5x">Leads</div>
        <div class="text-data">{{ this.chiffreAjout }}</div>
      </div>

      <div class="text-center wrapper padding-top-0-5x padding-bottom-0-5x">
        <div class="text-label padding-bottom-0-5x">Rate</div>
        <div class="text-data">{{ this.chiffreLead }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  name: "layoutChiffre",

  props: ['titre', 'chiffreVue', 'chiffreAjout', 'chiffreLead'],


}
</script>

<style scoped>
.wrapper{
  border: 1px solid #D6D6FF;
  width: calc((100vw - var(--size-2x)) * 0.3);
  border-radius: 8px;
}

.text-period{
  line-height: 150%!important;
}

.text-data{
  font-size: calc((26/390) * (100vw - var(--size-2x)))!important;
  line-height: 150%;
  font-weight: 600;
  font-family: "Lexend";
  color: #0400BD;
}

.text-label{
  font-size: calc((16/390) * (100vw - var(--size-2x)))!important;
  line-height: 150%;
  font-weight: 400;
  font-family: "Lexend";
  color: #000000;
}
</style>