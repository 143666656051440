<template>
  <svg width="20" height="20" viewBox="0 0 20 20" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0001 18.9582C5.05841 18.9582 1.04175 14.9415 1.04175 9.99984C1.04175 5.05817 5.05841 1.0415 10.0001 1.0415C14.9417 1.0415 18.9584 5.05817 18.9584 9.99984C18.9584 14.9415 14.9417 18.9582 10.0001 18.9582ZM10.0001 2.2915C5.75008 2.2915 2.29175 5.74984 2.29175 9.99984C2.29175 14.2498 5.75008 17.7082 10.0001 17.7082C14.2501 17.7082 17.7084 14.2498 17.7084 9.99984C17.7084 5.74984 14.2501 2.2915 10.0001 2.2915Z" />
    <path d="M7.49995 18.125H6.66662C6.32495 18.125 6.04162 17.8417 6.04162 17.5C6.04162 17.1583 6.30828 16.8833 6.64995 16.875C5.34162 12.4083 5.34162 7.59167 6.64995 3.125C6.30828 3.11667 6.04162 2.84167 6.04162 2.5C6.04162 2.15833 6.32495 1.875 6.66662 1.875H7.49995C7.69995 1.875 7.89162 1.975 8.00828 2.13333C8.12495 2.3 8.15828 2.50833 8.09162 2.7C6.52495 7.40833 6.52495 12.5917 8.09162 17.3083C8.15828 17.5 8.12495 17.7083 8.00828 17.875C7.89162 18.025 7.69995 18.125 7.49995 18.125Z" />
    <path d="M12.5 18.1252C12.4333 18.1252 12.3667 18.1169 12.3 18.0919C11.975 17.9836 11.7917 17.6252 11.9083 17.3002C13.475 12.5919 13.475 7.40856 11.9083 2.6919C11.8 2.3669 11.975 2.00856 12.3 1.90023C12.6333 1.7919 12.9833 1.9669 13.0917 2.2919C14.75 7.25856 14.75 12.7252 13.0917 17.6836C13.0083 17.9586 12.7583 18.1252 12.5 18.1252Z" />
    <path d="M10 14.3335C7.675 14.3335 5.35833 14.0085 3.125 13.3502C3.11667 13.6835 2.84167 13.9585 2.5 13.9585C2.15833 13.9585 1.875 13.6752 1.875 13.3335V12.5002C1.875 12.3002 1.975 12.1085 2.13333 11.9918C2.3 11.8752 2.50833 11.8418 2.7 11.9085C7.40833 13.4752 12.6 13.4752 17.3083 11.9085C17.5 11.8418 17.7083 11.8752 17.875 11.9918C18.0417 12.1085 18.1333 12.3002 18.1333 12.5002V13.3335C18.1333 13.6752 17.85 13.9585 17.5083 13.9585C17.1667 13.9585 16.8917 13.6918 16.8833 13.3502C14.6417 14.0085 12.325 14.3335 10 14.3335Z" />
    <path d="M17.4999 8.12497C17.4332 8.12497 17.3666 8.11663 17.2999 8.09163C12.5916 6.52497 7.3999 6.52497 2.69157 8.09163C2.35823 8.19997 2.00823 8.02497 1.8999 7.69997C1.7999 7.36663 1.9749 7.01663 2.2999 6.9083C7.26657 5.24997 12.7332 5.24997 17.6916 6.9083C18.0166 7.01663 18.1999 7.37497 18.0832 7.69997C18.0082 7.9583 17.7582 8.12497 17.4999 8.12497Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>