<template>
  <div class="d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center">
    <template v-if="isBigPicker">
      <div class="rect-option-big bg-rect-yellow" :class="yellow" v-on:click="createNewSticker('yellow')"></div>
      <div class="rect-option-big bg-rect-red" :class="red" v-on:click="createNewSticker('red')"></div>
      <div class="rect-option-big bg-rect-purple" :class="purple" v-on:click="createNewSticker('purple')"></div>
      <div class="rect-option-big bg-rect-seagreen" :class="seagreen" v-on:click="createNewSticker('seagreen')"></div>
      <div class="rect-option-big bg-rect-orange" :class="orange" v-on:click="createNewSticker('orange')"></div>
      <div class="rect-option-big bg-rect-pink" :class="pink" v-on:click="createNewSticker('pink')"></div>
    </template>

    <template v-else>
      <div class="rect-option-small bg-rect-yellow" :class="yellow" v-on:click="createNewSticker('yellow')"></div>
      <div class="rect-option-small bg-rect-red" :class="red" v-on:click="createNewSticker('red')"></div>
      <div class="rect-option-small bg-rect-purple" :class="purple" v-on:click="createNewSticker('purple')"></div>
      <div class="rect-option-small bg-rect-seagreen" :class="seagreen" v-on:click="createNewSticker('seagreen')"></div>
      <div class="rect-option-small bg-rect-orange" :class="orange" v-on:click="createNewSticker('orange')"></div>
      <div class="rect-option-small bg-rect-pink" :class="pink" v-on:click="createNewSticker('pink')"></div>
    </template>
  </div>
</template>

<script>
export default {

  props: {

    isBigPicker: {
      default: false,
    },

    colorDefault : {
      default: null,
    },
  },

  data() {
    return {
      yellow: "link-disable",
      red: "link-disable",
      purple: "link-disable",
      seagreen: "link-disable",
      orange: "link-disable",
      pink: "link-disable",
    }
  },


  methods : {

    createNewSticker(color){
      this.yellow = "link-disable";
      this.red = "link-disable";
      this.purple = "link-disable";
      this.seagreen = "link-disable";
      this.orange = "link-disable";
      this.pink = "link-disable";

      switch (color) {
        case 'yellow':
          this.yellow = "active link-active";
          this.$emit('onPick', '#FFC266');
          break;
        case 'red':
          this.red = "active link-active";
          this.$emit('onPick', '#FF3F61');
          break;
        case 'purple':
          this.purple = "active link-active";
          this.$emit('onPick', '#B63FFF');
          break;
        case 'seagreen':
          this.seagreen = "active link-active";
          this.$emit('onPick', '#00CBBF');
          break;
        case 'orange':
          this.orange = "active link-active";
          this.$emit('onPick', '#FF6B00');
          break;
        case 'pink':
          this.pink = "active link-active";
          this.$emit('onPick', '#FF3FB2');
          break;
      }

    },

    getColorDefault(){
      switch (this.colorDefault) {
        case '#FF3F61':
          this.red = "active link-active";
          break;
        case '#B63FFF':
          this.purple = "active link-active";
          break;
        case '#FF3FB2':
          this.pink = "active link-active";
          break;
        case '#FFC266':
          this.yellow = "active link-active";
          break;
        case '#FF6B00':
          this.orange = "active link-active";
          break;
        case '#00CBBF':
          this.seagreen = "active link-active";
          break;
      }
    }

  },

  mounted(){
    this.getColorDefault()
  }

}
</script>

<style scoped>

.rect-option-big{
  width: calc((24/1310) * (100vw - var(--size-2x)));
  height: calc((24/1310) * (100vw - var(--size-2x)));
  border-radius: 3px;
  margin-left: var(--size-0-5x);
  margin-right: var(--size-0-5x);
}

.rect-option-small{
  width: calc((24/390) * (100vw - var(--size-2x)));
  height: calc((24/390) * (100vw - var(--size-2x)));
  border-radius: 3px;
  margin-left: var(--size-0-5x);
  margin-right: var(--size-0-5x);
}

.bg-rect-yellow{
  background: #FFC266;
}

.bg-rect-red{
  background-color: #FF3F61;
}

.bg-rect-purple{
  background: #B63FFF;
}

.bg-rect-seagreen {
  background-color: #00CBBF;
}

.bg-rect-orange{
  background-color: #FF6B00;
}

.bg-rect-pink{
  background-color: #FF3FB2;
}

.active.link-active {
  border: 1px solid #0400BD!important;
}

.link-disable{
  border: 1px solid #ffffff!important;
}
</style>