<template>
  <svg viewBox="0 0 24 24" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.08577 23.9151C4.65848 23.8502 3.88314 23.6127 3.36743 23.4115C2.6841 23.1455 2.19693 22.8288 1.68407 22.3166C1.17122 21.8045 0.853806 21.3173 0.589176 20.634C0.388029 20.1183 0.150504 19.3429 0.0855946 17.9157C0.0142658 16.3728 0 15.9099 0 12.0011C0 8.09225 0.0156923 7.63004 0.0848813 6.08577C0.14979 4.65848 0.388742 3.88457 0.588463 3.36743C0.854519 2.6841 1.17193 2.19693 1.68336 1.68336C2.1955 1.17122 2.68268 0.853092 3.36672 0.588463C3.88243 0.387315 4.65777 0.14979 6.08506 0.0848813C7.62861 0.0142658 8.09225 0 11.9996 0C15.9085 0 16.3707 0.0156923 17.9149 0.0848813C19.3422 0.14979 20.1161 0.388742 20.6333 0.588463C21.3166 0.853092 21.8038 1.17122 22.3166 1.68336C22.8295 2.1955 23.1455 2.68339 23.4115 3.36672C23.6127 3.88243 23.8502 4.65777 23.9151 6.08506C23.9857 7.62933 24 8.09154 24 12.0004C24 15.9077 23.9857 16.3714 23.9151 17.9157C23.8502 19.3429 23.6113 20.1183 23.4115 20.634C23.1455 21.3173 22.8288 21.8045 22.3166 22.3166C21.8045 22.8288 21.3166 23.1455 20.6333 23.4115C20.1176 23.6127 19.3422 23.8502 17.9149 23.9151C16.3721 23.9857 15.9085 24 11.9996 24C8.09225 24 7.62862 23.9864 6.08577 23.9151Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9 7.0125C15.5875 5.7 13.8375 5 12 5C8.15 5 5 8.15 5 12C5 13.225 5.35001 14.45 5.96251 15.5L5 19L8.67501 18.0375C9.72501 18.5625 10.8625 18.9125 12 18.9125C15.85 18.9125 19 15.7625 19 11.9125C19 10.075 18.2125 8.325 16.9 7.0125ZM12 17.775C10.95 17.775 9.90001 17.5125 9.02501 16.9875L8.84999 16.9L6.66249 17.5125L7.27501 15.4125L7.09999 15.15C6.48749 14.1875 6.22499 13.1375 6.22499 12.0875C6.22499 8.9375 8.85 6.3125 12 6.3125C13.575 6.3125 14.975 6.925 16.1125 7.975C17.25 9.1125 17.775 10.5125 17.775 12.0875C17.775 15.15 15.2375 17.775 12 17.775ZM15.15 13.4C14.975 13.3125 14.1 12.875 13.925 12.875C13.75 12.7875 13.6625 12.7875 13.575 12.9625C13.4875 13.1375 13.1375 13.4875 13.05 13.6625C12.9625 13.75 12.875 13.75 12.7 13.75C12.525 13.6625 12 13.4875 11.3 12.875C10.775 12.4375 10.425 11.825 10.3375 11.65C10.25 11.475 10.3375 11.3875 10.425 11.3C10.5125 11.2125 10.6 11.125 10.6875 11.0375C10.775 10.95 10.775 10.8625 10.8625 10.775C10.95 10.6875 10.8625 10.6 10.8625 10.5125C10.8625 10.425 10.5125 9.55 10.3375 9.2C10.25 8.9375 10.075 8.9375 9.98751 8.9375C9.90001 8.9375 9.81249 8.9375 9.63749 8.9375C9.54999 8.9375 9.37499 8.9375 9.19999 9.1125C9.02499 9.2875 8.58751 9.725 8.58751 10.6C8.58751 11.475 9.19999 12.2625 9.28749 12.4375C9.37499 12.525 10.5125 14.3625 12.2625 15.0625C13.75 15.675 14.0125 15.5 14.3625 15.5C14.7125 15.5 15.4125 15.0625 15.5 14.7125C15.675 14.275 15.675 13.925 15.5875 13.925C15.5 13.4875 15.325 13.4875 15.15 13.4Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>