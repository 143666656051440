<template>
  <div class="col-xl-12 margin-left-1x margin-right-1x">

    <div class="text-start margin-top-3x">
      <label class="form-label text-title text-start">
        Manage the settings of<br /> your profile
      </label>
    </div>

    <div class="left-inner-addon input-container">
      <i class="icon">
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.49161 18.1393C6.10828 18.1393 4.71661 17.6143 3.65828 16.5559C1.54994 14.4393 1.54994 11.0059 3.65828 8.89759C3.89994 8.65592 4.29994 8.65592 4.54161 8.89759C4.78328 9.13926 4.78328 9.53926 4.54161 9.78092C2.91661 11.4059 2.91661 14.0476 4.54161 15.6726C6.16661 17.2976 8.80828 17.2976 10.4333 15.6726C11.2166 14.8893 11.6499 13.8393 11.6499 12.7226C11.6499 11.6143 11.2166 10.5643 10.4333 9.77259C10.1916 9.53092 10.1916 9.13092 10.4333 8.88926C10.6749 8.64759 11.0749 8.64759 11.3166 8.88926C12.3416 9.91426 12.8999 11.2726 12.8999 12.7226C12.8999 14.1726 12.3333 15.5309 11.3166 16.5559C10.2666 17.6143 8.88328 18.1393 7.49161 18.1393Z" fill="#9CA3AF"/>
          <path d="M15.8916 12.0227C15.7333 12.0227 15.5749 11.9644 15.4499 11.8394C15.2083 11.5977 15.2083 11.1977 15.4499 10.956C17.1583 9.24769 17.1583 6.47269 15.4499 4.77269C13.7416 3.06436 10.9666 3.06436 9.26661 4.77269C8.44161 5.59769 7.98328 6.69769 7.98328 7.86436C7.98328 9.03102 8.44161 10.131 9.26661 10.956C9.50828 11.1977 9.50828 11.5977 9.26661 11.8394C9.02494 12.081 8.62494 12.081 8.38328 11.8394C7.32494 10.781 6.73328 9.36436 6.73328 7.86436C6.73328 6.36436 7.31661 4.94769 8.38328 3.88936C10.5749 1.69769 14.1416 1.69769 16.3416 3.88936C18.5333 6.08102 18.5333 9.65602 16.3416 11.8477C16.2166 11.9644 16.0499 12.0227 15.8916 12.0227Z" fill="#9CA3AF"/>
        </svg>
      </i>
      <input v-model="link" type="text" class="form-control" placeholder="profylead.com/profile/davordjuric" />
    </div>

    <div class="text-start">
      <label class="form-label text-title text-start">
        Manage your preferences
      </label>
    </div>

    <div class="d-flex justify-content-between margin-bottom-1x text-start align-items-center">
      <div class="text-params">
        Send a mail to your news leads
      </div>
      <div>
        <q-switch
            v-model="sendMail"
            :loading="loadingSendMail"
            @change="handleSendMailChange"
        />
      </div>
    </div>

    <div class="d-flex justify-content-between margin-bottom-2x text-start align-items-center">
      <div class="text-params">
        Remove the “Profylead” logo from your profile page
      </div>
      <div>
        <q-switch
            v-model="removeLogo"
            :loading="loadingRemoveLogo"
            @change="handleRemoveLogoChange"
        />
      </div>
    </div>

    <button-base :textColor="'text-white'" :bgColor="'bg-blue'" class="w-100 cta-button">
      <template v-slot:label>
        How to ?
      </template>
    </button-base>

    <button-base :textColor="'text-white'" :bgColor="'bg-red'" class="w-100 cta-button">
      <template v-slot:label>
        Sign out
      </template>
    </button-base>

    <button-base :textColor="'text-white'" :bgColor="'bg-light-blue'" class="w-100 cta-button">
      <template v-slot:label>
        <div class="text-black">
          Share feedback
        </div>
      </template>
    </button-base>

    <button-base :textColor="'text-white'" :bgColor="'bg-white'" class="w-100 cta-button btn-help">
      <template v-slot:label>
        <div class="text-black">
          Help and Support
        </div>
      </template>
    </button-base>

    <div class="text-term">
      Terms <span class="text-gray">and</span> Privacy
    </div>

  </div>

  <!-- nav -->
  <Nav :active="'fourth'" />

</template>

<script>
import Nav from "../Navigation/nav.pieds";
import ButtonBase from "../Components/Buttons/button.base"
import { QSwitch } from '@qvant/qui-max';
import { ref } from "vue";

export default {

  components: {
    Nav,
    ButtonBase,
    QSwitch
  },

  setup() {
    const sendMail = ref(false);
    const removeLogo = ref(false);
    const loadingSwitchSendMail = ref(false);
    const loadingSwitchRemoveLogo = ref(false);

    const handleSendMailChange = () => {
      loadingSwitchSendMail.value = true;

      // async request
      setTimeout(() => {
        loadingSwitchSendMail.value = false;
      }, 200);
    };

    const handleRemoveLogoChange = () => {
      loadingSwitchRemoveLogo.value = true;

      // async request
      setTimeout(() => {
        loadingSwitchRemoveLogo.value = false;
      }, 200);
    };

    return {
      sendMail: sendMail,
      removeLogo: removeLogo,
      loadingSendMail: loadingSwitchSendMail,
      loadingRemoveLogo: loadingSwitchRemoveLogo,
      handleSendMailChange,
      handleRemoveLogoChange,
    };
  },

  data() {
    return {
      link: null,
    }
  },

  methods : {

  },

  computed : {

  },

  mounted() {

  }

}
</script>

<style scoped>
.text-title{
  font-size: calc((22/390) * (100vw - var(--size-2x)))!important;
  line-height: 140%;
  font-weight: 500;
  font-family: "Lexend";
  color: #111827;
  margin-bottom: var(--size-1x)!important;
}

.text-term{
  font-size: calc((14/390) * (100vw - var(--size-2x)));
  line-height: 150%;
  font-weight: 500;
  font-family: "Lexend";
  color: #000000;
  margin-bottom: var(--size-1x);
}

.text-params{
  width: calc((100vw - var(--size-8x)));
}

.text-gray{
  color: #6B7280;
}

.cta-button {
  height: var(--size-3x)!important;
  font-size: calc(0.95 * var(--size-1x))!important;
  border-radius: calc(1.5 * var(--size-1x))!important;
  padding: 0 var(--size-1x) 0 var(--size-1x)!important;
  margin: 0 0 var(--size-1x) !important;
  flex-grow: 1;
}

.bg-red{
  background-color: #EC0000;
}

.bg-light-blue{
  background-color: #D6D6FF;
}

.btn-help{
  margin-top: var(--size-4x)!important;
  box-shadow: 0px 1px 6px rgba(211, 221, 255, 0.48), 0px 1px 4px rgba(63, 63, 63, 0.07)!important;
}

div.q-switch__handle{
  border: 2px solid #0400BD!important;
}

div.q-switch__wrapper{
  background-color: #0400BD!important;
}
</style>