import axios from "axios";
import store from "../store";

let tags = null;

export const getTags = () => {
  return new Promise((resolve, reject) => {
    if (tags == null) {
      axios.get(process.env.VUE_APP_ENV_API_URL + '/card/tags/',
        { headers: { Authorization: store.state.user.token } }
      )
        .then((response) => {
          tags = response.data
          resolve(tags);
        })
        .catch((error) => {
          reject(error);
        })
    }
    else {
      resolve(tags);
    }
  });
}

export const createTag = (tagData) => {
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_ENV_API_URL + '/card/tags/', tagData, {
      headers: { Authorization: store.state.user.token }
    })
      .then((response) => {
        tags = null;
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteTag = (tagId) => {
  return new Promise((resolve, reject) => {
    axios.delete(process.env.VUE_APP_ENV_API_URL + `/card/tags/${tagId}/`, {
      headers: { Authorization: store.state.user.token }
    })
      .then((response) => {
        tags = null;
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateTag = (tagId, updatedFields) => {
  return new Promise((resolve, reject) => {
    axios.patch(process.env.VUE_APP_ENV_API_URL + `/card/tags/${tagId}/`, updatedFields, {
      headers: { Authorization: store.state.user.token }
    })
      .then((response) => {
        tags = null;
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default { getTags, createTag, deleteTag, updateTag };