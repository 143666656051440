<template>
  <div class="row h-100 w-100 m-0">

    <!-- Navigation -->
    <slot name="nav"></slot>

    <div class="col-container overflow-hidden">

      <!-- Header -->
      <div class="header-bar d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center bg-white border-radius-10">
        <slot name="header"></slot>
      </div>

      <!-- Body -->
      <div class="d-flex flex-column overflow-auto">
        <slot name="main"></slot>
      </div>

    </div>

  </div>
</template>

<script>

export default {

  components: {},

}
</script>

<style scoped>
.header-bar {
  padding: calc((36 / 1728) * (100vw - var(--size-2x)));
  margin-bottom: calc((36 / 1728) * (100vw - var(--size-2x)));
}

.col-container{
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: calc((36 / 1728) * (100vw - var(--size-2x)));
  background: #F4F3FF;
}

.border-radius-10{
  border-radius: 10px;
}
</style>