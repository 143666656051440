<template>
  <svg width="24" height="24" viewBox="0 0 24 24" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.77 11.25H15.73C13.72 11.25 12.75 10.27 12.75 8.27V4.23C12.75 2.22 13.73 1.25 15.73 1.25H19.77C21.78 1.25 22.75 2.23 22.75 4.23V8.27C22.75 10.27 21.77 11.25 19.77 11.25ZM15.73 2.75C14.55 2.75 14.25 3.05 14.25 4.23V8.27C14.25 9.45 14.55 9.75 15.73 9.75H19.77C20.95 9.75 21.25 9.45 21.25 8.27V4.23C21.25 3.05 20.95 2.75 19.77 2.75H15.73Z" :fill="fillColor"/>
    <path d="M8.27 11.25H4.23C2.22 11.25 1.25 10.36 1.25 8.52V3.98C1.25 2.14 2.23 1.25 4.23 1.25H8.27C10.28 1.25 11.25 2.14 11.25 3.98V8.51C11.25 10.36 10.27 11.25 8.27 11.25ZM4.23 2.75C2.89 2.75 2.75 3.13 2.75 3.98V8.51C2.75 9.37 2.89 9.74 4.23 9.74H8.27C9.61 9.74 9.75 9.36 9.75 8.51V3.98C9.75 3.12 9.61 2.75 8.27 2.75H4.23Z" :fill="fillColor"/>
    <path d="M8.27 22.75H4.23C2.22 22.75 1.25 21.77 1.25 19.77V15.73C1.25 13.72 2.23 12.75 4.23 12.75H8.27C10.28 12.75 11.25 13.73 11.25 15.73V19.77C11.25 21.77 10.27 22.75 8.27 22.75ZM4.23 14.25C3.05 14.25 2.75 14.55 2.75 15.73V19.77C2.75 20.95 3.05 21.25 4.23 21.25H8.27C9.45 21.25 9.75 20.95 9.75 19.77V15.73C9.75 14.55 9.45 14.25 8.27 14.25H4.23Z" :fill="fillColor"/>
    <path d="M20.5 18.25H14.5C14.09 18.25 13.75 17.91 13.75 17.5C13.75 17.09 14.09 16.75 14.5 16.75H20.5C20.91 16.75 21.25 17.09 21.25 17.5C21.25 17.91 20.91 18.25 20.5 18.25Z" :fill="fillColor"/>
    <path d="M17.5 21.25C17.09 21.25 16.75 20.91 16.75 20.5V14.5C16.75 14.09 17.09 13.75 17.5 13.75C17.91 13.75 18.25 14.09 18.25 14.5V20.5C18.25 20.91 17.91 21.25 17.5 21.25Z" :fill="fillColor"/>
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>