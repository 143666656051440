<template>
  <svg width="32" height="33" viewBox="0 0 32 33" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.0001 30.8334H12.0001C4.76008 30.8334 1.66675 27.7401 1.66675 20.5001V12.5001C1.66675 5.26008 4.76008 2.16675 12.0001 2.16675H20.0001C27.2401 2.16675 30.3334 5.26008 30.3334 12.5001V20.5001C30.3334 27.7401 27.2401 30.8334 20.0001 30.8334ZM12.0001 4.16675C5.85341 4.16675 3.66675 6.35341 3.66675 12.5001V20.5001C3.66675 26.6467 5.85341 28.8334 12.0001 28.8334H20.0001C26.1467 28.8334 28.3334 26.6467 28.3334 20.5001V12.5001C28.3334 6.35341 26.1467 4.16675 20.0001 4.16675H12.0001Z" />
    <path d="M11.9999 14.8333C9.97325 14.8333 8.33325 13.1933 8.33325 11.1667C8.33325 9.14 9.97325 7.5 11.9999 7.5C14.0266 7.5 15.6666 9.14 15.6666 11.1667C15.6666 13.1933 14.0266 14.8333 11.9999 14.8333ZM11.9999 9.5C11.0799 9.5 10.3333 10.2467 10.3333 11.1667C10.3333 12.0867 11.0799 12.8333 11.9999 12.8333C12.9199 12.8333 13.6666 12.0867 13.6666 11.1667C13.6666 10.2467 12.9199 9.5 11.9999 9.5Z" />
    <path d="M3.55986 26.7668C3.23986 26.7668 2.91986 26.6068 2.73319 26.3268C2.42653 25.8735 2.54653 25.2468 3.01319 24.9402L9.58652 20.5268C11.0265 19.5535 13.0132 19.6735 14.3199 20.7802L14.7599 21.1668C15.4265 21.7402 16.5599 21.7402 17.2132 21.1668L22.7599 16.4068C24.1732 15.1935 26.3999 15.1935 27.8265 16.4068L29.9999 18.2735C30.4132 18.6335 30.4665 19.2602 30.1065 19.6868C29.7465 20.1002 29.1199 20.1535 28.6932 19.7935L26.5199 17.9268C25.8532 17.3535 24.7199 17.3535 24.0532 17.9268L18.5065 22.6868C17.0932 23.9002 14.8665 23.9002 13.4399 22.6868L12.9999 22.3002C12.3865 21.7802 11.3732 21.7268 10.6932 22.1935L4.11986 26.6068C3.94653 26.7135 3.74653 26.7668 3.55986 26.7668Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>