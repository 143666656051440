<template>
  <router-view v-slot="{ Component }">
    <transition :name="transitionName">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>

export default {
  name: 'app',

  data () {
    return {
      transitionName: 'fade'
    }
  },

  watch: {
    '$route' (to, from) {
      if (!from.path.includes("profil") &&
        (
          from.path.includes("edit") || 
          from.path.includes("modifTelecharge") || 
          from.path.includes("ajoutSection") || 
          from.path.includes("agencerSection") || 
          from.path.includes("modifInteraction")
        )) {
        this.transitionName = 'fade';
        // this.transitionName = 'slide-right';
      } else if((from.path === '/template') ||
          (from.path === '/leads') ||
          (from.path === '/parametre') ||
          (from.path === '/statistique') ||
          (from.path === '/equipe') ||
          (to.path === '/template') ||
          (to.path === '/leads') ||
          (to.path === '/parametre') ||
          (to.path === '/statistique') ||
          (to.path === '/equipe')
          ){
        this.transitionName = 'fade';
      } else {
        this.transitionName = 'fade';
        // this.transitionName = 'slide-left';
      }
    }
  }

}

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lexend');
@import url('https://fonts.googleapis.com/css2?family=Poppins');


:root {
  --size-0-5x : 0.5rem;
  --size-0-75x : 0.75rem;
  --size-1x: 1rem;
  --size-2x: 2rem;
  --size-3x: 3rem;
  --size-4x: 4rem;
  --size-5x: 5rem;
  --size-6x: 6rem;
  --size-7x: 7rem;
  --size-8x: 8rem;
  --size-9x: 9rem;
  --size-10x: 10rem;
  --size-14x: 14rem;
  --size-16x: 16rem;
  --size-20x: 20rem;
  --color-white: #ffffff;
  --color-white-secondary: #cdced1;
  --color-white-thirdary: #D9D9D9;
  --color-grey: #DEDEDE;
  --color-grey-secondary: #AEAEAE;
  --color-grey-thirdary: #F2F5FF;
  --color-grey-quaternary: #9191A4;
  --color-grey-quinary: #989898;
  --color-muted: #767676;
  --color-warning: #FF4949;
  --color-orange: #FF9000;
  --color-orange-secondary: #FF9100;
  --color-green: #09CF40;
  --color-purple: #7000FF;
  --color-blue: #A7C1FF;
  --color-blue-secondary: #92B2FF;
  --color-blue-thirdary: #10103A;
  --color-ghost: #c8ebfb;
  --color-black: #000000;
  --color-black-secondary: #333333;

  --box-shadow-default: 0px 1px 6px rgba(211, 221, 255, 0.48), 0px 1px 4px rgba(63, 63, 63, 0.07)!important;

  --letter-spacing-base: 0!important;

}


/* scrollbar */
::-webkit-scrollbar {
  width: var(--size-0-5x);
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

::-webkit-scrollbar-thumb {
  background: #0400ea; 
}

::-webkit-scrollbar-thumb:hover {
  background: #02007c; 
}

.vti__dropdown-list {
  z-index: 2!important;
}

.input-container {
	padding-bottom: var(--size-1x);
}
.left-inner-addon {
    position: relative;
}
.left-inner-addon input {
    padding-left: var(--size-3x) !important; 
    font-size: var(--size-1x) !important;
}
.left-inner-addon i {
    position: absolute;
    padding: calc(0.75 * var(--size-1x));
    pointer-events: none;
}

.form-control {
  border-radius: var(--size-0-5x)!important;
  height: var(--size-3x);
}

.logo {
  height: calc(1.2 * var(--size-1x));
  margin-top: calc(0.4 * var(--size-1x));
}

.icon {
  display: flex;
  width: var(--size-3x);
  margin-bottom: var(--size-0-75x);
}

.cursor-pointer {
   cursor: pointer;
}

.corner-radius {
  border-radius: var(--size-0-5x)!important;
}

.q-input{
  --field-background-color-base: var(--color-grey-thirdary)!important;
}

.q-button_theme_primary {
  --button-main-bg: black!important;
  --button-hover-active-bg: black!important;
}

.q-button_theme_secondary {
  --button-main-bg: #EEEFF2!important;
  --button-hover-active-bg: #EEEFF2!important;
  color: black;
  --color-primary: black;
  --color-primary-darker: grey;
  --button-hover-color: black;
}

.vti__input{
  width: unset!important;
}

.q-switch__wrapper{
  background-color: #0400BD!important;
}

.q-switch__handle{
  border: 2px solid #0400BD!important;
}

.q-input__inner{
  /*color: #B63FFF !important;*/
  background-color: unset !important;
  border-radius: 8px !important;
  border: 1px solid #EEEFF2 !important;
  box-shadow: unset !important;
}

.q-button {
  --box-shadow-default: 0px 1px 6px rgba(211, 221, 255, 0.48), 0px 1px 4px rgba(63, 63, 63, 0.07)!important;
  border-radius: var(--size-0-5x)!important;
  text-transform: initial!important;
}

.q-button+.q-button {
    margin-left: var(--size-1x)!important;
}

.q-message-box-container__container {
  width: calc(100vw - var(--size-2x))!important;
  border-radius: var(--size-0-5x)!important;
}

@media screen and (min-width: 750px) {
  .q-message-box-container__container {
    /*width: inherit!important;*/
    width: fit-content !important;
  }
}

.q-message-box-container__clickable-shadow {
  background: #000000BF;
}

.q-message-box-container__close {
  display: none!important;
}

.q-message-box-content__submessage {
  text-align: center;
}

.q-message-box-content__actions:first-child {
  background-color: white;
}

.q-message-box-content__title {
  margin-bottom: 0!important;
  margin-right: var(--size-2x)!important;
  margin-left: var(--size-2x)!important;
  text-align: center;
  font-size: calc((13/358) * (100vw - var(--size-2x)))!important;
}

.shadow-main {
  box-shadow: var(--box-shadow-default)!important;
}

.text-title{
  font-size: var(--size-1x)!important;
  font-weight: 600;
  color: #111827!important;
}

.text-subtitle{
  height: var(--size-1x)!important;
  margin: 0!important;
  margin-top: calc(0.9 * var(--size-1x))!important;

  font-size: calc(0.9 * var(--size-1x))!important;
}

.text-style-muted{
  height: var(--size-1x)!important;
  margin: 0!important;
  margin-top: var(--size-0-5x)!important;

  font-size: calc(0.7 * var(--size-1x))!important;
  overflow: hidden;
  text-overflow: ellipsis;

  color: #AEAEAE!important;
}

.text-style-muted svg {
  width: calc((8/358) * (100vw - var(--size-2x)));
}

.text-description{
  font-size: var(--size-1x)!important;
  font-family: "Lexend"!important;
  color: #6B7280!important;
}

.shadow-box {
  box-shadow: var(--box-shadow-default);
}

.cta-button {
  height: var(--size-3x)!important;
  font-size: calc(0.95 * var(--size-1x))!important;
  border-radius: calc(1.5 * var(--size-1x))!important;
  padding: 0 var(--size-1x) 0 var(--size-1x)!important;
  margin: 0;
  flex-grow: 1;
}

.rec-btn{
  width: var(--size-4x)!important;
  height: var(--size-4x)!important;
}

.rec-btn svg {
  width: calc((30/358) * (100vw - var(--size-2x)));
}

.link-white-part{
  /* width: calc((246/358) * (100vw - var(--size-2x)))!important; */
  height: var(--size-4x)!important;
  padding-left: var(--size-0-5x)!important;
  white-space: nowrap;
  overflow: hidden;
}

a {
  color: inherit!important; /* blue colors for links too */
  text-decoration: inherit!important; /* no underline */
  margin: 0!important;
  padding: 0!important;
}

.rounded-btn{
  border-radius: 10rem!important;
}

.border-radius-10{
  border-radius: 10px!important;
}

.margin-top-0-5x{
  margin-top: var(--size-0-5x);
}

.margin-top-1x{
  margin-top: var(--size-1x);
}

.margin-top-2x{
  margin-top: var(--size-2x);
}

.margin-top-3x{
  margin-top: var(--size-3x);
}

.margin-top-4x{
  margin-top: var(--size-4x);
}

.margin-top-7x{
  margin-top: var(--size-7x);
}

.margin-bottom-0-5x{
  margin-bottom: var(--size-0-5x);
}

.margin-bottom-1x{
  margin-bottom: var(--size-1x);
}

.margin-bottom-2x{
  margin-bottom: var(--size-2x);
}

.margin-bottom-3x{
  margin-bottom: var(--size-3x);
}

.margin-bottom-4x{
  margin-bottom: var(--size-4x);
}

.margin-left-1x{
  margin-left: var(--size-1x);
}

.margin-left-2x{
  margin-left: var(--size-2x);
}

.margin-left-3x{
  margin-left: var(--size-3x);
}

.margin-left-4x{
  margin-left: var(--size-4x);
}

.margin-right-1x{
  margin-right: var(--size-1x);
}

.margin-right-2x{
  margin-right: var(--size-2x);
}

.margin-right-3x{
  margin-right: var(--size-3x);
}

.margin-right-4x{
  margin-right: var(--size-4x);
}

.padding-top-0-5x{
  padding-top: var(--size-0-5x);
}

.padding-top-1x{
  padding-top: var(--size-1x);
}

.padding-top-2x{
  padding-top: var(--size-2x);
}

.padding-top-3x{
  padding-top: var(--size-3x);
}

.padding-top-4x{
  padding-top: var(--size-4x);
}

.padding-bottom-0-5x{
  padding-bottom: var(--size-0-5x);
}

.padding-bottom-1x{
  padding-bottom: var(--size-1x);
}

.padding-bottom-2x{
  padding-bottom: var(--size-2x);
}

.padding-bottom-3x{
  padding-bottom: var(--size-3x);
}

.padding-bottom-4x{
  padding-bottom: var(--size-4x);
}

.padding-bottom-5x{
  padding-bottom: var(--size-5x);
}

.padding-left-1x{
  padding-left: var(--size-1x);
}

.padding-left-2x{
  padding-left: var(--size-2x);
}

.padding-left-3x{
  padding-left: var(--size-3x);
}

.padding-left-4x{
  padding-left: var(--size-4x);
}

.padding-right-1x{
  padding-right: var(--size-1x);
}

.padding-right-2x{
  padding-right: var(--size-2x);
}

.padding-right-3x{
  padding-right: var(--size-3x);
}

.padding-right-4x{
  padding-right: var(--size-4x);
}

.padding-0-5x{
  padding: var(--size-0-5x);
}

.padding-1x{
  padding: var(--size-1x);
}

.padding-2x{
  padding: var(--size-2x);
}

.padding-3x{
  padding: var(--size-3x);
}

.padding-4x{
  padding: var(--size-4x);
}

*{
  font-weight: 500;
  font-family: "Lexend";
  font-style: normal;
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

#app {
  text-align: center;
  overflow: hidden;
}

.text-bold {
  font-weight: 600;
}

.q-message-box-container__shadow {
  background-color: #00000090!important;
}

.q-message-box-container__clickable-shadow {
  background: #00000090!important;
}

.card-body > h1 {
  line-height: 30px;
}

.btn{
  box-shadow: 0px 1px 6px rgba(211, 221, 255, 0.48), 0px 1px 4px rgba(63, 63, 63, 0.07);
  --bs-btn-border-radius: var(--size-0-5x)!important;
}

.text-color-blue{
  color: var(--color-blue-secondary);
}

.text-orange{
  color: var(--color-orange)!important;
}

.bg-orange{
  background: var(--color-orange)!important;
}

.bg-blue {
  background: #0400BD!important;
}

.bg-gray{
  background: #D1D5DB!important;
}

.bg-red {
  background: #EC0000!important;
}

.bg-green {
  background: #34A853!important;
}

#btn-suivante {
  background: var(--color-blue-secondary);
  font-weight: 500;
}

.q-form-item__error{
  margin-top: 2%;
  margin-bottom: 2%;
}

/* slide transition*/
.slide-left-enter-active, .slide-left-leave-active {
  transition: transform 1s;
}

.slide-left-enter, .slide-left-leave-to {
  transform: translateX(-100%);
}

.slide-left-enter-to, .slide-left-leave {
  transform: translateX(0);
}

.slide-right-enter-active, .slide-right-leave-active {
  transition: transform 1s;
}

.slide-right-enter, .slide-right-leave-to {
  transform: translateX(100%);
}

.slide-right-enter-to, .slide-right-leave {
  transform: translateX(0);
}

.slide-bottom-enter-active, .slide-bottom-leave-active {
  transition: transform 1s;
}

.slide-bottom-enter, .slide-bottom-leave-to {
  transform: translateY(100%);
}

.slide-bottom-enter-to, .slide-bottom-leave {
  transform: translateY(0);
}
</style>
