<template>
  <div class="row align-items-center h-100">
    <!-- column left -->
    <div class="col-6">
      <div class="card-body form-left-side text-start">

        <div class="text-title-login">Welcome back !</div>
        <p class="text-intro">Please log in to access to your own<br /> dashboard.</p>

        <form>
          <!-- Input Mail -->
          <div class="left-inner-addon">
            <q-form-item label="" class="text-start">
              <i class="icon z-index-100">
                <SVGUser :fill-color="'#9CA3AF'"/>
              </i>
              <q-input v-model="login" type="text" class="form-control" placeholder="Enter your mail"/>
            </q-form-item>
          </div>

          <!-- Input Password -->
          <div class="left-inner-addon">
            <q-form-item label="" class="text-start">
              <i class="icon z-index-100">
                <SVGKey :fill-color="'#9CA3AF'"/>
              </i>
              <q-input v-model="password" type="password" class="form-control" placeholder="Enter your password" password-switch/>
            </q-form-item>
          </div>

        </form>

        <div class="d-flex align-items-center">

          <div class="d-flex justify-content-between align-items-center">
            <input class="form-check-input checkbox-border m-0" type="checkbox" value="">
            <label class="form-check-label text-gray">&nbsp;Remember me</label>
          </div>

          <div class="col text-end">
            <strong class="text-blue text-bold"> Forgot password ?</strong>
          </div>

        </div>

        <q-button class="btn-login w-100 bg-blue text-white" v-on:click="signIn">
          <strong>Log in</strong>
        </q-button>

        <p class="text-center text-gray">Don’t have account? <strong class="text-blue text-bold">Create an account</strong></p>

      </div>
    </div>

    <!-- column right -->
    <div class="col-6 h-100">
      <div class="bd-placeholder-img h-100" v-bind:style="{ 'background-image': `url(${ image })` }"></div>
      <!--<img :src="image" alt="login"/>-->
    </div>
  </div>
</template>

<script>
import SVGUser from "../../../assets/svgs/app/svg.user"
import SVGKey from "../../../assets/svgs/app/svg.key"
import axios from "axios"

export default {

  name: "login.pc",

  components: {
    SVGUser,
    SVGKey,
  },

  data() {
    return {
      image: require("../../../assets/images/login.png"),
      login: null,
      password: null,
    }
  },

  methods : {
    /**
     * SIgn in
     * @return {Promise<void>}
     */
    async signIn() {
      await axios
          .post(this.$apiUrl + '/card/login/', {login: this.login, password: this.password})
          .then((response) => {
            this.$store.commit("saveToken", response.data.token)
            this.$toast.show('Succès!', {type: 'success'});
            this.$router.push("/dashboard");
          })

          .catch((error) => {
            console.log("error", error)
            this.$toast.show('Identifiant ou mot de passe incorrect!', {type: 'error'});
          })
    },
  }


}
</script>

<style scoped>
@media screen and (min-width: 750px) {

  .text-blue{
    color: #0400BD;
    font-family: "Poppins";
    font-weight: 600;
    line-height: calc((24 / 1440) * (100vw - var(--size-2x)));
    font-size: calc((16 / 1440) * (100vw - var(--size-2x)));
  }

  .text-title-login{
    color: #111827;
    font-family: "Poppins";
    font-size: calc((40 / 1440) * (100vw - var(--size-2x)));
    font-weight: 700;
    line-height: calc((24 / 1440) * (100vw - var(--size-2x)));
    margin-bottom: calc((24 / 1440) * (100vw - var(--size-2x)));
  }

  .text-gray{
    color: #9CA3AF;
    font-family: "Poppins";
    font-weight: 400;
    line-height: calc((24 / 1440) * (100vw - var(--size-2x)));
    font-size: calc((16 / 1440) * (100vw - var(--size-2x)));
  }

  .text-intro{
    color: #9CA3AF;
    font-family: "Poppins";
    font-weight: 400;
    line-height: calc((24 / 1440) * (100vw - var(--size-2x)));
    font-size: calc((20 / 1440) * (100vw - var(--size-2x)));
    margin-bottom: calc((35 / 1440) * (100vw - var(--size-2x)));
  }

  .form-left-side{
    padding: calc((122 / 1728) * (100vw - var(--size-2x)));
  }

  .z-index-100{
    z-index: 100;
  }

  .q-input:nth-child(2){
    width: 100%!important;
  }

  .form-check-input:checked{
    background-color: #0400BD !important;
    border: 0;
  }

  .text-blue{
    color: #0400BD;
    font-family: "Poppins";
    line-height: calc((24 / 1440) * (100vw - var(--size-2x)));
  }

  .btn-login{
    font-family: "Poppins";
    margin-top: calc((25 / 1440) * (100vw - var(--size-2x)));
    margin-bottom: calc((20 / 1440) * (100vw - var(--size-2x)));
    border-radius: 10px!important;
    background: linear-gradient(339deg, #0400BD 0%, #7000FF 100%) !important;
    height: var(--size-3x);
    font-weight: 700;
    line-height: calc((24 / 1440) * (100vw - var(--size-2x)));
    font-size: calc((20 / 1440) * (100vw - var(--size-2x)));
  }

  .checkbox-border{
    border: 1.5px solid #0400BD!important;
  }

  .bd-placeholder-img{
    background-size: cover;
    background-position: center center;
  }
}
</style>