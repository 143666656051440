<template>
  <div class="col">
    <div class="card border-radius-17 shadow">

      <!--<div class="bd-placeholder-img-2"></div>-->
      <!--<div class="bd-placeholder-img" v-bind:style="{ 'background-image': `url(${ this.$store.state.user.backgroundImage })` }"></div>-->

      <slot name="backgroundImage"></slot>

      <button-base :textColor="'text-white'" :bgColor="'bg-gray'" class="border-radius-28 btn-small btn-edit"></button-base>

      <div class="card-body bg-content border-bottom-radius">
        <p class="card-text text-card">{{ this.$store.state.user.prenom + " " + this.$store.state.user.nom }}</p>
        <p class="card-text text-muted">{{ this.$store.state.user.poste + " at " + this.$store.state.user.entreprise }}</p>
        <div class="border-radius-17 px-3 bg-gray-deg container-rect"></div>
      </div>

      <!--<div class="avatar-container" v-bind:style="{ 'background-image': `url(${ this.$store.state.user.avatarImage })` }"></div>-->
      <slot name="avatarImage"></slot>

      <div class="position-absolute padding-left-1x padding-top-1x">
        <SVGProfylead />
      </div>

    </div>
  </div>
</template>

<script>
import ButtonBase from "../Components/Buttons/button.base";
import SVGProfylead from "../../../assets/svgs/app/svg.profylead";

export default {

  props: [],

  components: {
    ButtonBase,
    SVGProfylead,
  },

  data() {
    return {

    }
  },

  methods : {

  },


}
</script>

<style scoped>
.bg-content{
  background: #FFFFFF;
}

.border-radius-17{
  border-radius: 17px;
}

.border-bottom-radius{
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.bg-gray-deg{
  background: linear-gradient(254.53deg, #E9E9E9 4.88%, #CBCBCB 85.64%);
}

.text-card{
  margin-top: calc((32/390) * (100vw - var(--size-2x)) + var(--size-1x));
  margin-bottom: var(--size-0-5x);
  font-size: calc((22/390) * (100vw - var(--size-2x)));
  line-height: calc((26/390) * (100vw - var(--size-2x)));
  font-weight: 600;
}

.text-muted{
  font-size: calc((11/390) * (100vw - var(--size-2x)));
  line-height: calc((18/390) * (100vw - var(--size-2x)));
  font-weight: 500;
}

.btn-small{
  width: calc((44/358) * (100vw - var(--size-2x)));
  height: calc((16/358) * (100vh - var(--size-2x)));
  padding: 0 !important;
}

.btn-edit{
  position: absolute;
  right: var(--size-1x);
  top: var(--size-1x);
  padding: 0 !important;
  width: calc((30/390) * (100vh - var(--size-2x)));
  height: calc((12/390) * (100vh - var(--size-2x)))!important;
}

.bg-gray{
  background: linear-gradient(254.53deg, #E9E9E9 4.88%, #CBCBCB 85.64%);
}

.shadow{
  border: 1px solid #EEEFF2!important;
  border-radius: 18px;
}

.container-rect{
  height: calc((67/390) * (100vh - var(--size-2x)));
  background: linear-gradient(254.53deg, #E9E9E9 4.88%, #CBCBCB 85.64%);
  border-radius: 6px;
}

.border-radius-28{
  border-radius: 28px !important;
}
</style>