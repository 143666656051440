<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 22.75H5C2.93 22.75 1.25 21.07 1.25 19V2C1.25 1.59 1.59 1.25 2 1.25C2.41 1.25 2.75 1.59 2.75 2V19C2.75 20.24 3.76 21.25 5 21.25H22C22.41 21.25 22.75 21.59 22.75 22C22.75 22.41 22.41 22.75 22 22.75Z" fill="#292D32"/>
    <path d="M4.99982 17.7501C4.82982 17.7501 4.64982 17.6901 4.50982 17.5701C4.19982 17.3001 4.15982 16.8301 4.42982 16.5101L9.01982 11.1501C9.51982 10.5701 10.2398 10.2201 10.9998 10.1901C11.7598 10.1701 12.5098 10.4501 13.0498 10.9901L13.9998 11.9401C14.2498 12.1901 14.5798 12.3101 14.9298 12.3101C15.2798 12.3001 15.5998 12.1401 15.8298 11.8701L20.4198 6.51008C20.6898 6.20008 21.1598 6.16006 21.4798 6.43006C21.7898 6.70006 21.8298 7.17006 21.5598 7.49006L16.9698 12.8501C16.4698 13.4301 15.7498 13.7801 14.9898 13.8101C14.2298 13.8301 13.4798 13.5501 12.9398 13.0101L11.9998 12.0601C11.7498 11.8101 11.4198 11.6801 11.0698 11.6901C10.7198 11.7001 10.3998 11.8601 10.1698 12.1301L5.57982 17.4901C5.41982 17.6601 5.20982 17.7501 4.99982 17.7501Z" fill="#292D32"/>
  </svg>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>