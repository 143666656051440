<template>
  <svg width="21" height="21" viewBox="0 0 21 21" fill="currentColor" class="me-3" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5471 0.34375C12.4602 0.343742 13.9598 0.343735 15.1495 0.472634C16.3636 0.604173 17.3473 0.877421 18.1873 1.48766C18.6957 1.85709 19.1429 2.30426 19.5123 2.81275C20.1226 3.65267 20.3958 4.63639 20.5274 5.8505C20.6563 7.0402 20.6563 8.53983 20.6562 10.4528V10.5472C20.6563 12.4602 20.6563 13.9598 20.5274 15.1495C20.3958 16.3636 20.1226 17.3473 19.5123 18.1873C19.1429 18.6957 18.6957 19.1429 18.1873 19.5123C17.3473 20.1226 16.3636 20.3958 15.1495 20.5274C13.9598 20.6563 12.4602 20.6563 10.5472 20.6562H10.4528C8.53975 20.6563 7.0402 20.6563 5.8505 20.5274C4.63639 20.3958 3.65267 20.1226 2.81275 19.5123C2.30426 19.1429 1.85709 18.6957 1.48766 18.1873C0.877421 17.3473 0.604173 16.3636 0.472634 15.1495C0.343735 13.9598 0.343742 12.4602 0.34375 10.5471V10.4529C0.343742 8.53981 0.343735 7.04022 0.472634 5.8505C0.604173 4.63639 0.877421 3.65267 1.48766 2.81275C1.85709 2.30426 2.30426 1.85709 2.81275 1.48766C3.65267 0.877421 4.63639 0.604173 5.8505 0.472634C7.04022 0.343735 8.5398 0.343742 10.4529 0.34375H10.5471ZM11.2812 5.29167C11.2812 4.86019 10.9315 4.51042 10.5 4.51042C10.0685 4.51042 9.71875 4.86019 9.71875 5.29167V15.7083C9.71875 16.1398 10.0685 16.4896 10.5 16.4896C10.9315 16.4896 11.2812 16.1398 11.2812 15.7083V5.29167ZM15.4479 9.45833C15.4479 9.02686 15.0981 8.67708 14.6667 8.67708C14.2352 8.67708 13.8854 9.02686 13.8854 9.45833V15.7083C13.8854 16.1398 14.2352 16.4896 14.6667 16.4896C15.0981 16.4896 15.4479 16.1398 15.4479 15.7083V9.45833ZM7.11458 11.5417C7.11458 11.1102 6.76481 10.7604 6.33333 10.7604C5.90186 10.7604 5.55208 11.1102 5.55208 11.5417V15.7083C5.55208 16.1398 5.90186 16.4896 6.33333 16.4896C6.76481 16.4896 7.11458 16.1398 7.11458 15.7083V11.5417Z" :fill="fillStatistiques"/>
  </svg>
</template>

<script>
export default {
  name: "svg.statistiques",

  props: ['fillStatistiques'],
}
</script>

<style scoped>

</style>