<template>
  <div class="col-interg-container text-start">
    <div class="padding-left-2x padding-top-2x padding-right-2x padding-bottom-2x margin-bottom-2x bg-white border-radius-10px">
      <div class="title-card">Integration CRM</div>

      <crm-item :is-show-switch="true" :type="crmSelected.type" :label="crmSelected.label"></crm-item>

      <button-base v-on:click="showCrmPopup" :textColor="'text-white'" :bgColor="'bg-blue'" class="margin-bottom-0-5x" id="btn-add-system">
        <template v-slot:label>
          Add a new integration system
        </template>
      </button-base>

    </div>
  </div>
</template>

<script>
import ButtonBase from "../LayoutComponent/layout.button";
import CRMPopup from "../LayoutComponent/Popups/CRMPopup";
import CrmItem from "./layout.crm.item";
import { useMessageBox } from "@qvant/qui-max";
import { ref } from "vue";

export default {

  components: {
    ButtonBase,
    CrmItem,
  },

  setup() {
    const crm = ref(false);
    const loading = ref(false);

    const changeSwitch = () => {
      loading.value = true;

      // async request
      setTimeout(() => {
        loading.value = false;
      }, 0);
    };

    return {
      crm: crm,
      loading: loading,
      changeSwitch,
    };
  },

  data() {
    return {
      crmSelected: {
        type: "Salesforce",
        label: "Salesforce",
        isActive: true,
      },
    }
  },

  methods : {

    async showCrmPopup() {
      try {
        let messageBox = useMessageBox();
        const result = await messageBox(
            {
              component: CRMPopup,
              listeners: {
                linkAccount: (args) => {
                  console.log(args)
                  this.crmSelected = args
                },
              }
            },
        );
        console.log('resolve', result);
      } catch (result) {
        console.log('reject', result);
      }
    }

  }

}

</script>

<style scoped>
*{
  font-family: Poppins;
}

.title-card{
  font-size: calc((33 / 1820) * (100vw - var(--size-2x)));
  line-height: calc((33 / 1820) * (100vw - var(--size-2x)));
  font-weight: 600;
}

.col-interg-container{
  width: calc(50% - var(--size-1x) * 0.5);
  height: fit-content;
}

.border-radius-10px{
  border-radius: 10px;
}

#btn-add-system{
  width: 100%!important;
  margin: 0!important;
  border-radius: 28px!important;
}
</style>