<template>
  <svg viewBox="0 0 24 24" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <rect width="23.9306" height="24" rx="6" />
    <path d="M16.6229 15.4688L17.1534 12H13.8348V9.75C13.8348 8.80078 14.2976 7.875 15.7839 7.875H17.2936V4.92188C17.2936 4.92188 15.9241 4.6875 14.6154 4.6875C11.8811 4.6875 10.0957 6.34922 10.0957 9.35625V12H7.05762V15.4688H10.0957V23.8547C10.7056 23.9508 11.3296 24 11.9653 24C12.6009 24 13.2249 23.9508 13.8348 23.8547V15.4688H16.6229Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>