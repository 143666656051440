<template>
  <base-popup>

    <template v-slot:title>
      <div id="popup-title">Select a CRM integration to add</div>
    </template>

    <template v-slot:body>
      <template v-for="(crm, index) in crms" :key="index">
        <crm-item
            :is-show-switch="false"
            :type="crm.type"
            :label="crm.label"
            :is-active="crm.isActive"
            v-on:click="selectToActive(crm.type)"
        ></crm-item>
      </template>
    </template>

    <template v-slot:button-bar>
      <button-base
          v-on:click="handleClick($emit, this.crms)"
          :textColor="'text-white'"
          :bgColor="'bg-blue'"
          class="border-radius-28 margin-bottom-0-5x"
          id="btn-add-card"
      >
        <template v-slot:label>
          Link your account
        </template>
      </button-base>
    </template>

  </base-popup>


</template>

<script>
import BasePopup from "./BasePopup"
import { inject } from 'vue';
import { QMessageBoxAction } from '@qvant/qui-max';
import ButtonBase from "../../LayoutComponent/layout.button";
import CrmItem from "../../Parametre/layout.crm.item";

let qMessageBoxContainer = undefined;

export default {

  props: [],

  components: {
    BasePopup,
    ButtonBase,
    CrmItem,
  },

  data() {
    return {
      itemActive: 0,

      crms: [
        {
          type: "Salesforce",
          label: "Salesforce",
          isActive: true,
        },
        {
          type: "Hubspot",
          label: "Hubspot",
          isActive: false,
        },
        {
          type: "Google",
          label: "Google Contacts",
          isActive: false,
        },
        {
          type: "Microsoft",
          label: "Microsoft Contacts",
          isActive: false,
        }
      ]
    }
  },

  setup(){
    qMessageBoxContainer = inject(
        'qMessageBoxContainer'
    )

    let handleClick = (emit, crms) => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.confirm
      });
      let crmSelected = crms.find((crm) => crm.isActive == true )
      emit("linkAccount", crmSelected);
    }

    return {
      qMessageBoxContainer,
      handleClick
    }
  },

  methods : {

    cancel: () => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.cancel
      });
    },

    /**
     * Add effect of crm active
     * @param type
     */
    selectToActive(type){
      this.crms.forEach((crm) => {
        crm.isActive = false
      })
      switch (type) {
        case 'Salesforce':
        {
          let SaleToUpdate = this.crms.find((crm) => crm.type == type )
          SaleToUpdate.isActive = !SaleToUpdate.isActive;
          break;
        }
        case 'Hubspot':
        {
          let HubToUpdate = this.crms.find((crm) => crm.type == type)
          HubToUpdate.isActive = !HubToUpdate.isActive;
          break;
        }
        case 'Google':
        {
          let GoogleToUpdate = this.crms.find((crm) => crm.type == type)
          GoogleToUpdate.isActive = !GoogleToUpdate.isActive;
          break;
        }
        case 'Microsoft':
        {
          let MicroToUpdate = this.crms.find((crm) => crm.type == type)
          MicroToUpdate.isActive = !MicroToUpdate.isActive;
          break;
        }
      }
    }

  },

  computed: {

  },

  mounted() {

  }
}
</script>

<style scoped>
#btn-add-card{
  width: 100%!important;
  margin-left: 0!important;
  margin-right: 0!important;
}

.border-radius-28{
  border-radius: 28px!important;
}
</style>