import VuexPersistence from 'vuex-persist'
import Vuex from 'vuex'

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
})

const store = new Vuex.Store({
    mutations: {
        saveCropImg(state, img) {
            state.user.avatarImage = img
        },

        saveBackgroundImg(state, img) {
            state.user.backgroundImage = img
        },

        saveAvatarContactInfo(state, img) {
            state.user.avatarImageContactInfo = img
        },

        saveTheme(state, theme) {
            state.theme = theme
        },

        saveLogo(state, logo) {
            state.user.logoEntreprise = logo
        },

        saveId(state, id) {
            state.user.id = id
        },

        saveIdComponent(state, id) {
            state.user.id_component_profile = id
        },

        saveIdProfiles(state, id_pageprofile) {
            state.user.id_pageprofile = id_pageprofile
        },

        savePrenom(state, prenom) {
            state.user.prenom = prenom
        },

        saveNom(state, nom) {
            state.user.nom = nom
        },

        saveTel(state, tel) {
            state.user.tel = tel
        },

        saveEmail(state, email) {
            state.user.email = email
        },

        savePoste(state, poste) {
            state.user.poste = poste
        },

        savePassword(state, password) {
            state.user.password = password
        },

        saveEntreprise(state, entreprise) {
            state.user.entreprise = entreprise
        },

        saveTitre(state, titre) {
            state.user.titre = titre
        },

        saveToken(state, token) {
            state.user.token = token
        },

        saveMember(state, member) {
            state.member = member
        },

        savePhoto(state, photo) {
            state.photo = photo
        },

        saveLead(state, lead) {
            state.lead = lead
        },

    },

    plugins: [vuexLocal.plugin],

    state: {
        user: {
            id: null,
            nom: null,
            tel: null,
            email: null,
            poste: null,
            titre: null,
            prenom: null,
            password: null,
            entreprise: null,
            id_pageprofile: null,
            id_component_profile: null,
            avatarImageContactInfo : null,
            avatarImage: require("../assets/images/profile-image.png"),
            backgroundImage: require("../assets/images/background-gray.png"),
            logoEntreprise: require("../assets/images/logo_reseaux_sociaux.png"),
            token: null,
            pdf: null, // template, après l'api est prêt, faut le supprime
            name: null, //template, nom du fichier pdf
        },

        member: {
            id: null,
            prenom: null,
            nom: null,
            poste: null,
            avatarImage: require("../assets/images/photo-de-profil-avatar.png"),
        },

        lead: {
            id: null,
            prenom: null,
            nom: null,
            entreprise: null,
            tel: null,
            email: null,
            image: null,
        },

        photo: {
            id: null,
            img: null,
            link: null,
        },

        theme: { // theme par default ou theme courant
            colorBouton: "rgb(255, 255, 255)",
            colorText: "#002458",
            colorTheme: "#002458",
        },

        themes: [ // tout les choix de theme
            {
                colorBouton: "rgb(255, 255, 255)", // themeYellow
                colorText: "#000000",
                colorTheme: "#000000",
            },
            {
                colorBouton: "rgb(255, 255, 255)", // themeYellow
                colorText: "#818181",
                colorTheme: "#818181",
            },
            {
                colorBouton: "rgb(255, 255, 255)", // themeYellow
                colorText: "#002458",
                colorTheme: "#002458",
            },
            {
                colorBouton: "rgb(255, 255, 255)", // themeYellow
                colorText: "#47B2FF",
                colorTheme: "#47B2FF",
            },
            {
                colorBouton: "rgb(255, 255, 255)", // themeYellow
                colorText: "#00F4BA",
                colorTheme: "#00F4BA",
            },
            {
                colorBouton: "rgb(255, 255, 255)", // themeYellow
                colorText: "#00DD16",
                colorTheme: "#00DD16",
            },
            {
                colorBouton: "rgb(255, 255, 255)", // themeYellow
                colorText: "#EED600",
                colorTheme: "#EED600",
            },
            {
                colorBouton: "rgb(255, 255, 255)", // themeRed
                colorText: "#F30000",
                colorTheme: "#F30000",
            },
            {
                colorBouton: "rgb(255, 255, 255)", // themeYellow
                colorText: "#9747FF",
                colorTheme: "#9747FF",
            },
            {
                colorBouton: "rgb(255, 255, 255)", // themeYellow
                colorText: "#E547FF",
                colorTheme: "#E547FF",
            },
        ],

    },

})

export default store