<template>
  <q-button
      theme="secondary"
      class="btn font-size margin-left-1x margin-right-1x"
      :class="!isDisabled ? this.textColor + ' ' + this.bgColor + ' ' + this.boxShadow : this.textColor + ' bg-gray ' + this.boxShadow"
      :disabled="isDisabled"
      :style="bgRGB ? 'background-color:'+bgRGB : ''"
  >
    <slot name="label"></slot>
  </q-button>
</template>

<script>
import { QButton } from '@qvant/qui-max';

export default {
  
  props: [ 'textColor', 'bgColor', 'bgRGB', 'boxShadow', 'isDisabled' ],

  components: {
    QButton
  },

}
</script>

<style scoped>
.font-size{
  font-size: calc((20 / 1728) * (100vw - var(--size-2x)));
  line-height: 150%;
}

.bg-gray{
  background: #D1D5DB;
}

.btn{
  width: calc((354 / 1728) * (100vw - var(--size-2x)));
}
</style>