<template>
  <svg width="20" height="20" viewBox="0 0 20 20" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0003 18.9583C5.05866 18.9583 1.04199 14.9417 1.04199 9.99999C1.04199 5.05832 5.05866 1.04166 10.0003 1.04166C14.942 1.04166 18.9587 5.05832 18.9587 9.99999C18.9587 14.9417 14.942 18.9583 10.0003 18.9583ZM10.0003 2.29166C5.75033 2.29166 2.29199 5.74999 2.29199 9.99999C2.29199 14.25 5.75033 17.7083 10.0003 17.7083C14.2503 17.7083 17.7087 14.25 17.7087 9.99999C17.7087 5.74999 14.2503 2.29166 10.0003 2.29166Z" />
    <path d="M8.95006 13.5667C8.79173 13.5667 8.6334 13.5083 8.5084 13.3833C8.26673 13.1417 8.26673 12.7417 8.5084 12.5L11.0084 10L8.5084 7.50001C8.26673 7.25834 8.26673 6.85834 8.5084 6.61667C8.75007 6.37501 9.15007 6.37501 9.39173 6.61667L12.3334 9.55834C12.5751 9.80001 12.5751 10.2 12.3334 10.4417L9.39173 13.3833C9.26673 13.5083 9.1084 13.5667 8.95006 13.5667Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>