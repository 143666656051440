<template>
  <div class="bg-body border-radius-10">

    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">

      <div class="font-size text-start">
        <slot name="title"></slot>
      </div>

    </div>

    <slot name="selector"></slot>

    <slot name="figurebar"></slot>

    <slot name="chart"></slot>

  </div>

</template>

<script>
export default {

}
</script>

<style scoped>
*{
  font-family: Poppins;
}

.font-size {
  font-size: calc((33 / 1820) * (100vw - var(--size-2x)));
  line-height: calc((33 / 1820) * (100vw - var(--size-2x)));
  font-weight: 600;
}

.border-radius-10{
  border-radius: 10px;
}
</style>