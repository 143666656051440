<template>
  <div class="d-flex justify-content-between" v-bind:class="showEdit==false?'container-buttonbar-wihtout-edit':'container-buttonbar'">
    <div v-on:click="$emit('moveUp')">
      <svg viewBox="0 0 32 32" :fill="fillColorUp" xmlns="http://www.w3.org/2000/svg" class="btn-up">
        <path d="M20.0001 30.3332H12.0001C4.76008 30.3332 1.66675 27.2398 1.66675 19.9998V11.9998C1.66675 4.75984 4.76008 1.6665 12.0001 1.6665H20.0001C27.2401 1.6665 30.3334 4.75984 30.3334 11.9998V19.9998C30.3334 27.2398 27.2401 30.3332 20.0001 30.3332ZM12.0001 3.6665C5.85341 3.6665 3.66675 5.85317 3.66675 11.9998V19.9998C3.66675 26.1465 5.85341 28.3332 12.0001 28.3332H20.0001C26.1467 28.3332 28.3334 26.1465 28.3334 19.9998V11.9998C28.3334 5.85317 26.1467 3.6665 20.0001 3.6665H12.0001Z"/> <!--fill="#0400BD"-->
        <path d="M20.7066 18.9465C20.4533 18.9465 20.2 18.8532 20 18.6532L16 14.6532L12 18.6532C11.6133 19.0398 10.9733 19.0398 10.5866 18.6532C10.2 18.2665 10.2 17.6265 10.5866 17.2398L15.2933 12.5332C15.68 12.1465 16.32 12.1465 16.7066 12.5332L21.4133 17.2398C21.8 17.6265 21.8 18.2665 21.4133 18.6532C21.2133 18.8532 20.96 18.9465 20.7066 18.9465Z"/>
      </svg>
    </div>

    <div v-on:click="$emit('moveDown')">
      <svg viewBox="0 0 32 32" :fill="fillColorDown" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.0001 30.3332H12.0001C4.76008 30.3332 1.66675 27.2398 1.66675 19.9998V11.9998C1.66675 4.75984 4.76008 1.6665 12.0001 1.6665H20.0001C27.2401 1.6665 30.3334 4.75984 30.3334 11.9998V19.9998C30.3334 27.2398 27.2401 30.3332 20.0001 30.3332ZM12.0001 3.6665C5.85341 3.6665 3.66675 5.85317 3.66675 11.9998V19.9998C3.66675 26.1465 5.85341 28.3332 12.0001 28.3332H20.0001C26.1467 28.3332 28.3334 26.1465 28.3334 19.9998V11.9998C28.3334 5.85317 26.1467 3.6665 20.0001 3.6665H12.0001Z"/> <!--fill="#0400BD"-->
        <path d="M16 19.8801C15.7466 19.8801 15.4933 19.7868 15.2933 19.5868L10.5866 14.8801C10.2 14.4934 10.2 13.8534 10.5866 13.4668C10.9733 13.0801 11.6133 13.0801 12 13.4668L16 17.4668L20 13.4668C20.3866 13.0801 21.0266 13.0801 21.4133 13.4668C21.8 13.8534 21.8 14.4934 21.4133 14.8801L16.7066 19.5868C16.5066 19.7868 16.2533 19.8801 16 19.8801Z"/>
      </svg>
    </div>

    <div v-on:click="$emit('showEditPopup')" :style="displayEdit">
      <svg viewBox="0 0 32 32" :fill="fillColorEdit" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.0533 17.3599C19.7999 17.3599 19.5333 17.2532 19.3466 17.0665L14.9599 12.6799C14.6266 12.3465 14.5733 11.8399 14.8266 11.4399L16.1999 9.26653C16.6399 8.55986 17.3333 8.11986 18.1199 8.03986C18.9866 7.94653 19.8933 8.2932 20.5866 8.98653L23.0533 11.4532C23.7199 12.1199 24.0533 13.0265 23.9599 13.9199C23.8666 14.7199 23.4399 15.4132 22.7733 15.8399L20.5999 17.2132C20.4133 17.3199 20.2266 17.3599 20.0533 17.3599ZM16.9333 11.8265L20.1999 15.0932L21.6933 14.1465C21.8799 14.0265 21.9466 13.8399 21.9599 13.6932C21.9866 13.4132 21.8666 13.0932 21.6266 12.8532L19.1599 10.3999C18.8933 10.1332 18.5866 9.99986 18.3333 10.0265C18.1466 10.0532 17.9999 10.1465 17.8799 10.3332L16.9333 11.8265Z"/>
        <path d="M11.0534 23.9734C10.2267 23.9734 9.45335 23.6667 8.90669 23.12C8.26669 22.48 7.96002 21.5467 8.08002 20.56L8.88002 13.8C9.14669 11.5467 10.24 10.64 12.5334 10.7867L15.72 10.9734C16.2667 11.0134 16.6934 11.48 16.6534 12.0267C16.6134 12.5734 16.1467 13 15.6 12.96L12.4 12.7867C11.1067 12.7067 10.9867 12.9067 10.8534 14.04L10.0534 20.8C10.0134 21.1734 10.1067 21.5067 10.3067 21.7067C10.52 21.92 10.84 22.0134 11.2134 21.96L17.9734 21.16C19.2 21.0134 19.3467 20.8267 19.24 19.6534L19.04 16.4267C19.0134 15.88 19.4267 15.4 19.9734 15.3734C20.52 15.3334 21 15.76 21.0267 16.3067L21.2134 19.4934C21.44 21.7334 20.5067 22.88 18.2 23.1467L11.44 23.9467C11.32 23.9734 11.1867 23.9734 11.0534 23.9734Z"/>
        <path d="M9.98666 23.0268C9.73332 23.0268 9.47999 22.9335 9.27999 22.7335C8.89332 22.3468 8.89332 21.7068 9.27999 21.3201L11.7467 18.8535C12.1333 18.4668 12.7733 18.4668 13.16 18.8535C13.5467 19.2401 13.5467 19.8801 13.16 20.2668L10.6933 22.7335C10.5067 22.9335 10.2533 23.0268 9.98666 23.0268Z"/>
        <path d="M20.0001 30.3332H12.0001C4.76008 30.3332 1.66675 27.2398 1.66675 19.9998V11.9998C1.66675 4.75984 4.76008 1.6665 12.0001 1.6665H20.0001C27.2401 1.6665 30.3334 4.75984 30.3334 11.9998V19.9998C30.3334 27.2398 27.2401 30.3332 20.0001 30.3332ZM12.0001 3.6665C5.85341 3.6665 3.66675 5.85317 3.66675 11.9998V19.9998C3.66675 26.1465 5.85341 28.3332 12.0001 28.3332H20.0001C26.1467 28.3332 28.3334 26.1465 28.3334 19.9998V11.9998C28.3334 5.85317 26.1467 3.6665 20.0001 3.6665H12.0001Z"/>
      </svg>
    </div>
  </div>
</template>

<script>

export default {

  props: ["fillColorUp", "fillColorDown", "fillColorEdit", 'showEdit'],

  components: {},

  data() {
    return {
      displayEdit: 'display: block;'
    }
  },

  methods: {
    checkEdit(){
      if( this.showEdit == false ){
        this.displayEdit = 'display: none;'
      }
    }
  },

  mounted() {
   this.checkEdit();
  }

}
</script>

<style scoped>
svg {
  width: calc(2 * var(--size-1x));
}
</style>