<template>
  <div>
    <div class="margin-bottom-1x margin-left-1x margin-right-1x">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center ">
        <div v-bind:style="textColor" class="col text-start p-0 text-black text-title">{{ title }}</div>
        <div :style="onDisplay">
          <router-link v-bind:to="'/site/edit/' + this.link + '/' + this.id" v-if="!byManager">

<button-base
  :textColor="'text-white'"
  :bgColor="'bg-blue'"
  class="edit-button "
>
  <template v-slot:label>
    <SVGFatrows :fillColor="'white'"/>
    Edit
  </template>
</button-base>

</router-link>

<button-base
v-else
v-on:click="$emit('editClicked')"
:textColor="'text-white'"
:bgColor="'bg-blue'"
class="edit-button "
>
<template v-slot:label>
  <SVGFatrows :fillColor="'white'"/>
  Edit
</template>
</button-base>
        </div>
      </div>
    </div>
    
    <div v-for="site, index in this.datas"  :key="index"
         class="shadow-main margin-top-0-7x margin-left-1x margin-right-1x rounded-3"
         :class="index+1===this.datas.length ? 'margin-bottom-2x' : ''"
        >

      <div v-on:click="registerClick(site)" class="row justify-content-center align-items-center m-0 p-0">

        <a :href="site.link" class="text-decoration-none" target="_blank" rel="noreferrer">

          <div class="d-flex justify-content-between rounded-3 m-0 p-0">

            <div v-if="site.thumbnail" class="d-flex rounded-3 overflow-hidden text-center text-white justify-content-center align-items-center p-0 rec-btn">
              <img class="h-100 rounded-3" v-bind:src="site.thumbnail" alt="image d'un site"/>
            </div>

            <div class="text-start text-dark link-white-part" v-bind:style="site.thumbnail ? '' : 'padding-left: var(--size-1x)!important'">
              <p class="text-subtitle">{{ site.subtitle }}</p>
              <p class="text-style-muted">
                {{ site.link }}
              </p>
            </div>
            <div class="d-flex align-items-center padding-right-1x">
              <svg width="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2702 0.321289L14.6792 3.73036L11.2701 7.1383L10.3767 6.2446L12.2609 4.36106H8.35612C8.00003 4.36106 7.65852 4.50251 7.40673 4.7543C7.15494 5.0061 7.01348 5.3476 7.01348 5.70369V8.17203H5.74982V5.70369C5.74982 5.01246 6.02441 4.34954 6.51319 3.86076C7.00196 3.37199 7.66488 3.0974 8.35612 3.0974H12.2592L10.3766 1.21483L11.2702 0.321289ZM1.9399 6.33763C1.84557 6.33763 1.75509 6.37507 1.68835 6.44174C1.62168 6.50835 1.58414 6.59868 1.58397 6.69291V14.5899C1.58414 14.6841 1.62162 14.7744 1.68822 14.841C1.75475 14.9075 1.8449 14.945 1.93897 14.9452C1.93879 14.9452 1.93915 14.9452 1.93897 14.9452L10.8238 14.9452C10.8236 14.9452 10.824 14.9452 10.8238 14.9452C10.9179 14.945 11.0087 14.9075 11.0752 14.8408C11.1419 14.7741 11.1793 14.6836 11.1793 14.5893V9.65262H12.443V14.5893C12.443 15.0185 12.2726 15.4302 11.9692 15.7339C11.6659 16.0376 11.2544 16.2084 10.8251 16.2089L1.93885 16.2089C1.5099 16.2084 1.09799 16.0378 0.794678 15.7345C0.491369 15.4312 0.32077 15.02 0.320313 14.591L0.320312 6.6925C0.32077 6.26326 0.491607 5.85108 0.795289 5.54772C1.09897 5.24437 1.51066 5.07397 1.9399 5.07397H3.41943V6.33763H1.9399Z" fill="#9CA3AF"/>
              </svg>
            </div>

          </div>
        </a>

      </div>

    </div>

  </div>
</template>

<script>
import ButtonBase from "../../Components/Buttons/button.base"
import SVGFatrows from "../../../../assets/svgs/admin/svg.fatrows";
import { createStatistic } from "../../../../services/management/StatisticsService";


export default {

  props: [ 'byManager', 'textColor', 'title', 'id', 'datas', 'disableModif', 'link' ],

  components: {
    ButtonBase,
    SVGFatrows
  },

  data() {
    return {
      onDisplay: 'display: block;'
    }
  },

  methods : {
    registerClick(site){
      createStatistic({
        type: "click",
        component: this.id,
        sub_component_link: site.subtitle || site.link,
      })
      .then()
      .catch(error => {
        console.log("error:", error);
      });
    },
  },

  mounted() {
    if( this.disableModif === true ){
      this.onDisplay = 'display: none;'
    }

    console.log(this)
  }
}
</script>

<style scoped>
.text-subtitle{
  height: calc((10/358) * (100vw - var(--size-2x)));
  margin: 0;
  margin-top: calc((18/358) * (100vw - var(--size-2x)));

  font-size: calc((12/358) * (100vw - var(--size-2x)));
}

.text-style-muted svg {
  width: calc((8/358) * (100vw - var(--size-2x)));
}

.rec-btn-full{
  background: rgb(0, 36, 88);
}

.rec-btn-full span{
  font-size: calc((18/358) * (100vw - var(--size-2x)));
}

.margin-top-0-7x{
  margin-top: 0.7rem;
}

.text-title{
  font-size: calc((20/390) * (100vw - var(--size-2x)));
  line-height: 162%;
  font-weight: 600;
  font-family: "Poppins";
  color: #111827;
}
</style>