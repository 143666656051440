<template>
  <base-popup>

    <template v-slot:svg-left>
      <SVGEdit/>
    </template>

    <template v-slot:svg-right>
      <span class="row align-items-center" v-on:click="cancel">
         <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#0400BD"/>
          <path d="M9.16999 15.5799C8.97999 15.5799 8.78999 15.5099 8.63999 15.3599C8.34999 15.0699 8.34999 14.5899 8.63999 14.2999L14.3 8.63986C14.59 8.34986 15.07 8.34986 15.36 8.63986C15.65 8.92986 15.65 9.40986 15.36 9.69986L9.69998 15.3599C9.55998 15.5099 9.35999 15.5799 9.16999 15.5799Z" fill="#0400BD"/>
          <path d="M14.83 15.5799C14.64 15.5799 14.45 15.5099 14.3 15.3599L8.63999 9.69986C8.34999 9.40986 8.34999 8.92986 8.63999 8.63986C8.92999 8.34986 9.40998 8.34986 9.69998 8.63986L15.36 14.2999C15.65 14.5899 15.65 15.0699 15.36 15.3599C15.21 15.5099 15.02 15.5799 14.83 15.5799Z" fill="#0400BD"/>
        </svg>
      </span>
    </template>

    <template v-slot:title v-if="this.type == 'backgroundImage'">
      Do you want to modify  your background ?
    </template>
    <template v-slot:title v-else-if="this.type == 'avatar'">
      Do you want to modify  your profile picture ?
    </template>

    <template v-slot:body>

      <div class="margin-bottom-1x">
        <input ref="updateImg" type="file" @change="uploadImage" accept="image/*" hidden>

        <template v-if="image">
          <img :src="image" class="w-100 img-border-radius" :alt="image" />
        </template>
      </div>

    </template>

    <template v-slot:button-bar>
      <q-button
          v-on:click="changePicture()"
          theme="secondary"
          class="btn p-0 mt-0 margin-bottom-0-5x w-100 bg-blue"
      >
        <span class="text-white">
          Change this picture
        </span>
      </q-button>

      <q-button
          v-on:click="save($emit, this.type, this.image)"
          theme="secondary"
          class="btn btn-save p-0 mx-0 mt-0 margin-bottom-0-5x w-100 bg-blue"
      >
          <span class="text-white">
            Save the modifications
          </span>
      </q-button>
    </template>

  </base-popup>

  <!-- image cropper dialogue -->
  <image-cropper-dialog
      ref="cropperDialog"
      aspectRatio="background"
      :uploadedImage="this.imageToCrop"
      @onCrop="(croppedImage) => {
        this.image = croppedImage
      }"
  />

</template>

<script>
import BasePopup from "./BasePopup"
import { inject } from 'vue';
import { QMessageBoxAction, QButton } from '@qvant/qui-max';
import SVGEdit from "../../../../assets/svgs/app/svg.gallery-edit-circle";
import ImageCropperDialog from '../../GestionCrop/image.cropper.vue';

let qMessageBoxContainer = undefined;

export default {

  props: [ 'type', 'backgroundImage', 'avatar' ],

  components: {
    QButton,
    BasePopup,
    SVGEdit,
    ImageCropperDialog,
  },

  data() {
    return {
      image: null,
      imageToCrop: null,
    }
  },

  setup: () => {
    let infos = "";

    qMessageBoxContainer = inject(
        'qMessageBoxContainer'
    );

    return {
      qMessageBoxContainer,
      infos,
    }
  },

  methods : {

    cancel: () => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.cancel
      });
    },

    save: (emit, type, image) => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.confirm
      });
      emit("save", {type, image});
    },

    async uploadImage(event) {
      if (!event) return;
      let file = event.target.files[0];
      let maxAllowedSize = 20 * 1024 * 1024; // 1Mb = 1024 * 1024
      if(file.size > maxAllowedSize) { // supérieur à 20Mb
        event.target.value = "";
      }else{
        event.target.value = "";
        this.imageToCrop = await this.toBase64(file)
        this.$refs.cropperDialog.initCropper(file.type);
        this.showPopup();
      }
    },

    /**
     * afficher Popup
     */
    showPopup(){
      document.querySelector(".d-modal").style.display = "block";
    },

    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },

    changePicture(){
      this.$refs.updateImg.click()
    },

  },

  mounted() {
    if(this.type == 'backgroundImage'){
      this.image = this.backgroundImage
    } else if(this.type == 'avatar'){
      this.image = this.avatar
    }
  }

}
</script>

<style scoped>
.btn{
  width: calc(100vw - var(--size-5x));
  height: var(--size-3x);
  align-items: center;
  justify-content: center;
  display: flex;
}

.bg-blue {
  background-color: #0400BD;
}

.btn-save{
  margin-left: 0!important;
}

.img-border-radius{
  border-radius: 10px;
}
</style>