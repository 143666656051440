<template>
  <div class="row h-100 m-0">
    <div class="col-xl-12 p-0 pb-5">

      <Entete v-if="!(componentId >= 0)" @cancelEdit="cancelEdit" @saveEdit="saveEdit" />

      <div class="padding-top-1x padding-bottom-1x padding-left-1x padding-right-1x h-100">
        <div class="modal-body text-center h-50">
          <div class="text-start h-100">

            <div class="form-group padding-bottom-1x">
              <label for="section-title" class="form-label text-title">Modify the section title</label>
              <div class="left-inner-addon input-container">
                <i class="icon">
                  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.7751 6.60007C17.4334 6.60007 17.1501 6.31673 17.1501 5.97507V4.4584C17.1501 3.85007 16.6584 3.3584 16.0501 3.3584H3.9501C3.34176 3.3584 2.8501 3.85007 2.8501 4.4584V5.9834C2.8501 6.32507 2.56676 6.6084 2.2251 6.6084C1.88343 6.6084 1.6001 6.32507 1.6001 5.97507V4.4584C1.6001 3.1584 2.65843 2.1084 3.9501 2.1084H16.0501C17.3501 2.1084 18.4001 3.16673 18.4001 4.4584V5.9834C18.4001 6.32507 18.1251 6.60007 17.7751 6.60007Z" stroke="#9CA3AF" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10 17.8915C9.65833 17.8915 9.375 17.6081 9.375 17.2665V3.4248C9.375 3.08314 9.65833 2.7998 10 2.7998C10.3417 2.7998 10.625 3.08314 10.625 3.4248V17.2665C10.625 17.6165 10.3417 17.8915 10 17.8915Z" stroke="#9CA3AF" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13.2832 17.8916H6.71655C6.37489 17.8916 6.09155 17.6083 6.09155 17.2666C6.09155 16.9249 6.37489 16.6416 6.71655 16.6416H13.2832C13.6249 16.6416 13.9082 16.9249 13.9082 17.2666C13.9082 17.6083 13.6249 17.8916 13.2832 17.8916Z" stroke="#9CA3AF" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </i>
                <input v-model="title" type="text" class="form-control text-description" id="section-title" placeholder="About me" />
              </div>
            </div>

            <div class="form-group h-100">
              <label class="form-label text-title">Modify your text zone</label>
              <textarea v-model="description" class="form-control text-description h-100" rows="3" />
            </div>

          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Entete from "../../Navigation/nav.enTete.vue";
import axios from "axios";


export default {

  components: { Entete },

  props: [
    'componentId', 'pageId'
  ],

  data() {
    return {
      description: null,
      title: null,
    }
  },

  methods : {

    /**
     * Annuler des modifications et redirection routeur
     */
    cancelEdit(){
      this.$router.push('/profil/edit/' + this.$route.params.link);
    },

    /**
     * Enregistrer des modifications
     */
    saveEdit(){
      const payloadId = this.$route.params.id || this.componentId;
      if(payloadId) {
        axios
            .patch(this.$apiUrl + '/card/components/' + payloadId + '/',
                {title: this.title, description: this.description},
                {headers: {Authorization: this.$store.state.user.token}})
            .then((res) => {
              this.$toast.show(
                  'Votre modification a été enregistré avec succès !',
                  {type: 'success'}
              );
              if (this.componentId) {
                this.$emit("componentSaved", res.data);
              }
              else {
                this.$router.push('/profil/edit/' + this.$route.params.link);
              }
            })
            .catch(error => {
              this.$toast.show(error.message, {type: 'error'});
              console.log(error.message)
            })
      }else{
        axios
            .post(this.$apiUrl + '/card/components/',
                {
                  component: "BioComponent",
                  title: this.title,
                  description: this.description,
                  page_profile: this.pageId ?? this.$store.state.user.id_pageprofile
                },
                {headers: {Authorization: this.$store.state.user.token}})
            .then((res) => {
              console.log(res.data);
              this.$toast.show(
                  'Votre modification a été enregistré avec succès !',
                  {type: 'success'}
              );
              if (this.componentId >= 0){
                this.$emit("componentSaved", res.data);
              }
              else {
                this.$router.push('/profil/edit/' + this.$route.params.link);
              }
            })
            .catch(error => {
              this.$toast.show(error.message, {type: 'error'});
              console.log(error.message)
            })
      }

    },

    /**
     * Getter composant Text sur l'API
     */
    getText(){
      const payloadId = this.$route.params.id || this.componentId;
      if(payloadId) {
        axios
            .get(this.$apiUrl + '/card/components/' + payloadId + '/',
                {headers: {Authorization: this.$store.state.user.token}})

            .then(response => {
              this.description = response.data.description;
              this.title = response.data.title;
            })

            .catch(error => {
              console.log(error.message)
            })
      }
    },
  },

  mounted() {
    this.getText();
    document.querySelector("#app").style.overflow = "unset";
  }

};

</script>

<style scoped>

</style>

