<template>
  <div class="corner-radius margin-left-1x margin-right-1x margin-top-1x margin-bottom-2x">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">

      <div>
        <button-base
            :textColor="'text-blue'"
            :bgColor="'bg-white'"
            class="edit-button border-blue"
            v-on:click="$emit('cancelEdit')"
        >
          <template v-slot:label>
            <SVGClose :fillColor="'#0400BD'"/>
            Cancel
          </template>
        </button-base>
      </div>

      <div>
        <button-base
            :textColor="'text-white'"
            :bgColor="'bg-blue'"
            class="edit-button"
            v-on:click="$emit('saveEdit')"
        >
          <template v-slot:label>
            <SVGTick :fillColor="'white'"/>
            Save
          </template>
        </button-base>
      </div>

    </div>
  </div>
</template>

<script>
import ButtonBase from "../Components/Buttons/button.base"
import SVGClose from "../../../assets/svgs/app/svg.close-circle"
import SVGTick from "../../../assets/svgs/app/svg.tick-circle"

export default {

  components: { ButtonBase, SVGClose, SVGTick },

}
</script>

<style scoped>
.text-blue{
  color: #0400BD;
}

.border-blue{
  border: 1px solid #0400BD;
}
</style>