<template>
  <div class="d-flex justify-content-between">
    <slot name="svg-left"></slot>
    <slot name="svg-right"></slot>
  </div>
</template>

<script>
export default {

  methods : {

  },

}
</script>

<style scoped>

</style>