<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5 11C14.7091 11 16.5 9.20914 16.5 7C16.5 4.79086 14.7091 3 12.5 3C10.2909 3 8.5 4.79086 8.5 7C8.5 9.20914 10.2909 11 12.5 11Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" :fill="fillColor"/>
    <path d="M6.5 21V19C6.5 17.9391 6.92143 16.9217 7.67157 16.1716C8.42172 15.4214 9.43913 15 10.5 15H14.5C15.5609 15 16.5783 15.4214 17.3284 16.1716C18.0786 16.9217 18.5 17.9391 18.5 19V21" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" :fill="fillColor"/>
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>