<template>
  <svg width="21" height="21" viewBox="0 0 24 17" fill="currentColor" class="me-3" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.65063 7.36812C7.78554 6.50781 7.25 5.31645 7.25 4C7.25 2.68355 7.78554 1.49219 8.65063 0.631879C8.15256 0.387327 7.59232 0.25 7 0.25C4.92893 0.25 3.25 1.92893 3.25 4C3.25 6.07107 4.92893 7.75 7 7.75C7.59232 7.75 8.15256 7.61267 8.65063 7.36812Z" :fill="fillLead"/>
    <path d="M8.25 4C8.25 1.92893 9.92893 0.25 12 0.25C14.0711 0.25 15.75 1.92893 15.75 4C15.75 6.07107 14.0711 7.75 12 7.75C9.92893 7.75 8.25 6.07107 8.25 4Z" :fill="fillLead"/>
    <path d="M15.3494 0.631879C16.2145 1.49219 16.75 2.68355 16.75 4C16.75 5.31645 16.2145 6.50781 15.3494 7.36812C15.8474 7.61267 16.4077 7.75 17 7.75C19.0711 7.75 20.75 6.07107 20.75 4C20.75 1.92893 19.0711 0.25 17 0.25C16.4077 0.25 15.8474 0.387327 15.3494 0.631879Z" :fill="fillLead"/>
    <path d="M5.25 13C5.25 10.9289 6.92893 9.25 9 9.25H15C17.0711 9.25 18.75 10.9289 18.75 13C18.75 15.0711 17.0711 16.75 15 16.75H9C6.92893 16.75 5.25 15.0711 5.25 13Z" :fill="fillLead"/>
    <path d="M0.25 12C0.25 9.92893 1.92893 8.25 4 8.25H9C6.37665 8.25 4.25 10.3766 4.25 13C4.25 14.0249 4.57458 14.9739 5.12655 15.75H4C1.92893 15.75 0.25 14.0711 0.25 12Z" :fill="fillLead"/>
    <path d="M19.75 13C19.75 14.0249 19.4254 14.9739 18.8734 15.75H20C22.0711 15.75 23.75 14.0711 23.75 12C23.75 9.92893 22.0711 8.25 20 8.25H15C17.6234 8.25 19.75 10.3766 19.75 13Z" :fill="fillLead"/>
  </svg>
</template>

<script>
export default {

  name: "svg.leads",

  props: ['fillLead']
}
</script>

<style scoped>

</style>