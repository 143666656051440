<template>
  <picture-base @prev="prev">

    <template v-slot:title>
      Background Picture
    </template>


    <template v-slot:form>
      <Profile>

        <template v-slot:backgroundImage>
          <template v-if="!isUpload">
            <div class="bd-placeholder-img-2"></div>
          </template>

          <template v-else>
            <div class="bd-placeholder-img" v-bind:style="{ 'background-image': `url(${ this.imageToUpload })` }"></div>
          </template>

        </template>

        <template v-slot:avatarImage>
          <div class="avatar-container" v-bind:style="{ 'background-image': `url(${ this.$store.state.user.avatarImage })` }"></div>
        </template>

      </Profile>
    </template>


    <template v-slot:btn-upload>
      <input ref="img" type="file" @change="uploadImage" accept="image/*" hidden>
      <q-button v-on:click="uploadPicture" class="w-100 cta-button bg-blue text-white margin-top-1x" id="btn-upload">
        <SVGPictureAdd :fillColor="'white'"/>
        Upload a picture
      </q-button>
    </template>


    <template v-slot:btn-next>
      <q-button v-on:click="next" class="w-100 cta-button bg-blue text-white margin-top-2x margin-0" id="btn-next">
        I don’t want to add a picture yet
        <SVGArrowRight :fillColor="'white'"/>
      </q-button>
    </template>

  </picture-base>

  <!-- image cropper dialogue -->
  <image-cropper-dialog
      ref="cropperDialog"
      aspectRatio="landscape"
      :uploadedImage="imageToUpload"
      @onCrop="(croppedImage) => {
        this.imageToUpload = croppedImage
        this.changeInnerHTML()
        this.isUpload = true;
      }"
  />

</template>

<script>
import PictureBase from "./picture.base";
import SVGPictureAdd from "../../../assets/svgs/app/svg.picture-add-arrow";
import SVGArrowRight from "../../../assets/svgs/app/svg.arrow-circle-right";
import Profile from "./layout.profile";
import ImageCropperDialog from '../GestionCrop/image.cropper.vue';

export default {

  components: {
    SVGPictureAdd,
    SVGArrowRight,
    PictureBase,
    Profile,
    ImageCropperDialog,
  },

  data() {
    return {
      imageToUpload: null,
      isUpload: false
    }
  },

  methods: {
    /**
     * Redirect to previous page
     */
    prev(){
      this.$router.push('/photo');
    },

    /**
     * Redirect to next page
     */
    next(){
      this.$router.push('/password')
    },

    /**
     * add event listener click
     */
    uploadPicture(){
      this.$refs.img.click()
    },

    /**
     * upload image
     */
    async uploadImage(event) {
      if (!event) return;
      let file = event.target.files[0];
      let maxAllowedSize = 20 * 1024 * 1024; // 1Mb = 1024 * 1024
      if(file.size > maxAllowedSize) { // supérieur à 20Mb
        event.target.value = "";
        this.$toast.show('Erreur, La taille maximale d\'un fichier est de 20 MB!', {type: 'error'});
      }else{
        event.target.value = "";
        this.imageToUpload = await this.toBase64(file);
        this.$refs.cropperDialog.initCropper(file.type);
        this.showPopup();
        this.$toast.show('Votre image a été uploadé avec succès !', {type: 'success'});
      }
    },

    /**
     * Transform to base64
     */
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },

    /**
     * Display innerHTML
     */
    changeInnerHTML(){
      document.querySelector("#btn-upload").innerHTML = `Change this picture`;
      document.querySelector("#btn-next").innerHTML =  `
        Next step
        <svg width="20" height="20" viewBox="0 0 20 20" fill="white" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.0003 18.9583C5.05866 18.9583 1.04199 14.9417 1.04199 9.99999C1.04199 5.05832 5.05866 1.04166 10.0003 1.04166C14.942 1.04166 18.9587 5.05832 18.9587 9.99999C18.9587 14.9417 14.942 18.9583 10.0003 18.9583ZM10.0003 2.29166C5.75033 2.29166 2.29199 5.74999 2.29199 9.99999C2.29199 14.25 5.75033 17.7083 10.0003 17.7083C14.2503 17.7083 17.7087 14.25 17.7087 9.99999C17.7087 5.74999 14.2503 2.29166 10.0003 2.29166Z" />
          <path d="M8.95006 13.5667C8.79173 13.5667 8.6334 13.5083 8.5084 13.3833C8.26673 13.1417 8.26673 12.7417 8.5084 12.5L11.0084 10L8.5084 7.50001C8.26673 7.25834 8.26673 6.85834 8.5084 6.61667C8.75007 6.37501 9.15007 6.37501 9.39173 6.61667L12.3334 9.55834C12.5751 9.80001 12.5751 10.2 12.3334 10.4417L9.39173 13.3833C9.26673 13.5083 9.1084 13.5667 8.95006 13.5667Z" />
        </svg>
      `;
    },

    /**
     * afficher Popup
     */
    showPopup(){
      document.querySelector(".d-modal").style.display = "block";
    },

  },

  mounted() {
    document.querySelector("#app").style.overflowX = "hidden"
    document.querySelector("#app").style.overflowY = "unset"
    this.imageToUpload = this.$store.state.user.backgroundImage;
  }

}
</script>

<style scoped>

.bd-placeholder-img-2{
  height: calc((145/358) * (100vw - var(--size-2x)));
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
  background: linear-gradient(284.83deg, #0400BD -7.8%, #7000FF 99.63%);
}

.bd-placeholder-img{
  height: calc((145/358) * (100vw - var(--size-2x)));
  background-size: cover;
  background-position: center center;
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
}

.cta-button {
  height: var(--size-3x)!important;
  font-size: calc(0.95 * var(--size-1x))!important;
  border-radius: calc(1.5 * var(--size-1x))!important;
  padding: 0 var(--size-1x) 0 var(--size-1x)!important;
  flex-grow: 1;
  margin-left: 0!important;
}
</style>