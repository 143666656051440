<template>
  <svg width="25" height="24" viewBox="0 0 25 24" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.48993 21.5001C7.82993 21.5001 6.15993 20.8701 4.88993 19.6001C2.35993 17.0601 2.35993 12.9401 4.88993 10.4101C5.17993 10.1201 5.65993 10.1201 5.94993 10.4101C6.23993 10.7001 6.23993 11.1801 5.94993 11.4701C3.99993 13.4201 3.99993 16.5901 5.94993 18.5401C7.89993 20.4901 11.0699 20.4901 13.0199 18.5401C13.9599 17.6001 14.4799 16.3401 14.4799 15.0001C14.4799 13.6701 13.9599 12.4101 13.0199 11.4601C12.7299 11.1701 12.7299 10.6901 13.0199 10.4001C13.3099 10.1101 13.7899 10.1101 14.0799 10.4001C15.3099 11.6301 15.9799 13.2601 15.9799 15.0001C15.9799 16.7401 15.2999 18.3701 14.0799 19.6001C12.8199 20.8701 11.1599 21.5001 9.48993 21.5001Z" />
    <path d="M19.5701 14.1602C19.3801 14.1602 19.1901 14.0902 19.0401 13.9402C18.7501 13.6502 18.7501 13.1702 19.0401 12.8802C21.0901 10.8302 21.0901 7.50023 19.0401 5.46023C16.9901 3.41023 13.6601 3.41023 11.6201 5.46023C10.6301 6.45023 10.0801 7.77023 10.0801 9.17023C10.0801 10.5702 10.6301 11.8902 11.6201 12.8802C11.9101 13.1702 11.9101 13.6502 11.6201 13.9402C11.3301 14.2302 10.8501 14.2302 10.5601 13.9402C9.29008 12.6702 8.58008 10.9702 8.58008 9.17023C8.58008 7.37023 9.28008 5.67023 10.5601 4.40023C13.1901 1.77023 17.4701 1.77023 20.1101 4.40023C22.7401 7.03023 22.7401 11.3202 20.1101 13.9502C19.9601 14.0902 19.7601 14.1602 19.5701 14.1602Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>