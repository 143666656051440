<template>
  <svg width="20" height="20" viewBox="0 0 20 20" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.50065 8.95833C6.23398 8.95833 5.20898 7.93333 5.20898 6.66667C5.20898 5.4 6.23398 4.375 7.50065 4.375C8.76732 4.375 9.79232 5.4 9.79232 6.66667C9.79232 7.93333 8.76732 8.95833 7.50065 8.95833ZM7.50065 5.625C6.92565 5.625 6.45898 6.09167 6.45898 6.66667C6.45898 7.24167 6.92565 7.70833 7.50065 7.70833C8.07565 7.70833 8.54232 7.24167 8.54232 6.66667C8.54232 6.09167 8.07565 5.625 7.50065 5.625Z" />
    <path d="M12.4993 18.9583H7.49935C2.97435 18.9583 1.04102 17.025 1.04102 12.5V7.49996C1.04102 2.97496 2.97435 1.04163 7.49935 1.04163H10.8327C11.1743 1.04163 11.4577 1.32496 11.4577 1.66663C11.4577 2.00829 11.1743 2.29163 10.8327 2.29163H7.49935C3.65768 2.29163 2.29102 3.65829 2.29102 7.49996V12.5C2.29102 16.3416 3.65768 17.7083 7.49935 17.7083H12.4993C16.341 17.7083 17.7077 16.3416 17.7077 12.5V8.33329C17.7077 7.99163 17.991 7.70829 18.3327 7.70829C18.6744 7.70829 18.9577 7.99163 18.9577 8.33329V12.5C18.9577 17.025 17.0243 18.9583 12.4993 18.9583Z" />
    <path d="M15 7.2917C14.6583 7.2917 14.375 7.00836 14.375 6.6667V1.6667C14.375 1.4167 14.525 1.18336 14.7583 1.0917C14.9917 1.00003 15.2583 1.05003 15.4417 1.22503L17.1083 2.8917C17.35 3.13336 17.35 3.53336 17.1083 3.77503C16.8667 4.0167 16.4667 4.0167 16.225 3.77503L15.625 3.17503V6.6667C15.625 7.00836 15.3417 7.2917 15 7.2917Z" />
    <path d="M13.3339 3.95828C13.1755 3.95828 13.0172 3.89995 12.8922 3.77495C12.6505 3.53328 12.6505 3.13328 12.8922 2.89162L14.5589 1.22495C14.8005 0.983285 15.2005 0.983285 15.4422 1.22495C15.6839 1.46662 15.6839 1.86662 15.4422 2.10828L13.7755 3.77495C13.6505 3.89995 13.4922 3.95828 13.3339 3.95828Z" />
    <path d="M2.22443 16.4167C2.02443 16.4167 1.82443 16.3167 1.70776 16.1417C1.5161 15.8583 1.5911 15.4667 1.87443 15.275L5.98276 12.5167C6.88276 11.9167 8.12443 11.9833 8.9411 12.675L9.2161 12.9167C9.63276 13.275 10.3411 13.275 10.7494 12.9167L14.2161 9.94167C15.0994 9.18334 16.4911 9.18334 17.3828 9.94167L18.7411 11.1083C18.9994 11.3333 19.0328 11.725 18.8078 11.9917C18.5828 12.25 18.1828 12.2833 17.9244 12.0583L16.5661 10.8917C16.1494 10.5333 15.4494 10.5333 15.0328 10.8917L11.5661 13.8667C10.6828 14.625 9.2911 14.625 8.39943 13.8667L8.12443 13.625C7.7411 13.3 7.10776 13.2667 6.68276 13.5583L2.58276 16.3167C2.4661 16.3833 2.3411 16.4167 2.22443 16.4167Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>