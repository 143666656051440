<template>

  <div class="bg-login h-100">
    <div class="row bg-white form-login">

        <div class="card-body form-left-side text-start">

          <div class="text-title-login">Welcome back !</div>
          <p class="text-intro">Please log in to access to your own<br /> dashboard.</p>

          <form>
            <!-- Input Mail -->
            <div class="left-inner-addon">
              <q-form-item label="" class="text-start">
                <i class="icon z-index-100">
                  <SVGUser :fill-color="'#9CA3AF'"/>
                </i>
                <q-input v-model="login" type="text" class="form-control" placeholder="Enter your mail"/>
              </q-form-item>
            </div>

            <!-- Input Password -->
            <div class="left-inner-addon">
              <q-form-item label="" class="text-start">
                <i class="icon z-index-100">
                  <SVGKey :fill-color="'#9CA3AF'"/>
                </i>
                <q-input v-model="password" type="password" class="form-control" placeholder="Enter your password" password-switch/>
              </q-form-item>
            </div>

          </form>

          <div class="d-flex align-items-center">

            <div class="d-flex justify-content-between align-items-center">
              <input class="form-check-input checkbox-border m-0" type="checkbox" value="">
              <label class="form-check-label text-gray">&nbsp;Remember me</label>
            </div>

            <div class="col text-end">
              <strong class="text-blue text-bold" v-on:click="redirectToForgot">
                Forgot password ?
              </strong>
            </div>

          </div>

          <q-button class="btn-login w-100 bg-blue text-white" v-on:click="signIn">
            <strong>Log in</strong>
          </q-button>

          <p class="text-center text-gray m-0">Don’t have account?
            <strong class="text-blue text-bold" v-on:click="next">
              Create an account
            </strong>
          </p>

        </div>

    </div>
  </div>

</template>

<script>
import SVGUser from "../../../assets/svgs/app/svg.user"
import SVGKey from "../../../assets/svgs/app/svg.key"
import axios from "axios";

export default{

  components: {
    SVGUser,
    SVGKey,
  },

  data() {
    return {
      login: null,
      password: null,
    };
  },

  methods : {

    /**
     * Redirect to next page
     */
    next(){
      this.$router.push('/qui')
    },

    /**
     * Redirect to forgot password
     */
    redirectToForgot(){
      this.$router.push('/forgotPasswd')
    },

    /**
     * SIgn in
     * @return {Promise<void>}
     */
    async signIn(){
      await axios
          .post(this.$apiUrl + '/card/login/', { login: this.login, password: this.password } )
          .then((response) => {
            this.$store.commit("saveToken", response.data.token)
            this.$toast.show('Succès!', {type: 'success'});
            this.$router.push("/profil/edit/me");
          })

          .catch((error) => {
            console.log("error", error)
            this.$toast.show('Identifiant ou mot de passe incorrect!', {type: 'error'});
          })
    },
  },

  mounted() {
    document.querySelector("#app").style.overflowX = "hidden"
    document.querySelector("#app").style.overflowY = "unset"
  }

}
</script>

<style scoped>

.form-check-input:checked{
  background-color: #0400BD !important;
  border: 0;
}

.checkbox-border{
  border: 1px solid #0400BD!important;
}

.q-input:nth-child(2){
  width: 100%!important;
}

.bg-login{
  background-image: url(../../../assets/images/login.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.form-login{
  position: absolute;
  border-radius: 15px;
  top: calc((100vh - calc((418 / 844) * (100vh))) * 0.5);
  left: 0;
  right: 0;
  padding: calc((45 / 430) * (100vw - var(--size-2x))) calc((28 / 430) * (100vw - var(--size-2x)));
  margin: 0 calc((28 / 430) * (100vw - var(--size-2x)));
}

.text-title-login{
  color: #111827;
  font-weight: 700;
  font-family: "Poppins";
  font-size: calc((27 / 430) * (100vw - var(--size-2x)));
  line-height: calc((17 / 430) * (100vw - var(--size-2x)));
  margin-bottom: calc((16 / 430) * (100vw - var(--size-2x)));
}

.text-gray{
  color: #9CA3AF;
  font-family: "Poppins";
  font-weight: 400;
  line-height: calc((16 / 430) * (100vw - var(--size-2x)));
  font-size: calc((11 / 430) * (100vw - var(--size-2x)));
}

.text-intro{
  color: #9CA3AF;
  font-family: "Poppins";
  font-weight: 400;
  line-height: calc((24 / 430) * (100vw - var(--size-2x)));
  font-size: calc((14 / 430) * (100vw - var(--size-2x)));
  margin-bottom: calc((44 / 430) * (100vw - var(--size-2x)));
}

.z-index-100{
  z-index: 100;
}

.text-blue{
  color: #0400BD;
  font-family: "Poppins";
  font-weight: 600;
  line-height: calc((16 / 430) * (100vw - var(--size-2x)));
  font-size: calc((11 / 430) * (100vw - var(--size-2x)));
}

.btn-login{
  font-family: "Poppins";
  font-weight: 700;
  margin-top: calc((21 / 430) * (100vw - var(--size-2x)));
  margin-bottom: calc((15 / 430) * (100vw - var(--size-2x)));
  border-radius: 6.834px;
  background: linear-gradient(339deg, #0400BD 0%, #7000FF 100%)!important;
  height: var(--size-3x);
  line-height: calc((16 / 430) * (100vw - var(--size-2x)));
  font-size: calc((14 / 430) * (100vw - var(--size-2x)));
}
</style>

