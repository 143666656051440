<template>
  <div class="col-xl-12 margin-left-1x margin-right-1x">

    <div class="text-start margin-top-3x margin-bottom-0-5x">
      <label class="margin-bottom-1x text-title text-start">Your Statistics</label>
    </div>

    <LegendBar v-on:changeOption="changeOptionLine" class=""/>
    <LineChart :datasOption="this.line" class="chart-container"/>

    <Layout titre="Last 7 days" chiffreVue="34" chiffreAjout="17" chiffreLead="50.0 %"/>
    <Layout titre="Last 30 days" chiffreVue="156" chiffreAjout="56" chiffreLead="35.9 %"/>
    <Layout titre="This year" chiffreVue="3607" chiffreAjout="1580" chiffreLead="43.8 %"/>

  </div>

  <Nav :active="'third'" />
</template>

<script>
import Nav from "../Navigation/nav.pieds";
import Layout from "./chiffres.layout.vue";
import LegendBar from "./layout.legendbar";
import LineChart from "../../admin/Charts/layout.line.chart";
import line from "../../admin/Charts/Structure/line";
import lineOption from "../../admin/Charts/Structure/line";
import axios from "axios";

export default {

  components: {
    Nav,
    Layout,
    LegendBar,
    LineChart,
  },

  data() {
    return {
      line,
      lineOption,
    };
  },

  methods : {

    /**
     * Getter datas linechart
     */
    getDatasLineChart(){
      axios
          .get('https://my-json-server.typicode.com/ziyi-hub/PROFYLEAD_API/LineChart',
              { headers: { Authorization: this.$store.state.user.token } })
          .then((response) => {
            this.line.xAxis.data = response.data.week.datasLegend;
            this.line.series[0].data = response.data.week.datasSeries;
          })
          .catch((error) => {
            console.log("error", error);
          })
    },

    /**
     * Permet de changer datas par rapport aux choix
     * @param event
     */
     changeOptionLine(timeFrame) {
      console.log(timeFrame)
      // document.querySelectorAll(".legend-item").forEach((item) => {
      //   item.className = "btn legend-item choice-disable";
      // })

      // event.target.className = "btn legend-item choice-enable";

      // axios
      //     .get('https://my-json-server.typicode.com/ziyi-hub/PROFYLEAD_API/LineChart',
      //         { headers: { Authorization: this.$store.state.user.token } })
      //     .then((response) => {
      //       switch (event.target.innerHTML) {
      //         case '1D':
      //           this.lineOption.xAxis.data = response.data.day.datasLegend;
      //           this.lineOption.series[0].data = response.data.day.datasSeries;
      //           break;
      //         case '1W':
      //           this.lineOption.xAxis.data = response.data.week.datasLegend;
      //           this.lineOption.series[0].data = response.data.week.datasSeries;
      //           break;
      //         case '1M':
      //           this.lineOption.xAxis.data = response.data.month.datasLegend;
      //           this.lineOption.series[0].data = response.data.month.datasSeries;
      //           break;
      //         case '1Y':
      //           this.lineOption.xAxis.data = response.data.year.datasLegend;
      //           this.lineOption.series[0].data = response.data.year.datasSeries;
      //           break;
      //         case 'All':
      //           this.lineOption.xAxis.data = response.data.all.datasLegend;
      //           this.lineOption.series[0].data = response.data.all.datasSeries;
      //           break;
      //         default:
      //           break;
      //       }
      //     })
      //     .catch((error) => {
      //       console.log("error", error);
      //     })

    },


  },

  mounted() {
    this.getDatasLineChart();
  }
};

</script>

<style scoped>
.text-title{
  font-size: calc((22/390) * (100vw - var(--size-2x)))!important;
  line-height: 140%!important;
  font-weight: 500!important;
  font-family: "Lexend";
  color: #111827;
}

.chart-container{
  height: calc((220/390) * (100vw - var(--size-2x)))!important;
}
</style>

