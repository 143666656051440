import { graphic } from "echarts/core";

export default {
    color: ['#0400BD'],
    tooltip: {
        trigger: 'axis'
    },
    grid: {
        top: '10%',
        bottom: '13%',
        left: 0,
        right: 0,
        containLabel: false,
    },
    xAxis: {
        type: 'category',
        boundaryGap: false,
        data: [
            "2:00",
            "4:00",
            "6:00",
            "8:00",
            "10:00",
            "12:00",
            "14:00",
            "16:00",
            "18:00",
            "20:00",
            "22:00",
            "24:00"
        ],
        axisLine: {
            show: false
        },
        axisTick: {
            show: false
        }
    },
    yAxis: {
        show: false,
        type: 'value'
    },
    series: [
        {
            data: [],
            type: 'line',
            showSymbol: false,
            areaStyle: {
                color: new graphic.LinearGradient(0, 1, 0, 0, [
                    {
                        offset: 0.1,
                        color: '#EEF0FE'
                    },
                    {
                        offset: 1,
                        color: 'rgb(201,201,239)'
                    }
                ])
            }
        }
    ]
}