<template>
  <base-popup>

    <template v-slot:svg-left>
      <SVGTel />
    </template>

    <template v-slot:svg-right>
      <span class="row align-items-center" v-on:click="cancel">
         <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#0400BD"/>
          <path d="M9.16999 15.5799C8.97999 15.5799 8.78999 15.5099 8.63999 15.3599C8.34999 15.0699 8.34999 14.5899 8.63999 14.2999L14.3 8.63986C14.59 8.34986 15.07 8.34986 15.36 8.63986C15.65 8.92986 15.65 9.40986 15.36 9.69986L9.69998 15.3599C9.55998 15.5099 9.35999 15.5799 9.16999 15.5799Z" fill="#0400BD"/>
          <path d="M14.83 15.5799C14.64 15.5799 14.45 15.5099 14.3 15.3599L8.63999 9.69986C8.34999 9.40986 8.34999 8.92986 8.63999 8.63986C8.92999 8.34986 9.40998 8.34986 9.69998 8.63986L15.36 14.2999C15.65 14.5899 15.65 15.0699 15.36 15.3599C15.21 15.5099 15.02 15.5799 14.83 15.5799Z" fill="#0400BD"/>
        </svg>
      </span>
    </template>

    <template v-slot:title>
      Do you want to modify or delete this link ?
    </template>

    <template v-slot:body>
      <div class="margin-bottom-1x">

        <div class="left-inner-addon input-container">
          <i class="icon">
            <SVGText :fill-color="'#9CA3AF'"/>
          </i>
          <input v-model="label" type="text" class="form-control text-description" :placeholder="label" />
        </div>

        <div class="row m-0 p-0">
          <div class="reseaux-btn">

            <a target="_blank" rel="noreferrer" v-on:click="createSocial('appel')">
              <q-button theme="secondary" class="btn text-danger btn-contact" :class="fondAppel">
                <SVGCall :fill-color="svgAppel"/>
              </q-button>
            </a>

            <a target="_blank" rel="noreferrer" v-on:click="createSocial('message')">
              <q-button theme="secondary" class="btn text-danger btn-contact" :class="fondMessage">
                <SVGSms :fill-color="svgMessage"/>
              </q-button>
            </a>

            <a target="_blank" rel="noreferrer" v-on:click="createSocial('mail')">
              <q-button theme="secondary" class="btn text-danger btn-contact" :class="fondMail">
                <SVGMail :fill-color="svgMail"/>
              </q-button>
            </a>

            <a target="_blank" rel="noreferrer" v-on:click="createSocial('link')">
              <q-button theme="secondary" class="btn text-danger btn-contact" :class="fondLink">
                <SVGWeb :fill-color="svgLink"/>
              </q-button>
            </a>

            <a target="_blank" rel="noreferrer" v-on:click="createSocial('localization')">
              <q-button theme="secondary" class="btn text-danger btn-contact" :class="fondLocalization">
                <SVGLocation :fill-color="svgLocalization"/>
              </q-button>
            </a>

          </div>

        </div>
      </div>


      <template v-if="isLocalization">
        <div class="left-inner-addon input-container">
          <i class="icon">
            <SVGLocation :fill-color="'#9CA3AF'"/>
          </i>
          <input v-model="address" type="text" class="form-control text-description" placeholder="Address" />
        </div>

        <div class="left-inner-addon input-container margin-bottom-1x">
          <i class="icon">
            <SVGLocation :fill-color="'#9CA3AF'"/>
          </i>
          <input v-model="city" type="text" class="form-control text-description" placeholder="City" />
        </div>
      </template>

      <template v-else-if="isLink">
        <div class="left-inner-addon input-container margin-bottom-1x">
          <i class="icon">
            <SVGLink :fill-color="'#9CA3AF'"/>
          </i>
          <input v-model="link" type="text" class="form-control text-description" placeholder="Link" />
        </div>
      </template>

      <template v-else>
        <vue-tel-input :value="link" @input="updatePhone" mode="international" id="tel-country-codes"></vue-tel-input>
      </template>

    </template>

    <template v-slot:button-bar>
      <q-button
          v-on:click="deleteLink($emit, this.id)"
          theme="secondary"
          class="btn p-0 btn-height mt-0 margin-bottom-0-5x w-100 bg-red"
      >
          <span class="text-white">
            Delete this contact link
          </span>
      </q-button>

      <q-button
          v-on:click="exchange($emit, this.contact, this.id, this.type, this.link, this.address, this.city, this.label)"
          theme="secondary"
          class="btn btn-save btn-height p-0 mx-0 mt-0 margin-bottom-0-5x w-100 bg-blue"
      >
          <span class="text-white">
            Save the modifications
          </span>
      </q-button>
    </template>

  </base-popup>

</template>

<script>
import BasePopup from "./BasePopup"
import { inject } from 'vue';
import SVGCall from "../../../../assets/svgs/app/svg.call-calling";
import SVGMail from "../../../../assets/svgs/app/svg.mail";
import SVGSms from "../../../../assets/svgs/app/svg.sms";
import SVGWeb from "../../../../assets/svgs/app/svg.website";
import SVGLocation from "../../../../assets/svgs/app/svg.location";
import SVGLink from "../../../../assets/svgs/app/svg.link";
import SVGText from "../../../../assets/svgs/app/svg.text-small";
import SVGTel from "../../../../assets/svgs/app/svg.tel-circle";
import { VueTelInput } from 'vue3-tel-input';
import { QMessageBoxAction, QButton } from '@qvant/qui-max';

let qMessageBoxContainer = undefined;

export default {

    props: [ 'contact' ],

    components: {
      QButton,
      BasePopup,
      SVGCall,
      SVGMail,
      SVGSms,
      SVGWeb,
      SVGLocation,
      SVGLink,
      SVGText,
      SVGTel,
      VueTelInput,
    },

    data() {
      return {
        type: null,
        link: null,
        address: null,
        city: null,
        isLocalization: false,
        isLink: false,
        id: null,
        label: null,

        fondAppel: "link-disable",
        fondMessage: "link-disable",
        fondMail: "link-disable",
        fondLink: "link-disable",
        fondLocalization: "link-disable",

        svgAppel: '#9CA3AF',
        svgMessage: '#9CA3AF',
        svgMail: '#9CA3AF',
        svgLink: '#9CA3AF',
        svgLocalization: '#9CA3AF',
      }
    },

    setup: () => {
        let infos = "";

        qMessageBoxContainer = inject(
            'qMessageBoxContainer'
        );

        return {
            qMessageBoxContainer,
            infos,
        }
    },

    methods : {
      updatePhone(params) {
        if (typeof params == "string") {
          this.link = params;
        }
      },

      cancel: () => {
          qMessageBoxContainer?.emitDoneEvent({
              action: QMessageBoxAction.cancel
          });
      },

      exchange(emit, contact, id, type, link, address, city, label){
          qMessageBoxContainer?.emitDoneEvent({
              action: QMessageBoxAction.confirm
          });
          contact.label = label;
          if(this.isLocalization){
            contact.type = type
            contact.address = address
            contact.city = city
          }else{
            contact.type = type
            contact.lien = link
          }
          emit("save", contact);
      },

      deleteLink: (emit, id) => {
        qMessageBoxContainer?.emitDoneEvent({
          action: QMessageBoxAction.confirm
        });
        emit("delete", {id});
      },

      createSocial(nameComponent){

        this.fondAppel = "link-disable";
        this.fondMessage = "link-disable";
        this.fondMail = "link-disable";
        this.fondLink = "link-disable";
        this.fondLocalization = "link-disable";

        this.svgAppel = '#9CA3AF';
        this.svgMessage = '#9CA3AF';
        this.svgMail = '#9CA3AF';
        this.svgLink = '#9CA3AF';
        this.svgLocalization = '#9CA3AF';

        switch (nameComponent) {
          case 'appel':
            this.isLocalization = false
            this.isLink = false
            this.type = nameComponent

            this.fondAppel = "active link-active";
            this.svgAppel = '#0400BD';
            break;
          case 'message':
            this.isLocalization = false
            this.isLink = false
            this.type = nameComponent

            this.fondMessage = "active link-active";
            this.svgMessage = '#0400BD';
            break;
          case 'mail':
            this.isLocalization = false
            this.isLink = false
            this.type = nameComponent

            this.fondMail = "active link-active";
            this.svgMail = '#0400BD';
            break;
          case 'link':
            this.isLocalization = false
            this.isLink = true
            this.type = nameComponent

            this.fondLink = "active link-active";
            this.svgLink = '#0400BD';
            break;
          case 'localization':
            this.isLocalization = true
            this.type = nameComponent

            this.fondLocalization = "active link-active";
            this.svgLocalization = '#0400BD';
            break;
        }
      },
    },

  mounted() {
      this.label = this.contact.label
      this.id = this.contact.id;
      this.type = this.contact.type;

    switch (this.type) {
      case 'appel':
        this.isLocalization = false
        this.link = this.contact.lien;

        this.fondAppel = "active link-active";
        this.svgAppel = '#0400BD';
        break;
      case 'message':
        this.isLocalization = false
        this.link = this.contact.lien;

        this.fondMessage = "active link-active";
        this.svgMessage = '#0400BD';
        break;
      case 'mail':
        this.isLocalization = false
        this.link = this.contact.lien;

        this.fondMail = "active link-active";
        this.svgMail = '#0400BD';
        break;
      case 'link':
        this.isLink = true;
        this.isLocalization = false;

        this.fondLink = "active link-active";
        this.svgLink = '#0400BD';
        break;
      case 'localization':
        this.isLocalization = true
        this.address = this.contact.address;
        this.city = this.contact.city;

        this.fondLocalization = "active link-active";
        this.svgLocalization = '#0400BD';
        break;
    }
  }
}
</script>

<style scoped>
.btn{
  align-items: center;
  justify-content: center;
  display: flex;
}

.btn-contact{
  margin: calc((((100vw - var(--size-2x)) / 5) - ((100vw - var(--size-2x)) / 6.2)) / 5)!important;
  width: calc((100vw - var(--size-2x)) / 6);
  height: 100%;
  box-shadow: 0px 0.77933px 4.67598px rgba(211, 221, 255, 0.48), 0px 0.77933px 3.11732px rgba(63, 63, 63, 0.07)!important;
  border: unset!important;
}

.active.link-active {
  background: #D6D6FF!important;
  border: 1px solid #0400BD!important;
}

.link-disable{
  background: #FFFFFF!important;
}

.reseaux-btn {
  display: flex;
  height: calc((100vw - var(--size-2x)) / 6);
  margin: 0;
  padding: 0;
}

.bg-red{
  background: #EC0000;
}

.bg-blue {
  background-color: #0400BD;
}

.btn-save{
  margin-left: 0!important;
}

.btn-height{
  height: var(--size-3x);
}

.text-description{
  line-height: 160%;
  font-weight: 400;
  font-family: "Lexend";
  color: #6B7280;
}

#tel-country-codes{
  border-radius: 8px!important;
  border: 1px solid #ced4da;
  height: var(--size-3x);
  margin-bottom: var(--size-2x);
}
</style>