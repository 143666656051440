<template>
  <tag :bg-tag="'#ffffff'"
       class="btn-outline"
       :style="{ border: '1px solid ' + this.colorOutline }">
    <template v-slot:labelTag>
      <slot name="labelTag"></slot>
    </template>
  </tag>
</template>

<script>
import Tag from "./tag.base";

export default {
  props: [ 'colorOutline' ],

  components: {
    Tag,
  },

}
</script>

<style scoped>
.btn-outline{
  color: #9CA3AF!important;
}
</style>