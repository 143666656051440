<template>

  <div class="row h-100 m-0">
    <div class="col-xl-12 p-0 pb-5">
      <Entete class="text-capitalize" :title="this.$route.params.nom" @handleNotification="this.$router.push('/ajoutSection')" @handleSubmitClick="handleSubmitClick" />
      <div class="card-body text-white p-md-5 margin-top">
        <q-form
            :rules="rul"
            ref="form"
            :model="formModel"
            class="mx-3 text-start text-dark "
        ><!-- :rules="rul" -->

          <v-switch :case="this.$route.params.nom">
            <template #texte>

              <q-form-item label="Titre de la section" prop="texte.section" class="text-start my-2 ">
                <q-input v-model="formModel.texte.section" type="text" class="form-control" />
              </q-form-item>

              <q-form-item label="Contenu" prop="texte.description" class="text-start my-2 ">
                <q-textarea
                    v-model="formModel.texte.description"
                    resize="vertical"
                    :autosize="{ minRows: 6, maxRows: 8 }"
                />
              </q-form-item>
            </template>

            <template #documents>

              <q-form-item label="Titre de la section" prop="documents.section" class="text-start my-2">
                <q-input v-model="formModel.documents.section" type="text" class="form-control"/>
              </q-form-item>


              <q-form-item label="URL" prop="documents.link" class="text-start my-2">
                <q-input v-model="formModel.documents.link" type="text" class="form-control" id="url"/>
              </q-form-item>


              <q-form-item label="Titre de lien" prop="documents.subtitle" class="text-start my-2">
                <q-input v-model="formModel.documents.subtitle" type="text" class="form-control" id="titre"/>
              </q-form-item>

              <q-form-item label="Ajouter un PDF" prop="documents.pdf" class="text-center my-2">
                <img v-bind:src="formModel.documents.pdf" width="300" alt="">
                <q-upload
                    :multiple="false"
                    :limit="1"
                    :accept="'.pdf'"
                    :disabled="false"
                    :clearable="true"
                    :validate-event="true"
                    :text-upload-file="'uploader un file'"
                    :text-replace-file="'changer un file'"
                    :text-loading-file="'charger un file'"
                    :value="formModel.documents.file"
                    @select="handleFileSelectDoc"
                    @abort="handleAbort"
                    @clear="handleClearDoc"
                />
              </q-form-item>
            </template>

            <template #photos>

              <q-form-item label="Titre de la section" prop="photos.section" class="text-start my-2">
                <q-input v-model="formModel.photos.section" type="text" class="form-control"/>
              </q-form-item>

              <q-form-item label="URL" prop="photos.link" class="text-start my-2">
                <q-input v-model="formModel.photos.link" type="text" class="form-control" id="link" placeholder="" />
              </q-form-item>


              <q-form-item label="Modifier l'image" prop="photo" class="text-center my-2">
                <img class="w-100" v-bind:src="formModel.photos.img" alt="">
                <q-upload
                    :multiple="false"
                    :limit="1"
                    :accept="'image/*'"
                    :disabled="false"
                    :clearable="true"
                    :validate-event="true"
                    :text-upload-file="'uploader un file'"
                    :text-replace-file="'changer un file'"
                    :text-loading-file="'charger un file'"
                    :value="formModel.photos.file"
                    @select="handleFileSelectPhoto"
                    @abort="handleAbort"
                    @clear="handleClearPhoto"
                />
              </q-form-item>
            </template>


            <template #videos>

              <q-select v-model="value">
                <q-option
                    v-for="(option, index) in options"
                    :key="index"
                    :label="option"
                    :value="option"
                    @click="changeOption(index)"
                />
              </q-select>

              <q-form-item label="Titre de la section" prop="videos.section" class="text-start my-2">
                <q-input v-model="formModel.videos.section" type="text" class="form-control"/>
              </q-form-item>


              <template v-if="formModel.videos.player === 'UploadLocal'">

                <div class="text-center">
                  <q-upload
                      :multiple="false"
                      :limit="1"
                      :accept="'.mp4'"
                      :disabled="false"
                      :clearable="true"
                      :validate-event="true"
                      :text-upload-file="'uploader un file'"
                      :text-replace-file="'changer un file'"
                      :text-loading-file="'charger un file'"
                      :value="formModel.videos.file"
                      @select="handleFileSelectVideo"
                      @abort="handleAbort"
                      @clear="handleClearVideo"
                  />
                </div>


                <div>
                  <input ref="imageField" type="file" @change="uploadImage" accept="image/*" hidden>
                  <q-button
                      v-on:click="onUploaded"
                      theme="secondary"
                      id="btn-photo"
                      class="btn w-100 text-lowercase text-font text-color-blue"
                  >
                    <span class="text-capitalize">Ajouter</span> une miniature
                  </q-button>
                </div>

              </template>

              <template v-else>
                <q-form-item label="Id de vidéo Youtube" prop="videos.videoId" class="text-start my-2">
                  <q-input v-model="formModel.videos.videoId" type="text" class="form-control"/>
                </q-form-item>
              </template>

            </template>


            <template #site>

              <q-form-item label="Titre de la section" prop="site.section" class="text-start my-2">
                <q-input v-model="formModel.site.section" type="text" class="form-control"/>
              </q-form-item>

              <q-form-item label="URL" prop="site.url" class="text-start my-2">
                <q-input v-model="formModel.site.url" type="text" class="form-control"/>
              </q-form-item>

              <q-form-item label="Titre de lien" prop="site.titre" class="text-start my-2">
                <q-input v-model="formModel.site.titre" type="text" class="form-control"/>
              </q-form-item>

              <q-form-item label="Modifier l'image" prop="titre" class="text-center my-2">
                <img v-bind:src="formModel.site.logoSite" width="300" alt="">
                <q-upload
                    :multiple="false"
                    :limit="1"
                    :accept="'image/*'"
                    :disabled="false"
                    :clearable="true"
                    :validate-event="true"
                    :text-upload-file="'uploader un file'"
                    :text-replace-file="'changer un file'"
                    :text-loading-file="'charger un file'"
                    :value="formModel.site.file"
                    @select="handleFileSelectSite"
                    @abort="handleAbort"
                    @clear="handleClearSite"
                />
              </q-form-item>
            </template>


            <template #calendly>
              calendly
            </template>
            <template #application>
              application
            </template>
            <template #google>
              google
            </template>


            <template #social>

              <q-form-item label="Titre de la section" prop="social.section" class="text-start my-2">
                <q-input v-model="formModel.social.section" type="text" class="form-control" id="section"/>
              </q-form-item>

              <q-collapse
                  v-model="activeNames"
                  :accordion="false"
              >

                <template v-for="reseau in formModel.social.datas" :key="reseau.id">

                  <q-collapse-item :title="reseau.component">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-start">
                      <div>
                        <h6 class="m-0">Afficher sur mon profil</h6>

                        <q-form-item
                            :label="'Lien de votre ' + reseau.component "
                            prop=""
                            class="text-start my-2"
                        >
                          <q-input v-model="reseau.link" type="url" class="form-control" placeholder="https://www.reseaux.com/nom_de_compte"/>
                        </q-form-item>

                      </div>
                      <q-switch
                          :id="reseau.component"
                          v-model="reseau.isOn"
                          :loading="reseau.isLoading"
                          :disabled="reseau.isDisabled"
                          :active-value="reseau.isActive"
                          :inactive-value="reseau.isInactive"
                      />
                    </div>
                  </q-collapse-item>

                </template>

              </q-collapse>
            </template>

            <template #contact>

              <q-form-item label="Titre de la section" prop="contact.section" class="text-start my-2">
                <q-input v-model="formModel.contact.section" type="text" class="form-control"/>
              </q-form-item>

              <q-form-item label="Lien d'appel" prop="contact.appel" class="text-start my-2">
                <q-input v-model="formModel.contact.appel" type="text" class="form-control"/>
              </q-form-item>

              <q-form-item label="Lien de message" prop="contact.message" class="text-start my-2">
                <q-input v-model="formModel.contact.message" type="text" class="form-control"/>
              </q-form-item>

              <q-form-item label="Lien de mail" prop="contact.mail" class="text-start my-2">
                <q-input v-model="formModel.contact.mail" type="text" class="form-control"/>
              </q-form-item>

            </template>

          </v-switch>

        </q-form>
      </div>
    </div>


    <Navigation active="profil" />

  </div>

</template>

<script>
import { useStore } from 'vuex';
import { useRouter, useRoute } from "vue-router";
import Navigation from "../../Navigation/nav.pieds";
import Entete from "../../Navigation/nav.enTete.vue";
import { ref, reactive, getCurrentInstance, toRaw, /*onMounted*/ } from "vue";
import axios from "axios";

let sourceMiniature;

let model = {
  texte: { section: '', description: '' },
  documents: { section: '', link: '', subtitle: '', pdf: '', file: null },
  photos: { section: '', link: '', img: '', file: null },
  videos: { section: '', player: 'UploadLocal', videoId: '', sourceVideo: '', file: null },
  site: { section: '', url: '', titre: '', logoSite: '', file: null },
  social: { section: '', datas: [
        {
          id: 0,
          component: "LinkedIn",
          isOn: false,
          isLoading: false,
          isDisabled: false,
          isActive: true,
          isInactive: false,
          link: null
        },
        {
          id: 1,
          component: "Instagram",
          isOn: false,
          isLoading: false,
          isDisabled: false,
          isActive: true,
          isInactive: false,
          link: null
        },
        {
          id: 2,
          component: "Facebook",
          isOn: false,
          isLoading: false,
          isDisabled: false,
          isActive: true,
          isInactive: false,
          link: null
        },
        {
          id: 3,
          component: "Twitter",
          isOn: false,
          isLoading: false,
          isDisabled: false,
          isActive: true,
          isInactive: false,
          link: null
        },
        {
          id: 4,
          component: "YouTube",
          isOn: false,
          isLoading: false,
          isDisabled: false,
          isActive: true,
          isInactive: false,
          link: null
        },
        {
          id: 5,
          component: "WhatsApp",
          isOn: false,
          isLoading: false,
          isDisabled: false,
          isActive: true,
          isInactive: false,
          link: null
        },
    ]
  },
  contact: { section: '', appel: '', message: '', mail: '' },
};

export default {

  name: "creationSection",

  components: { Navigation, Entete },

  setup() {
    let form = ref(null);
    let router = useRouter();
    let store = useStore();
    let route = useRoute();
    let app = getCurrentInstance();
    let formModel = reactive(model);
    let options = [
      'Uploader une vidéo locale',
      'Uploader une vidéo via Youtube',
      'Uploader une vidéo via Vimeo'
    ];
    let value = ref('Uploader une vidéo locale');
    let activeNames = ref(['1']);

    let rules = {
      "texte.section": app.appContext.config.globalProperties.$validator_not_null,
      "texte.description": app.appContext.config.globalProperties.$validator_not_null,

      "documents.section": app.appContext.config.globalProperties.$validator_not_null,
      "documents.link": app.appContext.config.globalProperties.$validator_not_null,
      "documents.subtitle": app.appContext.config.globalProperties.$validator_not_null,

      "photos.section": app.appContext.config.globalProperties.$validator_not_null,
      "photos.link": app.appContext.config.globalProperties.$validator_not_null,

      "videos.section": app.appContext.config.globalProperties.$validator_not_null,
      "videos.videoId": app.appContext.config.globalProperties.$validator_not_null,

      "site.section": app.appContext.config.globalProperties.$validator_not_null,
      "site.url": app.appContext.config.globalProperties.$validator_not_null,

      "social.section": app.appContext.config.globalProperties.$validator_not_null,

      "contact.section": app.appContext.config.globalProperties.$validator_not_null,
      "contact.appel": app.appContext.config.globalProperties.$validator_telephone,
      "contact.message": app.appContext.config.globalProperties.$validator_telephone,
      "contact.mail": app.appContext.config.globalProperties.$validator_email,
    };
    let rul = reactive(rules);

    let changeOption = (keyOption) => {
      if(keyOption === 0){
        formModel.videos.player = 'UploadLocal'
      } else if(keyOption === 1){
        formModel.videos.player = 'Youtube'
      }else{
        formModel.videos.player = 'Vimeo'
      }
    };

    const handleFileSelectDoc = async (sourceFile, fileId) => {
      formModel.documents.file = {
        id: fileId,
        sourceFile,
        name: sourceFile.name,
        loading: 0
      };

      let toBase64 = async (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      };

      const promise = async () =>
          new Promise(resolve => {
            const interval = setInterval(() => {
              if (!formModel.documents.file) return;
              let file = formModel.documents.file;

              let maxAllowedSize = 20 * 1024 * 1024; // 1Mb = 1024 * 1024
              if(toRaw(file).sourceFile.size > maxAllowedSize) { // si file supérieur à 20Mb
                formModel.documents.file = null;
                app.appContext.config.globalProperties.$toast.show('Erreur, La taille maximale d\'un fichier est de 20 MB!', {type: 'error'});
              }else{
                //console.log(file.sourceFile);
                toBase64(file.sourceFile).then((response) => {
                  formModel.documents.pdf = response
                  document.querySelector(".q-upload-drop-zone").className = "q-upload-drop-zone w-100"
                })

                if (formModel.documents.file.loading === null) formModel.documents.file.loading = 0;
                formModel.documents.file.loading += 10;
              }
            }, 100);

            setTimeout(() => {
              clearInterval(interval);
              if (formModel.documents.file) formModel.documents.file.loading = null;
              resolve();
            }, 1000);
          });

      await promise();
    };

    const handleFileSelectPhoto = async (sourceFile, fileId) => {
      formModel.photos.file = {
        id: fileId,
        sourceFile,
        name: sourceFile.name,
        loading: 0
      };

      let toBase64 = async (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      };

      const promise = async () =>
          new Promise(resolve => {
            const interval = setInterval(() => {
              if (!formModel.photos.file) return;
              let file = formModel.photos.file;

              let maxAllowedSize = 20 * 1024 * 1024; // 1Mb = 1024 * 1024
              if(toRaw(file).sourceFile.size > maxAllowedSize) { // si file supérieur à 20Mb
                formModel.photos.file = null;
                app.appContext.config.globalProperties.$toast.show('Erreur, La taille maximale d\'un fichier est de 20 MB!', {type: 'error'});
              }else{
                //console.log(file.sourceFile);
                toBase64(file.sourceFile).then((response) => {
                  formModel.photos.img = response
                  document.querySelector(".q-upload-drop-zone").className = "q-upload-drop-zone w-100"
                })

                if (formModel.photos.file.loading === null) formModel.photos.file.loading = 0;
                formModel.photos.file.loading += 10;
              }
            }, 100);

            setTimeout(() => {
              clearInterval(interval);
              if (formModel.photos.file) formModel.photos.file.loading = null;
              resolve();
            }, 1000);
          });

      await promise();
    };

    const handleFileSelectVideo = async (sourceFile, fileId) => {
      formModel.videos.file = {
        id: fileId,
        sourceFile,
        name: sourceFile.name,
        loading: 0
      };

      let toBase64 = async (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      };

      const promise = async () =>
          new Promise(resolve => {
            const interval = setInterval(() => {
              if (!formModel.videos.file) return;
              let file = formModel.videos.file;

              let maxAllowedSize = 20 * 1024 * 1024; // 1Mb = 1024 * 1024
              if(toRaw(file).sourceFile.size > maxAllowedSize) { // si file supérieur à 20Mb
                formModel.videos.file = null;
                app.appContext.config.globalProperties.$toast.show('Erreur, La taille maximale d\'un fichier est de 20 MB!', {type: 'error'});
              }else{
                //console.log(file.sourceFile);
                toBase64(file.sourceFile).then((response) => {
                  formModel.videos.sourceVideo = response
                  document.querySelector(".q-upload-drop-zone").className = "q-upload-drop-zone w-100"
                })

                if (formModel.videos.file.loading === null) formModel.videos.file.loading = 0;
                formModel.videos.file.loading += 10;
              }
            }, 100);

            setTimeout(() => {
              clearInterval(interval);
              if (formModel.videos.file) formModel.videos.file.loading = null;
              resolve();
            }, 1000);
          });

      await promise();
    };

    const handleFileSelectSite = async (sourceFile, fileId) => {
      formModel.site.file = {
        id: fileId,
        sourceFile,
        name: sourceFile.name,
        loading: 0
      };

      let toBase64 = async (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      };

      const promise = async () =>
          new Promise(resolve => {
            const interval = setInterval(() => {
              if (!formModel.site.file) return;
              let file = formModel.site.file;

              let maxAllowedSize = 20 * 1024 * 1024; // 1Mb = 1024 * 1024
              if(toRaw(file).sourceFile.size > maxAllowedSize) { // si file supérieur à 20Mb
                formModel.site.file = null;
                app.appContext.config.globalProperties.$toast.show('Erreur, La taille maximale d\'un fichier est de 20 MB!', {type: 'error'});
              }else{
                //console.log(file.sourceFile);
                toBase64(file.sourceFile).then((response) => {
                  formModel.site.logoSite = response
                  document.querySelector(".q-upload-drop-zone").className = "q-upload-drop-zone w-100"
                })

                if (formModel.site.file.loading === null) formModel.site.file.loading = 0;
                formModel.site.file.loading += 10;
              }
            }, 100);

            setTimeout(() => {
              clearInterval(interval);
              if (formModel.site.file) formModel.site.file.loading = null;
              resolve();
            }, 1000);
          });

      await promise();
    };

    const handleAbort = () => {
      console.log('abort uploading');
    };

    const handleClearDoc = () => {
      formModel.documents.file = null;
      formModel.documents.pdf = null;
    };

    const handleClearPhoto = () => {
      formModel.photos.file = null;
      formModel.photos.img = null;
    };

    const handleClearVideo = () => {
      formModel.videos.file = null;
      formModel.videos.sourceVideo = null;
    };

    const handleClearSite = () => {
      formModel.site.file = null;
      formModel.site.logoSite = null;
    };

    /**
     * Valider le formule est par rapport règle et enregistrer dans stockage
     * @return {Promise<void>}
     */
    let handleSubmitClick = async () => {
      await form?.value?.validate().then((valid) => {
        if (valid) {
          let { isValid, invalidFields } = valid;

          console.log('QForm | validate', isValid, invalidFields);
          if (isValid) {
            if(route.params.nom === "texte"){
              axios
                  .post(app.appContext.config.globalProperties.$apiUrl + '/card/components/', {
                    component: "BioComponent",
                    title: formModel.texte.section,
                    description: formModel.texte.description,
                    page_profile: store.state.user.id_pageprofile
                  }, { headers: { Authorization: store.state.user.token } })
                  .then((response) => {
                    app.appContext.config.globalProperties.$toast.show(
                        'Votre composant a été crée avec succès !',
                        {type: 'success'}
                    );
                    console.log(response.data);
                    router.push('/profil/user')
                  })
                  .catch((error) => {
                    app.appContext.config.globalProperties.$toast.show(error.message, {type: 'error'});
                    console.log("error", error);
                  })

            }else if (route.params.nom === "documents"){

              axios
                  .post(app.appContext.config.globalProperties.$apiUrl + '/card/components/', {
                    component: "TelechargeComponent",
                    title: formModel.documents.section,
                    subtitle: formModel.documents.subtitle,
                    link: formModel.documents.link,
                    sourceFile: formModel.documents.pdf,
                    page_profile: store.state.user.id_pageprofile
                  }, { headers: { Authorization: store.state.user.token } })
                  .then((response) => {
                    app.appContext.config.globalProperties.$toast.show(
                        'Votre composant a été crée avec succès !',
                        {type: 'success'}
                    );
                    console.log(response.data);
                    router.push('/profil/user')
                  })
                  .catch((error) => {
                    app.appContext.config.globalProperties.$toast.show(error.message, {type: 'error'});
                    console.log("error", error);
                  })

            }else if(route.params.nom === "photos"){

              axios
                  .post(app.appContext.config.globalProperties.$apiUrl + '/card/components/', {
                    component: "PhotosComponent",
                    title: formModel.photos.section,
                    datas: [
                      {
                        id: 1,
                        link: formModel.photos.link,
                        img: formModel.photos.img,
                      },
                    ],
                    page_profile: store.state.user.id_pageprofile
                  }, { headers: { Authorization: store.state.user.token } })
                  .then((response) => {
                    app.appContext.config.globalProperties.$toast.show(
                        'Votre composant a été crée avec succès !',
                        {type: 'success'}
                    );
                    console.log(response.data);
                    router.push('/profil/user')
                  })
                  .catch((error) => {
                    app.appContext.config.globalProperties.$toast.show(error.message, {type: 'error'});
                    console.log("error", error);
                  })

              console.log("photos")
            }else if(route.params.nom === "videos"){
              if((formModel.videos.player === "Youtube") || (formModel.videos.player === "Vimeo")){

                axios
                    .post(app.appContext.config.globalProperties.$apiUrl + '/card/components/', {
                      component: "VideoComponent",
                      title: formModel.videos.section,
                      player: formModel.videos.player,
                      videoId: formModel.videos.videoId,
                      page_profile: store.state.user.id_pageprofile
                    }, { headers: { Authorization: store.state.user.token } })
                    .then(() => {
                      app.appContext.config.globalProperties.$toast.show(
                          'Votre composant a été crée avec succès !',
                          {type: 'success'}
                      );
                      router.push('/profil/user')
                    })
                    .catch((error) => {
                      app.appContext.config.globalProperties.$toast.show(error.message, {type: 'error'});
                      console.log("error", error);
                    })

              }else if(formModel.videos.player === "UploadLocal"){
                axios
                    .post(app.appContext.config.globalProperties.$apiUrl + '/card/components/', {
                      component: "VideoComponent",
                      title: formModel.videos.section,
                      player: formModel.videos.player,
                      sourceVideo: formModel.videos.sourceVideo,
                      sourceMiniature: sourceMiniature,
                      page_profile: store.state.user.id_pageprofile
                    }, { headers: { Authorization: store.state.user.token } })
                    .then(() => {
                      app.appContext.config.globalProperties.$toast.show(
                          'Votre composant a été crée avec succès !',
                          {type: 'success'}
                      );
                      router.push('/profil/user')
                    })
                    .catch((error) => {
                      app.appContext.config.globalProperties.$toast.show(error.message, {type: 'error'});
                      console.log("error", error);
                    })
              }

            }else if (route.params.nom === "site"){

              axios
                  .post(app.appContext.config.globalProperties.$apiUrl + '/card/components/', {
                    component: "SiteComponent",
                    title: formModel.site.section,
                    subtitle: formModel.site.titre,
                    link: formModel.site.url,
                    img: formModel.site.logoSite,
                    page_profile: store.state.user.id_pageprofile
                  }, { headers: { Authorization: store.state.user.token } })
                  .then((response) => {
                    app.appContext.config.globalProperties.$toast.show(
                        'Votre composant a été crée avec succès !',
                        {type: 'success'}
                    );
                    console.log(response.data);
                    router.push('/profil/user')
                  })
                  .catch((error) => {
                    app.appContext.config.globalProperties.$toast.show(error.message, {type: 'error'});
                    console.log("error", error);
                  })

            }else if(route.params.nom === "calendly"){
              console.log("calendly")
            }else if(route.params.nom === "application"){
              console.log("application")
            }else if(route.params.nom === "google"){
              console.log("google")
            }else if(route.params.nom === "social"){

              axios
                  .post(app.appContext.config.globalProperties.$apiUrl + '/card/components/', {
                    component: "ReseauxSociauxComponent",
                    title: formModel.social.section,
                    datas: formModel.social.datas,
                    page_profile: store.state.user.id_pageprofile
                  }, { headers: { Authorization: store.state.user.token }})

                  .then(response => {
                    app.appContext.config.globalProperties.$toast.show(
                        'Votre modification a été enregistré avec succès !',
                        {type: 'success'}
                    );
                    console.log(response.data);
                    router.push('/profil/user')
                  })

                  .catch(error => {
                    app.appContext.config.globalProperties.$toast.show(error.message, {type: 'error'});
                    console.log(error.message)
                  })

            }else if(route.params.nom === "contact"){
              axios
                  .post(app.appContext.config.globalProperties.$apiUrl + '/card/components/', {
                    component: "ContactComponent",
                    title: formModel.contact.section,
                    datas: [
                      {
                        id: 1,
                        type: "appel",
                        lien: formModel.contact.appel
                      },
                      {
                        id: 2,
                        type: "message",
                        lien: formModel.contact.message
                      },
                      {
                        id: 3,
                        type: "mail",
                        lien: formModel.contact.mail
                      }
                    ],
                    page_profile: store.state.user.id_pageprofile
                  }, { headers: { Authorization: store.state.user.token } })
                  .then((response) => {
                    app.appContext.config.globalProperties.$toast.show(
                        'Votre composant a été crée avec succès !',
                        {type: 'success'}
                    );
                    console.log(response.data);
                    router.push('/profil/user')
                  })
                  .catch((error) => {
                    app.appContext.config.globalProperties.$toast.show(error.message, {type: 'error'});
                    console.log("error", error);
                  })
            }
          }
        }
      })
    };

    return {
      rul,
      form,
      value,
      options,
      formModel,
      activeNames,
      handleAbort,
      changeOption,
      handleSubmitClick,
      handleClearDoc,
      handleClearPhoto,
      handleClearVideo,
      handleClearSite,
      handleFileSelectDoc,
      handleFileSelectPhoto,
      handleFileSelectVideo,
      handleFileSelectSite,
    };
  },

  data() {
    return {

    }
  },

  methods : {
    async uploadImage(event) {
      if (!event) return;
      let file = event.target.files[0];
      let maxAllowedSize = 20 * 1024 * 1024; // 1Mb = 1024 * 1024
      if(file.size > maxAllowedSize) { // si file supérieur à 20Mb
        event.target.value = "";
        this.$toast.show('Erreur, La taille maximale d\'un fichier est de 20 MB!', {type: 'error'});
      }else{
        event.target.value = "";
        this.uploadedImage = await this.toBase64(file);
        sourceMiniature = await this.toBase64(file);
        //console.log(this.uploadedImage)
        this.$toast.show('Votre image a été uploadé avec succès !', {type: 'success'});
      }
    },

    /**
     * se transformer en format base 64
     * @param file
     * @return {Promise<unknown>}
     */
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },

    /**
     * uploader une image
     */
    onUploaded(){
      this.$refs.imageField.click();
    },
  },

  computed: {

  },

  mounted() {
    document.querySelector("#app").style.overflow = "unset"
  }

}
</script>

<style scoped>
.q-switch{
  --switch-main-color: #FF9000 !important;
}

.q-upload, .q-upload-drop-zone{
  width: 100%;
  height: 300px;
  --q-upload-size: 100%;
}

.margin-top{
  margin-top: 120px;
}

.text-color-blue{
  color: #92B2FF;
}
</style>