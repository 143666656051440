<template>
  <base-popup>

    <template v-slot:svg-left>
      <SVGFeature />
    </template>

    <template v-slot:svg-right>
      <span class="row align-items-center" v-on:click="cancel">
        <SVGClose />
      </span>
    </template>

    <template v-slot:title>
      Do you want to create a new sticker ?
    </template>

    <template v-slot:body>
      <color-picker
          :is-big-picker="false"
          :colorDefault="color"
          @onPick="(pickedColor) => {
            this.color = pickedColor
          }"
      ></color-picker>

      <div class="left-inner-addon input-container margin-top-1x">
        <i class="icon">
          <SVGText :fill-color="'#9CA3AF'"/>
        </i>
        <input v-model="name" type="text" class="form-control text-description" placeholder="Name" />
      </div>
    </template>

    <template v-slot:button-bar>
      <q-button
          v-on:click="add($emit)"
          theme="secondary"
          class="btn btn-save p-0 mx-0 mt-0 margin-bottom-0-5x w-100 bg-blue"
      >
          <span class="text-white">
            Create this sticker
          </span>
      </q-button>
    </template>

  </base-popup>

</template>

<script>
import { inject } from 'vue';
import BasePopup from "./BasePopup"
import { QMessageBoxAction, QButton } from '@qvant/qui-max';
import SVGText from "../../../../assets/svgs/app/svg.text-small";
import SVGClose from "../../../../assets/svgs/app/svg.close-popup";
import SVGFeature from "../../../../assets/svgs/app/svg.featured-icon";
import ColorPicker from "../../Components/Pickers/ColorPicker";

let qMessageBoxContainer = undefined;

export default {

  props: [ 'tags' ],

  components: {
    BasePopup,
    SVGText,
    SVGClose,
    SVGFeature,
    ColorPicker,
    QButton,
  },

  data() {
    return {
      name: null,
      color: '#FFC266',
    }
  },

  setup: () => {
    qMessageBoxContainer = inject(
        'qMessageBoxContainer'
    );

    return {
      qMessageBoxContainer,
    }
  },

  methods : {

    cancel: () => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.cancel
      });
    },

    add(emit){
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.confirm
      });
      let newTag = []
      newTag.push({
        label: this.name,
        color: this.color.slice(1),
      })
      emit("add", newTag[0]);
    }
  },

}
</script>

<style scoped>
.btn{
  height: var(--size-3x);
}

.bg-blue {
  background-color: #0400BD;
}

.text-description{
  line-height: 150%;
  font-weight: 300;
  font-family: "Lexend";
}

.form-control{
  font-size: var(--size-1x)!important;
}

</style>