<template>
  <svg width="32" height="33" viewBox="0 0 32 33" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.0001 30.8334H12.0001C4.76008 30.8334 1.66675 27.7401 1.66675 20.5001V12.5001C1.66675 5.26008 4.76008 2.16675 12.0001 2.16675H18.6667C19.2134 2.16675 19.6667 2.62008 19.6667 3.16675C19.6667 3.71341 19.2134 4.16675 18.6667 4.16675H12.0001C5.85341 4.16675 3.66675 6.35341 3.66675 12.5001V20.5001C3.66675 26.6467 5.85341 28.8334 12.0001 28.8334H20.0001C26.1467 28.8334 28.3334 26.6467 28.3334 20.5001V13.8334C28.3334 13.2867 28.7867 12.8334 29.3334 12.8334C29.8801 12.8334 30.3334 13.2867 30.3334 13.8334V20.5001C30.3334 27.7401 27.2401 30.8334 20.0001 30.8334Z" />
    <path d="M29.3334 14.8334H24.0001C19.4401 14.8334 17.6667 13.0601 17.6667 8.50007V3.16673C17.6667 2.76673 17.9067 2.3934 18.2801 2.24673C18.6534 2.08673 19.0801 2.18007 19.3734 2.46007L30.0401 13.1267C30.3201 13.4067 30.4134 13.8467 30.2534 14.2201C30.0934 14.5934 29.7334 14.8334 29.3334 14.8334ZM19.6667 5.58007V8.50007C19.6667 11.9401 20.5601 12.8334 24.0001 12.8334H26.9201L19.6667 5.58007Z" />
    <path d="M17.3333 18.8333H9.33325C8.78659 18.8333 8.33325 18.3799 8.33325 17.8333C8.33325 17.2866 8.78659 16.8333 9.33325 16.8333H17.3333C17.8799 16.8333 18.3333 17.2866 18.3333 17.8333C18.3333 18.3799 17.8799 18.8333 17.3333 18.8333Z" />
    <path d="M14.6666 24.1667H9.33325C8.78659 24.1667 8.33325 23.7134 8.33325 23.1667C8.33325 22.6201 8.78659 22.1667 9.33325 22.1667H14.6666C15.2133 22.1667 15.6666 22.6201 15.6666 23.1667C15.6666 23.7134 15.2133 24.1667 14.6666 24.1667Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>