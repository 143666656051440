<template>
  <div class="col-performances text-start">

    <div class="col-global-perform">
      <PerformancesLayout
          :title="'Global performances'"
          :line-datas="globalChartDatas"
          :is-perform-spc="'global'"
      ></PerformancesLayout>
    </div>

    <div class="col-team-perform">
      <PerformancesLayout
          :title="'Team Performances'"
          :template-role="template_role1"
          :line-datas="teamPerformDatas"
          :is-perform-spc="'team'"
      >
        <template v-slot:btnSelector>
          <q-form-item label="Team" prop="template" class="text-start padding-top">
            <team-template-selector
                :options="options_team_template"
                :value="options_team_template[0]"
                v-on:changeOption="selectTemplateFrame1">

            </team-template-selector>
          </q-form-item>
        </template>
      </PerformancesLayout>
    </div>

    <div class="col-section-perform">
      <PerformancesLayout
          :title="'Section Performances'"
          :template-role="template_role2"
          :line-datas="sectionPerformDatas"
          :is-perform-spc="'section'"
      >
        <template v-slot:btnSelector>
          <q-form-item label="Team" class="text-start padding-top">
            <section-template-selector
                :options="options_section_template"
                :value="options_section_template[0]"
                v-on:changeOption="selectTemplateFrame2">

            </section-template-selector>
          </q-form-item>

          <q-form-item label="Sections" class="text-start">
            <section-template-selector
                :options="options_section_section"
                :value="options_section_section[0]"
                v-on:changeOption="selectTemplateFrame3">

            </section-template-selector>
          </q-form-item>
        </template>
      </PerformancesLayout>
    </div>

  </div>
</template>

<script>
import TeamTemplateSelector from "../Performance/layout.buttonselector";
import PerformancesLayout from "../Performance/layout.performance";
import SectionTemplateSelector from "../Performance/layout.buttonselector";
import StatisticsService from "../../../services/management/StatisticsService";
import TemplateService from "../../../services/management/TemplateService";

export default {

  components: {
    TeamTemplateSelector,
    PerformancesLayout,
    SectionTemplateSelector
  },

  data() {
    return {
      template_role1: 0, // role of selector template of chart Team Performances
      template_role2: 0, // role of selector template of chart Section Performances
      template_role3: 0, // role of selector section of chart Section Performances

      options_team_template: [],
      options_section_template: [],
      options_section_section: [],

      globalChartDatas: [],
      teamPerformDatas: [],
      sectionPerformDatas: [],
    };
  },

  methods : {

    /**
     * Selector template of Chart Team Performances
     * @param keyOption
     */
    selectTemplateFrame1(keyOption){
      this.template_role1 = keyOption;
      console.log(this.template_role1)
      StatisticsService.getStatistics().then((response) => {
        this.teamPerformDatas = response.team_views_chart.find(team => team.template == this.options_team_template[this.template_role1])
        this.getTemplateColor(0, this.teamPerformDatas.template)
      })
          .catch((error) => {
          console.log("error", error);
        })
    },

    /**
     * Selector template of Chart Section Performances
     * @param keyOption
     */
    selectTemplateFrame2(keyOption){
      this.template_role2 = keyOption;
      console.log(this.template_role2)
      StatisticsService.getStatistics().then((response) => {
        this.sectionPerformDatas = response.section_views_chart.find(team => team.template == this.options_section_template[this.template_role2])
        this.getTemplateColor(1, this.sectionPerformDatas.template)
      })
          .catch((error) => {
            console.log("error", error);
          })
    },

    /**
     * Selector section of Chart Section Performances
     * @param keyOption
     */
    selectTemplateFrame3(keyOption){
      this.template_role3 = keyOption;
      console.log(this.template_role3)
      StatisticsService.getStatistics().then((response) => {
        this.sectionPerformDatas = response.section_views_chart.find(team => team.template == this.options_section_template[this.template_role2])
        //console.log(this.sectionPerformDatas.sections.find(component => component.component == this.options_section_section[this.template_role3]))
        this.sectionPerformDatas = this.sectionPerformDatas.sections.find(component => component.component == this.options_section_section[this.template_role3])
        console.log(this.sectionPerformDatas)
      })
          .catch((error) => {
            console.log("error", error);
          })
    },

    getGlobalViewsChart(){
      StatisticsService.getStatistics()
          .then((response) => {
            this.globalChartDatas = response.global_views_chart
            console.log(this.globalChartDatas)
          })
          .catch((error) => {
            console.log("error", error);
          })
    },

    getTeamViewsChart(){
      StatisticsService.getStatistics()
          .then((response) => {
            this.teamPerformDatas = response.team_views_chart
            response.team_views_chart.forEach((item) => {
              console.log(item.template)
              this.options_team_template.push(item.template)
            })
            // initialisation selector
            this.teamPerformDatas = response.team_views_chart.find(team => team.template == this.options_team_template[0])

          })
          .catch((error) => {
            console.log("error", error);
          })
    },

    getSectionViewsChart(){
      StatisticsService.getStatistics()
          .then((response) => {
            this.sectionPerformDatas = response.section_views_chart
            let tmpSections
            response.section_views_chart.forEach((item) => {
              tmpSections = item.sections
              this.options_section_template.push(item.template)
            })
            tmpSections.forEach((section) => {
              this.options_section_section.push(section.component)
            })
            // initialisation selector
            this.sectionPerformDatas = response.section_views_chart.find(team => team.template == this.options_section_template[0])
            this.sectionPerformDatas = this.sectionPerformDatas.sections.find(component => component.component == this.options_section_section[0])
            console.log(this.sectionPerformDatas)
          })
          .catch((error) => {
            console.log("error", error);
          })
    },

    getTemplateColor(order, templateChosen){
      TemplateService.getTemplates()
          .then((response) => {
            response.forEach((item) => {
              console.log(item.label == templateChosen)
              if(item.label == templateChosen){
                let value = "color: #" + item.color + " !important;"
                document.querySelectorAll(".q-input__inner")[order].setAttribute("style", value)
              }else{
                console.log("not found")
              }
            })
          })
    }

  },


  mounted() {
    this.getGlobalViewsChart()
    this.getTeamViewsChart()
    this.getSectionViewsChart()
    this.getTemplateColor()
  },

}
</script>

<style scoped>

.col-performances{
  width: calc(53% - calc((36 / 1728) * (100vw - var(--size-2x))));
  height: fit-content;
}

.padding-top{
  padding-top: calc((36 / 1728) * (100vw - var(--size-2x)));
}

.col-global-perform, .col-team-perform, .col-section-perform{
  margin-bottom: calc((36 / 1728) * (100vw - var(--size-2x)));
  padding: calc((36 / 1728) * (100vw - var(--size-2x)));
  background: #FFFFFF;
  border-radius: 10px;
}
</style>