<template>
  <div class="input-container form-control p-0 margin-bottom-1x">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center text-description text-black h-100">

      <span class="text-center padding-right-1x" v-bind:class="this.hasSvg?'':'row align-items-center padding-left-1x'">
        <span class="padding-left-1x">
          <template v-if="this.hasSvg">
            <slot name="svg"></slot>
          </template>
          <template v-else>
            <template v-if="this.isEllipsis">
              <p class="overflow-ellipsis h-100 m-0 text-start">
                <slot name="svg"></slot>
              </p>
            </template>

            <template v-else>
              <span class="overflow-ellipsis h-100 m-0 text-start">
                <slot name="svg"></slot>
              </span>
            </template>
          </template>
        </span>
      </span>

      <span class="row align-items-center flex-grow-1">
        <template v-if="this.hasSvg">
          <p class="overflow-ellipsis h-100 m-0 p-0 text-start">
            <slot name="label"></slot>
          </p>
        </template>
        <template v-else>
          <span class="label text-description">
            <slot name="label"></slot>
          </span>
        </template>
      </span>

      <span class="padding-left-1x padding-right-1x">
        <slot name="buttonbar"></slot>
      </span>
    </div>
  </div>
</template>

<script>


export default {

  props: [ 'hasSvg', 'isEllipsis' ],

  components: {

  },

  methods : {

  },

  mounted(){

  }

}
</script>

<style scoped>
.text-description{
  font-size: calc((16/1728) * (100vw - var(--size-2x)))!important;
  line-height: 160%;
  font-weight: 400;
  font-family: "Lexend";
  color: #000000;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}
</style>