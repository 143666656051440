import axios from "axios";
import store from "../../store";

let workers = null

export const getWorkers = () => {
    return new Promise((resolve, reject) => {
        if (workers==null) {
            return axios.get(process.env.VUE_APP_ENV_API_URL + '/card/management/managed-users/',
                { headers: { Authorization: store.state.user.token } }
            )
            .then((response) => {
                workers = response.data
                resolve(workers);
            })
            .catch((error) => {
                reject(error);
            })
        }
        else {
            resolve(workers);
        }
    });
}

export const createWorker = (payload) => {
    return new Promise((resolve, reject) => {
        return axios.post(process.env.VUE_APP_ENV_API_URL + '/card/management/create-user/',
            payload,
            { headers: { Authorization: store.state.user.token } }
        )
        .then((response) => {
            workers = null;
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export const updateWorker = (userId, payload) => {
    return new Promise((resolve, reject) => {
        return axios.post(process.env.VUE_APP_ENV_API_URL + '/card/management/update-user/' + Number(userId).toString() + '/',
            payload,
            { headers: { Authorization: store.state.user.token } }
        )
        .then((response) => {
            workers = null;
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export const deleteWorker = (userId) => {
    return new Promise((resolve, reject) => {
        return axios.delete(process.env.VUE_APP_ENV_API_URL + '/card/management/delete-user/' + Number(userId).toString() + '/',
            { headers: { Authorization: store.state.user.token } }
        )
        .then((response) => {
            workers = null;
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}