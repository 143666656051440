import axios from "axios";
import store from "../../store";

let templates = null;

export const getTemplates = () => {
    return new Promise((resolve, reject) => {
        if (templates==null) {
            return axios.get(process.env.VUE_APP_ENV_API_URL + '/card/management/templates/',
                { headers: { Authorization: store.state.user.token } }
            )
            .then((response) => {
                templates = response.data
                resolve(templates);
            })
            .catch((error) => {
                reject(error);
            })
        }
        else {
            resolve(templates);
        }
    });
}

export const createTemplate = (payload) => {
    return new Promise((resolve, reject) => {
        return axios.post(process.env.VUE_APP_ENV_API_URL + '/card/management/create-template/',
            payload,
            { headers: { Authorization: store.state.user.token } }
        )
        .then((response) => {
            templates = null;
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export const deleteTemplate = (templateId) => {
    return new Promise((resolve, reject) => {
        return axios.delete(process.env.VUE_APP_ENV_API_URL + '/card/management/delete-template/' + Number(templateId).toString() + '/',
            { headers: { Authorization: store.state.user.token } }
        )
        .then((response) => {
            templates = null;
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export const updateTemplate = (templateId, payload) => {
    return new Promise((resolve, reject) => {
        return axios.post(process.env.VUE_APP_ENV_API_URL + '/card/management/update-template/' + templateId + '/',
            payload,
            { headers: { Authorization: store.state.user.token } }
        )
        .then((response) => {
            templates = null;
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export default { getTemplates, createTemplate, deleteTemplate, updateTemplate };