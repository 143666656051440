export default {
    grid: {
        top: '5%',
        left: '7%',
        right: 0,
        bottom: '10%',
        containLabel: false
    },
    xAxis: {
        type: "category",
        axisTick: { show: false },
        axisLine: { show: false }
    },
    yAxis: { type: "value" },
    tooltip: { trigger: "item" },
    series: [
        {
            name: "Vues",
            type: "bar",
            itemStyle: {color: '#7000FF'},
            markPoint: {
                data: [
                    {
                        name: "Maximum",
                        type: "max",
                    },
                ],
            },
            markLine: {
                data: [
                    {
                        name: "Average",
                        type: "average",
                    },
                ],
                symbol: ["none", "none"],
            },
            barCategoryGap: "40%",
            data: null,
        },
        {
            name: "Leads",
            type: "bar",
            itemStyle: {color: '#FF9100'},
            markPoint: {
                data: [
                    {
                        name: "Maximum",
                        type: "max",
                    },
                ],
            },
            markLine: {
                data: [
                    {
                        name: "Average",
                        type: "average",
                    },
                ],
                symbol: ["none", "none"],
            },
            barCategoryGap: "40%",
            data: null,
        },
    ],
};