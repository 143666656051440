<template>

  <leader-board>
    <template v-slot:title>
      Top 5 Leaderboard
    </template>

    <template v-slot:legendbar>
      <LegendBar class="col-legend-bar" v-on:changeOption="selectTimeFrame" />
    </template>

    <template v-slot:table>
      <Table
          ref="leadsTable"
          :roleTable="'moduleLeaders'"
          :rankings="displayedLeads"
          :isSelecting="isSelecting"
      />
    </template>

  </leader-board>

</template>

<script>
import LeaderBoard from "./layout.base";
import LegendBar from "../LayoutComponent/layout.legendbar";
import Table from "./layout.table"
import StatisticsService from "../../../services/management/StatisticsService";

export default {

  components: {
    LegendBar,
    LeaderBoard,
    Table
  },

  data() {
    return {
      filterFrom: null,
      isSelecting: false,
      teams: [],
    }
  },

  methods : {

    getTopTeams(){
      StatisticsService.getStatistics()
          .then((response) => {
            //Tirer un tableau en fonction du classement
            this.teams = response.team_top;
          })
          .catch((error) => {
            console.log("error", error);
          })
    },

    /**
     * Permet de changer datas par rapport aux choix
     * @param timeframe
     */
    selectTimeFrame(timeframe){
      this.filterFrom = timeframe;
    },

  },

  computed: {
    displayedLeads() {
      let selectedTF = '1D';
      let computedDatas = [];
      Object.keys(StatisticsService.choices).forEach(k => {
        if (StatisticsService.choices[k] == this.filterFrom){
          selectedTF = k;
        }
      });
      this.teams.forEach((team) => {
        computedDatas = [...computedDatas, ...team[selectedTF]]
      })
      computedDatas.sort((i1, i2) => (i2.views) - (i1.views))

      return computedDatas
    },
  },

  created() {
    this.getTopTeams();
  }

}
</script>

<style scoped>
.col-legend-bar{
  padding-top: calc((36 / 1728) * (100vw - var(--size-2x)));
  padding-bottom: calc((36 / 1728) * (100vw - var(--size-2x)));
}
</style>