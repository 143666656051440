<template>
    <div class="padding-top-1x padding-bottom-1x padding-left-1x padding-right-1x">
        <div class="modal-body text-center">
            <svg class="icon" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="4" width="48" height="48" rx="24" fill="#D6D6FF"/>
                <path d="M37 23V33C37 36 35.5 38 32 38H24C20.5 38 19 36 19 33V23C19 20 20.5 18 24 18H32C35.5 18 37 20 37 23Z" stroke="#0400BD" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M30.5 20.5V22.5C30.5 23.6 31.4 24.5 32.5 24.5H34.5" stroke="#0400BD" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M24 29H28" stroke="#0400BD" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M24 33H32" stroke="#0400BD" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F4F3FF" stroke-width="8"/>
            </svg>
            
            <p class="text-black text-bold">
                Would you like to download this document?
            </p>

            <p class="text-muted">
                {{ filename }}
            </p>

            <div class="d-flex justify-content-between">
                <q-button
                    v-on:click="cancel"
                    theme="secondary"
                    class="btn p-0 mt-0 mb-0 margin-right-0-5x w-100 cancel-btn"
                >
                    <span class="">
                        Cancel
                    </span>
                </q-button>

                <q-button
                    v-on:click="download($emit)"
                    theme="secondary"
                    class="btn p-0 mt-0 mb-0 margin-left-0-5x w-100 bg-blue"
                >
                    <span class="text-white">
                        Download
                    </span>
                </q-button>
            </div>
        </div>
    </div>
</template>

<script>
import { inject } from 'vue';
import { QMessageBoxAction } from '@qvant/qui-max';

let qMessageBoxContainer = undefined;

export default {
    name: "popup.download",

    props: [ 'filename' ],

    setup: () => {
        qMessageBoxContainer = inject(
            'qMessageBoxContainer'
        );

        return {
            qMessageBoxContainer,
        }
    },

    methods : {
        cancel: () => {
            qMessageBoxContainer?.emitDoneEvent({
                action: QMessageBoxAction.cancel
            });
        },
        download: (emit) => {
            qMessageBoxContainer?.emitDoneEvent({
                action: QMessageBoxAction.cancel
            });
            emit("download");
        }
    },
}
</script>

<style scoped>
.cancel-btn {
    border: 1px solid #D1D5DB;
}

.btn{
    width: calc(100vw - var(--size-5x));
    height: var(--size-3x);
    align-items: center;
    justify-content: center;
    display: flex;
}

.text-muted {
    font-size: var(--size-0-5x);
}

.margin-right-0-5x {
    margin-right: var(--size-0-5x);
}

.margin-left-0-5x {
    margin-left: var(--size-0-5x);
}
</style>