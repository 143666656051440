<template>
  <table class="table table-striped table-hover table-sm text-start">
    <tbody class="classement">
    <tr
        v-for="(classement, index) in this.rankings"
        :key="index"
        class="d-flex justify-content-between align-items-center border-radius-10px"
    >
      <td class="text-team"
          v-if="index < 4"
          :style="index == 0 ? 'color: #FFC266;' : index == 1 ? 'color: #9CA3AF;' : index == 2 ? 'color: #FF6B00' : 'color: #111827;' ">
        {{ index + 1 }}
      </td>
      <td v-else class="rank-black text-team ">{{ index + 1 }}</td>

      <td class="text-team overflow-ellipsis">
        <img :src="classement.avatar" alt="avatar" class="rounded-circle me-2 img-avatar">
        {{ classement.full_name }}
      </td>
      <td class="text-team">{{ classement.views }} views</td>
      <td class="text-team">{{ classement.leads }} leads</td>

    </tr>
    </tbody>
  </table>
</template>

<script>
export default {

  props: ["rankings", "roleTable"],

  mounted() {
    console.log(this.rankings)
  }

}
</script>

<style scoped>

tr:nth-child(odd) td:first-child { border-top-left-radius: 10px; }
tr:nth-child(odd) td:first-child { border-bottom-left-radius: 10px; }

tr:nth-child(odd) td:last-child { border-top-right-radius: 10px; }
tr:nth-child(odd) td:last-child { border-bottom-right-radius: 10px; }

tbody tr:nth-child(odd){
  background: #F4F3FF;
}

.table > :not(caption) > * > * {
  box-shadow: unset!important;
  border-bottom: unset!important;
}

tbody tr{
  border: 0 solid var(--color-white);
}

.classement tr td:nth-child(4),.classement tr td:nth-child(3){
  color: #0400BD;
}

.rank-black{
  color: #111827;
}

.border-radius-10px{
  border-radius: 10px;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}

td:nth-child(2) {
  width: calc((100 / 791) * (100vw - var(--size-2x)));
  white-space: nowrap;
  overflow: hidden;
}

.text-team{
  font-family: "Poppins";
  font-size: calc((16 / 1310) * (100vw - var(--size-2x)));
  line-height: 170%;
  font-weight: 500;
  padding: var(--size-1x);
}

.img-avatar{
  width: calc((32 / 1728) * (100vw - var(--size-2x)));
  height: calc((32 / 1728) * (100vw - var(--size-2x)));
}
</style>