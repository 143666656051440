<template>
  <div
      :class="isActive ? 'active link-active' : 'link-disable'"
      class="link-disable label-crm d-flex justify-content-between align-items-center"
  >
    <v-switch :case="type">

      <template #Salesforce>
        <div class="row text-params align-items-center">
          <div class="col-4 img-salesforce crm-icon"></div>
          <div class="col">{{ label }}</div>
        </div>
      </template>

      <template #Hubspot>
        <div class="row text-params align-items-center">
          <div class="col-4 img-hubspot crm-icon"></div>
          <div class="col">{{ label }}</div>
        </div>
      </template>

      <template #Google>
        <div class="row text-params align-items-center">
          <div class="col-4 img-google-contact crm-icon"></div>
          <div class="col">{{ label }}</div>
        </div>
      </template>

      <template #Microsoft>
        <div class="row text-params align-items-center">
          <div class="col-4 img-microsoft-contact crm-icon"></div>
          <div class="col">{{ label }}</div>
        </div>
      </template>

    </v-switch>

    <div v-if="isShowSwitch">
      <q-switch
          v-model="crm"
          :loading="loading"
          @change="changeSwitch"/>
    </div>
  </div>
</template>

<script>
import { QSwitch } from '@qvant/qui-max';
import VSwitch from '@lmiller1990/v-switch';
import { ref } from "vue";

export default {

  props: [ 'type', 'label', 'isShowSwitch', 'isActive' ],

  components: {
    QSwitch,
    VSwitch,
  },

  data() {
    return {

    }
  },

  setup() {
    const crm = ref(true);
    const loading = ref(false);

    const changeSwitch = () => {
      loading.value = true;

      // async request
      setTimeout(() => {
        loading.value = false;
      }, 0);
    };

    return {
      crm: crm,
      loading: loading,
      changeSwitch,
    };
  },


  methods : {

  },


  mounted() {

  }

}
</script>

<style scoped>

.label-crm{
  border: 1px solid #EEEFF2;
  border-radius: 8px;
  padding: var(--size-0-75x) var(--size-1x);
  margin-top: var(--size-1x);
  margin-bottom: var(--size-1x);

}

.label-crm:nth-child(5){
  margin-bottom: var(--size-2x);
}

.crm-icon{
  width: calc((32 / 1310) * (100vw - var(--size-2x)));
  height: calc((32 / 1310) * (100vw - var(--size-2x)));
  margin-left: var(--size-0-75x);
}

.active.link-active {
  background: #F4F3FF!important;
  border: 1px solid #0400BD;
  border-radius: 8px;
}

.link-disable{
  background: #FFFFFF!important;
}

.img-salesforce{
  background: url(../../../assets/images/salesforce.png), 50% / cover no-repeat;
  width: 33px;
  height: 23px;
}

.img-hubspot{
  background: url(../../../assets/images/hubspot.png), 50% / cover no-repeat;
  width: 33px;
  height: 23px;
}

.img-google-contact{
  background: url(../../../assets/images/google-contact.png), 50% / cover no-repeat;
  width: 33px;
  height: 23px;
}


.img-microsoft-contact{
  background: url(../../../assets/images/microsoft-contact.png), 50% / cover no-repeat;
  width: 33px;
  height: 23px;
}
</style>