<template>

  <architecture-base>

    <template v-slot:nav>

      <!-- Navigation bar -->
      <Sidebar active="template" />
    </template>

    <template v-slot:header>
      <!-- Title -->
      <div class="title">Templates</div>

      <!-- Button tool bar -->
      <button-bar :bg-color2="'bg-blue'" :btn2Click="addCard">

        <template v-slot:valueLabel2>
          <SVGTemplateAdd :fillColor="'#ffffff'"/>
          Create a new template
        </template>

      </button-bar>
    </template>


    <template v-slot:main>
      <div class="d-flex w-auto overflow-hidden">
        <div class="template-list-layout text-start">
          <div class="sub-title-col">Templates ({{ templates?.length ?? 0 }})</div>
          <div class="template-select border-radius-10">
            <div v-for="(template, index) in templates" :key="index"
              class="template-button p-3"
              :class="selectedTemplateId===template.id ? 'template-button-selected' : ''"
              :onClick="() => selectTemplate(template)"
            >
              <q-button
                  class="cta-button shadow-none btn-sticker"
                  :style="{backgroundColor: '#' + template.color}"
              >
                {{ template.label }}
              </q-button>
            </div>
          </div>
        </div>

        <div class="phone-layout bg-white">
          <ProfileEdit
            v-if="!!templates.find(t=>t.id===selectedTemplateId)?.page?.id"
            ref="profileEdit"
            :key="selectedTemplateId"
            :templatePage="templates.find(t=>t.id===selectedTemplateId)?.page?.id"
            @editClicked="editComponent"
          ></ProfileEdit>
        </div>

        <div class="edit-layout bg-white text-start">
          <template v-if="templates.length>0">
            <template v-if="!selectedComponent && !selectingNewComponent && !reorganizingComponents">
              
              <div class="sub-title-col">Informations</div>
              <q-form
                  ref="form"
                  :model="updateTemplateModel"
              >

                <!-- Input Reference number -->
                <!--<q-form-item label="Modify the template name" prop="label" class="text-start my-2">
                  <q-input v-model="updateTemplateModel.label" type="text" class="form-control" placeholder="Enter the new template's name"/>
                </q-form-item>-->

                <q-form-item label="Modify the template name" prop="label" class="text-start">
                  <div class="left-inner-addon">
                    <i class="icon">
                      <SVGText :fill-color="'#9CA3AF'" />
                    </i>
                    <input v-model="updateTemplateModel.label" type="text" class="form-control" placeholder="Enter the new template's name" />
                  </div>
                </q-form-item>

                <!-- Pin Code -->
                <q-form-item label="Choose the sticker color" prop="color" class="text-start my-2">
                  <color-picker
                    :is-big-picker="true"
                    :key="updateTemplateModel?.color"
                    :colorDefault="'#' + updateTemplateModel.color"
                    @onPick="(pickedColor) => {
                      updateTemplateModel.color = pickedColor.slice(1)
                    }"
                  ></color-picker>
                </q-form-item>

              </q-form>
              
              <button-base
                  :textColor="'text-white'"
                  :bgColor="'bg-blue'"
                  class="rounded-btn margin-bottom-0-5x"
                  id="btn-add-section"
                  v-on:click="addComponent"
              >
                <template v-slot:label>
                  <SVGElePlus :fill-color="'white'"/>
                  Add a new section
                </template>
              </button-base>

              <button-base
                  :textColor="'text-blue'"
                  :bgColor="'bg-white'"
                  class="rounded-btn margin-bottom-0-5x border-blue"
                  id="btn-reorganize"
                  v-on:click="reorganize"
              >
                <template v-slot:label>
                  <SVGConvert :fill-color="'#0400BD'"/>
                  Reorganize the sections
                </template>
              </button-base>

              <button-base
                  :textColor="'text-white'"
                  :bgColor="'bg-gray'"
                  class="rounded-btn margin-bottom-0-5x"
                  id="btn-settings"
              >
                <template v-slot:label>
                  <SVGCandle :fill-color="'white'" />
                  Template settings
                </template>
              </button-base>

              <button-base
                  :textColor="'text-white'"
                  :bgColor="'bg-red'"
                  class="rounded-btn"
                  id="btn-del-section"
                v-on:click="deleteTemplate"
              >
                <template v-slot:label>
                  Delete this template
                </template>
              </button-base>
            </template>

            <component v-if="!!selectedComponent && !selectingNewComponent && !reorganizingComponents"
              class="component-window"
              ref="componentEditRef"
              :key="selectedComponent"
              :is="selectedComponent?.component + 'Edit'"
              :componentId="selectedComponent?.id"
              :pageId="templates.find(t=>t.id===selectedTemplateId)?.page?.id"
              @componentSaved="componentSaved"
            ></component>
            
            <template v-if="!selectedComponent && !!selectingNewComponent && !reorganizingComponents">
              <div class="sub-title-col">Select the section that you want to add</div>
              <ComponentList class="component-window"
                :byManager="true"
                @selectComponentToAdd="createComponent"
              ></ComponentList>
            </template>

            <template v-if="!selectedComponent && !selectingNewComponent && !!reorganizingComponents">
              <div class="sub-title-col">Add, modify or remove any contact link</div>
              <ReorganizeComponents class="component-window"
                ref="reorganizeComponentsRef"
                :pageId="templates.find(t=>t.id===selectedTemplateId)?.page?.id"
                :byManager="true"
                @saved="reorganized"
              ></ReorganizeComponents>
            </template>
            
            <button-base
                v-if="!!selectedComponent || !!selectingNewComponent || !!reorganizingComponents"
                :textColor="'text-blue'"
                :bgColor="'bg-white'"
                class="rounded-btn margin-bottom-0-5x"
                v-on:click="back"
                id="btn-back"
            >
              <template v-slot:label>
                Back
              </template>
            </button-base>
            
            <button-base
                :textColor="'text-white'"
                :bgColor="'bg-green'"
                class="rounded-btn"
                id="btn-save"
              v-on:click="save"
            >
              <template v-slot:label>
                <SVGLocation :fill-color="'white'" />
                Save the modifications
              </template>
            </button-base>

            <button-base
                v-if="(selectedComponent?.id > 0) && !selectingNewComponent && !reorganizingComponents"
                :textColor="'text-white'" :bgColor="'bg-red'"
                class="rounded-btn"
                id="btn-delete-section"
                v-on:click="deleteSection"
            >
              <template v-slot:label>
                Delete this section
              </template>
            </button-base>
          </template>
        </div>
      </div>
    </template>

  </architecture-base>

</template>

<script>
import ArchitectureBase from "../LayoutComponent/layout.base";
import ButtonBar from "../LayoutComponent/layout.buttonbar"
import ButtonBase from "../LayoutComponent/layout.button";
import Sidebar from "../LayoutComponent/layout.sidebar.vue";
import templateService from "../../../services/management/TemplateService";

import CreateTemplatePopup from "../LayoutComponent/Popups/CreateTemplatePopup.vue";
import ProfileEdit from "../../app/GestionProfil/profil.edit.client.vue"
import { useMessageBox } from "@qvant/qui-max";
import { deleteComponent } from "../../../services/ComponentService";
import ColorPicker from "../../app/Components/Pickers/ColorPicker.vue";

import SVGTemplateAdd from "../../../assets/svgs/admin/svg.template.add";
import SVGSearch from "../../../assets/svgs/admin/svg.search";
import SVGText from "../../../assets/svgs/admin/svg.template.text";
import SVGElePlus from "../../../assets/svgs/admin/svg.template.element-plus";
import SVGConvert from "../../../assets/svgs/admin/svg.template.convertshape";
import SVGCandle from "../../../assets/svgs/admin/svg.template.candle-2";
import SVGLocation from "../../../assets/svgs/admin/svg.template.location-tick";

import ComponentList from "../../app/GestionProfil/Section/section.liste.vue";
import ReorganizeComponents from "../../app/GestionProfil/Section/section.agencer.vue";

// edit comp
import BioComponentEdit from "../../app/GestionProfil/Texte/texte.modifier.vue";
import ReseauxSociauxComponentEdit from "../../app/GestionProfil/Reseaux/reseaux.modifier.vue";
import PhotosComponentEdit from "../../app/GestionProfil/Photos/photos.modifier.vue";
import VideoComponentEdit from "../../app/GestionProfil/Video/video.modifier.vue";
import TelechargeComponentEdit from "../../app/GestionProfil/Telecharge/telecharge.modifier.vue";
import SiteComponentEdit from "../../app/GestionProfil/Site/site.modifier.vue";
import ContactComponentEdit from "../../app/GestionProfil/Contact/contact.modifier.vue";
import CTAComponentEdit from "../../app/GestionProfil/CTA/cta.modifier.vue";

export default {

  components: {
    Sidebar,
    ButtonBar,
    ButtonBase,
    ArchitectureBase,
    ProfileEdit,
    ColorPicker,

    SVGTemplateAdd,
    SVGSearch,
    SVGText,
    SVGElePlus,
    SVGConvert,
    SVGCandle,
    SVGLocation,

    ComponentList,
    ReorganizeComponents,

    BioComponentEdit,
    ReseauxSociauxComponentEdit,
    PhotosComponentEdit,
    VideoComponentEdit,
    TelechargeComponentEdit,
    SiteComponentEdit,
    ContactComponentEdit,
    CTAComponentEdit,
  },

  data() {
    return {
      templates: [],
      selectedTemplateId: null,
      selectedComponent: null,
      selectingNewComponent: false,
      reorganizingComponents: false,
      updateTemplateModel: {
        label: null,
        color: null,
      },
    };
  },

  methods: {
    async addCard(){
      try {
        let messageBox = useMessageBox();
        const result = await messageBox(
            {
              component: CreateTemplatePopup,
              listeners: {
                createTemplate: (template) => {
                  templateService.createTemplate(template)
                  .then(() => {
                    this.buildTemplates();
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                },
              }
            },
        );
        console.log('resolve', result);
      } catch (result) {
        console.log('reject', result);
      }
    },

    back(){
      this.selectedComponent=null;
      this.selectingNewComponent=false;
      this.reorganizingComponents=false;
    },

    addComponent(){
      this.selectedComponent = null;
      this.selectingNewComponent = true;
      this.reorganizingComponents = false;
    },

    reorganize(){
      this.selectedComponent = null;
      this.selectingNewComponent = false;
      this.reorganizingComponents = true;
    },

    createComponent(type){
      this.selectingNewComponent = false;
      this.reorganizingComponents = false;
      this.selectedComponent = {
        component: type,
        id: 0,
      }
    },

    componentSaved(component){
      this.selectedComponent = component;
      this.$refs.profileEdit.buildPage();
    },

    reorganized(){
      this.$refs.profileEdit.buildPage();
    },

    editComponent(component){
      this.selectedComponent = component;
      this.selectingNewComponent = false;
      this.reorganizingComponents = false;
    },

    selectTemplate(template){
      this.selectedTemplateId = template.id;

      const selectedTemplate = this.templates.find(t=>t.id===this.selectedTemplateId);
      this.updateTemplateModel.color = selectedTemplate?.color;
      this.updateTemplateModel.label = selectedTemplate?.label;
    },

    deleteTemplate(){
      templateService.deleteTemplate(this.selectedTemplateId)
      .then(() => {
        this.buildTemplates();
      })
      .catch((error) => {
        console.log(error);
      });
    },

    buildTemplates(){
      templateService.getTemplates()
      .then((templates) => {
        this.templates = templates;
        this.selectedTemplateId = templates.length > 0 ? templates[0].id : null

        const selectedTemplate = this.templates.find(t=>t.id===this.selectedTemplateId);
        this.updateTemplateModel.color = selectedTemplate?.color;
        this.updateTemplateModel.label = selectedTemplate?.label;
      })
      .catch((error) => {
        console.log(error);
      });
    },

    save(){
      if (this.selectedComponent?.id >= 0) {
        this.saveComponent();
      }
      else if (this.reorganizingComponents) {
        this.$refs.reorganizeComponentsRef.saveEdit();
      }
      else {
        templateService.updateTemplate(this.selectedTemplateId, this.updateTemplateModel)
        .then(() => {
          this.buildTemplates();
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },

    saveComponent(){
      this.$refs.componentEditRef.saveEdit();
    },

    deleteSection(){
      deleteComponent(this.selectedComponent.id)
      .then(() => {
        this.selectedComponent = null;
        this.selectingNewComponent = false;
        this.$refs.profileEdit.buildPage();
      })
      .catch((error) => {
        console.log(error);
      });
    },
  },

  mounted(){
    this.buildTemplates();
  },

}
</script>

<style scoped>

.sub-title-col{
  font-size: calc((26 / 1728) * (100vw - var(--size-2x)));
  line-height: calc((32 / 1728) * (100vw - var(--size-2x)));
  font-weight: 600;
  margin-bottom: var(--size-1x);
}

#btn-save,
#btn-del-section,
#btn-settings,
#btn-reorganize,
#btn-add-section,
#btn-back,
#btn-delete-section{
  width: 100%;
  margin: 0!important;
  margin-bottom: var(--size-0-5x)!important;
}

.text-blue{
  color: #0400BD;
}

.bg-gray{
  background: #D1D5DB;
}

.border-blue{
  border-radius: 28px;
  border: 1px solid #0400BD;
  background: #ffffff;
}

.component-window {
  overflow-y: scroll;
  margin-bottom: 1rem!important;
}

.title{
  font-size: calc((48 / 1728) * (100vw - var(--size-2x)));
  line-height: 100%;
  font-weight: 600;
}

.border-radius-10{
  border-radius: 10px;
}

.template-list-layout {
  width: calc(28% - calc((36 / 1728) * (100vw - var(--size-2x))));
  margin-right: calc((36 / 1728) * (100vw - var(--size-2x)));
  height: fit-content;
  max-height: 100%;
  background: #FFFFFF;
  padding: var(--size-1x) calc((36 / 1728) * (100vw - var(--size-2x)));
  border-radius: 10px;
}

.phone-layout {
  width: calc(40% - calc((36 / 1728) * (100vw - var(--size-2x))));
  margin-right: calc((36 / 1728) * (100vw - var(--size-2x)));
  z-index: 0;
  overflow-y: auto;
  border-radius: 10px;
}

.edit-layout {
  width: 32%;
  height: fit-content;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--size-1x) calc((36 / 1728) * (100vw - var(--size-2x)));
  border-radius: 10px;
}

.template-select {
  background-color: #F4F3FF;
  overflow: hidden;
}

.template-button-selected {
  background-color: #D6D6FF;
}

.template-button:hover {
  background-color: #E6E6FF;
  cursor: pointer;
}

.btn-sticker {
  height: var(--size-2x)!important;
  flex-grow: 0;
}
</style>