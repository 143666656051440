<template>
  <div class="d-flex justify-content-between legend-bar">
    <template v-for="(choice, index) in choices" :key="index">
      <button
          v-on:click="$emit('changeOption', $event)"
          class="btn legend-item choice-disable">
        {{ choice }}
      </button>
    </template>
  </div>
</template>

<script>

export default {

  data(){
    return {
      choices: ['1D', '1W', '1M', '1Y', 'All'],
    }
  },

  methods: {

    /**
     * initialisation choix
     */
    initialize() {
      document.querySelectorAll(".legend-item")[1].className = "btn legend-item choice-enable";
    },

  },

  mounted() {
    //initialisation
    this.initialize();
  },

}
</script>

<style scoped>
.legend-bar {
  gap: 5px;
}

.legend-item{
  border-radius: 28px;
  line-height: 100%;
  font-size: calc((22/390) * (100vw - var(--size-2x)));
  box-shadow: unset;
}

.choice-disable{
  color: #9CA3AF;
}

.choice-enable{
  background: #0400BD;
  color: white;
}

:not(.btn-check) + .btn:hover, .btn:first-child:hover{
  background: #0400BD;
  color: white;
}
</style>