<template>
  <table class="table table-striped table-hover table-sm text-start">
    <tbody class="classement">
    <tr
        v-for="(data, index) in this.datas"
        :key="index"
        class="d-flex justify-content-between align-items-center border-radius-10px"
    >
      <td class="overflow-ellipsis text-product">{{ data.link.split("?")[0].split("https://minio-api.e-lyf.fr/profylead-dev/")[1] }}</td>
      <td class=" text-product text-nowrap">{{ data.clicks + " dwld" }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {

  props: ["datas"],

}
</script>

<style scoped>

tr:nth-child(odd) td:first-child { border-top-left-radius: 10px; }
tr:nth-child(odd) td:first-child { border-bottom-left-radius: 10px; }

tr:nth-child(odd) td:last-child { border-top-right-radius: 10px; }
tr:nth-child(odd) td:last-child { border-bottom-right-radius: 10px; }

tbody tr:nth-child(odd){
  background: #F4F3FF;
}

.table > :not(caption) > * > * {
  box-shadow: unset!important;
  border-bottom: unset!important;
}

.border-radius-10px{
  border-radius: 10px;
}

.classement tr td:nth-child(2){
  color: #0400BD;
}

.text-product{
  font-family: "Poppins";
  font-size: calc((16 / 1310) * (100vw - var(--size-2x)));
  line-height: 170%;
  font-weight: 500;
  padding: var(--size-1x);
}

td:nth-child(1) {
  font-family: "Poppins";
  width: calc((170 / 791) * (100vw - var(--size-2x)));
  white-space: nowrap;
  overflow: hidden;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}
</style>