<template>
  <base-popup>

    <template v-slot:svg-left>
      <SVGLink />
    </template>

    <template v-slot:svg-right>
      <span class="row align-items-center" v-on:click="cancel">
         <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#0400BD"/>
          <path d="M9.16999 15.5799C8.97999 15.5799 8.78999 15.5099 8.63999 15.3599C8.34999 15.0699 8.34999 14.5899 8.63999 14.2999L14.3 8.63986C14.59 8.34986 15.07 8.34986 15.36 8.63986C15.65 8.92986 15.65 9.40986 15.36 9.69986L9.69998 15.3599C9.55998 15.5099 9.35999 15.5799 9.16999 15.5799Z" fill="#0400BD"/>
          <path d="M14.83 15.5799C14.64 15.5799 14.45 15.5099 14.3 15.3599L8.63999 9.69986C8.34999 9.40986 8.34999 8.92986 8.63999 8.63986C8.92999 8.34986 9.40998 8.34986 9.69998 8.63986L15.36 14.2999C15.65 14.5899 15.65 15.0699 15.36 15.3599C15.21 15.5099 15.02 15.5799 14.83 15.5799Z" fill="#0400BD"/>
        </svg>
      </span>
    </template>

    <template v-slot:title>
      Do you want to modify or delete this link ?
    </template>

    <template v-slot:body>

      <div class="left-inner-addon input-container">
        <i class="icon">
          <SVGText :fill-color="'#9CA3AF'"/>
        </i>
        <input v-model="subtitle" type="text" class="form-control text-description" placeholder="Link Title" />
      </div>

      <div class="left-inner-addon input-container margin-bottom-1x">
        <i class="icon">
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.49161 18.1393C6.10828 18.1393 4.71661 17.6143 3.65828 16.5559C1.54994 14.4393 1.54994 11.0059 3.65828 8.89759C3.89994 8.65592 4.29994 8.65592 4.54161 8.89759C4.78328 9.13926 4.78328 9.53926 4.54161 9.78092C2.91661 11.4059 2.91661 14.0476 4.54161 15.6726C6.16661 17.2976 8.80828 17.2976 10.4333 15.6726C11.2166 14.8893 11.6499 13.8393 11.6499 12.7226C11.6499 11.6143 11.2166 10.5643 10.4333 9.77259C10.1916 9.53092 10.1916 9.13092 10.4333 8.88926C10.6749 8.64759 11.0749 8.64759 11.3166 8.88926C12.3416 9.91426 12.8999 11.2726 12.8999 12.7226C12.8999 14.1726 12.3333 15.5309 11.3166 16.5559C10.2666 17.6143 8.88328 18.1393 7.49161 18.1393Z" fill="#9CA3AF"/>
            <path d="M15.8916 12.0227C15.7333 12.0227 15.5749 11.9644 15.4499 11.8394C15.2083 11.5977 15.2083 11.1977 15.4499 10.956C17.1583 9.24769 17.1583 6.47269 15.4499 4.77269C13.7416 3.06436 10.9666 3.06436 9.26661 4.77269C8.44161 5.59769 7.98328 6.69769 7.98328 7.86436C7.98328 9.03102 8.44161 10.131 9.26661 10.956C9.50828 11.1977 9.50828 11.5977 9.26661 11.8394C9.02494 12.081 8.62494 12.081 8.38328 11.8394C7.32494 10.781 6.73328 9.36436 6.73328 7.86436C6.73328 6.36436 7.31661 4.94769 8.38328 3.88936C10.5749 1.69769 14.1416 1.69769 16.3416 3.88936C18.5333 6.08102 18.5333 9.65602 16.3416 11.8477C16.2166 11.9644 16.0499 12.0227 15.8916 12.0227Z" fill="#9CA3AF"/>
          </svg>
        </i>
        <input v-model="link" type="text" class="form-control text-description" placeholder="Link" />
      </div>

    </template>

    <template v-slot:button-bar>
      <q-button
          v-on:click="deleteWeb($emit, this.id)"
          theme="secondary"
          class="btn p-0 mt-0 margin-bottom-0-5x w-100 bg-red"
      >
          <span class="text-white">
            Delete this link
          </span>
      </q-button>

      <q-button
          v-on:click="exchange($emit, this.id, this.link, this.subtitle, this.thumbnail)"
          theme="secondary"
          class="btn btn-save p-0 mx-0 mt-0 margin-bottom-0-5x w-100 bg-blue"
      >
          <span class="text-white">
            Save the modifications
          </span>
      </q-button>
    </template>

  </base-popup>
</template>

<script>
import { inject } from 'vue';
import BasePopup from "./BasePopup"
import { QMessageBoxAction, QButton } from '@qvant/qui-max';
import SVGText from "../../../../assets/svgs/app/svg.text-small";
import SVGLink from "../../../../assets/svgs/app/svg.link-circle";

let qMessageBoxContainer = undefined;

export default {

  props: [ 'website' ],

  components: {
    SVGText,
    BasePopup,
    SVGLink,
    QButton,
  },

  data() {
    return {
      id: null,
      link: null,
      subtitle: null,
      thumbnail: null,
    }
  },

  setup: () => {
    qMessageBoxContainer = inject(
        'qMessageBoxContainer'
    );

    return {
      qMessageBoxContainer,
    }
  },

  methods : {

    cancel: () => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.cancel
      });
    },

    exchange: (emit, id, link, subtitle, thumbnail) => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.confirm
      });

      emit("save", {id, link, subtitle, thumbnail});
    },

    deleteWeb: (emit, id) => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.confirm
      });
      emit("delete", {id});
    }
  },

  mounted(){
    console.log(this.website)
    this.id = this.website.id;
    this.link = this.website.link;
    this.subtitle = this.website.subtitle;
    this.thumbnail = this.website.thumbnail;
  }


}
</script>

<style scoped>
.btn{
  width: calc(100vw - var(--size-5x));
  height: var(--size-3x);
  align-items: center;
  justify-content: center;
  display: flex;
}

.bg-blue {
  background-color: #0400BD;
}

.bg-red{
  background: #EC0000;
}

.btn-save{
  margin-left: 0!important;
}

.text-description{
  line-height: 160%;
  font-weight: 400;
  font-family: "Lexend";
  color: #6B7280;
}
</style>