<template>
  <div class="my-4">
    <div class="row mx-3">

      <div
          v-bind:style="textColor"
          class="col text-start p-0 pb-1 text-black text-size-special-13"
      >
        {{ equipe.title }}
      </div>

      <div class="col text-end p-0 text-size-special-13" :style="onDisplay">
        <router-link
            v-bind:to="'/modifEquipe/' + this.id"
            class="text-orange text-decoration-none"
        >
          Modifier
        </router-link>
        <notification :id="this.id"></notification>
      </div>

    </div>

    <div class="row mx-3">
      <template v-for="(member, index) in equipe.datas" :key="index">
        <div v-bind:class="col">
          <div class="card shadow-sm rounded-top">
            <img v-bind:src="member.avatar" class="card-img-top h-100 rounded-top" alt="">
            <div class="card-body py-1 p-0">
              <p class="card-text text-black text-center m-0" v-bind:style="sizeName">{{ member.prenom }} {{ member.nom }} </p>
              <p class="card-text text-black text-center" v-bind:style="sizePost">{{ member.poste }}</p>
            </div>
          </div>
        </div>
      </template>
    </div>

    <p v-bind:style="textColor" class="text-center m-0 text-black text-voir-plus">Voir tous</p>

  </div>
</template>

<script>
import notification from "../profil.delete.composant";
export default {

  name: "layoutEquipe",

  props: [ 'id', 'textColor', 'equipe', 'disableModif' ],

  components: { notification },

  data() {
    return {
      col: null,
      sizeName: null,
      sizePost: null,
      onDisplay: 'display: block;'
    }
  },

  methods : {

  },

  mounted() {

    if( this.disableModif === true ){
      this.onDisplay = 'display: none;'
    }

    //nombre d'equipe
    let count = this.equipe.datas.length;

    if(count === 4){        // si y'a 5 reseaux sociaux, chaque bulle occupe 20% de largeur
      this.col = "col-3 p-1"
      this.sizeName = "font-size: 8px;"
      this.sizePost = "font-size: 7px;"
    } else if(count === 3){ // si y'a 4 reseaux sociaux, chaque bulle occupe 25% de largeur
      this.col = "col-4 p-1"
      this.sizeName = "font-size: 12px;"
      this.sizePost = "font-size: 10px;"
    }else if(count === 2){  // si y'a 3 reseaux sociaux, chaque bulle occupe 33% de largeur
      this.col = "col-6 p-1"
      this.sizeName = "font-size: 16px;"
      this.sizePost = "font-size: 14px;"
    }else if(count === 1){  // si y'a 2 reseaux sociaux, chaque bulle occupe 50% de largeur
      this.col = "col-12 p-1"
      this.sizeName = "font-size: 32px;"
      this.sizePost = "font-size: 28px;"
    }else if(count === 0){
      console.log("composant vide")
    }
  },
}
</script>

<style scoped>
.text-voir-plus{
  font-size: 11px;
}
</style>