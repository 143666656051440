<template>
  <svg width="21" height="21" viewBox="0 0 19 21" fill="currentColor" class="me-3" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.27195 1.07804C2.38147 0.998464 2.49473 0.925256 2.61243 0.857905C2.65024 0.94211 2.70371 1.02099 2.77286 1.09014C4.11719 2.43441 5.17239 3.48793 6.10256 4.23241C7.04425 4.9861 7.92613 5.48082 8.9422 5.64175C9.56298 5.74007 10.1954 5.74007 10.8162 5.64175C11.8038 5.48532 12.6646 5.01356 13.5768 4.29514C14.4774 3.58588 15.4884 2.58622 16.7611 1.31445C16.8019 1.27365 16.8373 1.22945 16.8672 1.18276C17.2521 1.48256 17.5932 1.83513 17.8803 2.23029C18.875 3.59938 18.875 5.55236 18.875 9.45833V11.5417C18.875 15.4476 18.875 17.4006 17.8803 18.7697C17.559 19.2119 17.1702 19.6007 16.728 19.922C15.359 20.9167 13.406 20.9167 9.5 20.9167C5.59403 20.9167 3.64104 20.9167 2.27195 19.922C1.82979 19.6007 1.44095 19.2119 1.1197 18.7697C0.125 17.4006 0.125 15.4476 0.125 11.5417V9.45833C0.125 5.55236 0.125 3.59938 1.1197 2.23029C1.44095 1.78813 1.82979 1.39929 2.27195 1.07804ZM5.33333 7.63542C4.90186 7.63542 4.55208 7.98519 4.55208 8.41667C4.55208 8.84814 4.90186 9.19792 5.33333 9.19792H13.6667C14.0981 9.19792 14.4479 8.84814 14.4479 8.41667C14.4479 7.98519 14.0981 7.63542 13.6667 7.63542H5.33333ZM5.33333 11.8021C4.90186 11.8021 4.55208 12.1519 4.55208 12.5833C4.55208 13.0148 4.90186 13.3646 5.33333 13.3646H13.6667C14.0981 13.3646 14.4479 13.0148 14.4479 12.5833C14.4479 12.1519 14.0981 11.8021 13.6667 11.8021H5.33333ZM5.33333 15.9688C4.90186 15.9688 4.55208 16.3185 4.55208 16.75C4.55208 17.1815 4.90186 17.5312 5.33333 17.5312H13.6667C14.0981 17.5312 14.4479 17.1815 14.4479 16.75C14.4479 16.3185 14.0981 15.9688 13.6667 15.9688H5.33333Z" :fill="fillTemplate"/>
    <path d="M9.5 0.083334C12.3124 0.083334 14.1123 0.083334 15.4108 0.454659C14.2438 1.61863 13.3708 2.46849 12.6101 3.0676C11.8085 3.6989 11.194 3.99993 10.5717 4.09848C10.1129 4.17116 9.64547 4.17116 9.18663 4.09848C8.54649 3.9971 7.91477 3.68151 7.07892 3.01252C6.30066 2.38963 5.40522 1.51086 4.20479 0.312162C5.4466 0.083334 7.10668 0.083334 9.5 0.083334Z" :fill="fillTemplate"/>
  </svg>
</template>

<script>
export default {
  name: "svg.templates",

  props: ['fillTemplate'],
}
</script>

<style scoped>

</style>