<template>
  <svg viewBox="0 0 24 24" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <rect width="23.9306" height="24" rx="6"/>
    <g clip-path="url(#clip0_697_478)">
      <path d="M4.9856 6.86765C4.9856 6.41719 5.1428 6.04557 5.4572 5.75279C5.7716 5.45999 6.18034 5.3136 6.68338 5.3136C7.17745 5.3136 7.57718 5.45773 7.8826 5.74603C8.197 6.04333 8.35421 6.43071 8.35421 6.90819C8.35421 7.34063 8.2015 7.70098 7.89608 7.98927C7.58168 8.28657 7.16846 8.43522 6.65643 8.43522H6.64295C6.14889 8.43522 5.74915 8.28657 5.44373 7.98927C5.1383 7.69198 4.9856 7.3181 4.9856 6.86765ZM5.16076 18.692V9.66495H8.15209V18.692H5.16076ZM9.80945 18.692H12.8008V13.6514C12.8008 13.3361 12.8367 13.0929 12.9086 12.9217C13.0343 12.6154 13.2252 12.3564 13.4812 12.1447C13.7373 11.933 14.0584 11.8271 14.4447 11.8271C15.4508 11.8271 15.9538 12.5073 15.9538 13.8677V18.692H18.9451V13.5163C18.9451 12.183 18.6307 11.1717 18.0019 10.4825C17.3731 9.79333 16.5422 9.44873 15.5091 9.44873C14.3503 9.44873 13.4476 9.94873 12.8008 10.9487V10.9758H12.7873L12.8008 10.9487V9.66495H9.80945C9.82741 9.95323 9.8364 10.8496 9.8364 12.3541C9.8364 13.8586 9.82741 15.9712 9.80945 18.692Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_697_478">
        <rect width="13.9595" height="14" fill="white" transform="translate(4.9856 5)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>