<template>
  <div v-bind:class="'figure-bar d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center'">

    <!-- total views -->
    <figure-base :bgColor="'bgBlue'">
      <template v-slot:numfigure>
        {{ this.total_views }}
      </template>
      <template v-slot:labelfigure>
        total views
      </template>
    </figure-base>

    <!-- total contacts -->
    <figure-base :bgColor="'bgBlue'">
      <template v-slot:numfigure>
        {{ this.total_contacts }}
      </template>
      <template v-slot:labelfigure>
        total contacts
      </template>
    </figure-base>

    <!-- total leads -->
    <figure-base :bgColor="'bgBlue'">
      <template v-slot:numfigure>
        {{ this.total_leads }}
      </template>
      <template v-slot:labelfigure>
        total leads
      </template>
    </figure-base>

  </div>
</template>

<script>
import FigureBase from "./layout.figure";

export default {

  props: [ 'total_contacts', 'total_leads', 'total_views' ],

  components: {
    FigureBase,
  },

  mounted(){
    console.log(this.total_contacts + " " + this.total_leads + " " + this.total_views)
  }

}
</script>

<style scoped>
.figure-bar{
  padding-top: calc((36 / 1728) * (100vw - var(--size-2x)));
  padding-bottom: calc((36 / 1728) * (100vw - var(--size-2x)));
}
</style>