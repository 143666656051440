<template>
  <div class="margin-bottom-1x">
    <div class="component-card padding-0-5x margin-top-1x text-white d-flex row">
      <div class="col-2 px-0 d-flex justify-content-center">
        <div class="row align-items-center">
          <slot name="icon"></slot>
        </div>
      </div>
      <div class="col row align-items-center">
        <div class="row">
          <slot name="title"></slot>
        </div>
        <div class="row text-description">
          <slot name="description"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  components: {

  },

}
</script>

<style scoped>
.text-description{
  padding-right: 0;
  color: #ffffff!important;
}

.component-card{
  /* height: var(--size-7x); */
  width: 100%;
  margin: 0;
  background: linear-gradient(284.83deg, #0400BD -7.8%, #7000FF 99.63%);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.13), 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
</style>