<template>
  <svg width="32" height="33" viewBox="0 0 32 33" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.0001 30.8334C8.09342 30.8334 1.66675 24.4067 1.66675 16.5001C1.66675 8.59342 8.09342 2.16675 16.0001 2.16675C23.9067 2.16675 30.3334 8.59342 30.3334 16.5001C30.3334 24.4067 23.9067 30.8334 16.0001 30.8334ZM16.0001 4.16675C9.20008 4.16675 3.66675 9.70008 3.66675 16.5001C3.66675 23.3001 9.20008 28.8334 16.0001 28.8334C22.8001 28.8334 28.3334 23.3001 28.3334 16.5001C28.3334 9.70008 22.8001 4.16675 16.0001 4.16675Z" />
    <path d="M12.0001 29.5H10.6667C10.1201 29.5 9.66674 29.0467 9.66674 28.5C9.66674 27.9533 10.0934 27.5133 10.6401 27.5C8.54673 20.3533 8.54673 12.6467 10.6401 5.5C10.0934 5.48667 9.66674 5.04667 9.66674 4.5C9.66674 3.95333 10.1201 3.5 10.6667 3.5H12.0001C12.3201 3.5 12.6267 3.66 12.8134 3.91333C13.0001 4.18 13.0534 4.51333 12.9467 4.82C10.4401 12.3533 10.4401 20.6467 12.9467 28.1933C13.0534 28.5 13.0001 28.8333 12.8134 29.1C12.6267 29.34 12.3201 29.5 12.0001 29.5Z" />
    <path d="M20 29.5001C19.8933 29.5001 19.7866 29.4867 19.68 29.4467C19.16 29.2734 18.8666 28.7001 19.0533 28.1801C21.56 20.6467 21.56 12.3534 19.0533 4.80674C18.88 4.28674 19.16 3.71341 19.68 3.54008C20.2133 3.36674 20.7733 3.64674 20.9466 4.16674C23.6 12.1134 23.6 20.8601 20.9466 28.7934C20.8133 29.2334 20.4133 29.5001 20 29.5001Z" />
    <path d="M16 23.4334C12.28 23.4334 8.57333 22.9134 5 21.8601C4.98667 22.3934 4.54667 22.8334 4 22.8334C3.45333 22.8334 3 22.3801 3 21.8334V20.5001C3 20.1801 3.16 19.8734 3.41333 19.6867C3.68 19.5001 4.01333 19.4467 4.32 19.5534C11.8533 22.0601 20.16 22.0601 27.6933 19.5534C28 19.4467 28.3333 19.5001 28.6 19.6867C28.8667 19.8734 29.0133 20.1801 29.0133 20.5001V21.8334C29.0133 22.3801 28.56 22.8334 28.0133 22.8334C27.4667 22.8334 27.0267 22.4067 27.0133 21.8601C23.4267 22.9134 19.72 23.4334 16 23.4334Z" />
    <path d="M28 13.4999C27.8934 13.4999 27.7867 13.4866 27.68 13.4466C20.1467 10.9399 11.84 10.9399 4.3067 13.4466C3.77337 13.6199 3.21337 13.3399 3.04004 12.8199C2.88004 12.2866 3.16004 11.7266 3.68004 11.5532C11.6267 8.8999 20.3734 8.8999 28.3067 11.5532C28.8267 11.7266 29.12 12.2999 28.9334 12.8199C28.8134 13.2332 28.4134 13.4999 28 13.4999Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>