<template>
  <base-popup>

    <template v-slot:title>
      <div id="popup-title">Do you want to delete the selected<br/> members ?</div>
    </template>

    <template v-slot:body>

    </template>

    <template v-slot:button-bar>
      <button-base v-on:click="cancel" :textColor="'text-blue'" :bgColor="'bg-white'" class="border-radius-28 margin-bottom-0-5x" id="btn-cancel">
        <template v-slot:label>
          No, I don’t want to
        </template>
      </button-base>

      <button-base v-on:click="deleteMemberSelected($emit)" :textColor="'text-white'" :bgColor="'bg-red'" class="border-radius-28 margin-bottom-0-5x" id="btn-delete-confirm">
        <template v-slot:label>
          Yes, please delete the selected members
        </template>
      </button-base>
    </template>

  </base-popup>


</template>

<script>
import BasePopup from "./BasePopup"
import { inject, ref, reactive, getCurrentInstance } from 'vue';
import { QMessageBoxAction } from '@qvant/qui-max';
import ButtonBase from "../../LayoutComponent/layout.button";

let qMessageBoxContainer = undefined;

export default {

  props: [],

  components: {
    BasePopup,
    ButtonBase,
  },

  data() {
    return {
      imageToUpload: null,
    }
  },

  setup(){
    qMessageBoxContainer = inject(
        'qMessageBoxContainer'
    )

    let form = ref(null);
    let model = { numReference: null, pinCode: null };
    let formModel = reactive(model);
    let app = getCurrentInstance();
    let rules = {
      numReference: app.appContext.config.globalProperties.$validator_not_null,
      pinCode: app.appContext.config.globalProperties.$validator_not_null,
    };
    let rul = reactive(rules);


    let addNewCard = (emit) => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.confirm
      });
      let newCard = []
      newCard.push({
        numReference: formModel.numReference,
        pinCode: formModel.pinCode,
      })
      emit("saveChanges", newCard);
    }

    return {
      form,
      rul,
      formModel,
      addNewCard,
      qMessageBoxContainer,
    }
  },

  methods : {

    cancel: () => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.cancel
      });
    },

    deleteMemberSelected(emit){
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.confirm
      });
      emit("deleteMemberSelected", "Successfully deleted member selected");
    },

  },

  mounted() {

  }
}
</script>

<style scoped>
#btn-cancel, #btn-delete-confirm{
  width: 100%!important;
  margin-left: 0!important;
  margin-right: 0!important;
}

#btn-cancel{
  border: 1px solid #0400BD;
}

.border-radius-28{
  border-radius: 28px!important;
}

.text-blue{
  color: #0400BD;
}

.bg-blue{
  background: #0400BD;
}

.bg-red{
  background: #EC0000;
}
</style>