<template>
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D6D6FF"/>
    <path d="M24.9901 37.5001C23.3301 37.5001 21.6601 36.8701 20.3901 35.6001C17.8601 33.0601 17.8601 28.9401 20.3901 26.4101C20.6801 26.1201 21.1601 26.1201 21.4501 26.4101C21.7401 26.7001 21.7401 27.1801 21.4501 27.4701C19.5001 29.4201 19.5001 32.5901 21.4501 34.5401C23.4001 36.4901 26.5701 36.4901 28.5201 34.5401C29.4601 33.6001 29.9801 32.3401 29.9801 31.0001C29.9801 29.6701 29.4601 28.4101 28.5201 27.4601C28.2301 27.1701 28.2301 26.6901 28.5201 26.4001C28.8101 26.1101 29.2901 26.1101 29.5801 26.4001C30.8101 27.6301 31.4801 29.2601 31.4801 31.0001C31.4801 32.7401 30.8001 34.3701 29.5801 35.6001C28.3201 36.8701 26.6601 37.5001 24.9901 37.5001Z" fill="#0400BD"/>
    <path d="M35.07 30.16C34.88 30.16 34.69 30.09 34.54 29.94C34.25 29.65 34.25 29.17 34.54 28.88C36.59 26.83 36.59 23.5 34.54 21.46C32.49 19.41 29.16 19.41 27.12 21.46C26.13 22.45 25.58 23.77 25.58 25.17C25.58 26.57 26.13 27.89 27.12 28.88C27.41 29.17 27.41 29.65 27.12 29.94C26.83 30.23 26.35 30.23 26.06 29.94C24.79 28.67 24.08 26.97 24.08 25.17C24.08 23.37 24.78 21.67 26.06 20.4C28.69 17.77 32.97 17.77 35.61 20.4C38.24 23.03 38.24 27.32 35.61 29.95C35.46 30.09 35.26 30.16 35.07 30.16Z" fill="#0400BD"/>
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F4F3FF" stroke-width="8"/>
  </svg>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>