<template>
  <div class="chart w-100">
    <v-chart :option="option" autoresize />
  </div>
</template>

<script>

export default {

  props: ['datasOption'],

  data(){
    return {

    }
  },

  methods: {

  },

  mounted() {

  },

  computed: {
    option() {
      return {
        ...this.datasOption,
        xAxis: {
          ...this.datasOption.xAxis,
          data: [
            '',
            ...this.datasOption.xAxis.data,
            '',
          ],
        },
        series: [
          {
            ...this.datasOption.series[0],
            data: [
              this.datasOption.series[0].data.length>0 ? this.datasOption.series[0].data[0] : 0,
              ...this.datasOption.series[0].data,
              this.datasOption.series[0].data.length>0 ? this.datasOption.series[0].data.slice(-1)[0] : 0,
            ]
          }
        ]
      };
    },
  },

}
</script>

<style scoped>
.chart{
  height: calc((75 / 360) * (100vh - var(--size-2x)));
}
</style>