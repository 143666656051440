<template>
  <button-base :text-color="'white'" :style="{ background: this.bgTag }">
    <template v-slot:label>
      <slot name="labelTag"></slot>
    </template>
  </button-base>
</template>

<script>
import ButtonBase from "../Components/Buttons/button.base";

export default {

  props: [ 'bgTag' ],

  components: {
    ButtonBase,
  },
}
</script>

<style scoped>

</style>