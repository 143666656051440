<template>
  <svg width="20" height="20" viewBox="0 0 20 20" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.9999 11.8084C8.2249 11.8084 6.7749 10.3667 6.7749 8.58337C6.7749 6.80003 8.2249 5.3667 9.9999 5.3667C11.7749 5.3667 13.2249 6.80837 13.2249 8.5917C13.2249 10.375 11.7749 11.8084 9.9999 11.8084ZM9.9999 6.6167C8.91657 6.6167 8.0249 7.50003 8.0249 8.5917C8.0249 9.68337 8.90824 10.5667 9.9999 10.5667C11.0916 10.5667 11.9749 9.68337 11.9749 8.5917C11.9749 7.50003 11.0832 6.6167 9.9999 6.6167Z" />
    <path d="M9.9999 18.9665C8.76657 18.9665 7.5249 18.4998 6.55824 17.5748C4.0999 15.2082 1.38324 11.4332 2.40824 6.9415C3.33324 2.8665 6.89157 1.0415 9.9999 1.0415C9.9999 1.0415 9.9999 1.0415 10.0082 1.0415C13.1166 1.0415 16.6749 2.8665 17.5999 6.94984C18.6166 11.4415 15.8999 15.2082 13.4416 17.5748C12.4749 18.4998 11.2332 18.9665 9.9999 18.9665ZM9.9999 2.2915C7.5749 2.2915 4.45824 3.58317 3.63324 7.2165C2.73324 11.1415 5.1999 14.5248 7.43324 16.6665C8.8749 18.0582 11.1332 18.0582 12.5749 16.6665C14.7999 14.5248 17.2666 11.1415 16.3832 7.2165C15.5499 3.58317 12.4249 2.2915 9.9999 2.2915Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>