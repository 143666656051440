<template>
  <div class="row h-100">
    <div class="col-xl-12">

      <!-- Button back to previous -->
      <back-previous @backPrev="$emit('prev')"/>

      <div class="card-body text-black margin-left-1x margin-right-1x margin-top-4x">

        <!-- Title -->
        <div class="text-start text-title">
          <slot name="title"></slot>
        </div>

        <!--  Form -->
        <form class="margin-middle margin-top-1x">
          <slot name="form"></slot>
        </form>

        <div>
          <slot name="btn-upload"></slot>
          <slot name="btn-next"></slot>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import BackPrevious from "../Navigation/back.previous.page";

export default {

  components: {
    BackPrevious,
  },

  methods: {

  },

}
</script>

<style scoped>
.text-title{
  font-size: calc((22/390) * (100vw - var(--size-2x))) !important;
  line-height: 140% !important;
  font-weight: 500!important;
  font-family: "Lexend" !important;
  color: #111827;
}

.margin-middle{
  margin-left: calc((37/390) * (100vw - var(--size-2x)));
  margin-right: calc((37/390) * (100vw - var(--size-2x)));
}
</style>