<template>
  <svg width="32" height="33" viewBox="0 0 32 33" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.6667 30.8333H13.6667V21.1667H12.4C11.6267 21.1667 11 20.54 11 19.7667V17.2333C11 16.46 11.6267 15.8333 12.4 15.8333H13.6667V12.5C13.6667 9.74 15.9067 7.5 18.6667 7.5H22.2667C23.04 7.5 23.6667 8.12665 23.6667 8.89998V12.1C23.6667 12.8733 23.04 13.5 22.2667 13.5H19.6667V15.8333H22.1733C22.6 15.8333 22.9867 16.02 23.2533 16.34C23.52 16.66 23.6267 17.0867 23.5467 17.5L23.04 20.0333C22.9067 20.6867 22.3333 21.1533 21.6667 21.1533H19.6667V30.8333ZM15.6667 28.8333H17.6667V19.1667H21.1867L21.4533 17.8333H17.68V12.9C17.68 12.1267 18.3067 11.5 19.08 11.5H21.68V9.5H18.6667C17.0133 9.5 15.6667 10.8467 15.6667 12.5V17.8333H13V19.1667H15.6667V28.8333Z" />
    <path d="M20.0001 30.8334H12.0001C4.76008 30.8334 1.66675 27.7401 1.66675 20.5001V12.5001C1.66675 5.26008 4.76008 2.16675 12.0001 2.16675H20.0001C27.2401 2.16675 30.3334 5.26008 30.3334 12.5001V20.5001C30.3334 27.7401 27.2401 30.8334 20.0001 30.8334ZM12.0001 4.16675C5.85341 4.16675 3.66675 6.35341 3.66675 12.5001V20.5001C3.66675 26.6467 5.85341 28.8334 12.0001 28.8334H20.0001C26.1467 28.8334 28.3334 26.6467 28.3334 20.5001V12.5001C28.3334 6.35341 26.1467 4.16675 20.0001 4.16675H12.0001Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>