<template>
  <svg width="33" height="33" viewBox="0 0 33 33" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5 14.8997C10.4734 14.8997 8.83337 13.2597 8.83337 11.2331C8.83337 9.20641 10.4734 7.56641 12.5 7.56641C14.5267 7.56641 16.1667 9.20641 16.1667 11.2331C16.1667 13.2597 14.5267 14.8997 12.5 14.8997ZM12.5 9.56641C11.58 9.56641 10.8334 10.3131 10.8334 11.2331C10.8334 12.1531 11.58 12.8997 12.5 12.8997C13.42 12.8997 14.1667 12.1531 14.1667 11.2331C14.1667 10.3131 13.42 9.56641 12.5 9.56641Z" />
    <path d="M20.5 30.8996H12.5C5.25996 30.8996 2.16663 27.8062 2.16663 20.5662V12.5662C2.16663 5.32624 5.25996 2.23291 12.5 2.23291H17.8333C18.38 2.23291 18.8333 2.68624 18.8333 3.23291C18.8333 3.77958 18.38 4.23291 17.8333 4.23291H12.5C6.35329 4.23291 4.16663 6.41958 4.16663 12.5662V20.5662C4.16663 26.7129 6.35329 28.8996 12.5 28.8996H20.5C26.6466 28.8996 28.8333 26.7129 28.8333 20.5662V13.8996C28.8333 13.3529 29.2866 12.8996 29.8333 12.8996C30.38 12.8996 30.8333 13.3529 30.8333 13.8996V20.5662C30.8333 27.8062 27.74 30.8996 20.5 30.8996Z" />
    <path d="M24.5 12.2328C23.9533 12.2328 23.5 11.7795 23.5 11.2328V3.23283C23.5 2.83283 23.74 2.4595 24.1133 2.31283C24.4867 2.16616 24.9133 2.24616 25.2067 2.52616L27.8733 5.19283C28.26 5.5795 28.26 6.2195 27.8733 6.60616C27.4867 6.99283 26.8467 6.99283 26.46 6.60616L25.5 5.64616V11.2328C25.5 11.7795 25.0467 12.2328 24.5 12.2328Z" />
    <path d="M21.8333 6.89966C21.58 6.89966 21.3267 6.80633 21.1267 6.60633C20.74 6.21966 20.74 5.57966 21.1267 5.19299L23.7933 2.52633C24.18 2.13966 24.82 2.13966 25.2067 2.52633C25.5933 2.91299 25.5933 3.55299 25.2067 3.93966L22.54 6.60633C22.34 6.80633 22.0867 6.89966 21.8333 6.89966Z" />
    <path d="M4.05994 26.833C3.73994 26.833 3.41994 26.673 3.23328 26.393C2.92661 25.9397 3.04661 25.313 3.49994 25.0063L10.0733 20.593C11.5133 19.633 13.4999 19.7397 14.8066 20.8463L15.2466 21.233C15.9133 21.8063 17.0466 21.8063 17.6999 21.233L23.2466 16.473C24.6599 15.2597 26.8866 15.2597 28.3133 16.473L30.4866 18.3397C30.8999 18.6997 30.9533 19.3263 30.5933 19.753C30.2333 20.1663 29.5933 20.2197 29.1799 19.8597L27.0066 17.993C26.3399 17.4197 25.2199 17.4197 24.5533 17.993L19.0066 22.753C17.5933 23.9663 15.3666 23.9663 13.9399 22.753L13.4999 22.3663C12.8866 21.8463 11.8733 21.793 11.1933 22.2597L4.63328 26.673C4.44661 26.7797 4.24661 26.833 4.05994 26.833Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>