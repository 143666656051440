<template>
  <div class="col-contact-info border-radius-10px bg-white padding-left-1x padding-top-1x padding-right-1x padding-bottom-1x">
    <div class="text-start sub-title margin-bottom-0-5x">
      Contact Informations
    </div>

    <template v-if="this.imageToUpload">
      <div class="d-flex justify-content-center">
        <div class="avatar-image margin-bottom-0-5x" v-bind:style="{ 'background-image': `url(${ this.imageToUpload })` }"></div>
      </div>
    </template>

    <template v-else>
      <div class="d-flex justify-content-center">
        <div class="avatar-image margin-bottom-0-5x" v-bind:style="{
            'background': 'linear-gradient(284.83deg, #0400BD -7.8%, #7000FF 99.63%) center center'
            }">
        </div>
      </div>
    </template>

    <button-base v-on:click="showPopupAddPicture" :textColor="'text-white'" :bgColor="'bg-blue'" class="border-radius-28 margin-bottom-0-5x" id="btn-add-picture">
      <template v-slot:label>
        Add a picture
      </template>
    </button-base>

    <q-form
        ref="form"
        :model="formModel"
        :rules="rul"
    >

      <q-form-item label="Template" prop="template" class="text-start my-2">
        <q-select v-model="formModel.template" class="champ-tmp" placeholder="Choose a template">
          <q-option
              v-for="template in templates"
              :key="template.id"
              :label="template.label"
              :value="template.id"
          />
        </q-select>
      </q-form-item>

      <!-- Input first_name -->
      <q-form-item label="First name" prop="first_name" class="text-start my-2">
        <q-input v-model="formModel.first_name" type="text" class="form-control" :placeholder="formModel.first_name ? formModel.first_name : 'Enter the first name'"/>
      </q-form-item>

      <!-- Input last_name -->
      <q-form-item label="Last name" prop="last_name" class="text-start my-2">
        <q-input v-model="formModel.last_name" type="text" class="form-control" placeholder="Enter the last name"/>
      </q-form-item>

      <!-- Email -->
      <q-form-item label="Email" prop="email" class="text-start my-2">
        <q-input v-model="formModel.email" type="email" class="form-control" placeholder="Enter the business email address" />
      </q-form-item>

      <!-- Tel -->
      <q-form-item label="Phone Number" prop="telephone" class="text-start my-2">
        <vue-tel-input :value="formModel.telephone" @input="updatePhone" mode="international" id="tel-country"></vue-tel-input>
      </q-form-item>

      <!-- Input position -->
      <q-form-item label="Job Position" prop="position" class="text-start my-2">
        <q-input v-model="formModel.position" type="text" class="form-control" placeholder="Enter the job position" />
      </q-form-item>

      <!-- Input Linkedin -->
      <q-form-item label="LinkedIn" prop="linkedin" class="text-start my-2">
        <q-input v-model="formModel.linkedin" type="text" class="form-control" placeholder="Enter the LinkedIn link" />
      </q-form-item>

      <!-- Input WhatsApp -->
      <q-form-item label="WhatsApp" prop="whatsapp" class="text-start my-2">
        <q-input v-model="formModel.whatsapp" type="text" class="form-control" placeholder="Enter the WhatsApp link" />
      </q-form-item>

    </q-form>

  </div>
</template>

<script>
import ButtonBase from "../LayoutComponent/layout.button";
import { ref, reactive, getCurrentInstance } from "vue";
import CropPopup from "../LayoutComponent/Popups/CropPicturePopup";
import { useMessageBox } from "@qvant/qui-max";
import { createWorker, getWorkers, updateWorker, deleteWorker } from "../../../services/management/UsersService";
import templateService from "../../../services/management/TemplateService";
import { VueTelInput } from 'vue3-tel-input';

export default {

  props: ['userId'],

  components: {
    ButtonBase,
    VueTelInput,
  },

  setup() {
    let form = ref(null);

    let model = {
      template: null,
      first_name: null,
      last_name: null,
      telephone: null,
      email: null,
      position: null,
      linkedin: null,
      whatsapp: null,
    };

    let formModel = reactive(model);
    let app = getCurrentInstance();
    let rules = {
      first_name: app.appContext.config.globalProperties.$validator_not_null,
      last_name: app.appContext.config.globalProperties.$validator_not_null,
      //telephone: app.appContext.config.globalProperties.$validator_telephone,
      email: app.appContext.config.globalProperties.$validator_email,
      position: app.appContext.config.globalProperties.$validator_not_null,
    };
    let rul = reactive(rules);

    let user = null;

    return {
      app,
      form,
      rul,
      formModel,
      user,
    };

  },


  data() {
    return {
      imageToUpload: null,
      templates: [],
    }
  },


  methods : {
    async showPopupAddPicture() {
      try {
        let messageBox = useMessageBox();
        const result = await messageBox(
            {
              component: CropPopup,
              props: {
                memberToEdit: this.memberToEdit,
              },
              listeners: {
                saveChanges: (args) => {
                  this.imageToUpload = args;
                },
              }
            },
        );
        console.log('resolve', result);
      } catch (result) {
        console.log('reject', result);
      }
    },

    updatePhone(params) {
      if (typeof params == "string") {
        this.formModel.telephone = params;
      }
    },

    async save() {
      return new Promise((resolve, reject) => {
        this.form.validate()
        .then((valid) => {
          if (valid?.isValid) {
            const payload = {
              "user": {
                ...this.form.model,
                "username": this.form.model.email,
                "tel": this.form.model.telephone,
                "avatar": this.imageToUpload,
                "socials": {
                  "linkedin": this.form.model.linkedin,
                  "whatsapp": this.form.model.whatsapp,
                },
              },
              "profile_component": {
                datas: [
                  {
                    "id": 1,
                    "tel": this.form.model.telephone,
                    "email": this.form.model.email,
                    "avatar": this.imageToUpload,
                    "position": this.form.model.position,
                    "last_name": this.form.model.last_name,
                    "first_name": this.form.model.first_name,
                    "backgroundImage": "",
                    "socials": {
                      "linkedin": this.form.model.linkedin,
                      "whatsapp": this.form.model.whatsapp,
                    },
                  }
                ],
              },
            }

            if (!this.userId) {
              createWorker(payload)
              .then((user) => {
                this.app.appContext.config.globalProperties.$toast.show("Success.", {type: 'success'});
                resolve(user);
              })
              .catch((error) => {
                reject(error);
              })
            }
            else {
              updateWorker(this.userId, payload)
              .then((user) => {
                this.app.appContext.config.globalProperties.$toast.show("Success.", {type: 'success'});
                resolve(user);
              })
              .catch((error) => {
                reject(error);
              })
            }
          }
          else {
            this.app.appContext.config.globalProperties.$toast.show("Please fill in every field.", {type: 'error'});
            reject()
          }
        })
        .catch((error) => {
          this.app.appContext.config.globalProperties.$toast.show("Please fill in every field.", {type: 'error'});
          reject(error)
        });
      });
    },

    async deleteMember() {
      if (this.userId) {
        return new Promise((resolve, reject) => {
          deleteWorker(this.userId)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          })
        });
      }
    }
  },

  mounted() {
    templateService.getTemplates()
    .then((templates) => {
      this.templates = templates;
    })
    .catch((error) => {
      console.log(error);
    });
    if(this.userId != null){
      getWorkers()
      .then((users) => {
        this.user = users.find(u => u.id == this.userId) ?? null;
        console.log(this.user);
        if (this.user) {
          console.log(this.user)
          Object.assign(this.formModel, {
            ...this.user,
            telephone: this.user.tel,
            linkedin: this.user.socials?.linkedin,
            whatsapp: this.user.socials?.whatsapp,
          });
          this.imageToUpload = this.user.avatar
        }
      })
      .catch((error) => {
        console.log(error);
      })
    }
  }

}
</script>

<style scoped>
*{
  font-family: Poppins;
}

.champ-tmp{
  height: var(--size-3x);
}

#tel-country{
  width: unset!important;
  height: var(--size-3x);
  background-color: unset !important;
  border-radius: 8px !important;
  border: 1px solid #EEEFF2 !important;
  box-shadow: unset !important;
}

.sub-title{
  font-size: calc((33 / 1820) * (100vw - var(--size-2x)));
  line-height: calc((33 / 1820) * (100vw - var(--size-2x)));
  font-weight: 600;
  color: #000000;
}

.col-contact-info{
  width: calc(50% - var(--size-1x) * 0.5);
  height: fit-content;
}

.avatar-image{
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  width: calc((222/1728) * (100vw - var(--size-2x)));
  height: calc((222/1728) * (100vw - var(--size-2x)));
}

.border-radius-10px{
  border-radius: 10px;
}

.border-radius-28{
  border-radius: 28px!important;
}

#btn-add-picture{
  width: 100%!important;
  margin-left: 0!important;
  margin-right: 0!important;
}
</style>