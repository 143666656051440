<template>
  <svg width="16" height="16" viewBox="0 0 16 16" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.33317 12.1665H6.6665C6.39317 12.1665 6.1665 11.9398 6.1665 11.6665C6.1665 11.3932 6.39317 11.1665 6.6665 11.1665H9.33317C9.6065 11.1665 9.83317 11.3932 9.83317 11.6665C9.83317 11.9398 9.6065 12.1665 9.33317 12.1665Z" />
    <path d="M1.3335 12.1665C1.06016 12.1665 0.833496 11.9398 0.833496 11.6665V4.99984C0.833496 2.05984 1.72683 1.1665 4.66683 1.1665C4.94016 1.1665 5.16683 1.39317 5.16683 1.6665C5.16683 1.93984 4.94016 2.1665 4.66683 2.1665C2.28683 2.1665 1.8335 2.61317 1.8335 4.99984V11.6665C1.8335 11.9398 1.60683 12.1665 1.3335 12.1665Z" />
    <path d="M14.6668 12.1665C14.3935 12.1665 14.1668 11.9398 14.1668 11.6665V4.99984C14.1668 2.61317 13.7135 2.1665 11.3335 2.1665C11.0602 2.1665 10.8335 1.93984 10.8335 1.6665C10.8335 1.39317 11.0602 1.1665 11.3335 1.1665C14.2735 1.1665 15.1668 2.05984 15.1668 4.99984V11.6665C15.1668 11.9398 14.9402 12.1665 14.6668 12.1665Z" />
    <path d="M4.80016 15.1669H3.20016C1.58683 15.1669 0.833496 14.4136 0.833496 12.8002V10.6069C0.833496 8.99357 1.58683 8.24023 3.20016 8.24023H4.80016C6.4135 8.24023 7.16683 8.99357 7.16683 10.6069V12.8002C7.16683 14.4136 6.4135 15.1669 4.80016 15.1669ZM3.20016 9.24023C2.14016 9.24023 1.8335 9.5469 1.8335 10.6069V12.8002C1.8335 13.8602 2.14016 14.1669 3.20016 14.1669H4.80016C5.86016 14.1669 6.16683 13.8602 6.16683 12.8002V10.6069C6.16683 9.5469 5.86016 9.24023 4.80016 9.24023H3.20016Z" />
    <path d="M12.8002 15.1669H11.2002C9.58683 15.1669 8.8335 14.4136 8.8335 12.8002V10.6069C8.8335 8.99357 9.58683 8.24023 11.2002 8.24023H12.8002C14.4135 8.24023 15.1668 8.99357 15.1668 10.6069V12.8002C15.1668 14.4136 14.4135 15.1669 12.8002 15.1669ZM11.2002 9.24023C10.1402 9.24023 9.8335 9.5469 9.8335 10.6069V12.8002C9.8335 13.8602 10.1402 14.1669 11.2002 14.1669H12.8002C13.8602 14.1669 14.1668 13.8602 14.1668 12.8002V10.6069C14.1668 9.5469 13.8602 9.24023 12.8002 9.24023H11.2002Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>