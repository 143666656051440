import { createApp } from 'vue'
import App from './App.vue'

import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

import VSwitch from '@lmiller1990/v-switch'

import Qui from '@qvant/qui-max'
import '@qvant/qui-max/dist/style.css'

import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

import router from './router'
import store from './store'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

import ECharts from 'vue-echarts'
import { use } from "echarts/core"

import VuePlyr from '@skjnldsv/vue-plyr'
import '@skjnldsv/vue-plyr/dist/vue-plyr.css'

// import ECharts modules manually to reduce bundle size
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart, PieChart, ScatterChart, LineChart } from 'echarts/charts'
import { GridComponent, TooltipComponent, LegendComponent, TitleComponent } from 'echarts/components'

use([
    CanvasRenderer,
    BarChart,
    PieChart,
    ScatterChart,
    LineChart,
    GridComponent,
    TooltipComponent,
    LegendComponent,
    TitleComponent
])

import Toaster from '@meforma/vue-toaster';

import 'bootstrap/dist/css/bootstrap.css'

let app = createApp(App)
app.config.globalProperties.$apiUrl = 'https://api.profylead.com' //process.env.VUE_APP_ENV_API_URL

app.config.globalProperties.$validator_not_null = [
    { required: true, message: 'Veuillez compléter le champ ci-dessus', trigger: 'change' },
    {
        required: true,
        message: 'La longueur doit être supérieur à 1',
        trigger: 'change',
        transform(value) {
            return value.trim();
        },
        asyncValidator: (rule, value) => {
            return new Promise((resolve, reject) => {
                if (!(value.length > 1)) {
                    reject('La longueur doit être supérieur à 1');  // reject with error message
                } else {
                    resolve();
                }
            });
        },
    }
]

app.config.globalProperties.$validator_password = [
    { required: true, message: 'Veuillez compléter le champ ci-dessus', trigger: 'change' },
    {
        required: true,
        message: 'Minimum 8 caractères, au moins 1 lettre majuscule, 1 lettre minuscule, 1 chiffre et 1 caractère spécial',
        trigger: 'change',
        transform(value) {
            return value.trim();
        },
        asyncValidator: (rule, value) => {
            return new Promise((resolve, reject) => {
                if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.,#';^_")(+=@$!%*?/&<>-])[A-Za-z\d.,#';^_")(+=@$!%*?/&<>-]{8,}$/.test(value)) {
                    reject('Votre mot de passe non valide');  // reject with error messages
                } else {
                    resolve();
                }
            });
        },
    }
]

app.config.globalProperties.$validator_email = [
    { required: true, message: 'Veuillez compléter le champ ci-dessus', trigger: 'change' },
    {
        required: true,
        message: 'Votre email non valide',
        trigger: 'change',
        transform(value) {
            return value.trim();
        },
        asyncValidator: (rule, value) => {
            return new Promise((resolve, reject) => {
                if (!/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(value)) {
                    reject('Votre identifiant non valide');  // reject with error message
                } else {
                    resolve();
                }
            });
        },
    },
]

app.config.globalProperties.$validator_telephone = [
    { required: true, message: 'Veuillez compléter le champ ci-dessus', trigger: 'change' },
    {
        required: true,
        message: 'Votre numéro de téléphone non valide',
        trigger: 'change',
        transform(value) {
            return value.trim();
        },
        asyncValidator: (rule, value) => {
            return new Promise((resolve, reject) => {
                if (!/^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm.test(value)) { //+336 /-33 /espace // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
                    reject('Votre numéro de téléphone non valide');  // reject with error message
                } else {
                    resolve();
                }
            });
        },
    }
]

app
    .use(router)
    .use(Vuex)
    .use(VueAxios, axios)
    .use(store)
    .use(Qui)
    .use(VueCropper)
    .component('v-switch', VSwitch)
    .component('v-chart', ECharts)
    .use(VuePlyr, {plyr: {}})
    .use(Toaster)
    .use(VueTelInput)
    .mount('#app');

