<template>
  <table class="table table-striped table-hover table-sm text-start">
    <tbody class="classement">
    <tr
        v-for="(data, index) in this.datas"
        :key="index"
        class="d-flex justify-content-between align-items-center border-radius-10px"
    >
      <td class="text-product"><img :src="data.src" alt="product" class="img-product"></td>
      <td class="text-product">{{ (data.picture.length < 30) ? data.picture : (data.picture.slice(0, 27) + "...") }}</td>
      <td class="text-product text-nowrap">{{ data.clicks + " clicks" }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {

  props: ["datas"],

}
</script>

<style scoped>

tr:nth-child(odd) td:first-child { border-top-left-radius: 10px; }
tr:nth-child(odd) td:first-child { border-bottom-left-radius: 10px; }

tr:nth-child(odd) td:last-child { border-top-right-radius: 10px; }
tr:nth-child(odd) td:last-child { border-bottom-right-radius: 10px; }

tbody tr:nth-child(odd){
  background: #F4F3FF;
}

.table > :not(caption) > * > * {
  box-shadow: unset!important;
  border-bottom: unset!important;
}

.border-radius-10px{
  border-radius: 10px;
}

.classement tr td:nth-child(3),.classement tr td:nth-child(4){
  color: #0400BD;
}

.text-product{
  font-family: "Poppins";
  font-size: calc((16 / 1310) * (100vw - var(--size-2x)));
  line-height: calc((20 / 1310) * (100vw - var(--size-2x)));
  font-weight: 500;
  padding: var(--size-1x);
}

.img-product{
  width: calc((73 / 1728) * (100vw - var(--size-2x)));
  height: calc((48 / 1728) * (100vw - var(--size-2x)));
}
</style>