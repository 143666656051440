<template>
  <q-select v-model="this.valueDefault" v-bind:class="'w-100'">
    <!-- choix de schéma -->
    <q-option
        v-for="(option, index) in this.options"
        :key="index"
        :label="option"
        :value="option"
        @click="$emit('changeOption', index)"
    />
  </q-select>
</template>

<script>
import { ref } from "vue";

export default {

  props: ['options', 'value'],

  setup(props) {
    console.log(props.value)

    const valueDefault = ref(props.value);
    return { valueDefault };
  },

  data() {
    return {

    }
  },


}
</script>

<style scoped>

</style>