<template>
  <div class="position-fixed shadow nav-bar padding-1x d-flex justify-content-between nav-bar">
    <div class="cta-button d-flex justify-content-between">

      <!-- Profile -->

      <span class="nav-tab row align-items-center" v-on:click="redirToProfile">
        <a class="nav-link link-disable" v-bind:class="first">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15 15.9375C11.0375 15.9375 7.8125 12.7125 7.8125 8.75C7.8125 4.7875 11.0375 1.5625 15 1.5625C18.9625 1.5625 22.1875 4.7875 22.1875 8.75C22.1875 12.7125 18.9625 15.9375 15 15.9375ZM15 3.4375C12.075 3.4375 9.6875 5.825 9.6875 8.75C9.6875 11.675 12.075 14.0625 15 14.0625C17.925 14.0625 20.3125 11.675 20.3125 8.75C20.3125 5.825 17.925 3.4375 15 3.4375Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7749 28.4375C19.2999 28.4375 18.8499 28.2624 18.5249 27.9374C18.1374 27.5499 17.9624 26.9875 18.0499 26.4L18.2874 24.7125C18.3499 24.275 18.6125 23.7625 18.925 23.4375L23.3499 19.0125C25.1249 17.2375 26.6875 18.25 27.45 19.0125C28.1 19.6625 28.4374 20.3624 28.4374 21.0624C28.4374 21.7749 28.1125 22.4374 27.45 23.0999L23.0249 27.525C22.7124 27.8375 22.1875 28.1 21.75 28.1625L20.0624 28.3999C19.9624 28.4249 19.8749 28.4375 19.7749 28.4375ZM25.3874 19.8999C25.1624 19.8999 24.9624 20.05 24.6749 20.3375L20.25 24.7625C20.2125 24.8 20.1499 24.9249 20.1499 24.9749L19.9249 26.5374L21.4874 26.3125C21.5374 26.3 21.6624 26.2375 21.6999 26.2L26.125 21.7749C26.325 21.5749 26.5624 21.2874 26.5624 21.0624C26.5624 20.8749 26.4125 20.6125 26.125 20.3375C25.825 20.0375 25.5999 19.8999 25.3874 19.8999Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M26.1502 24.0249C26.0627 24.0249 25.9752 24.0125 25.9002 23.9875C24.2502 23.525 22.9377 22.2125 22.4752 20.5625C22.3377 20.0625 22.6253 19.55 23.1253 19.4125C23.6253 19.275 24.1377 19.5624 24.2752 20.0624C24.5627 21.0874 25.3753 21.9 26.4003 22.1875C26.9003 22.325 27.1878 22.8499 27.0503 23.3374C26.9378 23.7499 26.5627 24.0249 26.1502 24.0249Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.2627 28.4375C3.7502 28.4375 3.3252 28.0125 3.3252 27.5C3.3252 22.1625 8.56273 17.8125 15.0002 17.8125C16.3627 17.8125 17.7127 18.0125 18.9752 18.3875C19.4752 18.5375 19.7502 19.0625 19.6002 19.55C19.4502 20.05 18.9252 20.325 18.4377 20.175C17.3377 19.85 16.1877 19.675 15.0002 19.675C9.60023 19.675 5.2002 23.175 5.2002 27.4875C5.2002 28.0125 4.7752 28.4375 4.2627 28.4375Z" />
          </svg>
        </a>
      </span>


      <!-- Leads -->

      <span class="nav-tab row align-items-center" v-on:click="redirToGroup">
        <a class="nav-link link-disable" v-bind:class="second">
          <svg width="31" height="30" viewBox="0 0 31 30" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9999 9.88746C22.9624 9.88746 22.9374 9.88746 22.8999 9.88746H22.8374C20.4749 9.81246 18.7124 7.98745 18.7124 5.73745C18.7124 3.43745 20.5874 1.57495 22.8749 1.57495C25.1624 1.57495 27.0374 3.44995 27.0374 5.73745C27.0249 7.99995 25.2624 9.82496 23.0124 9.89996C23.0124 9.88746 23.0124 9.88746 22.9999 9.88746ZM22.8749 3.43746C21.6124 3.43746 20.5874 4.46246 20.5874 5.72496C20.5874 6.96246 21.5499 7.96246 22.7874 8.01246C22.7999 7.99996 22.8999 7.99996 23.0124 8.01246C24.2249 7.94996 25.1624 6.94996 25.1749 5.72496C25.1749 4.46246 24.1499 3.43746 22.8749 3.43746Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0126 19.1001C22.5251 19.1001 22.0376 19.0626 21.5501 18.9751C21.0376 18.8876 20.7001 18.4001 20.7876 17.8876C20.8751 17.3751 21.3626 17.0376 21.8751 17.1251C23.4126 17.3876 25.0376 17.1001 26.1251 16.3751C26.7126 15.9876 27.0251 15.5001 27.0251 15.0126C27.0251 14.5251 26.7001 14.0501 26.1251 13.6626C25.0376 12.9376 23.3876 12.6501 21.8376 12.9251C21.3251 13.0251 20.8376 12.6751 20.7501 12.1626C20.6626 11.6501 21.0001 11.1626 21.5126 11.0751C23.5501 10.7126 25.6626 11.1001 27.1626 12.1001C28.2626 12.8376 28.9001 13.8876 28.9001 15.0126C28.9001 16.1251 28.2751 17.1876 27.1626 17.9376C26.0251 18.6876 24.5501 19.1001 23.0126 19.1001Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.9626 9.8875C7.9501 9.8875 7.9376 9.8875 7.9376 9.8875C5.6876 9.8125 3.9251 7.9875 3.9126 5.7375C3.9126 3.4375 5.7876 1.5625 8.0751 1.5625C10.3626 1.5625 12.2376 3.4375 12.2376 5.725C12.2376 7.9875 10.4751 9.8125 8.2251 9.8875L7.9626 8.95L8.0501 9.8875C8.0251 9.8875 7.9876 9.8875 7.9626 9.8875ZM8.0876 8.0125C8.1626 8.0125 8.2251 8.0125 8.3001 8.025C9.4126 7.975 10.3876 6.975 10.3876 5.7375C10.3876 4.475 9.3626 3.44999 8.1001 3.44999C6.8376 3.44999 5.8126 4.475 5.8126 5.7375C5.8126 6.9625 6.7626 7.95 7.9751 8.025C7.9876 8.0125 8.0376 8.0125 8.0876 8.0125Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.95 19.1001C6.4125 19.1001 4.9375 18.6876 3.8 17.9376C2.7 17.2001 2.0625 16.1376 2.0625 15.0126C2.0625 13.9001 2.7 12.8376 3.8 12.1001C5.3 11.1001 7.4125 10.7126 9.45 11.0751C9.9625 11.1626 10.3 11.6501 10.2125 12.1626C10.125 12.6751 9.6375 13.0251 9.125 12.9251C7.575 12.6501 5.9375 12.9376 4.8375 13.6626C4.25 14.0501 3.9375 14.5251 3.9375 15.0126C3.9375 15.5001 4.2625 15.9876 4.8375 16.3751C5.925 17.1001 7.55 17.3876 9.0875 17.1251C9.6 17.0376 10.0875 17.3876 10.175 17.8876C10.2625 18.4001 9.925 18.8876 9.4125 18.9751C8.925 19.0626 8.4375 19.1001 7.95 19.1001Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4999 19.2251C15.4624 19.2251 15.4374 19.2251 15.3999 19.2251H15.3374C12.9749 19.1501 11.2124 17.3251 11.2124 15.0751C11.2124 12.7751 13.0874 10.9126 15.3749 10.9126C17.6624 10.9126 19.5374 12.7876 19.5374 15.0751C19.5249 17.3376 17.7624 19.1626 15.5124 19.2376C15.5124 19.2251 15.5124 19.2251 15.4999 19.2251ZM15.3749 12.7751C14.1124 12.7751 13.0874 13.8001 13.0874 15.0626C13.0874 16.3001 14.0499 17.3001 15.2874 17.3501C15.2999 17.3376 15.3999 17.3376 15.5124 17.3501C16.7249 17.2876 17.6624 16.2876 17.6749 15.0626C17.6749 13.8126 16.6499 12.7751 15.3749 12.7751Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4996 28.4499C13.9996 28.4499 12.4996 28.0624 11.3371 27.2749C10.2371 26.5374 9.59961 25.4874 9.59961 24.3624C9.59961 23.2499 10.2246 22.1749 11.3371 21.4374C13.6746 19.8874 17.3371 19.8874 19.6621 21.4374C20.7621 22.1749 21.3996 23.2249 21.3996 24.3499C21.3996 25.4624 20.7746 26.5374 19.6621 27.2749C18.4996 28.0499 16.9996 28.4499 15.4996 28.4499ZM12.3746 23.0124C11.7871 23.3999 11.4746 23.8874 11.4746 24.3749C11.4746 24.8624 11.7996 25.3374 12.3746 25.7249C14.0621 26.8624 16.9246 26.8624 18.6121 25.7249C19.1996 25.3374 19.5121 24.8499 19.5121 24.3624C19.5121 23.8749 19.1871 23.3999 18.6121 23.0124C16.9371 21.8749 14.0746 21.8874 12.3746 23.0124Z" />
          </svg>
        </a>
      </span>


      <!-- Statistic -->

      <span class="nav-tab row align-items-center" v-on:click="redirToStatis">
        <a class="nav-link link-disable" v-bind:class="third">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.5 28.4375H2.5C1.9875 28.4375 1.5625 28.0125 1.5625 27.5C1.5625 26.9875 1.9875 26.5625 2.5 26.5625H27.5C28.0125 26.5625 28.4375 26.9875 28.4375 27.5C28.4375 28.0125 28.0125 28.4375 27.5 28.4375Z" />
            <path d="M17.8125 28.4375H12.1875C11.675 28.4375 11.25 28.0125 11.25 27.5V5C11.25 2.85 12.4375 1.5625 14.4375 1.5625H15.5625C17.5625 1.5625 18.75 2.85 18.75 5V27.5C18.75 28.0125 18.325 28.4375 17.8125 28.4375ZM13.125 26.5625H16.875V5C16.875 3.5625 16.2 3.4375 15.5625 3.4375H14.4375C13.8 3.4375 13.125 3.5625 13.125 5V26.5625Z" />
            <path d="M8.75 28.4375H3.75C3.2375 28.4375 2.8125 28.0125 2.8125 27.5V12.5C2.8125 10.35 3.9125 9.0625 5.75 9.0625H6.75C8.5875 9.0625 9.6875 10.35 9.6875 12.5V27.5C9.6875 28.0125 9.2625 28.4375 8.75 28.4375ZM4.6875 26.5625H7.8125V12.5C7.8125 10.9375 7.125 10.9375 6.75 10.9375H5.75C5.375 10.9375 4.6875 10.9375 4.6875 12.5V26.5625Z" />
            <path d="M26.25 28.4375H21.25C20.7375 28.4375 20.3125 28.0125 20.3125 27.5V18.75C20.3125 16.6 21.4125 15.3125 23.25 15.3125H24.25C26.0875 15.3125 27.1875 16.6 27.1875 18.75V27.5C27.1875 28.0125 26.7625 28.4375 26.25 28.4375ZM22.1875 26.5625H25.3125V18.75C25.3125 17.1875 24.625 17.1875 24.25 17.1875H23.25C22.875 17.1875 22.1875 17.1875 22.1875 18.75V26.5625Z" />
          </svg>
        </a>
      </span>


      <!-- Settings -->

      <span class="nav-tab row align-items-center" v-on:click="redirToSetting">
        <a class="nav-link link-disable" v-bind:class="fourth">
          <svg width="31" height="30" viewBox="0 0 31 30" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.25 28.4375C23.7375 28.4375 23.3125 28.0125 23.3125 27.5V13.75C23.3125 13.2375 23.7375 12.8125 24.25 12.8125C24.7625 12.8125 25.1875 13.2375 25.1875 13.75V27.5C25.1875 28.0125 24.7625 28.4375 24.25 28.4375Z" />
            <path d="M24.25 9.6875C23.7375 9.6875 23.3125 9.2625 23.3125 8.75V2.5C23.3125 1.9875 23.7375 1.5625 24.25 1.5625C24.7625 1.5625 25.1875 1.9875 25.1875 2.5V8.75C25.1875 9.2625 24.7625 9.6875 24.25 9.6875Z" />
            <path d="M15.5 28.4375C14.9875 28.4375 14.5625 28.0125 14.5625 27.5V21.25C14.5625 20.7375 14.9875 20.3125 15.5 20.3125C16.0125 20.3125 16.4375 20.7375 16.4375 21.25V27.5C16.4375 28.0125 16.0125 28.4375 15.5 28.4375Z" />
            <path d="M15.5 17.1875C14.9875 17.1875 14.5625 16.7625 14.5625 16.25V2.5C14.5625 1.9875 14.9875 1.5625 15.5 1.5625C16.0125 1.5625 16.4375 1.9875 16.4375 2.5V16.25C16.4375 16.7625 16.0125 17.1875 15.5 17.1875Z" />
            <path d="M6.75 28.4375C6.2375 28.4375 5.8125 28.0125 5.8125 27.5V13.75C5.8125 13.2375 6.2375 12.8125 6.75 12.8125C7.2625 12.8125 7.6875 13.2375 7.6875 13.75V27.5C7.6875 28.0125 7.2625 28.4375 6.75 28.4375Z" />
            <path d="M6.75 9.6875C6.2375 9.6875 5.8125 9.2625 5.8125 8.75V2.5C5.8125 1.9875 6.2375 1.5625 6.75 1.5625C7.2625 1.5625 7.6875 1.9875 7.6875 2.5V8.75C7.6875 9.2625 7.2625 9.6875 6.75 9.6875Z" />
            <path d="M9.25 14.6875H4.25C3.7375 14.6875 3.3125 14.2625 3.3125 13.75C3.3125 13.2375 3.7375 12.8125 4.25 12.8125H9.25C9.7625 12.8125 10.1875 13.2375 10.1875 13.75C10.1875 14.2625 9.7625 14.6875 9.25 14.6875Z" />
            <path d="M26.75 14.6875H21.75C21.2375 14.6875 20.8125 14.2625 20.8125 13.75C20.8125 13.2375 21.2375 12.8125 21.75 12.8125H26.75C27.2625 12.8125 27.6875 13.2375 27.6875 13.75C27.6875 14.2625 27.2625 14.6875 26.75 14.6875Z" />
            <path d="M18 17.1875H13C12.4875 17.1875 12.0625 16.7625 12.0625 16.25C12.0625 15.7375 12.4875 15.3125 13 15.3125H18C18.5125 15.3125 18.9375 15.7375 18.9375 16.25C18.9375 16.7625 18.5125 17.1875 18 17.1875Z" />
          </svg>
        </a>
      </span>

    </div>
  </div>
</template>

<script>
export default {

  props: ['active'],

  data() {
    return {
      first: "link-disable",
      second: "link-disable",
      third: "link-disable",
      fourth: "link-disable"
    };
  },

  methods : {

    redirToProfile(){
      this.$router.push('/profil/edit/' + this.$route.params.link);
    },

    redirToStatis(){
      this.$router.push('/analytic'); //changed by a correct router
    },

    redirToGroup(){
      this.$router.push('/leads'); //changed by a correct router
    },

    redirToSetting(){
      this.$router.push('/settings'); //changed by a correct router
    },

    checkNavTab(){
      if (this.active === "first"){
        this.first = "active link-active"
      }else if(this.active === "second"){
        this.second = "active link-active"
      }else if(this.active === "third"){
        this.third = "active link-active"
      }else if(this.active === "fourth"){
        this.fourth = "active link-active"
      }
    }

  },

  mounted: function() {
    this.checkNavTab();
  },

}
</script>

<style scoped>
.nav-bar {
  background-color: rgb(255, 255, 255);
  width: 100vw;
  bottom: 0;
  z-index: 100;
}

a.nav-link.active.link-active {
  color: #0400BD!important;
  border-bottom: 2px solid #0400BD;
}

a.nav-link.link-disable{
  color: #9CA3AF!important;
}

.nav-tab{
  padding: 0 var(--size-1x);
}

.nav-link > svg{
  margin-bottom: 5px;
}
</style>