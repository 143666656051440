<template>
  <div class="col">
    <input v-if="showBox" type="checkbox" id="checkbox" :value="boxModel" @input="updateBox"/>
    <div class="card border-radius-10 h-100" id="card-container">

      <svg
          class="bd-placeholder-img"
          v-bind:style="{ 'background-image': `url(${ this.bg })` }"
          width="100%"
          height="200"
          focusable="false">
      </svg>

      <router-link v-bind:to="'/equipe/edit/' + this.team.id">
        <button-base :textColor="'text-white'" :bgColor="'bg-blue-dark'" class="btn-edit btn-abs">
          <template v-slot:label>
            <SVGFatrows :fillColor="'white'"/>
            Edit
          </template>
        </button-base>
      </router-link>

      <div class="card-body bg-content border-bottom-radius">
        <p class="text-card">
          {{ team.contacts.first_name + ' ' + team.contacts.last_name }}
        </p>
        <p class="text-muted overflow-ellipsis">
          {{ team.contacts.position }}
        </p>

        <button-base
          v-if="team.contacts.template && templates.length>0"
          :textColor="'text-white'"
          :bgRGB="'#'+templates.find(t=>t.id===team.contacts.template).color"
          class="margin-bottom-1x btn-edit"
        >
          <template v-slot:label>{{ templates.find(t=>t.id===team.contacts.template).label }}</template>
        </button-base>

        <div class="d-flex justify-content-between px-3">
          <div class="text-statistic"><SVGPeople />&nbsp;{{ team.statistics.total_leads }}</div>
          <div class="text-statistic"><SVGEye />&nbsp;{{ team.statistics.total_views }}</div>
          <div class="text-statistic"><SVGDiagram />&nbsp;{{ team.statistics.total_views > 0 ? Math.round(100*team.statistics.total_leads / team.statistics.total_views) : 0}}%</div>
        </div>
      </div>

      <div class="avatar-container" v-bind:style="{ 'background-image': `url(${ team.contacts.avatar })` }"></div>

    </div>
  </div>
</template>

<script>
import ButtonBase from "../LayoutComponent/layout.button";
import SVGPeople from "../../../assets/svgs/admin/svg.people";
import SVGEye from "../../../assets/svgs/admin/svg.eye";
import SVGDiagram from "../../../assets/svgs/admin/svg.diagram";
import SVGFatrows from "../../../assets/svgs/admin/svg.fatrows";
import templateService from "../../../services/management/TemplateService";

export default {

  props: ['team', 'showBox'],

  components: {
    ButtonBase,
    SVGPeople,
    SVGEye,
    SVGDiagram,
    SVGFatrows
  },

  data() {
    return {
      boxModel: false,
      templates: [],
      bg: null
    }
  },

  methods : {
    updateBox(event){
      this.boxModel = event.target.checked;
      this.$emit("selectMember", this.team.id, this.boxModel);
    },
  },

  mounted(){
    //console.log(this.team.contacts.template)
    templateService.getTemplates()
    .then((templates) => {
      this.templates = templates;
      //console.log(this.templates);

      let tmp = this.templates.find(template => template.id == this.team.contacts.template)
      //console.log(tmp)

      let background = tmp?.page.components.find(element => element.component == "ProfileComponent")?.datas[0]?.backgroundImage
      if(background){
        this.bg = background
      }
    })
    .catch((error) => {
      console.log(error);
    });
  },


}
</script>

<style scoped>
*{
  font-family: Poppins;
}

.bg-content{
  background: #FFFFFF;
}

.border-radius-10{
  border-radius: 10px;
}

.border-bottom-radius{
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.bd-placeholder-img{
  height: calc((198 / 1728) * (100vw - var(--size-2x)));
  background-size: cover;
  background-position: center center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.avatar-container{
  position: absolute;
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  width: calc((144 / 1728) * (100vw - var(--size-2x)));
  height: calc((144 / 1728) * (100vw - var(--size-2x)));
  left: calc(100%/2 - calc((144 / 1728) * 0.5 * (100vw - var(--size-2x))));
  top: calc(calc((198 / 1728) * (100vw - var(--size-2x))) - calc((72 / 1728) * (100vw - var(--size-2x))));
}

.text-card{
  margin-top: calc( 0.5 * calc((36/326) * (100vw - var(--size-2x))));
  font-size: calc((26 / 1728) * (100vw - var(--size-2x)));
  line-height: calc((32 / 1728) * (100vw - var(--size-2x)));
  font-weight: 600;
  margin-bottom: 0;
  color: #111827;
}

.text-muted{
  font-size: calc((20 / 1728) * (100vw - var(--size-2x)));
  line-height: 150%;
  font-weight: 500;
  color: #6B7280;
  margin-top: calc((6/1728) * (100vw - var(--size-2x)));
  margin-bottom: calc((32/1728) * (100vw - var(--size-2x)));
}

.btn-abs{
  position: absolute;
  right: 0;
}

.btn-edit{
  top: var(--size-1x);
  height: var(--size-2x);
  width: auto;
  padding-top: 0!important;
  padding-bottom: 0!important;
  border-radius: 28px !important;
}

.text-statistic{
  font-size: calc((16 / 1728) * (100vw - var(--size-2x)));
  line-height: 150%;
  font-weight: 400;
  color: #111827;
}

.bg-blue-dark{
  background: #0400BD;
}

#card-container{
  border: unset!important;
  height: calc((492 / 1728) * (100vw - var(--size-2x)));
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}

p:nth-child(2) {
  width: calc((300 / 1728) * (100vw - var(--size-2x)));
  height: calc((100 / 1728) * (100vw - var(--size-2x)));
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
</style>