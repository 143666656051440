import axios from "axios";
import store from "../../store";

let statistics = null;

const choices = {
    '1D': 3600*24,
    '1W': 3600*24*7,
    '1M': 3600*24*30,
    '1Y': 3600*24*365,
    'All': 3600*24*365*100,
};

export const getStatistics = () => {
    return new Promise((resolve, reject) => {
        if (statistics==null) {
            statistics = "loading";
            axios.get(process.env.VUE_APP_ENV_API_URL + '/card/management/statistics/',
                { headers: { Authorization: store.state.user.token } }
            )
            .then((response) => {
                statistics = response.data
                resolve(statistics);
            })
            .catch((error) => {
                reject(error);
            })
        }
        else if(statistics==="loading") {
            setTimeout(function(){
                getStatistics()
                .then((statistics) => {
                    resolve(statistics);
                })
                .catch((error) => {
                    reject(error);
                });
            }, 1000);
        }
        else {
            resolve(statistics);
        }
    });
}

export const createStatistic = (payload) => {
    // type = models.CharField(max_length=50, null=False, blank=False) # click | view...
    // component = models.ForeignKey("BaseComponent", on_delete=models.CASCADE, null=False, blank=False)
    // sub_component_link = models.TextField(blank=False, null=True)
    return new Promise((resolve, reject) => {
        return axios.post(process.env.VUE_APP_ENV_API_URL + '/card/management/register-stat/',
            payload,
            { headers: { Authorization: store.state.user.token } }
        )
        .then((response) => {
            statistics = null;
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export default { getStatistics, createStatistic, choices };