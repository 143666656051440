import axios from "axios";
import store from "../store";

export const deleteComponent = (componentId) => {
    return new Promise((resolve, reject) => {
        return axios.delete(process.env.VUE_APP_ENV_API_URL + '/card/components/' + Number(componentId).toString() + '/',
            { headers: { Authorization: store.state.user.token } }
        )
        .then(() => {
            resolve();
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export default { deleteComponent };