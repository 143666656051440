<template>
  <div class="padding-top-1x padding-bottom-1x padding-left-1x padding-right-1x">
    <div class="modal-body text-center">

      <header-popup>
        <template v-slot:svg-left>
          <slot name="svg-left"></slot>
        </template>

        <template v-slot:svg-right>
          <slot name="svg-right"></slot>
        </template>
      </header-popup>


      <p class="text-black text-title text-bold text-start">
        <slot name="title"></slot>
      </p>


      <slot name="body"></slot>


      <div class="">
        <slot name="button-bar"></slot>
      </div>

    </div>
  </div>
</template>

<script>
import HeaderPopup from "./HeaderPopup"

export default {

  components: {
    HeaderPopup
  }

}
</script>

<style scoped>
.icon {
  width: var(--size-3x);
}
</style>