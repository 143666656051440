<template>
  <table class="table table-striped table-hover table-sm text-start">
    <tbody>
    <tr v-for="(lead, index) in sortedLeads" :key="index" class="font-size">
      <td class="ps-3">
        <input v-if="isSelecting" type="checkbox" :checked="selectedLeads.includes(lead)" :onchange="() => updateSelected(lead)">
      </td>
      <td class="col-first-name">{{ lead.full_name }}</td>
      <td>{{ lead.company }}</td>
      <td>{{ lead.email }}</td>
      <td>{{ lead.phone }}</td>
      <td v-if="lead.user?.avatar">
        <img :src="lead.user?.avatar" alt="" width="26" height="26" class="rounded-circle me-3">
        {{ lead.user?.first_name + " " +  lead.user?.last_name }}
      </td>
      <td>{{ new Date(lead.created_at).toLocaleDateString() }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {

  props: ["leads", "isSelecting"],

  data(){
    return {
      selectedLeads: [],
    }
  },

  methods: {
    updateSelected(lead){
      this.selectedLeads = this.selectedLeads.includes(lead) ? this.selectedLeads.filter(l => l.id === lead.id ? false : true) : [...this.selectedLeads, lead];
      this.$emit("onSelect", this.selectedLeads);
    }

  },

  computed: {
    sortedLeads() {
      return [...this.leads].sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    }
  },

  mounted() {
    console.log(this.leads)
  }

}
</script>

<style scoped>
*{
  font-family: Poppins;
}

tr:nth-child(odd) td:first-child { border-top-left-radius: 10px; }
tr:nth-child(odd) td:first-child { border-bottom-left-radius: 10px; }

tr:nth-child(odd) td:last-child { border-top-right-radius: 10px; }
tr:nth-child(odd) td:last-child { border-bottom-right-radius: 10px; }

tbody tr:nth-child(odd){
  background: #F4F3FF;
}

.table > :not(caption) > * > * {
  box-shadow: unset!important;
  border-bottom: unset!important;
}

tbody tr{
  border: 0 solid var(--color-white);
}

tbody td{
  vertical-align: middle;
}

.font-size{
  font-size: calc((16 / 1728) * (100vw - var(--size-2x)));
  line-height: 150%;
  font-weight: 500;
}

.col-first-name{
  height: calc((48 / 1728) * (100vw - var(--size-2x)));
  margin: calc(var(--size-0-5x) * 0.5) 0;
  padding: var(--size-1x);
}
</style>