<template>
  <div class="h-100 m-0">

    <div class="w-100 h-100 m-0 p-0 position-relative spinner-container" v-if="loading">
      <Spinner/>
    </div>

    <div class="text-start padding-bottom-5x" v-show="!loading">
      <!-- view and edit -->
      <div class="view-edit d-flex padding-left-1x padding-right-1x padding-top-1x">
        <q-button v-on:click="!templatePage ? redirectToProfile() : preview()" class="cta-button shadow-none top-button bg-blue">
          <span class="text-center">
            <SVGGlass :fill-color="'white'" />
          </span>
          View
        </q-button>

        <q-button v-if="templatePage || !has_template" v-on:click="changePicturePopup('backgroundImage')" class="cta-button shadow-none top-button bg-blue">
          <span class="text-center">
            <SVGGalleryEdit :fill-color="'white'" />
          </span>
          Edit
        </q-button>
      </div>

      <Nav v-if="!templatePage" :active="'first'"></Nav>

      <!-- profilecomponent -->
      <div class="profile-card">

        <template v-if="backgroundImage">
          <img :src="backgroundImage" class="w-100 profile-background" />
        </template>

        <template v-else>
          <div class="profile-background"></div>
        </template>

        <div class="profile-img p-0 col-img " v-bind:style="!templatePage ? 'background-image: url(' + user.avatar + ');' : 'background: linear-gradient(284.83deg, #0400BD -7.8%, #7000FF 99.63%);'">

          <q-button v-if="false && !templatePage" v-on:click="changePicturePopup('avatar')" class="shadow-none bg-blue btn-edit-avatar position-absolute-right">
            <span class="text-center">
              <SVGGalleryEdit :fill-color="'white'" />
            </span>
          </q-button>

        </div>

        <div
            class="profile-text-container padding-left-1x padding-right-1x"
        >
          <div class="profile-text-name fw-bolder text-center">{{ !templatePage ? (this.user.prenom + " " + this.user.nom) : "First name Last Name" }}</div>
          <div class="profile-text-info text-center mb-0">{{ !templatePage ? this.user.poste : "Position" }}</div>
          <div class="profile-text-info text-center mb-0">at {{ !templatePage ? this.user.entreprise : "Company" }}</div>
          <div v-if="false && !templatePage" class="profile-text-info text-center mb-0">
            <router-link v-bind:to="'/profil/edit/' + this.$route.params.link + '/' + this.idProfile" >
              <button-base :textColor="'text-white'" :bgColor="'bg-blue'" class="edit-button">
                <template v-slot:label>
                  <SVGUserEdit :fill-color="'white'"/>
                  Edit your main informations
                </template>
              </button-base>
            </router-link>
          </div>

        </div>
      </div>

      <template v-for="component in components" :key="component.id">

        <!-- <button-base
          :textColor="'text-white'"
          :bgColor="'bg-blue'"
          class="delete-button"
          :class="component.component === 'BioComponent' ? 'margin-top-0-5x' : ''"
          v-if="component.component !== 'ProfileComponent'"
          v-on:click="deleteComponent(component.id)"
        >
          <template v-slot:label>
            <SVGFatrows :fillColor="'white'"/>
            Delete
          </template>
        </button-base> -->

        <v-switch :case="component.component">

          <template #BioComponent>
            <Biographie
                :id="component.id"
                :link="this.$route.params.link"
                :textColor="colorTheme"
                :title="component.title"
                :description="component.description"
                :disableModif="false"
                :byManager="!!templatePage"
                @editClicked="$emit('editClicked', component)"
            />
          </template>

          <template #ContactComponent>
            <Contact
                :id="component.id"
                :textColor="colorTheme"
                :title="component.title"
                :datas="component.datas"
                :link="this.$route.params.link"
                :disableModif="false"
                :byManager="!!templatePage"
                @editClicked="$emit('editClicked', component)"
            />
          </template>

          <template #SiteComponent>
            <Site
                :id="component.id"
                :textColor="colorTheme"
                :title="component.title"
                :datas="component.datas"
                :link="this.$route.params.link"
                :disableModif="false"
                :byManager="!!templatePage"
                @editClicked="$emit('editClicked', component)"
            />
          </template>

          <template #TelechargeComponent>
            <Telecharge
                :id="component.id"
                :bgColor="linearGradient"
                :textColor="colorTheme"
                :title="component.title"
                :datas="component.datas"
                :link="this.$route.params.link"
                :disableModif="false"
                :byManager="!!templatePage"
                @editClicked="$emit('editClicked', component)"
            />
          </template>

          <template #ReseauxSociauxComponent>
            <ReseauxSociaux
                :id="component.id"
                :textColor="colorTheme"
                :title="component.title"
                :datas="component.datas"
                :link="this.$route.params.link"
                :disableModif="false"
                :byManager="!!templatePage"
                @editClicked="$emit('editClicked', component)"
            />
          </template>

          <!-- TODO: ce composant -->
          <template #EquipeComponent>
            <Equipe
                :id="component.id"
                :textColor="colorTheme"
                :equipe="component.params"
                :disableModif="true"
                :byManager="!!templatePage"
                @editClicked="$emit('editClicked', component)"
            />
          </template>

          <template #PhotosComponent>
            <Photos
                :id="component.id"
                :textColor="colorTheme"
                :title="component.title"
                :datas="component.datas"
                :link="this.$route.params.link"
                :disableModif="false"
                :byManager="!!templatePage"
                @editClicked="$emit('editClicked', component)"
            />
          </template>

          <template #VideoComponent>
            <Video
                :id="component.id"
                :textColor="colorTheme"
                :title="component.title"
                :player="component.player"
                :videoId="component.videoId"
                :sourceVideo="component.sourceVideo"
                :sourceMiniature="component.sourceMiniature"
                :link="this.$route.params.link"
                :disableModif="false"
                :byManager="!!templatePage"
                @editClicked="$emit('editClicked', component)"
            />
          </template>

          <template #CTAComponent>
            <CTAComponent
                :id="component.id"
                :title="component.title"
                :datas="component.datas"
                :disableModif="false"
                :byManager="!!templatePage"
                @editClicked="$emit('editClicked', component)"
            />
          </template>

          <template #default></template>

        </v-switch>

      </template>

      <div v-if="!templatePage" class="margin-top-0-7x margin-bottom-1x margin-left-1x margin-right-1x rounded-3">
        <button-base v-on:click="redirectToCreate" :textColor="'text-white'" :bgColor="'bg-blue'" class="w-100 height-3x">
          <template v-slot:label>
            <SVGPlus :fillColor="'white'"/>
            Add a new section
          </template>
        </button-base>
      </div>

      <div v-if="!templatePage" class="margin-top-0-7x margin-left-1x margin-right-1x rounded-3">
        <button-base v-on:click="redirectToArrange" :textColor="'text-blue'" :bgColor="'bg-white'" class="w-100 height-3x border-blue">
          <template v-slot:label>
            <SVGRotate :fillColor="'#0400BD'"/>
            Reorganize the sections
          </template>
        </button-base>
      </div>
    </div>
  </div>
</template>

<script>
import Biographie from './Texte/texte.layout.vue';
import Contact from './Contact/contact.layout.vue';
import Site from './Site/site.layout.vue';
import Telecharge from './Telecharge/telecharge.layout.vue';
import ReseauxSociaux from './Reseaux/reseaux.layout.vue';
import Equipe from './Equipe/equipe.layout';
import Photos from './Photos/photos.layout';
import Video from './Video/video.layout.vue';
import CTAComponent from './CTA/cta.layout.vue';
import Spinner from '../Navigation/spinner.vue';
import VCardPopup from "../Components/Popups/VCardPopup.vue";
import EditPopup from "../Components/Popups/EditBackPicturePopup";
//import ShareContactPopup from '../Components/Popups/ShareContactPopup.vue';
//import SaveContactPopup from '../Components/Popups/SaveContactPopup.vue';
import ButtonBase from "../Components/Buttons/button.base";
import SVGUserEdit from "../../../assets/svgs/app/svg.user-edit";
// import SVGPlus from "../../../assets/svgs/app/svg.element-plus";
// import SVGRotate from "../../../assets/svgs/app/svg.convertshape";
import SVGGalleryEdit from "../../../assets/svgs/app/svg.gallery-edit";
import SVGGlass from "../../../assets/svgs/app/svg.glass";
import Nav from "../Navigation/nav.pieds"
// import SVGFatrows from "../../../assets/svgs/admin/svg.fatrows";
import { useMessageBox } from "@qvant/qui-max";
import axios from "axios";
import { useRoute } from "vue-router";

export default {

  name: "profil.edit.client",

  props: ['templatePage'],

  components: {
    Biographie,
    Contact,
    Site,
    Telecharge,
    ReseauxSociaux,
    Equipe,
    Photos,
    Video,
    CTAComponent,
    Spinner,
    ButtonBase,
    SVGUserEdit,
    // SVGPlus,
    // SVGRotate,
    SVGGalleryEdit,
    SVGGlass,
    // SVGFatrows,
    Nav,
  },

  data() {
    return {
      has_template: false,

      user: {
        avatar: require("../../../assets/images/photo-de-profil-avatar.png"),
        prenom: null,
        nom: null,
        tel: null,
        email: null,
        poste: null,
        entreprise: null,
      },

      connexion: {
        prenom: null,
        nom: null,
        nomComplet: null,
        tel: null,
        email: null,
        poste: null,
        entreprise: null,
      },

      components: null,
      componentProfile: null,
      idProfile: null,
      datasProfile: [],

      vCard: null,

      backgroundImage: null,
      colorTheme: null,
      bgTheme: null,
      linearGradient: null,

      loading: true,
    };
  },

  methods : {
    async deleteComponent(id) {
      axios.delete(this.$apiUrl + '/card/components/' + id + '/',
      { headers: { Authorization: this.$store.state.user.token } })
      .then(() => {
        this.components = this.components.filter(c => c.id !== id);
        this.$toast.show('Component removed successfully!', {type: 'success'});
      })
      .catch((error) => {
        this.$toast.show(error.message, {type: 'error'});
        console.log(error.message);
      })
    },

    async downloadVCard() {
      if (this.vCard) {
        console.log(this.vCard)
        document.getElementById('download').href = this.vCard;
        document.getElementById('download').click();
        document.getElementById('download').removeAttribute("href");
      }

      try {
        let messageBox = useMessageBox();
        await messageBox(
          {
            component: VCardPopup,
            props: {
              name: this.user.prenom,
            },
            listeners: {

            }
          },
        );
      } catch {
        console.log("reject");
      }
    },


    async changePicturePopup(type){
      try {
        let messageBox = useMessageBox();
        const result = await messageBox(
            {
              component: EditPopup,
              props: {
                type: type,
                backgroundImage: this.backgroundImage,
                avatar: this.user.avatar
              },
              listeners: {
                save: (args) => {
                  if(args.type === 'backgroundImage'){
                    this.backgroundImage = args.image;
                    this.datasProfile[0].backgroundImage = args.image
                  } else if(args.type === 'avatar'){
                    this.user.avatar = args.image;
                    this.datasProfile[0].avatar = args.image
                  }
                  console.log(this.datasProfile)
                  this.saveEditPicture(this.datasProfile)
                },
              }
            },
        );
        console.log('resolve', result);
      } catch (result) {
        console.log('reject', result);
      }
    },


    saveEditPicture(newDatas){
      axios
          .patch(this.$apiUrl + '/card/components/' +  this.idProfile + '/',
              { datas: newDatas },
              { headers: { Authorization: this.$store.state.user.token }})
          .then(() => {
            this.$toast.show(
                'Votre modification a été enregistré avec succès !',
                { type: 'success' }
            );
            if (this.templatePage) {
              this.buildPage();
            }
            else {
              this.$router.push('/profil/edit/' + this.$route.params.link);
            }
          })
          .catch(error => {
            this.$toast.show(error.message, { type: 'error' });
            console.log(error.message)
          })
    },

    /**
     * uploader une image en local
     * @param event
     * @return {Promise<void>}
     */
    async uploadImage(event) {
      if (!event) return;
      let file = event.target.files[0];
      event.target.value = "";
      this.backgroundImage = await this.toBase64(file);
      await this.$refs.cropperDialog.initCropper(file.type);
      this.showPopup();
    },

    /**
     * se transforme en format base64
     * @param file
     * @return {Promise<unknown>}
     */
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },

    /**
     * afficher popup
     */
    showPopup(){
      document.querySelector(".d-modal").style.display = "block";
    },

    /**
     * uploader une image
     */
    onUploaded(){
      this.$refs.imageField.click();
    },

    redirectToArrange(){
      this.$router.push('/section/arrange/' + this.$route.params.link);
    },

    redirectToCreate(){
      this.$router.push('/' + this.$route.params.link + '/section');
    },

    redirectToProfile(){
      this.$router.push('/profil/' + this.$route.params.link);
    },

    async buildPage(){
      this.loading = true;
      let route = useRoute();
      let link = route?.params?.link || 'me';

      /**
       * récupération de la page profile à partir du lien
       */
      let pageProfile = null
      if (link!="me" && !this.templatePage) {
        try {
          let limit = 2;
          let res = await axios.get(this.$apiUrl + '/card/links/translate/' + link + '/', { headers: { Authorization: this.$store.state.user.token } });
          while (limit>0 && pageProfile==null) {
            console.log(res.data)
            if (res.data.redirect_to) {
              res = await axios.get(this.$apiUrl + '/card/links/' + res.data.redirect_to + '/', { headers: { Authorization: this.$store.state.user.token } });
            }
            else if (res.data.page_profile) {
              pageProfile = res.data.page_profile;
              limit+=1;
            }
            limit-=1;
          }
          if (limit===0) {
            throw new Error('link not resolved');
          }
        } catch (error) {
          console.log("error", error);
        }
      }

      pageProfile = this.templatePage || pageProfile ||  'me';
      console.log(pageProfile, this.templatePage);

      /**
       * récupération datas composants
       */
      try {
        const response = await axios.get(this.$apiUrl + '/card/pageprofiles/' + pageProfile + '/', { headers: { Authorization: this.$store.state.user.token } })
        this.components = response.data.components.sort((i1, i2) => (i1.order - i2.order));
      } catch (error) {
        console.log("error", error);
      }

      /**
       * récupération datas profil
       */
      const profileComponent = this.components.find(c=>c.component==="ProfileComponent")
      if (profileComponent) {
        this.user.avatar = profileComponent.datas[0].avatar
        this.user.prenom = profileComponent.datas[0].first_name
        this.user.nom = profileComponent.datas[0].last_name
        this.user.poste = profileComponent.datas[0].position
        this.user.entreprise = profileComponent.datas[0].company
        this.backgroundImage = profileComponent.datas[0].backgroundImage
        this.vCard = profileComponent.datas[0].v_card
        this.color = profileComponent.datas[0].color || "#000A62"
        // const response = await axios.get(this.$apiUrl + '/card/pageprofiles/' + profileComponent.datas[0].id + '/',
        //     { headers: { Authorization: this.$store.state.user.token } })
        // let profilComponent = response.data.components.find((e) => e.component === "ProfileComponent")
        this.idProfile = profileComponent.id
        this.datasProfile = profileComponent.datas;
      }

      /**
       * récupération thème
       */
      if (this.color !== null){
        this.colorTheme = "color: " + this.color + ';';
        this.bgTheme = "color: #FF9000;" + "background: " + "white" + ';';
        this.linearGradient = "background: " + this.color + ';';
      }

      setTimeout(() => {
        this.loading = false;
        document.querySelector("#app").style.overflow = "unset";
      }, 500);
    },

    preview(){
      console.log("preview");
    },

  },

  async mounted() {
    await this.buildPage();
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Varela+Round');

.delete-button {
  position: absolute;
  right: 7rem;
  height: var(--size-2x)!important;
  background-color: #EC0000!important;
}

.top-button {
  height: var(--size-2x)!important;
  flex-grow: 0;
}

.contact-cta {
  background-color: white;
  width: 100vw;
  bottom: 0;
  z-index: 1;
}

.nav-height{
  height: var(--size-3x)!important;
}

.spinner-container {
  z-index: 2;
}

.profile-background{
  z-index: -1;
  width: 100%;
  height: 10rem;
  background-size: cover;
  background: linear-gradient(284.83deg, #0400BD -7.8%, #7000FF 99.63%) no-repeat center center;
}

.col-img{
  width: 40%;
}

.modal-connexion-back {
  position: fixed;
  visibility: hidden;
  background-color: #00000090;
  top: 0;
  left: 0;
}

.d-modal-connexion {
  visibility: hidden;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 14px;
  position: fixed;
  top: 130%; /* Fallback for browsers that do not support Custom Properties */
  /* top: calc(var(--vh, 1vh) * 100); */
  left: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.3);
  overflow-y: scroll;
  border-radius: 1rem 1rem 0 0;
}

.d-modal-body-3 {
  width: calc(100vw - var(--size-2x));
}

.d-modal-btn {
  width: calc(100vw - var(--size-2x));
}

.popup-connect-title {
  font-size: calc((20/390) * (100vw - var(--size-2x)));
  line-height: calc((20/390) * (100vw - var(--size-2x)));
  font-style: normal;
  font-weight: 600;
}

.d-modal-connexion svg {
  width: calc((40/390) * (100vw - var(--size-2x)));
}

.btn{
  height: var(--size-4x);
  text-transform: initial;
  margin: 0;
}

.border-radius-10px{
  border-radius: 10px;
}

.q-button{
  margin-bottom: 0 !important;
}

/* carte de profile en haut */
.profile-card {
  width: 100%;
  margin-bottom: calc(-1 * var(--size-1x));
}

.btn-edit-profil{
  width: var(--size-2x)!important;
}

.profile-img {
  width: 8rem;
  height: 8rem;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  border: calc(1rem / 3) solid white;
  top: -4rem;
  left: calc(50% - 4rem);
  position: relative;
}

.profile-text-container {
  position: relative;
  width: 100%;
  top: -3rem;
}

.profile-text-name {
  /* margin-top: calc((16/390) * (100vw - var(--size-2x))); */
  font-size: var(--size-2x);
  /* line-height: calc((26/390) * (100vw - var(--size-2x))); */
  /* font-weight: 600; */
}

.profile-text-info {
  font-size: var(--size-1x);
  /* line-height: calc((14/390) * (100vw - var(--size-2x)));
  margin-top: calc((10/390) * (100vw - var(--size-2x))); */
  /* font-weight: 500; */
  color: #6B7280;
}

.profile-text-connect {
  height: calc((48/390) * (100vw - var(--size-2x)));
  padding: 0;
  margin-top: calc((16/390) * (100vw - var(--size-2x)));
}

.profile-text-connect-inner {
  text-transform: initial;
  font-size: calc((18/390) * (100vw - var(--size-2x)));
  line-height: calc((18/390) * (100vw - var(--size-2x)));
  font-weight: 600;
}

.view-edit{
  justify-content: space-between;
  position: relative;
  margin-bottom: -3rem;
}

.height-3x{
  height: var(--size-3x)!important;
}

.text-blue{
  color: #0400BD;
}

.border-blue{
  border: 1px solid #0400BD;
}

.btn-edit-avatar{
  height: var(--size-2x)!important;
  width: var(--size-2x)!important;
  padding: 0!important;
  border-radius: 50%!important;
}
</style>