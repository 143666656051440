<template>
  <div class="row h-100 m-0">
    <div class="col-xl-12 p-0 pb-5">

      <Entete v-if="!(componentId >= 0)" @cancelEdit="cancelEdit" @saveEdit="saveEdit()" />

      <div class="padding-top-1x padding-bottom-1x padding-left-1x padding-right-1x h-100">
        <div class="modal-body text-center h-50">
          <div class="text-start h-100">

            <div class="form-group padding-bottom-1x">
              <label class="form-label text-title">Modify the section title</label>
              <div class="left-inner-addon input-container">
                <i class="icon">
                  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.7751 6.60007C17.4334 6.60007 17.1501 6.31673 17.1501 5.97507V4.4584C17.1501 3.85007 16.6584 3.3584 16.0501 3.3584H3.9501C3.34176 3.3584 2.8501 3.85007 2.8501 4.4584V5.9834C2.8501 6.32507 2.56676 6.6084 2.2251 6.6084C1.88343 6.6084 1.6001 6.32507 1.6001 5.97507V4.4584C1.6001 3.1584 2.65843 2.1084 3.9501 2.1084H16.0501C17.3501 2.1084 18.4001 3.16673 18.4001 4.4584V5.9834C18.4001 6.32507 18.1251 6.60007 17.7751 6.60007Z" stroke="#9CA3AF" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10 17.8915C9.65833 17.8915 9.375 17.6081 9.375 17.2665V3.4248C9.375 3.08314 9.65833 2.7998 10 2.7998C10.3417 2.7998 10.625 3.08314 10.625 3.4248V17.2665C10.625 17.6165 10.3417 17.8915 10 17.8915Z" stroke="#9CA3AF" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13.2832 17.8916H6.71655C6.37489 17.8916 6.09155 17.6083 6.09155 17.2666C6.09155 16.9249 6.37489 16.6416 6.71655 16.6416H13.2832C13.6249 16.6416 13.9082 16.9249 13.9082 17.2666C13.9082 17.6083 13.6249 17.8916 13.2832 17.8916Z" stroke="#9CA3AF" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </i>
                <input v-model="title" type="text" class="form-control text-description" :placeholder="title" />
              </div>
            </div>

            <div class="form-group padding-bottom-1x">
              <label class="form-label text-title">Modify the YouTube video link</label>
              <div class="left-inner-addon input-container">
                <i class="icon">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.49173 17.9166C6.1084 17.9166 4.71673 17.3916 3.6584 16.3333C1.55007 14.2166 1.55007 10.7833 3.6584 8.67494C3.90007 8.43327 4.30007 8.43327 4.54173 8.67494C4.7834 8.9166 4.7834 9.3166 4.54173 9.55827C2.91673 11.1833 2.91673 13.8249 4.54173 15.4499C6.16673 17.0749 8.8084 17.0749 10.4334 15.4499C11.2167 14.6666 11.6501 13.6166 11.6501 12.4999C11.6501 11.3916 11.2167 10.3416 10.4334 9.54994C10.1917 9.30827 10.1917 8.90827 10.4334 8.6666C10.6751 8.42493 11.0751 8.42493 11.3167 8.6666C12.3417 9.6916 12.9001 11.0499 12.9001 12.4999C12.9001 13.9499 12.3334 15.3083 11.3167 16.3333C10.2667 17.3916 8.8834 17.9166 7.49173 17.9166Z" fill="#9CA3AF"/>
                    <path d="M15.8917 11.8C15.7334 11.8 15.5751 11.7417 15.4501 11.6167C15.2084 11.375 15.2084 10.975 15.4501 10.7334C17.1584 9.02503 17.1584 6.25003 15.4501 4.55003C13.7417 2.8417 10.9667 2.8417 9.26673 4.55003C8.44173 5.37503 7.9834 6.47503 7.9834 7.6417C7.9834 8.80837 8.44173 9.90837 9.26673 10.7334C9.5084 10.975 9.5084 11.375 9.26673 11.6167C9.02506 11.8584 8.62507 11.8584 8.3834 11.6167C7.32507 10.5584 6.7334 9.1417 6.7334 7.6417C6.7334 6.1417 7.31673 4.72503 8.3834 3.6667C10.5751 1.47503 14.1417 1.47503 16.3417 3.6667C18.5334 5.85837 18.5334 9.43337 16.3417 11.625C16.2167 11.7417 16.0501 11.8 15.8917 11.8Z" fill="#9CA3AF"/>
                  </svg>
                </i>
                <input v-model="link" type="text" class="form-control text-description" :placeholder="link" />
              </div>
            </div>

            <div class="form-group h-100">
              <label class="form-label text-title">Modify the video descritpion</label>
              <textarea v-model="description" class="form-control text-description h-100" rows="3" />
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Entete from "../../Navigation/nav.enTete.vue";
import axios from "axios";

export default {

  components: {
    Entete
  },

  props: [
    'componentId', 'pageId'
  ],

  data() {
    return {
      title: null,
      link: null,
      description: null,
    }
  },

  methods: {

    /**
     * Annuler des modifications et redirection routeur
     */
    cancelEdit() {
      this.$router.push('/profil/edit/' + this.$route.params.link);
    },

    /**
     * Enregistrer des modifications
     */
    saveEdit() {
      const payloadId = this.$route.params.id || this.componentId;
      if(payloadId) {

        axios
            .patch(this.$apiUrl + '/card/components/' + payloadId + '/',
                {title: this.title, videoId: this.link, sourceVideo: this.description},
                {headers: {Authorization: this.$store.state.user.token}})
            .then((res) => {
              this.$toast.show(
                  'Votre modification a été enregistré avec succès !',
                  {type: 'success'}
              );
              if (this.componentId) {
                this.$emit("componentSaved", res.data);
              }
              else {
                this.$router.push('/profil/edit/' + this.$route.params.link);
              }
            })
            .catch(error => {
              this.$toast.show(error.message, {type: 'error'});
              console.log(error.message)
            })

      }else{

        axios
            .post(this.$apiUrl + '/card/components/',
                {
                  component: "VideoComponent",
                  player: "Youtube",
                  title: this.title,
                  videoId: this.link,
                  sourceVideo: this.description,
                  page_profile: this.pageId ?? this.$store.state.user.id_pageprofile,
                },
                {headers: {Authorization: this.$store.state.user.token}})
            .then((res) => {
              this.$toast.show(
                  'Votre modification a été enregistré avec succès !',
                  {type: 'success'}
              );
              if (this.componentId >= 0){
                this.$emit("componentSaved", res.data);
              }
              else {
                this.$router.push('/profil/edit/' + this.$route.params.link);
              }
            })
            .catch(error => {
              this.$toast.show(error.message, {type: 'error'});
              console.log(error.message)
            })

      }
    },

    /**
     * Getter composant Text sur l'API
     */
    getVideo(){
      const payloadId = this.$route.params.id || this.componentId;
      if(payloadId) {
        axios
            .get(this.$apiUrl + '/card/components/' + payloadId + '/',
                {headers: {Authorization: this.$store.state.user.token}})

            .then(response => {
              this.title = response.data.title;
              this.link = response.data.videoId;
              this.description = response.data.sourceVideo;
            })

            .catch(error => {
              console.log(error.message)
            })
      }
    },

  },

  mounted() {
    this.getVideo();
    document.querySelector("#app").style.overflow = "unset";
  }

};

</script>

<style scoped>
</style>

