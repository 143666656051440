<template>
  <div class="row h-100">
    <div class="col-xl-12">

      <!-- Button back to previous -->
      <back-previous v-if="!hide_previous_button" @backPrev="$emit('prev')"/>

      <div class="card-body text-black margin-left-1x margin-right-1x margin-top-4x">

        <!-- Title -->
        <div class="text-start text-title">
          <slot name="title"></slot>
        </div>

        <!--  Form -->
        <form class="margin-top-1x">
          <slot name="form"></slot>
        </form>

        <q-button v-on:click="$emit('next')" class="w-100 cta-button bg-blue text-white margin-top-2x">
          <slot name="label"></slot>
        </q-button>

      </div>
    </div>
  </div>
</template>

<script>
import BackPrevious from "../Navigation/back.previous.page";

export default {
  props: [ "hide_previous_button" ],

  components: {
    BackPrevious,
  },

}
</script>

<style scoped>
.bg-blue{
  background: #0400BD;
}

.text-title{
  font-size: calc((22/390) * (100vw - var(--size-2x))) !important;
  line-height: 140% !important;
  font-weight: 500!important;
  font-family: "Lexend" !important;
  color: #111827;
}

.cta-button {
  height: var(--size-3x)!important;
  font-size: calc(0.95 * var(--size-1x))!important;
  border-radius: calc(1.5 * var(--size-1x))!important;
  padding: 0 var(--size-1x) 0 var(--size-1x)!important;
  flex-grow: 1;
}
</style>