<template>
  <base-popup>

    <template v-slot:svg-left>
      <svg class="icon" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="4" width="48" height="48" rx="24" fill="#D6D6FF"/>
        <path d="M35.77 27.25H31.73C29.72 27.25 28.75 26.27 28.75 24.27V20.23C28.75 18.22 29.73 17.25 31.73 17.25H35.77C37.78 17.25 38.75 18.23 38.75 20.23V24.27C38.75 26.27 37.77 27.25 35.77 27.25ZM31.73 18.75C30.55 18.75 30.25 19.05 30.25 20.23V24.27C30.25 25.45 30.55 25.75 31.73 25.75H35.77C36.95 25.75 37.25 25.45 37.25 24.27V20.23C37.25 19.05 36.95 18.75 35.77 18.75H31.73Z" fill="#0400BD"/>
        <path d="M24.27 27.25H20.23C18.22 27.25 17.25 26.36 17.25 24.52V19.98C17.25 18.14 18.23 17.25 20.23 17.25H24.27C26.28 17.25 27.25 18.14 27.25 19.98V24.51C27.25 26.36 26.27 27.25 24.27 27.25ZM20.23 18.75C18.89 18.75 18.75 19.13 18.75 19.98V24.51C18.75 25.37 18.89 25.74 20.23 25.74H24.27C25.61 25.74 25.75 25.36 25.75 24.51V19.98C25.75 19.12 25.61 18.75 24.27 18.75H20.23Z" fill="#0400BD"/>
        <path d="M24.27 38.75H20.23C18.22 38.75 17.25 37.77 17.25 35.77V31.73C17.25 29.72 18.23 28.75 20.23 28.75H24.27C26.28 28.75 27.25 29.73 27.25 31.73V35.77C27.25 37.77 26.27 38.75 24.27 38.75ZM20.23 30.25C19.05 30.25 18.75 30.55 18.75 31.73V35.77C18.75 36.95 19.05 37.25 20.23 37.25H24.27C25.45 37.25 25.75 36.95 25.75 35.77V31.73C25.75 30.55 25.45 30.25 24.27 30.25H20.23Z" fill="#0400BD"/>
        <path d="M36.5 34.25H30.5C30.09 34.25 29.75 33.91 29.75 33.5C29.75 33.09 30.09 32.75 30.5 32.75H36.5C36.91 32.75 37.25 33.09 37.25 33.5C37.25 33.91 36.91 34.25 36.5 34.25Z" fill="#0400BD"/>
        <path d="M33.5 37.25C33.09 37.25 32.75 36.91 32.75 36.5V30.5C32.75 30.09 33.09 29.75 33.5 29.75C33.91 29.75 34.25 30.09 34.25 30.5V36.5C34.25 36.91 33.91 37.25 33.5 37.25Z" fill="#0400BD"/>
        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F4F3FF" stroke-width="8"/>
      </svg>
    </template>

    <template v-slot:svg-right>
      <span class="d-flex align-items-center" v-on:click="cancel">
        <svg class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#0400BD"/>
          <path d="M9.16999 15.5799C8.97999 15.5799 8.78999 15.5099 8.63999 15.3599C8.34999 15.0699 8.34999 14.5899 8.63999 14.2999L14.3 8.63986C14.59 8.34986 15.07 8.34986 15.36 8.63986C15.65 8.92986 15.65 9.40986 15.36 9.69986L9.69998 15.3599C9.55998 15.5099 9.35999 15.5799 9.16999 15.5799Z" fill="#0400BD"/>
          <path d="M14.83 15.5799C14.64 15.5799 14.45 15.5099 14.3 15.3599L8.63999 9.69986C8.34999 9.40986 8.34999 8.92986 8.63999 8.63986C8.92999 8.34986 9.40998 8.34986 9.69998 8.63986L15.36 14.2999C15.65 14.5899 15.65 15.0699 15.36 15.3599C15.21 15.5099 15.02 15.5799 14.83 15.5799Z" fill="#0400BD"/>
        </svg>
      </span>
    </template>

    <template v-slot:title>
      Which social media link do you want to add ?
    </template>

    <template v-slot:body>
      <div class="margin-bottom-1x">
        <div class="row m-0 p-0">
          <div class="reseaux-btn">

            <a target="_blank" rel="noreferrer" v-on:click="createSocial('WhatsApp')">
              <q-button theme="secondary" class="btn text-danger btn-contact link-disable" :class="fondWhatsapp">
                <SVGWhatsApp :fill-color="svgWhatsapp"/><!-- '#25D366' -->
              </q-button>
            </a>

            <a target="_blank" rel="noreferrer" v-on:click="createSocial('LinkedIn')">
              <q-button theme="secondary" class="btn text-danger btn-contact link-disable" :class="fondLinkedIn">
                <SVGLinkedIn :fill-color="svgLinkedIn" />
              </q-button>
            </a>

            <a target="_blank" rel="noreferrer" v-on:click="createSocial('YouTube')">
              <q-button theme="secondary" class="btn text-danger btn-contact" :class="fondYouTube">
                <SVGYoutube :fill-color="svgYouTube" />
              </q-button>
            </a>

            <a target="_blank" rel="noreferrer" v-on:click="createSocial('Facebook')">
              <q-button theme="secondary" class="btn text-danger btn-contact" :class="fondFacebook">
                <SVGFacebook :fill-color="svgFacebook" />
              </q-button>
            </a>

            <a target="_blank" rel="noreferrer" v-on:click="createSocial('Instagram')">
              <q-button theme="secondary" class="btn text-danger btn-contact" :class="fondInstagram">
                <SVGInstagram :fill-color="svgInstagram" />
              </q-button>
            </a>

            <a target="_blank" rel="noreferrer" v-on:click="createSocial('Twitter')">
              <q-button theme="secondary" class="btn text-danger btn-contact" :class="fondTwitter">
                <SVGTwitter :fill-color="svgTwitter" />
              </q-button>
            </a>

            <a target="_blank" rel="noreferrer" v-on:click="createSocial('TikTok')">
              <q-button theme="secondary" class="btn text-danger btn-contact" :class="fondTT">
                
              <svg v-if="fondTT!='link-disable'" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.313538 4.03652C0.313538 1.88434 2.05823 0.139648 4.21041 0.139648H21.3567C23.5088 0.139648 25.2535 1.88434 25.2535 4.03652V21.1828C25.2535 23.335 23.5088 25.0796 21.3567 25.0796H4.21041C2.05823 25.0796 0.313538 23.335 0.313538 21.1828V4.03652Z" fill="black"/>
                <path d="M11.6132 11.2849V10.7995C11.4469 10.7773 11.2778 10.7634 11.1032 10.7634C9.02155 10.7607 7.32794 12.4557 7.32794 14.5419C7.32794 15.8208 7.96547 16.9526 8.94116 17.6379C8.31195 16.9638 7.92666 16.0566 7.92666 15.0634C7.92666 13.0077 9.57315 11.3321 11.6132 11.2849Z" fill="#25F4EE"/>
                <path d="M11.7047 16.789C12.6333 16.789 13.3927 16.0483 13.426 15.1272L13.4288 6.8962H14.9311C14.9006 6.72697 14.884 6.5522 14.884 6.37465H12.8328L12.8301 14.6057C12.7968 15.5267 12.0373 16.2674 11.1087 16.2674C10.8205 16.2674 10.5488 16.1953 10.3077 16.0677C10.6181 16.5032 11.1281 16.789 11.7047 16.789Z" fill="#25F4EE"/>
                <path d="M17.7391 9.6898V9.23208C17.1653 9.23208 16.6331 9.06285 16.1841 8.76877C16.5832 9.22653 17.1237 9.55945 17.7391 9.6898Z" fill="#25F4EE"/>
                <path d="M16.184 8.76876C15.7461 8.26665 15.4828 7.61193 15.4828 6.89619H14.934C15.0753 7.67852 15.541 8.34988 16.184 8.76876Z" fill="#FE2C55"/>
                <path d="M11.106 12.8135C10.1552 12.8135 9.3819 13.5875 9.3819 14.5391C9.3819 15.2021 9.75887 15.7791 10.3077 16.0677C10.1026 15.7847 9.98062 15.4379 9.98062 15.0606C9.98062 14.1091 10.754 13.3351 11.7047 13.3351C11.8821 13.3351 12.054 13.3656 12.2147 13.4155V11.3183C12.0484 11.296 11.8793 11.2822 11.7047 11.2822C11.6742 11.2822 11.6465 11.2849 11.616 11.2849V12.894C11.4525 12.844 11.2834 12.8135 11.106 12.8135Z" fill="#FE2C55"/>
                <path d="M17.739 9.6898V11.285C16.6746 11.285 15.6878 10.9438 14.8839 10.3667V14.5419C14.8839 16.6253 13.1904 18.3231 11.1059 18.3231C10.3021 18.3231 9.55368 18.0679 8.9411 17.6379C9.63129 18.3786 10.6153 18.8447 11.7047 18.8447C13.7863 18.8447 15.4827 17.1496 15.4827 15.0634V10.8883C16.2865 11.4653 17.2733 11.8065 18.3377 11.8065V9.75364C18.1298 9.75364 17.9302 9.73142 17.739 9.6898Z" fill="#FE2C55"/>
                <path d="M14.884 14.5419V10.3667C15.6878 10.9437 16.6746 11.2849 17.739 11.2849V9.68979C17.1237 9.55944 16.5831 9.22652 16.184 8.76876C15.5409 8.34988 15.078 7.67852 14.9311 6.89619H13.4288L13.426 15.1272C13.3928 16.0482 12.6332 16.789 11.7047 16.789C11.1281 16.789 10.6209 16.5032 10.3049 16.0704C9.75606 15.7791 9.37909 15.2049 9.37909 14.5419C9.37909 13.5903 10.1524 12.8163 11.1032 12.8163C11.2806 12.8163 11.4524 12.8468 11.6132 12.8968V11.2877C9.57312 11.3321 7.92664 13.0077 7.92664 15.0634C7.92664 16.0566 8.31192 16.961 8.94113 17.6379C9.55371 18.0679 10.3021 18.3231 11.106 18.3231C13.1876 18.3231 14.884 16.6253 14.884 14.5419Z" fill="white"/>
              </svg>
              <svg v-else viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.313538 4.38009C0.313538 2.22791 2.05823 0.483215 4.21041 0.483215H21.3567C23.5088 0.483215 25.2535 2.22791 25.2535 4.38009V21.5263C25.2535 23.6785 23.5088 25.4232 21.3567 25.4232H4.21041C2.05823 25.4232 0.313538 23.6785 0.313538 21.5263V4.38009Z" fill="#9CA3AF"/>
                <path d="M11.6132 11.6285V11.1431C11.4469 11.1209 11.2778 11.107 11.1032 11.107C9.02155 11.1042 7.32794 12.7992 7.32794 14.8854C7.32794 16.1643 7.96547 17.2962 8.94116 17.9814C8.31195 17.3073 7.92666 16.4001 7.92666 15.407C7.92666 13.3513 9.57315 11.6757 11.6132 11.6285Z" fill="white"/>
                <path d="M11.7047 17.1325C12.6333 17.1325 13.3927 16.3918 13.426 15.4708L13.4288 7.23977H14.9311C14.9006 7.07054 14.884 6.89577 14.884 6.71822H12.8328L12.8301 14.9493C12.7968 15.8703 12.0373 16.611 11.1087 16.611C10.8205 16.611 10.5488 16.5389 10.3077 16.4112C10.6181 16.8468 11.1281 17.1325 11.7047 17.1325Z" fill="white"/>
                <path d="M17.7391 10.0334V9.57565C17.1653 9.57565 16.6331 9.40641 16.1841 9.11234C16.5832 9.5701 17.1237 9.90301 17.7391 10.0334Z" fill="white"/>
                <path d="M16.184 9.11233C15.7461 8.61021 15.4828 7.9555 15.4828 7.23976H14.934C15.0753 8.02209 15.541 8.69345 16.184 9.11233Z" fill="white"/>
                <path d="M11.106 13.1571C10.1552 13.1571 9.3819 13.9311 9.3819 14.8826C9.3819 15.5457 9.75887 16.1227 10.3077 16.4112C10.1026 16.1283 9.98062 15.7815 9.98062 15.4042C9.98062 14.4526 10.754 13.6786 11.7047 13.6786C11.8821 13.6786 12.054 13.7091 12.2147 13.7591V11.6618C12.0484 11.6396 11.8793 11.6257 11.7047 11.6257C11.6742 11.6257 11.6465 11.6285 11.616 11.6285V13.2375C11.4525 13.1876 11.2834 13.1571 11.106 13.1571Z" fill="white"/>
                <path d="M17.739 10.0334V11.6285C16.6746 11.6285 15.6878 11.2873 14.8839 10.7103V14.8854C14.8839 16.9689 13.1904 18.6667 11.1059 18.6667C10.3021 18.6667 9.55368 18.4114 8.9411 17.9815C9.63129 18.7221 10.6153 19.1882 11.7047 19.1882C13.7863 19.1882 15.4827 17.4932 15.4827 15.407V11.2318C16.2865 11.8089 17.2733 12.1501 18.3377 12.1501V10.0972C18.1298 10.0972 17.9302 10.075 17.739 10.0334Z" fill="white"/>
                <path d="M14.884 14.8854V10.7103C15.6878 11.2873 16.6746 11.6285 17.739 11.6285V10.0334C17.1237 9.903 16.5831 9.57009 16.184 9.11233C15.5409 8.69345 15.078 8.02209 14.9311 7.23976H13.4288L13.426 15.4708C13.3928 16.3918 12.6332 17.1325 11.7047 17.1325C11.1281 17.1325 10.6209 16.8468 10.3049 16.414C9.75606 16.1227 9.37909 15.5485 9.37909 14.8854C9.37909 13.9339 10.1524 13.1599 11.1032 13.1599C11.2806 13.1599 11.4524 13.1904 11.6132 13.2403V11.6313C9.57312 11.6757 7.92664 13.3513 7.92664 15.407C7.92664 16.4001 8.31192 17.3045 8.94113 17.9814C9.55371 18.4114 10.3021 18.6667 11.106 18.6667C13.1876 18.6667 14.884 16.9688 14.884 14.8854Z" fill="white"/>
              </svg>

              </q-button>
            </a>

          </div>

        </div>
      </div>

      <div class="left-inner-addon input-container margin-bottom-1x">
        <i class="icon">
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.49161 18.1393C6.10828 18.1393 4.71661 17.6143 3.65828 16.5559C1.54994 14.4393 1.54994 11.0059 3.65828 8.89759C3.89994 8.65592 4.29994 8.65592 4.54161 8.89759C4.78328 9.13926 4.78328 9.53926 4.54161 9.78092C2.91661 11.4059 2.91661 14.0476 4.54161 15.6726C6.16661 17.2976 8.80828 17.2976 10.4333 15.6726C11.2166 14.8893 11.6499 13.8393 11.6499 12.7226C11.6499 11.6143 11.2166 10.5643 10.4333 9.77259C10.1916 9.53092 10.1916 9.13092 10.4333 8.88926C10.6749 8.64759 11.0749 8.64759 11.3166 8.88926C12.3416 9.91426 12.8999 11.2726 12.8999 12.7226C12.8999 14.1726 12.3333 15.5309 11.3166 16.5559C10.2666 17.6143 8.88328 18.1393 7.49161 18.1393Z" fill="#9CA3AF"/>
            <path d="M15.8916 12.0227C15.7333 12.0227 15.5749 11.9644 15.4499 11.8394C15.2083 11.5977 15.2083 11.1977 15.4499 10.956C17.1583 9.24769 17.1583 6.47269 15.4499 4.77269C13.7416 3.06436 10.9666 3.06436 9.26661 4.77269C8.44161 5.59769 7.98328 6.69769 7.98328 7.86436C7.98328 9.03102 8.44161 10.131 9.26661 10.956C9.50828 11.1977 9.50828 11.5977 9.26661 11.8394C9.02494 12.081 8.62494 12.081 8.38328 11.8394C7.32494 10.781 6.73328 9.36436 6.73328 7.86436C6.73328 6.36436 7.31661 4.94769 8.38328 3.88936C10.5749 1.69769 14.1416 1.69769 16.3416 3.88936C18.5333 6.08102 18.5333 9.65602 16.3416 11.8477C16.2166 11.9644 16.0499 12.0227 15.8916 12.0227Z" fill="#9CA3AF"/>
          </svg>
        </i>
        <input v-model="link" type="text" class="form-control text-description" placeholder="Link" />
      </div>
    </template>

    <template v-slot:button-bar>
      <q-button
          v-on:click="exchange($emit, this.link)"
          theme="secondary"
          class="btn btn-save p-0 mx-0 mt-0 margin-bottom-0-5x w-100 bg-blue"
      >
          <span class="text-white">
            Add this social media link
          </span>
      </q-button>
    </template>

  </base-popup>

</template>

<script>
import { inject } from 'vue';
import BasePopup from "./BasePopup"
import { QMessageBoxAction, QButton } from '@qvant/qui-max';
import SVGWhatsApp from "../../../../assets/svgs/app/svg.popup-whatsapp";
import SVGLinkedIn from "../../../../assets/svgs/app/svg.popup-linkedin";
import SVGYoutube from "../../../../assets/svgs/app/svg.popup-youtube";
import SVGFacebook from "../../../../assets/svgs/app/svg.popup-facebook";
import SVGInstagram from "../../../../assets/svgs/app/svg.popup-instagram";
import SVGTwitter from "../../../../assets/svgs/app/svg.popup-twitter";

let qMessageBoxContainer = undefined;
let component ;
let idMax = 0;
let orderMax = 0;

export default {

  props: [ 'listSocial' ],

  components: {
    BasePopup,
    QButton,
    SVGWhatsApp,
    SVGLinkedIn,
    SVGYoutube,
    SVGFacebook,
    SVGInstagram,
    SVGTwitter,
  },

  data() {
    return {
      link: null,
      fondWhatsapp: "link-disable",
      svgWhatsapp: '#9CA3AF',
      fondLinkedIn: "link-disable",
      svgLinkedIn: '#9CA3AF',
      fondYouTube: "link-disable",
      svgYouTube: '#9CA3AF',
      fondFacebook: "link-disable",
      svgFacebook: '#9CA3AF',
      fondInstagram: "link-disable",
      svgInstagram: '#9CA3AF',
      fondTwitter: "link-disable",
      fondTT: "link-disable",
      svgTwitter: '#9CA3AF',
    }
  },

  setup: () => {
    qMessageBoxContainer = inject(
        'qMessageBoxContainer'
    );

    return {
      qMessageBoxContainer,
    }
  },

  methods : {
    createSocial(nameComponent){
      //console.log(nameComponent)
      this.fondWhatsapp = "link-disable";
      this.fondLinkedIn = "link-disable";
      this.fondYouTube = "link-disable";
      this.fondFacebook = "link-disable";
      this.fondInstagram = "link-disable";
      this.fondTwitter = "link-disable";
      this.fondTT = "link-disable";

      this.svgWhatsapp = "#9CA3AF";
      this.svgLinkedIn = "#9CA3AF";
      this.svgYouTube = "#9CA3AF";
      this.svgFacebook = "#9CA3AF";
      this.svgInstagram = "#9CA3AF";
      this.svgTwitter = "#9CA3AF";


      switch (nameComponent) {
        case 'WhatsApp':
          component = nameComponent;
          this.fondWhatsapp = "active link-active";
          this.svgWhatsapp = '#25D366';
          break;
        case 'LinkedIn':
          component = nameComponent;
          this.fondLinkedIn = "active link-active";
          this.svgLinkedIn = '#0A66C2';
          break;
        case 'YouTube':
          component = nameComponent;
          this.fondYouTube = "active link-active";
          this.svgYouTube = '#FF0000';
          break;
        case 'Facebook':
          component = nameComponent;
          this.fondFacebook = "active link-active";
          this.svgFacebook = '#1877F2'
          break;
        case 'Instagram':
          component = nameComponent;
          this.fondInstagram = "active link-active"
          this.svgInstagram = 'url(#paint0_radial_697_482)'
          break;
        case 'Twitter':
          component = nameComponent;
          this.fondTwitter = "active link-active";
          this.svgTwitter = "#000000";
          break;
        case 'TikTok':
          component = nameComponent;
          this.fondTT = "active link-active";
          break;
      }
    },

    cancel: () => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.cancel
      });
    },

    exchange: (emit, link) => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.confirm
      });
      let social = []
      social.push({
        id: idMax,
        component: component,
        isDisabled: false,
        isInactive: false,
        isLoading: false,
        link: link,
        order: orderMax,
      })
      console.log(social[0])
      emit("add", social[0]);
    }
  },

  mounted(){
    if (this.listSocial.length !== 0) {
      this.listSocial.forEach((social) => {
        if (social.id > idMax) {
          idMax = social.id
        }
        if (social.order > orderMax) {
          orderMax = social.order
        }
      })
      idMax++
      orderMax++
    }else{
      console.log("create a component")
      idMax = 0
      orderMax = 0
    }

  }

}
</script>

<style scoped>
.btn{
  height: var(--size-3x);
  align-items: center;
  justify-content: center;
  display: flex;
}

.btn-contact{
  padding: 0;
  margin: 0;
  width: var(--size-3x);
  height: var(--size-3x);
  box-shadow: 0px 0.77933px 4.67598px rgba(211, 221, 255, 0.48), 0px 0.77933px 3.11732px rgba(63, 63, 63, 0.07)!important;
  border: unset!important;
}

.active.link-active {
  background: #D6D6FF!important;
  border: 1px solid #0400BD!important;
}

.link-disable{
  background: #FFFFFF!important;
}

.btn-contact svg {
  width: 45%;
}

.reseaux-btn {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 0;
  padding: 0;
}

.bg-blue {
  background-color: #0400BD;
}

.btn-save{
  margin-left: 0!important;
}

.text-description{
  line-height: 160%;
  font-weight: 400;
  font-family: "Lexend";
  color: #6B7280;
}
</style>