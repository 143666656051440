<template>
    <div class="padding-top-1x padding-bottom-1x padding-left-1x padding-right-1x">
        <div class="modal-body text-center">
            <p class="text-black text-bold">
                Félicitations ! Vos coordonnées ont bien étés envoyées à {{ name }}.
            </p>

            <div class="d-flex justify-content-center">
                <q-button
                    v-on:click="confirm"
                    theme="secondary"
                    class="btn p-0 mt-0 mb-0 bg-blue"
                >
                    <span class="text-white">
                        Continuer
                    </span>
                </q-button>
            </div>
        </div>
    </div>
</template>

<script>
import { inject } from 'vue';
import { QMessageBoxAction } from '@qvant/qui-max';

let qMessageBoxContainer = undefined;

export default {
    name: "popup.vcard",

    props: [ 'name' ],

    setup: () => {
        qMessageBoxContainer = inject(
            'qMessageBoxContainer'
        );

        return {
            qMessageBoxContainer,
        }
    },

    methods : {
        confirm: () => {
            qMessageBoxContainer?.emitDoneEvent({
                action: QMessageBoxAction.cancel
            });
        }
    },
}
</script>

<style scoped>
.cancel {
    color: #FF4949;
}

.btn{
    width: 50vw;
    height: var(--size-3x);
    align-items: center;
    justify-content: center;
    display: flex;
}

.text-muted {
    font-size: var(--size-0-5x);
}

.margin-right-0-5x {
    margin-right: var(--size-0-5x);
}

.margin-left-0-5x {
    margin-left: var(--size-0-5x);
}
</style>