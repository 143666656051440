<template>
  <svg width="20" height="20" viewBox="0 0 20 20" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5001 18.9584H7.50008C2.97508 18.9584 1.04175 17.0251 1.04175 12.5001V7.50008C1.04175 2.97508 2.97508 1.04175 7.50008 1.04175H11.6667C12.0084 1.04175 12.2917 1.32508 12.2917 1.66675C12.2917 2.00841 12.0084 2.29175 11.6667 2.29175H7.50008C3.65841 2.29175 2.29175 3.65841 2.29175 7.50008V12.5001C2.29175 16.3417 3.65841 17.7084 7.50008 17.7084H12.5001C16.3417 17.7084 17.7084 16.3417 17.7084 12.5001V8.33342C17.7084 7.99175 17.9917 7.70842 18.3334 7.70842C18.6751 7.70842 18.9584 7.99175 18.9584 8.33342V12.5001C18.9584 17.0251 17.0251 18.9584 12.5001 18.9584Z" />
    <path d="M18.3334 8.95841H15.0001C12.1501 8.95841 11.0417 7.85007 11.0417 5.00007V1.66674C11.0417 1.41674 11.1917 1.18341 11.4251 1.09174C11.6584 0.991739 11.9251 1.05007 12.1084 1.22507L18.7751 7.89174C18.9501 8.06674 19.0084 8.34174 18.9084 8.57507C18.8084 8.8084 18.5834 8.95841 18.3334 8.95841ZM12.2917 3.17507V5.00007C12.2917 7.15007 12.8501 7.70841 15.0001 7.70841H16.8251L12.2917 3.17507Z" />
    <path d="M10.8333 11.4583H5.83325C5.49159 11.4583 5.20825 11.1749 5.20825 10.8333C5.20825 10.4916 5.49159 10.2083 5.83325 10.2083H10.8333C11.1749 10.2083 11.4583 10.4916 11.4583 10.8333C11.4583 11.1749 11.1749 11.4583 10.8333 11.4583Z" /> <!-- #9CA3AF -->
    <path d="M9.16659 14.7917H5.83325C5.49159 14.7917 5.20825 14.5084 5.20825 14.1667C5.20825 13.8251 5.49159 13.5417 5.83325 13.5417H9.16659C9.50825 13.5417 9.79159 13.8251 9.79159 14.1667C9.79159 14.5084 9.50825 14.7917 9.16659 14.7917Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>