<template>
  <svg width="20" height="20" viewBox="0 0 20 20" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.1667 17.7082H5.83342C2.79175 17.7082 1.04175 15.9582 1.04175 12.9165V7.08317C1.04175 4.0415 2.79175 2.2915 5.83342 2.2915H14.1667C17.2084 2.2915 18.9584 4.0415 18.9584 7.08317V12.9165C18.9584 15.9582 17.2084 17.7082 14.1667 17.7082ZM5.83342 3.5415C3.45008 3.5415 2.29175 4.69984 2.29175 7.08317V12.9165C2.29175 15.2998 3.45008 16.4582 5.83342 16.4582H14.1667C16.5501 16.4582 17.7084 15.2998 17.7084 12.9165V7.08317C17.7084 4.69984 16.5501 3.5415 14.1667 3.5415H5.83342Z" />
    <path d="M9.99973 10.725C9.29973 10.725 8.5914 10.5083 8.04974 10.0666L5.4414 7.98331C5.17473 7.76664 5.12474 7.37497 5.3414 7.10831C5.55807 6.84164 5.94974 6.79164 6.21641 7.00831L8.82473 9.09164C9.45806 9.59998 10.5331 9.59998 11.1664 9.09164L13.7747 7.00831C14.0414 6.79164 14.4414 6.83331 14.6497 7.10831C14.8664 7.37497 14.8247 7.77498 14.5497 7.98331L11.9414 10.0666C11.4081 10.5083 10.6997 10.725 9.99973 10.725Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>