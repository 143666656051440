<template>
  <template v-if="!isDashboard">
    <login-mobile></login-mobile>
  </template>

  <template v-else>
    <login-pc></login-pc>
  </template>
</template>

<script>
import LoginMobile from "./login.mobile"
import LoginPc from "./login.pc"

export default {

  name: "login",

  components: {
    LoginMobile,
    LoginPc,
  },

  data() {
    return {
      isDashboard: (location.host.startsWith("dashboard") || (process.env.NODE_ENV === 'development' && process.env.VUE_APP_LOCATION_HOST === 'dashboard')),
    }
  },

  methods : {
  },

  mounted() {
    document.querySelector("#app").style.overflowY = "unset"
  }

}
</script>

<style scoped>

</style>