<template>

    <performance-base>
      <template v-slot:title>
        {{ this.title }}
      </template>

      <template v-slot:selector>
        <slot name="btnSelector"></slot>
      </template>

      <template v-slot:figurebar>

        <template v-if="isPerformSpc == 'section'">
          <figure-base :bgColor="'bgBlue'" class="figure-bar">
            <template v-slot:numfigure> {{ this.total_views_section }} </template>
            <template v-slot:labelfigure>total clicks</template>
          </figure-base>
        </template>

        <template v-else-if="isPerformSpc == 'global'">
          <FigureBar :total_contacts="total_contacts_global" :total_leads="total_leads_global" :total_views="total_views_global"/>
        </template>

        <template v-else-if="isPerformSpc == 'team'">
          <FigureBar :total_contacts="total_contacts_team" :total_leads="total_leads_team" :total_views="total_views_team"/>
        </template>

      </template>

      <template v-slot:chart>
        <!--<BarChart :datasOption="datasOption || barJour"/>-->
        <!--<PieChart />-->
        <!--<ScatterChart />-->
        <LegendBar v-on:changeOption="selectTimeFrame" />
        <LineChart :datasOption="setLineChart"/>
      </template>
    </performance-base>


</template>

<script>
//import ButtonSelector from "./layout.buttonselector";
import PerformanceBase from "./layout.base";
import FigureBar from "./layout.figurebar";
import FigureBase from "./layout.figure";
//import BarChart from "../Charts/layout.bar.chart";
//import PieChart from "../Charts/layout.pie.chart";
//import ScatterChart from "../Charts/layout.scatter.chart";
import LineChart from "../Charts/layout.line.chart";
import barJour from "../Charts/Structure/bar";
import barHeure from "../Charts/Structure/bar";
import line from "../Charts/Structure/line";
import lineOption from "../Charts/Structure/line";
import LegendBar from "../LayoutComponent/layout.legendbar";
import StatisticsService from "../../../services/management/StatisticsService";


export default {

  props: ['title', 'templateRole', 'lineDatas', 'isPerformSpc'],

  components: {
    PerformanceBase,
    //ButtonSelector,
    FigureBar,
    FigureBase,
    //BarChart,
    LineChart,
    //PieChart,
    //ScatterChart,
    LegendBar
  },

  data(){
    return {

      filterFrom: null,
      template_role: 0,

      total_contacts_global: -1,
      total_leads_global: -1,
      total_views_global: -1,

      total_contacts_team: -1,
      total_leads_team: -1,
      total_views_team: -1,

      total_views_section: -1,


      line: JSON.parse(JSON.stringify(line)),
      barJour,
      barHeure,
      lineOption,
      datasOption: null,
    }
  },

  methods: {

    /**
     * Permet de changer datas par rapport aux choix
     * @param timeframe
     */
    selectTimeFrame(timeframe){
      //console.log(timeframe);
      this.filterFrom = timeframe;
    },

    /**
     * Convert time to being format 8:00
     * @param timeIso
     * @param selectedTF
     * @return {string}
     */
    convertTime(timeIso, selectedTF){
      //console.log(selectedTF)
      //console.log(timeIso)
      if((selectedTF == '1Y') || (selectedTF == 'All')){
        let dates = timeIso.split('T')[0].split('-')
        return (dates[1] + "/" + dates[0])
      }else if ((selectedTF == '1W') || (selectedTF == '1M')){
        let dates = timeIso.split('T')[0].split('-')
        return (dates[2] + "/" + dates[1])
      }else{
        return new Date(timeIso).getHours().toString() + ":00"; //8:00
      }
    },

    /**
     * Get datas of chart
     */
    displayedLineChart() {

      if(this.isPerformSpc == 'global'){ // Chart Global
        this.total_contacts_global = this.lineDatas.total_contacts
        this.total_leads_global = this.lineDatas.total_leads
        this.total_views_global = this.lineDatas.total_views
      } else if(this.isPerformSpc == 'team'){ // Chart Team
        //console.log(this.lineDatas)
        this.total_contacts_team = this.lineDatas.total_contacts
        this.total_leads_team = this.lineDatas.total_leads
        this.total_views_team = this.lineDatas.total_views
      } else if(this.isPerformSpc == 'section'){ // Chart Section
        //console.log(this.lineDatas.total_views)
        this.total_views_section = this.lineDatas.total_views
      }


      let datasLegend = []
      let datasSeries = []
      let selectedTF = '1D';
      Object.keys(StatisticsService.choices).forEach(k => {
        if (StatisticsService.choices[k] == this.filterFrom){
          selectedTF = k;

            if(this.lineDatas[selectedTF]){
              let computedLineDatas = [];
              if((selectedTF == '1Y') || (selectedTF == 'All')){
                computedLineDatas = this.lineDatas[selectedTF].slice().sort((i1, i2) => (new Date(i1.month)) - (new Date(i2.month)))
              }else if ((selectedTF == '1W') || (selectedTF == '1M')){
                computedLineDatas = this.lineDatas[selectedTF].slice().sort((i1, i2) => (new Date(i1.day)) - (new Date(i2.day)))
              }else{
                computedLineDatas = this.lineDatas[selectedTF].slice().sort((i1, i2) => (new Date(i1.hour)) - (new Date(i2.hour)))
              }
              //console.log(computedLineDatas)
              computedLineDatas.forEach(data => {
                if((selectedTF == '1Y') || (selectedTF == 'All')){
                  datasLegend.push(this.convertTime(data.month, selectedTF))
                  //datasLegend.push(data.month)
                }else if((selectedTF == '1W') || (selectedTF == '1M')){
                  //console.log(data.day)
                  datasLegend.push(this.convertTime(data.day, selectedTF))
                  //datasLegend.push(data.day)
                }else{
                  //console.log(data.hour)
                  datasLegend.push(this.convertTime(data.hour, selectedTF))
                }
                datasSeries.push(data.views)
              })
            }



        }
      });
      //console.log(datasLegend)
      //console.log(datasSeries)
      this.line.xAxis.data = datasLegend;
      this.line.series[0].data = datasSeries;
    }

  },

  computed: {

    setLineChart(){
      this.displayedLineChart();
      return this.line
    }

  },

  mounted() {

  },

}
</script>

<style scoped>
.figure-bar{
  padding-top: calc((36 / 1728) * (100vw - var(--size-2x)));
  padding-bottom: calc((36 / 1728) * (100vw - var(--size-2x)));
}
</style>