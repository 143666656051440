<template>
  <base-popup>

    <template v-slot:title>
      <template v-if="isModeEdit">
        <div>Do you want to edit this picture ?</div>
      </template>

      <template v-else>
        <div id="popup-title">Do you want to add a picture ?</div>
      </template>
    </template>

    <template v-slot:body>
      <template v-if="this.imageToUpload">
        <div class="d-flex justify-content-center">
          <div class="avatar-image margin-bottom-1x" v-bind:style="{ 'background-image': `url(${ this.imageToUpload })` }"></div>
        </div>
      </template>

      <template v-else></template>
    </template>

    <template v-slot:button-bar>
      <input ref="img" type="file" @change="uploadImage" accept="image/*" hidden>
      <button-base v-on:click="uploadPicture" :textColor="'text-white'" :bgColor="'bg-blue'" class="border-radius-28 margin-bottom-0-5x" id="btn-upload-picture">
        <template v-slot:label>
          Upload a picture
        </template>
      </button-base>

      <button-base v-on:click="cancel" :textColor="'text-blue'" :bgColor="'bg-white'" class="border-radius-28 margin-bottom-0-5x" id="btn-cancel">
        <template v-slot:label>
          Cancel
        </template>
      </button-base>

      <button-base v-on:click="save($emit)" :textColor="'text-white'" :bgColor="'bg-lightgreen'" class="border-radius-28 margin-bottom-0-5x" id="btn-save-changes">
        <template v-slot:label>
          Save the modifications
        </template>
      </button-base>
    </template>

  </base-popup>

  <!-- image cropper dialogue -->
  <image-cropper-dialog
      ref="cropperDialog"
      aspectRatio="contactInfo"
      :uploadedImage="imageToUpload"
      @onCrop="(croppedImage) => {
        this.imageToUpload = croppedImage
        if(this.memberToEdit == null){
          this.changeInnerHTML()
        }
      }"
  />

</template>

<script>
import BasePopup from "./BasePopup"
import { inject } from 'vue';
import { QMessageBoxAction } from '@qvant/qui-max';
import ButtonBase from "../../LayoutComponent/layout.button";
import ImageCropperDialog from '../../../app/GestionCrop/image.cropper';

let qMessageBoxContainer = undefined;

export default {

  props: ['memberToEdit'],

  components: {
    BasePopup,
    ButtonBase,
    ImageCropperDialog,
  },

  data() {
    return {
      imageToUpload: null,
      isModeEdit: false,
    }
  },

  setup: () => {
    qMessageBoxContainer = inject(
        'qMessageBoxContainer'
    )

    return {
      qMessageBoxContainer,
    }
  },

  methods : {

    cancel: () => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.cancel
      });
    },

    save(emit){
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.confirm
      });
      //this.$store.commit("saveAvatarContactInfo", this.imageToUpload)
      emit("saveChanges", this.imageToUpload);
    },

    uploadPicture() {
      this.$refs.img.click()
    },

    async uploadImage(event) {
      if (!event) return;
      let file = event.target.files[0];
      let maxAllowedSize = 20 * 1024 * 1024; // 1Mb = 1024 * 1024
      if(file.size > maxAllowedSize) { // supérieur à 20Mb
        event.target.value = "";
      }else{
        event.target.value = "";
        this.imageToUpload = await this.toBase64(file);
        this.$refs.cropperDialog.initCropper(file.type);
        this.showPopup();
      }
    },

    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },

    showPopup(){
      document.querySelector(".d-modal").style.display = "block";
    },

    changeInnerHTML(){
      document.querySelector("#popup-title").innerHTML = `Do you want to add this picture ?`;
      document.querySelector("#btn-upload-picture").innerHTML = `Change this picture`;
    }

  },

  mounted() {
    if(this.memberToEdit != null){
      this.imageToUpload = this.memberToEdit.week[0].contacts.avatar
      this.isModeEdit = true
    }
  }
}
</script>

<style scoped>
#btn-upload-picture, #btn-cancel, #btn-save-changes, #btn-change-picture{
  width: 100%!important;
  margin-left: 0!important;
  margin-right: 0!important;
}

.bg-lightgreen{
  background: #25D366;
}

#btn-cancel{
  color: #0400BD;
  border: 1px solid #0400BD;
}

.border-radius-28{
  border-radius: 28px!important;
}

.avatar-image{
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  width: calc((222/1728) * (100vw - var(--size-2x)));
  height: calc((222/1728) * (100vw - var(--size-2x)));
}
</style>