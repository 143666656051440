import axios from "axios";
import store from "../store";

let leads = null;

export const getManagerLeads = () => {
    return new Promise((resolve, reject) => {
        if (leads == null) {
            axios.get(process.env.VUE_APP_ENV_API_URL + '/card/management/leads/',
                { headers: { Authorization: store.state.user.token } }
            )
                .then((response) => {
                    leads = response.data
                    resolve(leads);
                })
                .catch((error) => {
                    reject(error);
                })
        }
        else {
            resolve(leads);
        }
    });
}

export const deleteManagerLeads = (payload) => {
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_ENV_API_URL + '/card/management/delete-leads/',
            payload,
            { headers: { Authorization: store.state.user.token } }
        )
            .then((response) => {
                leads = null;
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    });
}

export const getLeads = () => {
    return new Promise((resolve, reject) => {
        if (leads == null) {
            axios.get(process.env.VUE_APP_ENV_API_URL + '/card/leads/', {
                headers: { Authorization: store.state.user.token }
            })
                .then((response) => {
                    leads = response.data
                    resolve(leads);
                })
                .catch((error) => {
                    reject(error);
                });
        }
        else {
            resolve(leads);
        }
    });
};

export const createLead = (leadData) => {
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_ENV_API_URL + '/card/leads/', leadData, {
            headers: { Authorization: store.state.user.token }
        })
            .then((response) => {
                leads = null;
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const deleteLead = (leadId) => {
    return new Promise((resolve, reject) => {
        axios.delete(process.env.VUE_APP_ENV_API_URL + `/card/leads/${leadId}/`, {
            headers: { Authorization: store.state.user.token }
        })
            .then((response) => {
                leads = null;
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const deleteMultipleLeads = (leadIds) => {
    const deletePromises = leadIds.map((leadId) => deleteLead(leadId));

    return new Promise((resolve, reject) => {
        Promise.all(deletePromises)
            .then((results) => {
                // The results array will contain the responses of all the delete requests
                leads = null;
                resolve(results.map(r => r.data));
            })
            .catch((error) => {
                reject(error); // Propagate the error if any of the delete requests fail
            });
    })
};

export const updateLead = (leadId, updatedFields) => {
    return new Promise((resolve, reject) => {
        axios.patch(process.env.VUE_APP_ENV_API_URL + `/card/leads/${leadId}/`, updatedFields, {
            headers: { Authorization: store.state.user.token }
        })
            .then((response) => {
                leads = null;
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export default { getManagerLeads, deleteManagerLeads, getLeads, createLead, deleteLead, deleteMultipleLeads, updateLead };