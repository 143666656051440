<template>
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D6D6FF"/>
    <path d="M37 23V33C37 36 35.5 38 32 38H24C20.5 38 19 36 19 33V23C19 20 20.5 18 24 18H32C35.5 18 37 20 37 23Z" stroke="#0400BD" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M30.5 20.5V22.5C30.5 23.6 31.4 24.5 32.5 24.5H34.5" stroke="#0400BD" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24 29H28" stroke="#0400BD" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24 33H32" stroke="#0400BD" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F4F3FF" stroke-width="8"/>
  </svg>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>