<template>
  <div class="corner-radius margin-left-1x margin-right-1x margin-top-1x margin-bottom-2x">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">

      <div>
        <button-base
            :textColor="'text-blue'"
            :bgColor="'bg-white'"
            class="edit-button border-blue"
            v-on:click="$emit('backPrev')"
        >
          <template v-slot:label>
            <SVGArrowLeft :fillColor="'#0400BD'"/>
            Previous
          </template>
        </button-base>
      </div>

      <div></div>

    </div>
  </div>
</template>

<script>
import ButtonBase from "../Components/Buttons/button.base"
import SVGArrowLeft from "../../../assets/svgs/app/svg.arrow-circle-left"


export default {
  components: { ButtonBase, SVGArrowLeft },
}
</script>

<style scoped>
.text-blue{
  color: #0400BD;
}

.border-blue{
  border: 1px solid #0400BD;
}
</style>