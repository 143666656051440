<template>
  <svg width="21" height="21" viewBox="0 0 20 19" fill="currentColor" class="me-3" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.29167 8.71875C3.86019 8.71875 3.51042 9.06853 3.51042 9.5C3.51042 9.93147 3.86019 10.2812 4.29167 10.2812L12.625 10.2812L12.625 12.625C12.625 13.5927 12.625 14.0766 12.5609 14.4814C12.2079 16.71 10.46 18.4579 8.23143 18.8109C7.82657 18.875 7.34271 18.875 6.375 18.875C5.40729 18.875 4.92343 18.875 4.51857 18.8109C2.28996 18.4579 0.5421 16.71 0.189123 14.4814C0.124999 14.0766 0.124999 13.5927 0.124999 12.625L0.125 6.375C0.125 5.40728 0.125 4.92343 0.189123 4.51857C0.542101 2.28996 2.28996 0.542099 4.51857 0.189123C4.92343 0.124999 5.40729 0.124999 6.375 0.124999C7.34271 0.124999 7.82657 0.124999 8.23143 0.189123C10.46 0.5421 12.2079 2.28996 12.5609 4.51857C12.625 4.92343 12.625 5.40729 12.625 6.375L12.625 8.71875L4.29167 8.71875ZM12.625 8.71875L17.6359 8.71875C17.2948 8.38265 16.7927 7.98307 16.0594 7.40159L14.2229 5.9455C13.8849 5.67743 13.8281 5.18604 14.0962 4.84795C14.3642 4.50986 14.8556 4.45309 15.1937 4.72116L17.0675 6.20684C17.768 6.76224 18.346 7.22054 18.7578 7.63074C19.1804 8.05169 19.5307 8.51891 19.6258 9.11596C19.6461 9.24316 19.6562 9.37147 19.6562 9.5C19.6562 9.62853 19.6461 9.75684 19.6258 9.88403C19.5307 10.4811 19.1804 10.9483 18.7578 11.3693C18.346 11.7795 17.768 12.2378 17.0675 12.7932L15.1937 14.2788C14.8556 14.5469 14.3642 14.4901 14.0962 14.1521C13.8281 13.814 13.8849 13.3226 14.2229 13.0545L16.0594 11.5984C16.7927 11.0169 17.2948 10.6174 17.6359 10.2813L12.625 10.2812L12.625 8.71875Z" fill="#9191A4"/>
  </svg>
</template>

<script>
export default {
  name: "svg.signout"
}
</script>

<style scoped>

</style>