<template>

  <architecture-base>

    <!-- Navigation -->
    <template v-slot:nav>
      <!-- Navigation bar -->
      <Sidebar active="equipe" />
    </template>

    <!-- Header -->
    <template v-slot:header>
      <!-- Title -->
      <div class="title">Edit</div>

      <!-- Button tool bar -->
      <button-bar :bg-color2="'bg-red'" :bg-color3="'bg-lightgreen'">

        <template v-slot:valueLabel1>
          <div v-on:click="backPrevious">Back</div>
        </template>

        <template v-slot:valueLabel2>
          <div v-on:click="deleteMember">Delete this team member</div>
        </template>

        <template v-slot:valueLabel3>
          <div v-on:click="saveMember">Save the modifications</div>
        </template>

      </button-bar>
    </template>


    <!-- Content -->
    <template v-slot:main>
      <div class="d-flex justify-content-between overflow-auto">

        <!-- Contact informations -->
        <contact-info
          ref="contact"
          :userId="userId">
        </contact-info>

        <!-- Business card -->
        <business-card
          ref="businessCard"
          :userId="userId"
        ></business-card>

      </div>
    </template>


  </architecture-base>

</template>

<script>
import ButtonBar from "../LayoutComponent/layout.buttonbar";
import ArchitectureBase from "../LayoutComponent/layout.base";
import Sidebar from "../LayoutComponent/layout.sidebar.vue";
import ContactInfo from "./layout.contact.informations";
import BusinessCard from "./layout.business.card";
import DeleteMemberPopup from "../LayoutComponent/Popups/DeleteMemberPopup";
import { useMessageBox } from "@qvant/qui-max";
import { useRoute } from "vue-router";

export default {

  components: {
    ArchitectureBase,
    Sidebar,
    ButtonBar,
    ContactInfo,
    BusinessCard
  },

  data() {
    return {
      userId: useRoute().params.id,
    }
  },


  methods : {

    /**
     * redirect to router previous
     */
    backPrevious(){
      this.$router.push('/equipe')
    },

    /**
     * Show popup delete member
     * @return {Promise<void>}
     */
    async deleteMember(){
      try {
        let messageBox = useMessageBox();
        const result = await messageBox(
            {
              component: DeleteMemberPopup,
              listeners: {
                deleteMember: () => {
                  this.$refs.contact.deleteMember()
                  .then((data) => {
                    console.log(data)
                    this.$router.push('/equipe');
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                },
              }
            },
        );
        console.log('resolve', result);
      } catch (result) {
        console.log('reject', result);
      }
    },

    saveMember(){
      this.$refs.contact.save()
      .then(() => {

      })
      .catch((error) => {
        console.log(error);
      })

      this.$refs.businessCard.save()
      .then(() => {

      })
      .catch((error) => {
        console.log(error);
      })
    },
  },

  computed: {

  },

  mounted() {
  }

}
</script>

<style scoped>

.title{
  font-size: calc((48 / 1728) * (100vw - var(--size-2x)));
  line-height: 100%;
  font-weight: 600;
}
</style>