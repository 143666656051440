<template>
  <info-base @prev="prev" @next="next">

    <template v-slot:title>
      Contact information
    </template>

    <template v-slot:form>

      <q-form
          ref="form"
          :model="formModel"
          :rules="rul"
      >

        <!-- Email -->
        <q-form-item label="Email" prop="email" class="text-start margin-bottom-1x">
          <q-input v-model="formModel.email" type="email" class="form-control" placeholder="Enter your mail adress" />
        </q-form-item>

        <!-- Tel -->
        <q-form-item label="Phone Number" prop="telephone" class="text-start">
          <vue-tel-input :value="formModel.telephone" @input="updatePhone" mode="international" id="tel-country-codes"></vue-tel-input>
        </q-form-item>

      </q-form>

    </template>

    <template v-slot:label>
      Next step
      <SVGArrowRight :fillColor="'white'"/>
    </template>

  </info-base>
</template>

<script>
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { ref, reactive, getCurrentInstance } from "vue";
import SVGArrowRight from "../../../assets/svgs/app/svg.arrow-circle-right";
import InfoBase from "./info.base";
import { VueTelInput } from 'vue3-tel-input';

export default {

  components: {
    SVGArrowRight,
    InfoBase,
    VueTelInput
  },

  setup() {
    let form = ref(null);
    let store = useStore();
    let router = useRouter();
    let model = { telephone: null, email: null };
    let formModel = reactive(model);
    let app = getCurrentInstance();
    let rules = {
      telephone: app.appContext.config.globalProperties.$validator_not_null,
      email: app.appContext.config.globalProperties.$validator_email
    };
    let rul = reactive(rules);


    /**
     * Redirect to next page
     */
    let next = async () => {
      await form?.value?.validate()
          .then((valid) => {
            if (valid) {
              let { isValid, invalidFields } = valid;
              console.log('QForm | validate', isValid, invalidFields);
              console.log(formModel.telephone)

              if (isValid) {
                store.commit("saveTel", formModel.telephone)
                store.commit("saveEmail", formModel.email)
                router.push('/activite');
              }
            }
          })

          .catch(() => {
            app.appContext.config.globalProperties.$toast.show("Veuillez remplir tous les champs", {type: 'error'});
          })
    };

    /**
     * Redirect to previous page
     */
    let prev = () => {
      router.push('/qui');
    };

    let updatePhone = (phone, phoneObject) => {
      if (phoneObject?.formatted) {
        formModel.telephone = phoneObject.formatted
      }
    }

    return {
      form,
      rul,
      formModel,
      next,
      prev,
      updatePhone
    };

  },

  data() {
    return {

    };
  },

  methods : {

  },
};
</script>

<style>
.q-input:nth-child(2){
  width: 75%!important;
}

.form-control{
  height: var(--size-3x);
}

#tel-country-codes{
  border-radius: 8px!important;
  border: 1px solid #ced4da;
  height: var(--size-3x);
}
</style>

