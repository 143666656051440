<template>
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D6D6FF"/>
    <path d="M31 38.75H25C19.57 38.75 17.25 36.43 17.25 31V25C17.25 19.57 19.57 17.25 25 17.25H30C30.41 17.25 30.75 17.59 30.75 18C30.75 18.41 30.41 18.75 30 18.75H25C20.39 18.75 18.75 20.39 18.75 25V31C18.75 35.61 20.39 37.25 25 37.25H31C35.61 37.25 37.25 35.61 37.25 31V26C37.25 25.59 37.59 25.25 38 25.25C38.41 25.25 38.75 25.59 38.75 26V31C38.75 36.43 36.43 38.75 31 38.75Z" fill="#0400BD"/>
    <path d="M38 26.75H34C30.58 26.75 29.25 25.42 29.25 22V18C29.25 17.7 29.43 17.42 29.71 17.31C29.99 17.19 30.31 17.26 30.53 17.47L38.53 25.47C38.74 25.68 38.81 26.01 38.69 26.29C38.57 26.57 38.3 26.75 38 26.75ZM30.75 19.81V22C30.75 24.58 31.42 25.25 34 25.25H36.19L30.75 19.81Z" fill="#0400BD"/>
    <path d="M29 29.75H23C22.59 29.75 22.25 29.41 22.25 29C22.25 28.59 22.59 28.25 23 28.25H29C29.41 28.25 29.75 28.59 29.75 29C29.75 29.41 29.41 29.75 29 29.75Z" fill="#0400BD"/>
    <path d="M27 33.75H23C22.59 33.75 22.25 33.41 22.25 33C22.25 32.59 22.59 32.25 23 32.25H27C27.41 32.25 27.75 32.59 27.75 33C27.75 33.41 27.41 33.75 27 33.75Z" fill="#0400BD"/>
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F4F3FF" stroke-width="8"/>
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>