<template>
  <base-popup>

    <template v-slot:svg-left>
      <SVGEdit />
    </template>

    <template v-slot:title>
      Crop picture ?
    </template>

    <template v-slot:body>

      <div class="margin-bottom-1x">
        <img id="imageToLoad" :src="photo.img">
        
        <template v-if="srcImg">
          <vue-cropper
            ref="cropper"
            :guides="true"
            :background="true"
            :view-mode="2"
            :auto-crop-area="1"
            drag-mode="move"
            :src="srcImg"
          >
          </vue-cropper>
          <q-button
              v-on:click="replace"
              theme="primary"
              class="btn w-100 my-2 bg-blue text-white"
          >
            Crop
          </q-button>
        </template>
      </div>
    </template>
  </base-popup>
</template>

<script>
import BasePopup from "./BasePopup"
import { inject } from 'vue';
import { QMessageBoxAction } from '@qvant/qui-max';
import SVGEdit from "../../../../assets/svgs/app/svg.gallery-edit-circle"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

let qMessageBoxContainer = undefined;

export default {

  props: [ 'photo' ],

  components: {
    BasePopup,
    SVGEdit,
    VueCropper,
  },

  data() {
    return {
      srcImg: null,
    }
  },

  setup: () => {
    qMessageBoxContainer = inject(
        'qMessageBoxContainer'
    );

    return {
      qMessageBoxContainer,
    }
  },

  methods : {
    replace() {
      this.$refs.cropper.rotate(45);
    },

    cancel: () => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.cancel
      });
    },

    getBase64Image(img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/png");
      return dataURL.replace(/^data:image\/?[A-z]*;base64,/);
    },
  },

  mounted() {
    var base64 = this.getBase64Image(document.querySelector("#imageToLoad"));
    this.srcImg = base64;
  }
}
</script>

<style scoped>
.btn{
  width: calc(100vw - var(--size-5x));
  height: var(--size-3x);
  align-items: center;
  justify-content: center;
  display: flex;
}

.btn-save{
  margin-left: 0!important;
}

.text-description{
  line-height: 160%;
  font-weight: 400;
  font-family: "Lexend";
  color: #6B7280;
}

.img-border-radius{
  border-radius: 10px;
}
</style>