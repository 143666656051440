<template>
  <div class="bg-white w-100 h-100 d-flex align-items-center justify-content-center">
    <div class="row row-cols-2 lds-roller">
      <div id="p3"></div>
      <div id="p4"></div>
      <div id="p2"></div>
      <div id="p5"></div>
      <div id="p1"></div>
    </div>
  </div>
</template>

<script>
function hexMix(colorFrom, colorTo, ratio) {
	const hex = function(x) {
		x = x.toString(16);
		return (x.length == 1) ? '0' + x : x;
	};
	let r = Math.ceil(parseInt(colorTo.substring(0, 2), 16) * ratio + parseInt(colorFrom.substring(0, 2), 16) * (1 - ratio)),
		g = Math.ceil(parseInt(colorTo.substring(2, 4), 16) * ratio + parseInt(colorFrom.substring(2, 4), 16) * (1 - ratio)),
		b = Math.ceil(parseInt(colorTo.substring(4, 6), 16) * ratio + parseInt(colorFrom.substring(4, 6), 16) * (1 - ratio));
	return hex(r) + hex(g) + hex(b);
}

function animate({duration, draw, timing}) {
  let start = performance.now();
  requestAnimationFrame(function animate(time) {
    let timeFraction = (time - start) / duration;
    if (timeFraction > 1) timeFraction = 1;
    let progress = timing(timeFraction)
    draw(progress);
    if (timeFraction < 1) {
      requestAnimationFrame(animate);
    }
  });
}

const animation = (bulb) => {
  try {
    let next = false;
    let elem = document.getElementById("p"+bulb);
    let reverse = (elem.style.background === "rgb(222, 222, 222)");
    if (bulb) {
      animate({
        duration: 250,
        timing(timeFraction) {
          return timeFraction;
        },
        draw(progress) {
          if (reverse) {
            elem.style.background = "#" + hexMix("DEDEDE", "FFA500", progress);
          }
          else {
            elem.style.background = "#" + hexMix("FFA500", "DEDEDE", progress);
          }
          if (progress>0.5 && !next) {
            next = true
            animation((bulb%5)+1)
          }
        }
      });
    }
  } catch (error) {
    // animation ended
  }
}

export default {

  name: "Spinner",

  mounted() {
    animation(1)
  },

  data () {

    return {

    }
  }

}
</script>

<style scoped>
.lds-roller {
  width: 3rem;
  height: 4.5rem;
  text-align: center;
}

.lds-roller div {
  content: " ";
  display: flex;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background: #FFA500;
  padding: 0;
  margin: 0.15rem;
}
</style>