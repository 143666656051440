<template>
  <architecture-base>

    <template v-slot:nav>
      <Sidebar active="dashboard" />
    </template>

    <template v-slot:header>
      <!-- Title -->
      <div class="title">Dashboard</div>

      <!-- Button tool bar -->
      <button-bar :bg-color2="'bg-blue'">

        <template v-slot:valueLabel2>
          <SVGCardAdd :fillColor="'#ffffff'"/>
          <a :href="'https://www.profylead.com/contact/'" target="_blank" rel="noreferrer">Order a new card</a>
        </template>

      </button-bar>
    </template>

    <template v-slot:main>

      <div class="d-flex w-auto row-perform-leaderboard">

        <div class="col-perform">

          <!-- Global performances -->
          <div class="col-global-perform">
            <global-perform
                :is-perform-spc="'global'"
                :title="'Global performances'"
                :line-datas="globalChartDatas">

            </global-perform>
          </div>

        </div>

        <div class="col-ranking">

          <!-- Top 5 Leaderboard -->
          <div class="col-top-leaderboard">
            <leader-board>
            </leader-board>
          </div>

          <!-- Button manage your team -->
          <Button v-on:click="redirToTeam" :textColor="'text-white'" :bgColor="'bg-blue'" class="border-radius-10 w-100" id="btn-manage-team">
            <template v-slot:label>
              <SVGUser />
              Manage your team
            </template>
          </Button>

        </div>

      </div>

      <div class="col-last-collected-leads bg-white border-radius-10">

        <!-- Last collected leads -->
        <layout-last-leads>
          <template v-slot:title>Last collected leads</template>

          <template v-slot:table>
            <table-last-leads :leads="sortedLeads"></table-last-leads>
          </template>
        </layout-last-leads>
      </div>

    </template>

  </architecture-base>

</template>

<script>
import StatisticsService from "../../../services/management/StatisticsService";
import ArchitectureBase from "../LayoutComponent/layout.base";
import Sidebar from "../LayoutComponent/layout.sidebar";
import Button from "../LayoutComponent/layout.button";
import SVGUser from "../../../assets/svgs/admin/svg.user";
import GlobalPerform from "../Performance/layout.performance";
import LeaderBoard from "../Leaderboard/layout.leaderboard";
import LayoutLastLeads from "../LastLeads/layout.last.leads";
import SVGCardAdd from "../../../assets/svgs/admin/svg.card.add";
import ButtonBar from "../LayoutComponent/layout.buttonbar";
import TableLastLeads from "../LastLeads/layout.table";
import { getManagerLeads } from "../../../services/LeadsService";

export default {

  name: "dashboard",

  components: {
    ArchitectureBase,
    Sidebar,
    Button,
    SVGUser,
    GlobalPerform,
    LeaderBoard,
    LayoutLastLeads,
    ButtonBar,
    SVGCardAdd,
    TableLastLeads,
  },

  data() {
    return {
      teams: [],
      leads: [],
      isSelecting: false,
      globalChartDatas: [],
    }
  },

  methods : {

    getGlobalViewsChart(){
      StatisticsService.getStatistics()
          .then((response) => {
            this.globalChartDatas = response.global_views_chart
            console.log(this.globalChartDatas)
          })
          .catch((error) => {
            console.log("error", error);
          })
    },

    redirToTeam(){
      this.$router.push('/equipe');
    },

    /**
     * Getter datas de leads
     */
    getTopTeams(){
      StatisticsService.getStatistics()
      .then((response) => {
        //Tirer un tableau en fonction du classement
        console.log(response.team_top)
        this.teams = response.team_top;
        console.log(this.teams);
      })
      .catch((error) => {
        console.log("error", error);
      })
    },

    /**
     * Getter datas de leads
     */
    getLastLeads(){
      getManagerLeads()
      .then((leads) => {
        console.log(leads);
        this.leads = leads;
      })
      .catch((error) => {
        console.log("error", error);
      })
    },

    /**
     * Permet de changer datas par rapport aux choix
     * @param event
     */
    selectTimeFrame(timeframe){
      this.filterFrom = timeframe;
    },
  },

  computed: {
    sortedLeads() {
      return [...this.leads].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).slice(0, 5)
    },

    displayedLeads() {
      return this.teams
    }
  },

  mounted() {
    this.getTopTeams();
    this.getLastLeads();
    this.getGlobalViewsChart();
  }
}
</script>

<style scoped>

.title{
  font-size: calc((48 / 1728) * (100vw - var(--size-2x)));
  line-height: 100%;
  font-weight: 600;
}

.row-perform-leaderboard{
  margin-bottom: calc((36 / 1728) * (100vw - var(--size-2x)));
}

.col-perform{
  height: 100%;
  width: calc(53% - calc((36 / 1728) * (100vw - var(--size-2x))));
  margin-right: calc((36 / 1728) * (100vw - var(--size-2x)));
}

.col-global-perform, .col-top-leaderboard, .col-last-collected-leads{
  background: #ffffff;
  border-radius: 10px;
  padding: calc((36 / 1728) * (100vw - var(--size-2x)));
}

.col-ranking{
  width: 47%;
  height: 100%;
}

.bg-blue{
  background: #0400BD;
}

.border-radius-10{
  border-radius: 10px;
}

#btn-manage-team{
  margin: calc((36 / 1728) * (100vw - var(--size-2x))) 0 0;
}
</style>