<template>
  <base-popup>

    <template v-slot:title>
      <div id="popup-title">Create a new template</div>
    </template>

    <template v-slot:body>

      <q-form
          ref="form"
          :model="formModel"
      >

        <!-- Input Reference number -->
        <q-form-item label="Template name" prop="label" class="text-start my-2">
          <q-input v-model="formModel.label" type="text" class="form-control" placeholder="Enter the new template's name"/>
        </q-form-item>

        <!-- Pin Code -->
        <q-form-item label="Color" prop="color" class="text-start my-2"> 
          <color-picker
              :is-big-picker="true"
              @onPick="(pickedColor) => {
                formModel.color = pickedColor.slice(1)
              }"
          ></color-picker>
        </q-form-item>

      </q-form>
    </template>

    <template v-slot:button-bar>
      <button-base v-on:click="createTemplate($emit)" :textColor="'text-white'" :bgColor="'bg-blue'" class="border-radius-28 margin-bottom-0-5x" id="btn-add-card">
        <template v-slot:label>
          Create template
        </template>
      </button-base>
    </template>

  </base-popup>


</template>

<script>
import BasePopup from "./BasePopup"
import { inject } from 'vue';
import { QMessageBoxAction } from '@qvant/qui-max';
import ButtonBase from "../../LayoutComponent/layout.button";
import { QForm, QInput, QFormItem } from "@qvant/qui-max";
import ColorPicker from "../../../app/Components/Pickers/ColorPicker.vue";

let qMessageBoxContainer = undefined;

export default {

  props: [],

  components: {
    ColorPicker,
    BasePopup,
    ButtonBase,
    QForm,
    QFormItem,
    QInput,
  },

  data() {
    return {
      formModel: {
        label: null,
        color: null
      },
    }
  },

  setup(){
    qMessageBoxContainer = inject(
        'qMessageBoxContainer'
    )

    return {
      qMessageBoxContainer,
    }
  },

  methods : {

    cancel: () => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.cancel
      });
    },

    createTemplate(emit){
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.confirm
      });
      let newTemplate = {
        label: this.formModel.label,
        color: this.formModel.color,
      }
      emit("createTemplate", newTemplate);
    }

  },

  mounted() {

  }
}
</script>

<style scoped>
#btn-add-card{
  width: 100%!important;
  margin-left: 0!important;
  margin-right: 0!important;
}

.border-radius-28{
  border-radius: 28px!important;
}
</style>