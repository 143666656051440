<template>
  <svg width="24" height="24" viewBox="0 0 24 24" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.85 12.75H16.15C13.49 12.75 12.25 11.51 12.25 8.85V5.15C12.25 2.49 13.49 1.25 16.15 1.25H18.85C21.51 1.25 22.75 2.49 22.75 5.15V8.85C22.75 11.51 21.51 12.75 18.85 12.75ZM16.15 2.75C14.31 2.75 13.75 3.31 13.75 5.15V8.85C13.75 10.69 14.31 11.25 16.15 11.25H18.85C20.69 11.25 21.25 10.69 21.25 8.85V5.15C21.25 3.31 20.69 2.75 18.85 2.75H16.15Z" />
    <path d="M7.85 22.75H5.15C2.49 22.75 1.25 21.51 1.25 18.85V15.15C1.25 12.49 2.49 11.25 5.15 11.25H7.85C10.51 11.25 11.75 12.49 11.75 15.15V18.85C11.75 21.51 10.51 22.75 7.85 22.75ZM5.15 12.75C3.31 12.75 2.75 13.31 2.75 15.15V18.85C2.75 20.69 3.31 21.25 5.15 21.25H7.85C9.69 21.25 10.25 20.69 10.25 18.85V15.15C10.25 13.31 9.69 12.75 7.85 12.75H5.15Z" />
    <path d="M14.9999 22.75C14.7299 22.75 14.4799 22.6 14.3499 22.37C14.2199 22.14 14.2199 21.85 14.3599 21.62L15.4099 19.87C15.6199 19.51 16.0799 19.4 16.4399 19.61C16.7999 19.82 16.9099 20.28 16.6999 20.64L16.4299 21.09C19.1899 20.44 21.2599 17.96 21.2599 15C21.2599 14.59 21.5999 14.25 22.0099 14.25C22.4199 14.25 22.7599 14.59 22.7599 15C22.7499 19.27 19.2699 22.75 14.9999 22.75Z" />
    <path d="M2 9.75C1.59 9.75 1.25 9.41 1.25 9C1.25 4.73 4.73 1.25 9 1.25C9.27 1.25 9.52 1.4 9.65 1.63C9.78 1.86 9.78 2.15 9.64 2.38L8.59 4.14C8.38 4.49 7.92 4.61 7.56 4.39C7.21 4.18 7.09 3.72 7.31 3.36L7.58 2.91C4.81 3.56 2.75 6.04 2.75 9C2.75 9.41 2.41 9.75 2 9.75Z" />
  </svg>
</template>

<script>
export default {

  props: ['fillColor'],

}
</script>

<style scoped>

</style>