<template>
  <svg width="20" height="33" viewBox="0 0 20 20" :fill="fillColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5001 18.9584H7.50008C2.97508 18.9584 1.04175 17.0251 1.04175 12.5001V7.50008C1.04175 2.97508 2.97508 1.04175 7.50008 1.04175H12.5001C17.0251 1.04175 18.9584 2.97508 18.9584 7.50008V12.5001C18.9584 17.0251 17.0251 18.9584 12.5001 18.9584ZM7.50008 2.29175C3.65841 2.29175 2.29175 3.65841 2.29175 7.50008V12.5001C2.29175 16.3417 3.65841 17.7084 7.50008 17.7084H12.5001C16.3417 17.7084 17.7084 16.3417 17.7084 12.5001V7.50008C17.7084 3.65841 16.3417 2.29175 12.5001 2.29175H7.50008Z" />
    <path d="M8.96662 13.6417C8.61662 13.6417 8.29162 13.5584 7.99995 13.3917C7.33328 13.0084 6.94995 12.2251 6.94995 11.2334V8.76675C6.94995 7.78342 7.33328 6.99175 7.99995 6.60841C8.66662 6.22508 9.53328 6.28342 10.3916 6.78342L12.5333 8.01675C13.3833 8.50841 13.875 9.23341 13.875 10.0001C13.875 10.7667 13.3833 11.4917 12.5333 11.9834L10.3916 13.2167C9.90828 13.5001 9.41662 13.6417 8.96662 13.6417ZM8.97495 7.60841C8.84162 7.60841 8.72495 7.63341 8.63328 7.69175C8.36662 7.85008 8.20828 8.24175 8.20828 8.76675V11.2334C8.20828 11.7584 8.35828 12.1501 8.63328 12.3084C8.89995 12.4667 9.31662 12.4001 9.77495 12.1334L11.9166 10.9001C12.375 10.6334 12.6333 10.3084 12.6333 10.0001C12.6333 9.69175 12.375 9.35841 11.9166 9.10008L9.77495 7.86675C9.47495 7.69175 9.19995 7.60841 8.97495 7.60841Z" />
  </svg>
</template>

<script>
export default {
  props: ['fillColor']
}
</script>

<style scoped>

</style>