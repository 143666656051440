<template>
  <svg width="21" height="21" viewBox="0 0 25 24" fill="currentColor" class="me-3" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.0722 0.779984C9.64504 -0.233261 15.3549 -0.233261 20.9278 0.779984C23.1351 1.18132 24.7396 3.1038 24.7396 5.34732V14.0295C24.7396 17.022 22.0992 19.3267 19.1342 18.9224C17.1916 18.6575 15.2371 18.5095 13.2812 18.4783V21.4833C13.9316 21.5288 14.579 21.6312 15.2159 21.7904L16.8561 22.2005C17.2747 22.3051 17.5292 22.7293 17.4246 23.1479C17.3199 23.5665 16.8958 23.821 16.4772 23.7163L14.8369 23.3062C14.0697 23.1145 13.2849 23.0186 12.5 23.0186C11.7151 23.0186 10.9302 23.1145 10.1631 23.3062L8.52281 23.7163C8.10422 23.821 7.68005 23.5665 7.5754 23.1479C7.47075 22.7293 7.72525 22.3051 8.14384 22.2005L9.7841 21.7904C10.421 21.6312 11.0684 21.5288 11.7187 21.4833V18.4783C9.76285 18.5095 7.80835 18.6575 5.86575 18.9224C2.90075 19.3267 0.260406 17.022 0.260406 14.0295V5.34732C0.260406 3.1038 1.86487 1.18132 4.0722 0.779984Z" :fill="fillDashboard"/>
  </svg>
</template>

<script>
export default {

  name: "svg.dashboard",

  props: ['fillDashboard'],
}
</script>

<style scoped>

</style>