<template>
  <base-popup>

    <template v-slot:svg-left>
      <SVGEdit />
    </template>

    <template v-slot:svg-right>
      <span class="row align-items-center" v-on:click="cancel">
         <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#0400BD"/>
          <path d="M9.16999 15.5799C8.97999 15.5799 8.78999 15.5099 8.63999 15.3599C8.34999 15.0699 8.34999 14.5899 8.63999 14.2999L14.3 8.63986C14.59 8.34986 15.07 8.34986 15.36 8.63986C15.65 8.92986 15.65 9.40986 15.36 9.69986L9.69998 15.3599C9.55998 15.5099 9.35999 15.5799 9.16999 15.5799Z" fill="#0400BD"/>
          <path d="M14.83 15.5799C14.64 15.5799 14.45 15.5099 14.3 15.3599L8.63999 9.69986C8.34999 9.40986 8.34999 8.92986 8.63999 8.63986C8.92999 8.34986 9.40998 8.34986 9.69998 8.63986L15.36 14.2999C15.65 14.5899 15.65 15.0699 15.36 15.3599C15.21 15.5099 15.02 15.5799 14.83 15.5799Z" fill="#0400BD"/>
        </svg>
      </span>
    </template>

    <template v-slot:title>
      Do you want to modify  or delete this picture ?
    </template>

    <template v-slot:body>

      <div class="margin-bottom-1x">
        <input ref="updateImg" type="file" @change="uploadImage" accept="image/*" hidden>

        <template v-if="image">
          <div class="img-container margin-bottom-1x" v-bind:style="{ 'background-image': `url(${ this.image })` }"></div>
        </template>
        <q-button
            v-on:click="changePicture()"
            theme="secondary"
            class="btn btn-save p-0 m-0 margin-bottom-0-5x w-100 bg-blue"
        >
            <span class="text-white">
              Change this picture
            </span>
        </q-button>

      </div>

      <div class="left-inner-addon input-container">
        <i class="icon">
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.49161 18.1393C6.10828 18.1393 4.71661 17.6143 3.65828 16.5559C1.54994 14.4393 1.54994 11.0059 3.65828 8.89759C3.89994 8.65592 4.29994 8.65592 4.54161 8.89759C4.78328 9.13926 4.78328 9.53926 4.54161 9.78092C2.91661 11.4059 2.91661 14.0476 4.54161 15.6726C6.16661 17.2976 8.80828 17.2976 10.4333 15.6726C11.2166 14.8893 11.6499 13.8393 11.6499 12.7226C11.6499 11.6143 11.2166 10.5643 10.4333 9.77259C10.1916 9.53092 10.1916 9.13092 10.4333 8.88926C10.6749 8.64759 11.0749 8.64759 11.3166 8.88926C12.3416 9.91426 12.8999 11.2726 12.8999 12.7226C12.8999 14.1726 12.3333 15.5309 11.3166 16.5559C10.2666 17.6143 8.88328 18.1393 7.49161 18.1393Z" fill="#9CA3AF"/>
            <path d="M15.8916 12.0227C15.7333 12.0227 15.5749 11.9644 15.4499 11.8394C15.2083 11.5977 15.2083 11.1977 15.4499 10.956C17.1583 9.24769 17.1583 6.47269 15.4499 4.77269C13.7416 3.06436 10.9666 3.06436 9.26661 4.77269C8.44161 5.59769 7.98328 6.69769 7.98328 7.86436C7.98328 9.03102 8.44161 10.131 9.26661 10.956C9.50828 11.1977 9.50828 11.5977 9.26661 11.8394C9.02494 12.081 8.62494 12.081 8.38328 11.8394C7.32494 10.781 6.73328 9.36436 6.73328 7.86436C6.73328 6.36436 7.31661 4.94769 8.38328 3.88936C10.5749 1.69769 14.1416 1.69769 16.3416 3.88936C18.5333 6.08102 18.5333 9.65602 16.3416 11.8477C16.2166 11.9644 16.0499 12.0227 15.8916 12.0227Z" fill="#9CA3AF"/>
          </svg>
        </i>
        <input v-model="link" type="text" class="form-control text-description" :placeholder="link" />
      </div>

      <div class="left-inner-addon input-container margin-bottom-1x">
        <i class="icon">
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.775 7.16647C17.4333 7.16647 17.15 6.88314 17.15 6.54147V5.0248C17.15 4.41647 16.6583 3.9248 16.05 3.9248H3.94998C3.34164 3.9248 2.84998 4.41647 2.84998 5.0248V6.5498C2.84998 6.89147 2.56664 7.1748 2.22498 7.1748C1.88331 7.1748 1.59998 6.89147 1.59998 6.54147V5.0248C1.59998 3.7248 2.65831 2.6748 3.94998 2.6748H16.05C17.35 2.6748 18.4 3.73314 18.4 5.0248V6.5498C18.4 6.89147 18.125 7.16647 17.775 7.16647Z" fill="#9CA3AF"/>
            <path d="M10 18.4579C9.65833 18.4579 9.375 18.1745 9.375 17.8329V3.99121C9.375 3.64954 9.65833 3.36621 10 3.36621C10.3417 3.36621 10.625 3.64954 10.625 3.99121V17.8329C10.625 18.1829 10.3417 18.4579 10 18.4579Z" fill="#9CA3AF"/>
            <path d="M13.2833 18.458H6.71668C6.37501 18.458 6.09167 18.1747 6.09167 17.833C6.09167 17.4913 6.37501 17.208 6.71668 17.208H13.2833C13.625 17.208 13.9083 17.4913 13.9083 17.833C13.9083 18.1747 13.625 18.458 13.2833 18.458Z" fill="#9CA3AF"/>
          </svg>
        </i>
        <input v-model="title" type="text" class="form-control text-description" :placeholder="title" />
      </div>

    </template>

    <template v-slot:button-bar>
      <q-button
          v-on:click="deletePicture($emit, this.idToDelete)"
          theme="secondary"
          class="btn p-0 mt-0 margin-bottom-0-5x w-100 bg-red"
      >
          <span class="text-white">
            Delete this picture
          </span>
      </q-button>

      <q-button
          v-on:click="exchange($emit, this.idToDelete, this.link, this.title, this.image)"
          theme="secondary"
          class="btn btn-save p-0 mx-0 mt-0 margin-bottom-0-5x w-100 bg-blue"
      >
          <span class="text-white">
            Save the modifications
          </span>
      </q-button>
    </template>

  </base-popup>

  <!-- image cropper dialogue -->
  <image-cropper-dialog
      ref="cropperDialog"
      aspectRatio="cropPicture"
      :uploadedImage="this.imageToCrop"
      @onCrop="(croppedImage) => {
        this.image = croppedImage
      }"
  />

</template>

<script>
import BasePopup from "./BasePopup";
import { inject } from 'vue';
import { QMessageBoxAction, QButton } from '@qvant/qui-max';
import SVGEdit from "../../../../assets/svgs/app/svg.gallery-edit-circle";
import ImageCropperDialog from '../../GestionCrop/image.cropper.vue';

let qMessageBoxContainer = undefined;

export default {

  props: [ 'photo' ],

  components: {
    QButton,
    BasePopup,
    SVGEdit,
    ImageCropperDialog,
  },

  data() {
    return {
      title: null,
      link: null,
      image: null,
      idToDelete: null,
      imageToCrop: null,
    }
  },

  setup: () => {
    let infos = "";

    qMessageBoxContainer = inject(
        'qMessageBoxContainer'
    );

    return {
      qMessageBoxContainer,
      infos,
    }
  },

  methods : {

    cancel: () => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.cancel
      });
    },

    exchange: (emit, id, link, title, image) => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.confirm
      });
      emit("save", {id, link, title, image});
    },

    deletePicture: (emit, id) => {
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.confirm
      });
      emit("delete", {id});
    },

    async uploadImage(event) {
      if (!event) return;
      let file = event.target.files[0];
      let maxAllowedSize = 20 * 1024 * 1024; // 1Mb = 1024 * 1024
      if(file.size > maxAllowedSize) { // supérieur à 20Mb
        event.target.value = "";
      }else{
        event.target.value = "";
        this.imageToCrop = await this.toBase64(file)
        this.$refs.cropperDialog.initCropper(file.type);
        this.showPopup();
      }
    },

    /**
     * afficher Popup
     */
    showPopup(){
      document.querySelector(".d-modal").style.display = "block";
    },

    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },

    changePicture(){
      this.$refs.updateImg.click()
    },

  },

  mounted() {
    this.link = this.photo.link;
    this.title = this.photo.title;
    this.idToDelete = this.photo.id;
    this.image = this.photo.img;
  }

}
</script>

<style scoped>
.btn{
  width: calc(100vw - var(--size-5x));
  height: var(--size-3x);
  align-items: center;
  justify-content: center;
  display: flex;
}

.bg-red{
  background: #EC0000;
}

.bg-blue {
  background-color: #0400BD;
}

.btn-save{
  margin-left: 0!important;
}

.text-description{
  line-height: 160%;
  font-weight: 400;
  font-family: "Lexend";
  color: #6B7280;
}

.img-border-radius{
  border-radius: 10px;
}

.img-container{
  border-radius: 10px;
  background-size: cover;
  background-position: center center;
  height: calc((65/390) * (100vw - var(--size-2x)));
}
</style>