<template>
    <div class="padding-top-1x padding-bottom-1x padding-left-1x padding-right-1x">
        <div class="modal-body text-center">
            <svg class="icon" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="4" width="48" height="48" rx="24" fill="#D6D6FF"/>
                <path d="M29 18L38 27L29 35.5V30C22 30 19 37.5 19 37.5C19 29 21.5 23.5 29 23.5V18Z" stroke="#0400BD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F4F3FF" stroke-width="8"/>
            </svg>
            
            <p class="text-black text-bold">
                Share you contact with {{ name }}
            </p>
            
            <div>
                <div class="left-inner-addon input-container">
                    <i class="icon">
                        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.99996 9.16667C11.8409 9.16667 13.3333 7.67428 13.3333 5.83333C13.3333 3.99238 11.8409 2.5 9.99996 2.5C8.15901 2.5 6.66663 3.99238 6.66663 5.83333C6.66663 7.67428 8.15901 9.16667 9.99996 9.16667Z" stroke="#9CA3AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5 17.5V15.8333C5 14.9493 5.35119 14.1014 5.97631 13.4763C6.60143 12.8512 7.44928 12.5 8.33333 12.5H11.6667C12.5507 12.5 13.3986 12.8512 14.0237 13.4763C14.6488 14.1014 15 14.9493 15 15.8333V17.5" stroke="#9CA3AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </i>
                    <input type="text"
                        class="form-control" 
                        placeholder="Full Name" 
                        v-model="contact.full_name" />
                </div>

                <div class="left-inner-addon input-container">
                    <i class="icon">
                        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.8333 4.16699H4.16667C3.24619 4.16699 2.5 4.91318 2.5 5.83366V14.167C2.5 15.0875 3.24619 15.8337 4.16667 15.8337H15.8333C16.7538 15.8337 17.5 15.0875 17.5 14.167V5.83366C17.5 4.91318 16.7538 4.16699 15.8333 4.16699Z" stroke="#9CA3AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M2.5 5.83398L10 10.834L17.5 5.83398" stroke="#9CA3AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </i>
                    <input type="text"
                        class="form-control" 
                        placeholder="Email" 
                        v-model="contact.email" />
                </div>
                
                <div class="input-container">
                  <vue-tel-input :value="contact.phone" @input="updatePhone" mode="international" id="tel-country-codes"></vue-tel-input>
                </div>
            </div>

            <div class="">
                <q-button
                    v-on:click="exchange"
                    theme="secondary"
                    class="btn p-0 mt-0 margin-bottom-0-5x w-100 bg-blue"
                >
                    <span class="text-white">
                        Exchange Contact
                    </span>
                </q-button>

                <q-button
                    v-on:click="cancel"
                    theme="secondary"
                    class="btn p-0 mt-0 mb-0 w-100 cancel-btn"
                >
                    <span class="">
                        Cancel
                    </span>
                </q-button>
            </div>
        </div>
    </div>
</template>

<script>
import { inject } from 'vue';
import { QMessageBoxAction } from '@qvant/qui-max';
import { VueTelInput } from 'vue3-tel-input';

let qMessageBoxContainer = undefined;

export default {
    name: "popup.shareContact",

    props: [ 'name' ],

    components: {
      VueTelInput,
    },

    setup: () => {
        let contact = {
            full_name: "",
            phone: "",
            email: "",
        };

        qMessageBoxContainer = inject(
            'qMessageBoxContainer'
        );

        return {
            qMessageBoxContainer,
            contact,
        }
    },

    methods : {
      updatePhone(params) {
        if (typeof params == "string") {
          this.contact.phone = params;
        }
      },

      cancel() {
        qMessageBoxContainer?.emitDoneEvent({
            action: QMessageBoxAction.cancel
        });
      },
      exchange() {
        this.qMessageBoxContainer?.emitDoneEvent({
            action: QMessageBoxAction.confirm
        });

        console.log(this.contact)
        this.$emit("exchange", this.contact);
      }
    },
}
</script>

<style scoped>
.bg-orange {
    background-color: #FF9000;
}

.bg-blue {
    background-color: #0400BD;
}

.cancel-btn {
    border: 1px solid #D1D5DB;
}

.btn{
    width: calc(100vw - var(--size-5x));
    height: var(--size-3x);
    align-items: center;
    justify-content: center;
    display: flex;
}
</style>