<template>
  <LastLeads>
    <template v-slot:header>
      <h1 class="font-size text-start m-0">
        <slot name="title"></slot>
      </h1>
      <slot name="legendbar"></slot>
      <slot name="btnbar"></slot>
    </template>

    <template v-slot:table>
      <slot name="table"></slot>
    </template>
  </LastLeads>

</template>

<script>
import LastLeads from "./layout.base";

export default {

  components: {
    LastLeads,
  },

}
</script>

<style scoped>
*{
  font-family: Poppins;
}

.font-size {
  font-size: calc((33 / 1820) * (100vw - var(--size-2x)));
  line-height: calc((33 / 1820) * (100vw - var(--size-2x)));
  font-weight: 600;
}

</style>