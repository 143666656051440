<template>

  <div class="col-top-teams">
    <div class="sub-title">{{ this.title }}</div>
    <div class="bg-white border-radius-10">
      <leader-board>

        <template v-slot:title></template>

        <template v-slot:template>
          <q-form-item label="Team" prop="template" class="text-start padding-top">
            <button-selector :options="options" :value="options[0]" v-on:changeOption="selectTemplateFrame"></button-selector>
          </q-form-item>
        </template>

        <template v-slot:legendbar>
          <LegendBar class="col-legend-bar" v-on:changeOption="selectTimeFrame" />
        </template>

        <template v-slot:table>
          <Table
              ref="leadsTable"
              :roleTable="'moduleTeams'"
              :rankings="displayedLeads"
              :isSelecting="isSelecting"
          />
        </template>

      </leader-board>
    </div>
  </div>

</template>

<script>
import LeaderBoard from "../Leaderboard/layout.base";
import LegendBar from "../LayoutComponent/layout.legendbar";
import Table from "../Leaderboard/layout.table";
import ButtonSelector from "../Performance/layout.buttonselector";
import StatisticsService from "../../../services/management/StatisticsService";

export default {

  props: ['title'],

  components: {
    LegendBar,
    LeaderBoard,
    Table,
    ButtonSelector,
  },

  data() {
    return {
      filterFrom: null,
      isSelecting: false,
      teams: [],
      template_role: null,
      options: [],
    }
  },

  methods : {
    getTopTeams(){
      StatisticsService.getStatistics()
      .then((response) => {
        //Tirer un tableau en fonction du classement
        this.teams = response.team_top;
        this.options = this.teams.map(section => section.template);
        this.template_role = 0;
      })
      .catch((error) => {
        console.log("error", error);
      })
    },

    /**
     * Permet de changer datas par rapport aux choix
     * @param event
     */
    selectTimeFrame(timeframe){
      this.filterFrom = timeframe;
    },

    selectTemplateFrame(keyOption){
      this.template_role = keyOption;
    },

  },

  computed: {
    displayedLeads() {
      let selectedTF = '1D';
      Object.keys(StatisticsService.choices).forEach(k => {
        if (StatisticsService.choices[k] == this.filterFrom) selectedTF = k;
      });
      console.log(this.template_role != null ? this.teams[this.template_role][selectedTF] : [])
      return this.template_role != null ? this.teams[this.template_role][selectedTF] : [];
    }
  },

  created() {
    this.getTopTeams();
  }

}
</script>

<style scoped>
.sub-title{
  font-family: "Poppins";
  font-size: calc((32 / 1728) * (100vw - var(--size-2x)));
  line-height: 100%;
  font-weight: 600;
  color: #000000;
}

.col-top-teams{
  border-radius: 10px;
  padding: calc((36 / 1728) * (100vw - var(--size-2x)));
  margin-bottom: calc((36 / 1728) * (100vw - var(--size-2x)));
  background: #FFFFFF;
}

.padding-top{
  padding-top: calc((36 / 1728) * (100vw - var(--size-2x)));
}

.col-legend-bar{
  padding: calc((36 / 1728) * (100vw - var(--size-2x))) 0;
}
</style>