<template>

  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
    <div class="col-last-leads w-100 d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center bg-white border-radius-10">
      <slot name="header"></slot>
    </div>
  </div>

  <slot name="table"></slot>

</template>

<script>
export default {

}
</script>

<style scoped>
.col-last-leads{
  padding-bottom: calc((36 / 1728) * (100vw - var(--size-2x)));
}
</style>