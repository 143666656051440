<template>
  <base-popup>

    <template v-slot:svg-left>
      <SVGFeature />
    </template>

    <template v-slot:svg-right>
      <span class="row align-items-center" v-on:click="cancel">
        <SVGClose />
      </span>
    </template>

    <template v-slot:title>
      Do you want to edit this sticker ?
    </template>

    <template v-slot:body>
      <color-picker
          :is-big-picker="false"
          :colorDefault="'#' + this.color"
          @onPick="(pickedColor) => {
            this.color = pickedColor.slice(1);
          }"
      ></color-picker>

      <div class="left-inner-addon input-container margin-top-1x">
        <i class="icon">
          <SVGText :fill-color="'#9CA3AF'"/>
        </i>
        <input v-model="name" type="text" class="form-control text-description" :placeholder="'label'"/>
      </div>
    </template>

    <template v-slot:button-bar>
      <q-button
          v-on:click="save()"
          theme="secondary"
          class="btn p-0 mx-0 mt-0 margin-bottom-0-5x w-100 bg-blue"
      >
          <span class="text-white">
            Save the modifications
          </span>
      </q-button>

      <q-button
          v-on:click="delSticker()"
          theme="secondary"
          class="btn p-0 btn-height w-100 bg-red"
          id="btn-del-sticker"
      >
          <span class="text-white">
            Delete this sticker
          </span>
      </q-button>

    </template>

  </base-popup>

</template>

<script>
import { inject } from 'vue';
import BasePopup from "./BasePopup"
import { QMessageBoxAction, QButton } from '@qvant/qui-max';
import SVGText from "../../../../assets/svgs/app/svg.text-small";
import SVGClose from "../../../../assets/svgs/app/svg.close-popup";
import SVGFeature from "../../../../assets/svgs/app/svg.featured-icon";
import ColorPicker from "../../Components/Pickers/ColorPicker";
import DeleteStickerPopup from '../../Components/Popups/DeleteStickerPopup';
import { useMessageBox } from "@qvant/qui-max";

let qMessageBoxContainer = undefined;

export default {

  props: [ 'tag' ],

  components: {
    BasePopup,
    SVGText,
    SVGClose,
    SVGFeature,
    ColorPicker,
    QButton,
  },

  data() {
    return {
      id: null,
      color: null,
      name: null,
    }
  },

  setup: () => {
    qMessageBoxContainer = inject(
        'qMessageBoxContainer'
    );

    return {
      qMessageBoxContainer,
    }
  },

  methods : {

    cancel(){
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.cancel
      });
    },

    save(){
      qMessageBoxContainer?.emitDoneEvent({
        action: QMessageBoxAction.confirm
      });
      const savedData = {
        id: this.id,
        name: this.name,
        color: this.color,
      };
      this.$emit("save", savedData);
    },

    async delSticker(){
      try {
        let messageBox = useMessageBox();
        const result = await messageBox(
            {
              component: DeleteStickerPopup,
              props: {
                tag: this.tag,
              },
              listeners: {
                delete: (id) => {
                  this.$emit('onDelete', id);
                },
              }
            },
        );
        console.log('resolve', result);
        await qMessageBoxContainer?.emitDoneEvent({
          action: QMessageBoxAction.confirm
        });
      } catch (result) {
        console.log('reject', result);
      }
    },
  },

  mounted(){
    this.id = this.tag.id;
    this.name = this.tag.name;
    this.color = this.tag.color;
  }

}
</script>

<style scoped>
.btn{
  height: var(--size-3x);
}

#btn-del-sticker{
  margin: 0!important;
}

.form-control{
  font-size: var(--size-1x)!important;
}

.bg-blue {
  background-color: #0400BD;
}

.bg-red{
  background: #EC0000;
}

.text-description{
  line-height: 150%;
  font-weight: 300;
  font-family: "Lexend";
  color: #4B5563!important;
}

</style>